
import React, { Component } from "react"
import { ListView } from "antd-mobile"
import HeaderView from "../../component/header/headerView"
import ss from "./css/index.module.css"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import grayRightIcon from "../../image/gray_right_icon.png"
class Order extends Component {
    constructor(props) {
        super(props)
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
        })
        this.state = {
            actived:0,
            dataSource,
            pageNo: 1,
            pageSize: 2,
            hasMore: true,
            refreshing: true,
            isLoading: true,
            dataArr: [],
            totalPage: 1,
        }
    }
    componentDidMount(){
        this.handleGetOrder()
    }
    handFilter(type){
        this.setState({
            actived:type
        })
    }
    // 总接口
    handleGetOrder = () => {
        if (this.state.pageNo > this.state.totalPage) {
            this.setState({
                refreshing: false,
                isLoading: false,
                hasMore: false,
            })
            return false
        }
        this.handleGetInventoryOrder() //库存
    }
    // 获取库存订单
    handleGetInventoryOrder(ref = false) {
        util.getYangAxios(
            api.inventoryOrder,
            {
                currentPage: this.state.pageNo,
            },
            async (res) => {
                const dataList = res.list
                this.setState({
                    totalPage: res.totalPage,
                })
                // 这里表示上拉加载更多
                // 合并state中已有的数据和新增的数据
                var dataArr = this.state.dataArr.concat(dataList) //关键代码
                this.setState({
                    pageNo: this.state.pageNo,
                    dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
                    refreshing: false,
                    isLoading: false,
                    dataArr: dataArr, // 保存新数据进state
                })
            },
            (err) => {
                console.log(err)
            }
        )
    }
    // 滑动到底部时加载更多
    onEndReached = (event) => {
        // 加载中或没有数据了都不再加载
        if (this.state.isLoading || !this.state.hasMore) {
            return
        }
        this.setState(
            {
                isLoading: true,
                pageNo: this.state.pageNo + 1, // 加载下一页
            },
            () => {
                this.handleGetOrder()
            }
        )
    }
     // 订单文字渲染
  handleOrderTextRender = (title, content, isLook = false) => {
    return (
      <div className={[`${ss.row_align}`,`${ss.order_text_div}`].join(' ') } style={{ width: "100%" }}>
        <div className={ss.row_align}>
          <div className={ss.order_title_text}>{title}</div>
          <div className={ss.order_content_text}>{content}</div>
        </div>
        {isLook && (
          <div className={[`${ss.order_look_text}`,`${ss.row_end}`].join(' ')}>
            查看
            <img src={grayRightIcon} alt="" className={ss.gray_right_icon} />
          </div>
        )}
      </div>
    )
  }
    // 渲染消息
    handleMsgRender = (title, num) => {
        let row
        row = (item, sectionID, index) => {
            // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
            return (
              <>
                <div
                  className={"order_card"}
                  key={index}
                  onClick={() => this.handleSkipDetails(item, num)}
                >
                  {this.handleOrderTextRender("订单号：", item.order_sn, true)}
                  {this.handleOrderTextRender("产品名称：", item.product_name)}
                  {this.handleOrderTextRender("产品数量：", item.number)}
                  {this.handleOrderTextRender("产品金额：", item.total_price)}
                  {this.handleOrderTextRender("订货方：", item.agent_name)}
                  {this.handleOrderTextRender("发货方：", item.sale_agent_name)}
                  {this.handleOrderTextRender("订单时间：", item.created_at)}
                </div>
                <div className={"order_card_line"} />
              </>
            )
          }
        return (
            <div>
                <ListView
                    contentContainerStyle={{ backgroundColor: "#F6F7FB" }}
                    ref={(el) => (this.lv = el)}
                    dataSource={this.state.dataSource}
                    renderFooter={() => (
                        <div className="footer">
                            {this.state.isLoading ? "加载中..." : "暂无更多数据"}
                        </div>
                    )}
                    renderRow={row}
                    useBodyScroll
                    onEndReachedThreshold={10}
                    onEndReached={this.onEndReached}
                    pageSize={10}
                />
            </div>
        )
    }
    render() {
        const { actived }=this.state
        return (
            <div  className={ss.order_container}>  
                    <HeaderView title={'订单中心'} />
                    <div className={ss.order_line} />
                    <div className={ss.order_title}>订单汇总</div>
                    <div className={ss.order_total_container}>
                        <div className={ss.order_total_info}>
                            <div className={ss.order_number}>56</div>
                            <div className={ss.order_price}>¥ 2.3万</div>
                            <div className={ss.order_total_txt}>总订单</div>
                        </div>
                        <div className={ss.order_total_info}>
                            <div className={ss.order_number}>56</div>
                            <div className={ss.order_price}>¥ 2.3万</div>
                            <div className={ss.order_total_txt}>直接订单</div>
                        </div>
                        <div className={ss.order_total_info}>
                            <div className={ss.order_number}>56</div>
                            <div className={ss.order_price}>¥ 2.3万</div>
                            <div className={ss.order_total_txt}>间接订单</div>
                        </div>
                    </div>
                    <div className={ss.order_title}>订单明细</div>
                    <div className={ss.filter_container}>
                        <div className={[`${ss.filter_info}`,`${actived===0?ss.filter_active:''}`].join(' ')} onClick={()=>{this.handFilter(0)}}>直属订单</div>
                        <div className={[`${ss.filter_info}`,`${actived===1?ss.filter_active:''}`].join(' ')} onClick={()=>{this.handFilter(1)}}>小店订单</div>
                    </div>
                    {this.handleMsgRender("订单中心", 1)}
            </div>
        )
    }
}

export default Order