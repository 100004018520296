import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet, tabSetIndex } from "../../actions/XggsUserInformation"
import {  Toast, Modal,  } from "antd-mobile"
import { Modal as MeModal, Upload, Radio, Checkbox } from "antd"
import { PlusOutlined } from "@ant-design/icons"
import JpModal from "component/jpModal/jpModal"
import ss from "./css/cashMoney.module.css"
import "./css/uploadImg.css"
import { util,  } from "common/util"
import { api } from "common/Config"
import { isNull } from "utils/isNull"
import AddNewcard from "image/add_newcard.png"
import grayRight from "image/gray_right_icon.png"
import backBlackIcon from "../../image/back_black_icon.png"

import XieYi from "component/xieyi/xieyi"

class CashMoney extends Component {
  bagInputRef = React.createRef()
  bankInputRef = React.createRef()
  constructor(props) {
    super(props)
    this.state = {
      isReload: true, //是否刷新
      wantBagMoney: "",
      cardModal: false,
      jpShow: true,
      medata: {
        jpShow: true,
      },
      isMoneyBag: null,
      tipShow: false,
      wantBankMoney: "",
      isMemodalVisible: false,
      bagHasMoney: "", //钱包余额
      bankCardList: [],
      bankInfo: "",
      files: [],
      qiToken: "",
      pointPwd: [
        {
          name: "",
        },
        {
          name: "",
        },
        {
          name: "",
        },
        {
          name: "",
        },
        {
          name: "",
        },
        {
          name: "",
        },
      ],
      pointCount: 0,
      all_service_money: "0.00",
      six_service_money: "0.00",
      radioValue: JSON.parse(sessionStorage.getItem("range")).type == 1 ? 1 :3,
      iszc_Click: null,
      istx_Click: null,
      xieYiModal: false,
      xieYiChecked: false,
      hefubao: null,
      hasMoney: "",
      djMoney: "",
    }
  }
  render() {
    const { userData } = this.props.UserDataReducer
    return (
      <div id="cashMoney" className={`${"gray_input"} ${ss.cashMoney}`}>
        {this.cashMoneyDom()}
        <Modal
          className={"xieyi_modal_view"}
          visible={this.state.xieYiModal}
          transparent
          maskClosable={false}
          onClose={() => {
            this.setState({ xieYiModal: false })
          }}
          title="自由职业者服务协议"
          footer={[
            {
              text: "取消",
              onPress: () => {
                this.setState({ xieYiModal: false, xieYiChecked: false })
              },
            },
            {
              text: "确定",
              onPress: () => {
                if (!this.state.xieYiChecked) {
                  Toast.info("请先仔细阅读协议并同意", 1.5)
                } else {
                  this.postHeFuBao()
                }
              },
            },
          ]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <div
            style={{
              overflow: "scroll",
              paddingBottom: "20px",
              textAlign: "left",
              color: "#333",
            }}
          >
            <XieYi name={userData.name}></XieYi>
            <Checkbox
              onChange={() => {
                this.setState({
                  xieYiChecked: !this.state.xieYiChecked,
                })
              }}
            >
              点击按钮即表示你同意并愿意遵守协议
            </Checkbox>
          </div>
        </Modal>
      </div>
    )
  }

  componentDidMount() {
   
    this.init()
    // new Vconsole()
  }

  init = () => {
    // console.log("this.props.location", this.props.location)
    this.getuserType()
    this.handleGetQiNiu()
    this.getEnterBagType()
    this.handleGetChannel({ mode: 1 })

    this.props.location.state &&
      this.props.location.state.radioValue &&
      this.setState({
        radioValue: this.props.location.state.radioValue,
      })
  }

  getEnterBagType = () => {
    this.setState(
      {
        isMoneyBag: this.props.location.state.isMoneyBag ?? false,
      },
      () => {
        this.state.isMoneyBag && this.getBagMoney()
        this.state.isMoneyBag && this.getBankCardList()
        document.title=!this.state.isMoneyBag ? "提现" : "转出"
      }
    )
  }

  getuserType = () => {
    this.setState({
      userType: JSON.parse(sessionStorage.getItem("range")).type,
    })
  }
  // 商家账户统计
  handleGetChannel = (data) => {
    util.getYangAxios(
      api.agentsIncome,
      data,
      async (res) => {
        this.setState(
          {
            hasMoney: Number(res.surplus),
            djMoney: Number(res.freezeBalance),
          },
          () => {
            if (Number(this.state.hasMoney) == 0) {
              this.setState({
                istx_Click: false,
              })
            } else {
              this.setState({
                istx_Click: true,
              })
            }
          }
        )
      },
      (err) => {
        console.log(err)
      },
      () => {},
      true
    )
  }
  // 商家账户统计
  // handleGetChannel = (data) => {
  //   util.getYangAxios(
  //     api.accountstatis,
  //     data,
  //     async (res) => {
  //       this.setState(
  //         {
  //           hasMoney: Number(res.agent.balance),
  //           djMoney: Number(res.agent.freeze_balance),
  //         },
  //         () => {
  //           if (Number(this.state.hasMoney) == 0) {
  //             this.setState({
  //               istx_Click: false,
  //             })
  //           } else {
  //             this.setState({
  //               istx_Click: true,
  //             })
  //           }
  //         }
  //       )
  //     },
  //     (err) => {
  //       console.log(err)
  //     },
  //     () => {},
  //     true
  //   )
  // }

  getBagMoney = () => {
    Toast.loading("加载中", 0)
    util.getYangAxios(
      api.getBagMoney,
      {},
      async (res) => {
        if (res.rspCode && res.rspCode == "00000") {
          this.setState(
            {
              bagHasMoney: res.avlblAmt / 100,
              iszc_Click: true,
            },
            () => {
              Toast.hide()
            }
          )
        } else {
          this.setState(
            {
              bagHasMoney: "0.00",
              iszc_Click: false,
            },
            () => {
              Toast.hide()
              Toast.info(res.rspResult, 1)
            }
          )
        }
      },
      (err) => {
        console.error(err)
      },
      () => {},
      true
    )
  }

  getBankCardList = () => {
    util.getYangAxios(
      api.bankCardListL,
      {},
      async (res) => {
        // console.log(res, "res")
        this.setState({ bankCardList: res })
      },
      (err) => {
        console.error(err)
      },
      () => {},
      true
    )
  }

  postHeFuBao = () => {
    util.postYangAxios(
      api.hefubao,
      {},
      async (res) => {
        // console.log(res, "res---post")
        this.setState({
          xieYiModal: false,
          hegubao: true,
        })
      },
      (err) => {
        console.error(err)
      },
      () => {}
    )
  }

  cashRadioChange = (e) => {
    this.setState({
      radioValue: e.target.value,
    })
  }

  goBackF = () => {
    this.props.history.push({
      pathname: "cashBag",
      state: {
        ...this.props.location.state,
      },
    })
  }

  cashMoneyDom = () => {
    const {
      wantBagMoney,
      cardModal,
      jpShow,
      isMoneyBag,
      tipShow,
      wantBankMoney,
      bankTipShow,
      isMemodalVisible,
      bagHasMoney,
      bankCardList,
      bankInfo,
      files,
      pointPwd,
      all_service_money,
      six_service_money,
      qiToken,
      radioValue,
      hasMoney,
      djMoney,
      istx_Click,
      iszc_Click,
    } = this.state
    const userType = JSON.parse(sessionStorage.getItem("range")).type
    return (
      <>
        <div className={ss.posiNull}>
          <div className={"row_between title_text"}>
            <img
              src={backBlackIcon}
              alt=""
              className={"back_black_icon"}
              onClick={this.goBackF}
             
            />
            {/* <div>{!isMoneyBag ? "提现" : "转出"}</div> */}
            <div></div>
            <div style={{ opacity: 0 }}>-</div>
          </div>
        </div>

        {!isMoneyBag ? (
          /* 到钱包 */
          <>
            <div
              className={
                jpShow === true
                  ? `${ss.cashMoneyDom_two} `
                  : `${ss.cashMoneyDom} `
              }
            >
              <div
                className={`${"row_between"} ${ss.cashLine_row} ${
                  ss.bottom_border
                }`}
              >
                <span>账户余额</span>
                <span>
                  ¥
                  {hasMoney && Number(hasMoney) + Number(djMoney) == 0
                    ? "0.00"
                    : (Number(hasMoney) + Number(djMoney)).toFixed(2)}
                </span>
              </div>

              <div className={` ${ss.cashLine_row} ${ss.bottom_border}`}>
                <span style={{ display: "inline-block", width: "90px" }}>
                  选择
                </span>
                <div style={{ textAlign: "left" }} className={"radio_view"}>
                  <Radio.Group
                    onChange={this.cashRadioChange}
                    value={radioValue}
                  >
                    {userType == 2 && (
                      <>
                        {/* <div>
                          <Radio value={1}>提交发票</Radio>
                        </div> */}
                        <div>
                          <Radio value={3}>灵活用工(6%)</Radio>
                        </div>
                        {/* <div>
                          <Radio value={2}>代交个人所得税(20%)</Radio>
                        </div> */}
                      </>
                    )}
                    {userType == 1 && (
                      <>
                        <Radio value={1}>提交发票</Radio>
                      </>
                    )}
                  </Radio.Group>
                </div>
              </div>

              {radioValue == 1 && (
                <div
                  className={`${"row_between"} ${ss.cashLine_row} ${
                    ss.bottom_border
                  }`}
                >
                  <span>上传发票</span>
                  <span className={"Upload_box"}>
                    <Upload
                      action="http://upload.qiniu.com"
                      listType="picture-card"
                      fileList={files}
                      maxCount={9}
                      onChange={this.uploadChange}
                      data={{ token: qiToken, key: this.randomImageNum() }}
                      showUploadList={{
                        showRemoveIcon: true,
                        showDownloadIcon: false,
                        showPreviewIcon: false,
                      }}
                    >
                      {files.length >= 9 ? null : (
                        <div>
                          <PlusOutlined />
                        </div>
                      )}
                    </Upload>
                  </span>
                </div>
              )}

              <div className={ss.gray_box}></div>
              <div>
                <div className={` ${ss.cashLine_row} ${ss.bottom_border}`}>
                  <p>提现金额</p>
                  <div className={"row_between"}>
                    <div>
                      <span className={ss.font_size}>¥</span>
                      <input
                        className={ss.cashMoney_v}
                        value={wantBagMoney}
                        name="wantBagMoney"
                        ref={this.bagInputRef}
                        placeholder="输入金额"
                        onChange={this.formChange}
                        readOnly
                        onFocus={() => {
                          this.setState({
                            jpShow: true,
                          })
                          this.bagInputRef.current.blur()
                        }}
                        onClick={() => {
                          this.setState({
                            jpShow: true,
                          })
                          this.bagInputRef.current.blur()
                        }}
                      />
                    </div>

                    {istx_Click && Number(hasMoney) != 0 && (
                      <span
                        className={ss.allTi}
                        onClick={this.allCash.bind(this, hasMoney)}
                      >
                        全部提现
                      </span>
                    )}

                    {istx_Click != null &&
                      istx_Click == false &&
                      Number(hasMoney) == 0 && (
                        <span className={ss.no_allTi}>全部提现</span>
                      )}
                  </div>
                </div>
                <div
                  className={`${"row_between"} ${ss.cashLine_row} ${
                    ss.bottom_border
                  }`}
                >
                  {!tipShow ? (
                    <span className={ss.fontGray}>
                      可提现金额 &nbsp;&nbsp; ¥{" "}
                      {hasMoney ? (
                        <span className={ss.green_font_view}>
                          {Number(hasMoney).toFixed(2)}
                        </span>
                      ) : (
                        <span className={ss.green_font_view}>0.00</span>
                      )}
                      （冻结金额 ¥{" "}
                      {djMoney ? (
                        <span className={ss.red_font_view}>
                          {Number(djMoney).toFixed(2)}
                        </span>
                      ) : (
                        <span className={ss.red_font_view}>0.00</span>
                      )}
                      ）
                    </span>
                  ) : (
                    <span className={ss.info_red}>输入金额超过可提现余额</span>
                  )}
                </div>

                <div className={ss.cashLine_row}>
                  <div className={"row_between"}>
                    <p>
                      <span>服务费</span>
                      <span>（￥2）</span>
                    </p>
                    <p>¥{"2.00"}</p>
                  </div>

                  <div className={"row_between"}>
                    <p>
                      <span>支付手续费</span>
                      <span>（提现金额的千分之六）</span>
                    </p>
                    <p>¥{six_service_money}</p>
                  </div>

                  {radioValue == 2 && (
                    <p className={"row_between"}>
                      <span>个人所得税（20%）</span>
                      <span>
                        ¥ {Number(wantBagMoney) >= 10  ? ((Number(wantBagMoney) - Number(all_service_money)) * 0.2 ).toFixed(2)  : "0.00"}
                      </span>
                    </p>
                  )}

                  {radioValue == 3 && (
                    <p className={"row_between"}>
                      <span>灵活用工（6%）</span>
                      <span>
                        ¥ {
                            Number(wantBagMoney) >= 10 ? 
                            (
                              (Number(wantBagMoney) - Number(all_service_money)) / 1.06 <= 1000 ?
                              (Number(wantBagMoney) - Number(all_service_money)) / 1.06 * 0.06 + 1 :
                              (Number(wantBagMoney) - Number(all_service_money)) / 1.06 * 0.06  
                            ).toFixed(2) 
                            : "0.00"
                          }
                      </span>
                    </p>
                  )}

                  <div className={"row_between"}>
                    <span>实际到账</span>
                    <span>
                      ¥
                      {
                        Number(wantBagMoney) >= 10 ? 
                          radioValue == 2 ? 
                            (Number(wantBagMoney) - Number(all_service_money) - (Number(wantBagMoney) - Number(all_service_money)) * 0.2).toFixed(2)
                          : radioValue == 3 ? 
                            ((Number(wantBagMoney) - Number(all_service_money)) / 1.06).toFixed(2)
                            : (Number(wantBagMoney) - Number(all_service_money)).toFixed(2)
                        : "0.00"
                      }
                    </span>
                  </div>

                  {jpShow == false && Number(hasMoney) != 0 && (
                    <button
                      className={ss.cash_Btn_View}
                      onClick={(v) => {
                        this.cashValue(v, "wantBagMoney")
                      }}
                    >
                      提现
                    </button>
                  )}

                  {jpShow == false && Number(hasMoney) == 0 && (
                    <button className={ss.no_cash_Btn_View} disabled>
                      提现
                    </button>
                  )}
                </div>

                {istx_Click != null && jpShow === true && (
                  <JpModal
                    data={this.state.medata}
                    fontTip={"提 现"}
                    isClick={Number(hasMoney) == 0 ? false : true}
                    cancleFn={this.cancleFn.bind(this, "jpShow")}
                    setValueFn={(v) => {
                      this.setValueFn(v, "wantBagMoney")
                    }}
                    cashValue={(v) => {
                      this.cashValue(v, "wantBagMoney")
                    }}
                  ></JpModal>
                )}
              </div>
            </div>
          </>
        ) : (
          /* 到银行 */
          <>
            <div className={ss.cashMoneyDom}>
              <div
                className={`${"row_between"} ${ss.cashLine_row} ${
                  ss.bottom_border
                }`}
              >
                <span>钱包余额</span>
                <span>¥{Number(bagHasMoney).toFixed(2)}</span>
              </div>
              <div className={`${"row_between"} ${ss.cashLine_row}`}>
                <span>转出至</span>
                <div onClick={this.toSelectCard}>
                  {isNull(bankInfo.bankName) ? (
                    <span className={ss.fontCw}>
                      银行卡 <img className={ss.grayRight} src={grayRight}  alt="" />
                    </span>
                  ) : (
                    <div className={ss.display_style}>
                      <div className={ss.font_view}>
                        <img
                         alt=""
                          className={ss.logo_img}
                          src={
                            !isNull(bankInfo.bankLogo)
                              ? bankInfo.bankLogo
                              : AddNewcard
                          }
                        />
                        <span>
                          {bankInfo.bankName} <br />
                          {`( **** ${bankInfo.bankAcctNo.substring(
                            bankInfo.bankAcctNo.length - 4,
                            bankInfo.bankAcctNo.length
                          )} )`}
                        </span>
                      </div>
                      <img className={ss.grayRight} src={grayRight}  alt="" />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={` ${ss.cashLine_row} ${ss.bottom_border}`}>
                  <p>转出金额</p>
                  <div className={"row_between"}>
                    <div>
                      <span className={ss.font_size}>¥</span>
                      <input
                        className={ss.cashMoney_v}
                        value={wantBankMoney}
                        name="wantBankMoney"
                        ref={this.bankInputRef}
                        placeholder="输入金额"
                        onChange={this.formChange}
                        onFocus={() => {
                          this.setState({
                            jpShow: true,
                          })
                          this.bankInputRef.current.blur()
                        }}
                        onClick={() => {
                          this.setState({
                            jpShow: true,
                          })
                          this.bankInputRef.current.blur()
                        }}
                      />
                    </div>

                    {iszc_Click && Number(bagHasMoney) != 0 && (
                      <span
                        className={ss.allTi}
                        onClick={() => {
                          this.setState({
                            wantBankMoney: Number(bagHasMoney),
                          })
                        }}
                      >
                        全部转出
                      </span>
                    )}
                    {iszc_Click != null && Number(bagHasMoney) == 0 && (
                      <span className={ss.no_allTi}>全部转出{iszc_Click}</span>
                    )}
                  </div>
                </div>

                <div className={`${"row_between"} ${ss.cashLine_row}`}>
                  {!bankTipShow ? (
                    <span className={ss.fontGray}>
                      可转出金额 &nbsp;&nbsp;&nbsp;&nbsp; ¥{" "}
                      <span className={ss.green_font_view}>
                        {Number(bagHasMoney).toFixed(2)}
                      </span>
                    </span>
                  ) : (
                    <span className={ss.info_red}>输入金额超过可转出余额</span>
                  )}
                </div>

                <div className={`${ss.zcBtn_view}`}>
                  {jpShow == false && Number(bagHasMoney) != 0 && (
                    <button
                      className={ss.cash_Btn_View}
                      onClick={(v) => {
                        this.cashValue(v, "wantBankMoney")
                      }}
                    >
                      转出
                    </button>
                  )}

                  {jpShow == false && Number(bagHasMoney) == 0 && (
                    <button className={ss.no_cash_Btn_View} disabled>
                      转出
                    </button>
                  )}
                </div>

                {iszc_Click != null && jpShow === true && (
                  <JpModal
                    data={this.state.medata}
                    fontTip={"转 出"}
                    isClick={Number(bagHasMoney) == 0 ? false : true}
                    cancleFn={this.cancleFn.bind(this, "jpShow")}
                    setValueFn={(v) => {
                      this.setValueFn(v, "wantBankMoney")
                    }}
                    cashValue={(v) => {
                      this.cashValue(v, "wantBankMoney")
                    }}
                  ></JpModal>
                )}
              </div>
            </div>
          </>
        )}

        <Modal
          popup
          visible={cardModal}
          onClose={this.modalClose.bind(this, "cardModal")}
          animationType="slide-up"
        >
          <div className={ss.modal_view}>
            <div className={ss.modalHeader_view}>
              <span
                className={ss.modalHeader_close}
                onClick={this.modalClose.bind(this, "cardModal")}
              >
                X
              </span>
              <span>选择到账银行卡</span>
            </div>

            <div style={{ paddingTop: "45px" }}>
              {bankCardList.length > 0 &&
                bankCardList.map((item, index) => {
                  return (
                    <div
                      className={
                        index == 0
                          ? `${ss.card_item} ${ss.borderTop}`
                          : `${ss.card_item}`
                      }
                      key={item.bankAcctNo}
                      onClick={this.sureBankCard.bind(this, item)}
                    >
                      <img
                         alt=""
                        className={ss.logo_img}
                        src={
                          item.bankinfo &&
                          item.bankinfo.logo_url &&
                          item.bankinfo.logo_url
                            ? "https://cdn.ellll.com/" + item.bankinfo.logo_url
                            : AddNewcard
                        }
                      />

                      <div className={ss.fontCw}>
                        {item.bankName}
                        {/* <br />
                        {item.bankAcctNo} */}
                        <br />
                        {`( **** ${item.bankAcctNo.substring(
                          item.bankAcctNo.length - 4,
                          item.bankAcctNo.length
                        )} )`}
                      </div>
                    </div>
                  )
                })}
            </div>

            <div
              className={`${"row_between"} ${ss.add_new_card}`}
              onClick={() =>
                this.props.history.push({
                  pathname: "addBankCard",
                  state: {
                    ...this.props.location.state,
                    page: "cashMoney",
                  },
                })
              }
            >
              <div className={ss.fontCw}>
                <img className={ss.add_newc_logo} src={AddNewcard}  alt="" />
                <span>使用新卡提现</span>
              </div>
              <img className={ss.grayRight} src={grayRight}  alt="" />
            </div>
          </div>
        </Modal>

        <MeModal
          visible={isMemodalVisible}
          footer={null}
          closable={false}
          maskClosable={false}
          className={"zhifu_modal"}
        >
          <div className={ss.memodal_view}>
            <div className={ss.memodal_middle}>
              <div className={ss.tixian_font}>提现</div>
              <div
                className={ss.memodal_number}
              >{`¥ ${this.state.wantBankMoney}`}</div>
            </div>
            <div className={ss.memodal_footer}>
              <div>
                {pointPwd.map((item, index) => {
                  return (
                    <span className={ss.password_num}>
                      <i
                        className={
                          !isNull(item.name) ? `${ss.pont_black} ` : ""
                        }
                      ></i>
                    </span>
                  )
                })}
              </div>
            </div>
          </div>
        </MeModal>
      </>
    )
  }

  formChange = (e) => {
    const { name, value } = e.target
    // console.log("name: value", name, value)
    this.setState({
      [name]: value,
    })
  }

  modalClose = (modal) => {
    this.setState({
      [modal]: false,
    })
  }

  cancleFn = (name) => {
    this.setState({
      [name]: false,
    })
  }

  toNumAdd = () => {
    let arr = this.state.pointPwd
    let count = this.state.pointCount
    arr.forEach((item, index) => {
      if (index == count) {
        // console.log("+++", index, count)
        item.name = "·"
      }
    })
    this.setState({
      pointPwd: [...arr],
      pointCount: this.state.pointCount + 1,
    })
  }

  toNumDel = () => {
    let arr = this.state.pointPwd
    let count = this.state.pointCount

    arr.forEach((item, index) => {
      if (index == count - 1) {
        // console.log("---", index, count - 1)
        item.name = ""
      }
    })
    this.setState({
      pointPwd: [...arr],
      pointCount: this.state.pointCount - 1,
    })
  }

  tipShow = (name) => {
    const { wantBagMoney, wantBankMoney, bagHasMoney, hasMoney } = this.state
    let m = ""
    let n = ""
    let q = ""
    name === "wantBagMoney" ? (m = wantBagMoney) : (m = wantBankMoney)
    name === "wantBagMoney" ? (n = hasMoney) : (n = bagHasMoney)
    name === "wantBagMoney" ? (q = "tipShow") : (q = "bankTipShow")
    if (Number(m) > Number(n)) {
      this.setState({
        [q]: true,
      })
    } else {
      this.setState({
        [q]: false,
      })
    }
  }

  uploadChange = ({ fileList }) => {
    console.log("fileList", fileList)
    this.setState({
      files: fileList,
    })
  }

  randomImageNum = () => {
    let r = Math.floor(Math.random() * 1000000000)
    let f = Math.floor(Math.random() * 1000000000)
    let d = Date.parse(new Date())
    return `xg_GS${d}${r}${f}`
  }

  handleGetQiNiu = () => {
    util.postYangAxios(
      api.qiNiuToken,
      {},
      async (res) => {
        this.setState({
          qiToken: res.token,
        })
      },
      (err) => {
        console.log(err)
      },
      () => {},
      true
    )
  }

  allCash = (hasMoney) => {
    this.setState(
      {
        wantBagMoney: Number(hasMoney),
      },
      () => {
        this.calculationCount()
        this.tipShow("wantBagMoney")
      }
    )
  }

  setValueFn = (v, name) => {
    // console.log("v", v)
    // console.log("name", name)
    // console.log("this.state[name]", this.state[name])
    if (v !== "del") {
      let val = String(this.state[name])
      let isPoint = val.includes(".")
      // console.log(isPoint)
      if (v === ".") {
        if (!isPoint) {
          this.setState({
            [name]: val.length == 0 ? "0" + v : this.state[name] + v,
            all_service_money: "0.00",
            six_service_money: "0.00",
          })
        }
      } else {
        let val = String(this.state[name])
        let isPoint = val.includes(".")
        if (isPoint) {
          let arr = val.split(".")
          // console.log("arr", arr)
          if (arr[1].length >= 2) {
            return
          }
        }

        if (this.state[name].length == 8) {
          return
        }
        this.setState(
          {
            [name]:
              v != "."
                ? this.state[name] + v
                : this.state[name].length > 0
                ? this.state[name] + v
                : "0" + v,
          },
          () => {
            this.tipShow(name)
            this.calculationCount()
          }
        )
      }
    }

    v === "del" &&
      this.setState(
        (preState, prop) => {
          let val = String(preState[name])

          // console.log("del-val", val)
          if (val.length > 0) {
            let sv = val.substring(0, val.length - 1)

            return {
              [name]: sv,
              all_service_money: "0.00",
              six_service_money: "0.00",
            }
          }
        },
        () => {
          this.tipShow(name)
          this.calculationCount()
        }
      )
  }

  calculationCount = () => {
    // console.log("this.state.wantBagMoney", this.state.wantBagMoney)
    if (Number(this.state.wantBagMoney) >= 10) {
      let hh = (Number(this.state.wantBagMoney) * 0.006 + 2).toFixed(2)
      let six_hh = (Number(this.state.wantBagMoney) * 0.006).toFixed(2)
      this.setState({
        all_service_money: hh,
        six_service_money: six_hh,
      })
    } else {
      this.setState({
        all_service_money: "0.00",
        six_service_money: "0.00",
      })
    }
  }

  //提现 点击事件
  cashValue = (v, name) => {
    const {
      wantBagMoney,
      tipShow,
      wantBankMoney,
      bankTipShow,
      files,
      radioValue,
    } = this.state
    let params = {}
    let fpUrl = ""
    files.forEach((item, index) => {
      if (index == 0) {
        fpUrl = item.response.key
      } else {
        fpUrl += "," + item.response.key
      }
    })

    if (name === "wantBagMoney") {
      if (radioValue == 0) {
        Toast.info("请选择提现类型")
        return
      }

      if (radioValue == 1 && isNull(fpUrl)) {
        Toast.info("请上传发票")
        return
      }

      if (radioValue == 3) {
        /* start */
        //是否绑定银行卡
        util.getYangAxios(
          api.gethasbankcard,
          {},
          async (res) => {
            if (res.bankcard == null) {
              Toast.info("请先绑定银行卡", 1)
              setTimeout(() => {
                this.props.history.push({
                  pathname: "addBankCard",
                  state: {
                    page: "cashMoney",
                    radioValue: 3,
                  },
                })
              }, 1100)
              return
            } else {
              //是否签过协议
              util.getYangAxios(
                api.hefubao,
                {},
                async (res) => {
                  if (res.sign == null) {
                    this.setState({
                      hegubao: res.sign,
                      xieYiModal: true,
                    })
                    return
                  } else {
                    this.setState({
                      hegubao: res.sign,
                      xieYiChecked: true,
                    })
                    if (Number(wantBagMoney) < Number(10)) {
                      Toast.info("最低提现额度为10元")
                      return
                    }
                    if (tipShow) {
                      Toast.info("账户余额不足")
                      return
                    }

                    params = {
                      money: wantBagMoney,
                      type: 3,
                    }
                    this.cashApi(params)
                  }
                },
                (err) => {
                  console.error(err)
                },
                () => {}
              )
            }
          },
          (err) => {
            console.error(err)
          }
        )

        /* end */

        //是否签过协议
        // util.getYangAxios(
        //   api.hefubao,
        //   {},
        //   async (res) => {
        //     if (res.sign == null) {
        //       this.setState({
        //         hegubao: res.sign,
        //         xieYiModal: true,
        //       })
        //       return
        //     } else {
        //       this.setState({
        //         hegubao: res.sign,
        //         xieYiChecked: true,
        //       })
        //       //是否绑定银行卡
        //       util.getYangAxios(
        //         api.gethasbankcard,
        //         {},
        //         async (res) => {
        //           console.log("有无卡--res", res)
        //           if (res.bankcard == null) {
        //             Toast.info("请先绑定银行卡", 1)
        //             setTimeout(() => {
        //               this.props.history.push({
        //                 pathname: "addBankCard",
        //                 state: {
        //                   page: "cashMoney",
        //                   radioValue: 3,
        //                 },
        //               })
        //             }, 1100)
        //             return
        //           } else {
        //             if (Number(wantBagMoney) < Number(10)) {
        //               Toast.info("最低提现额度为10元")
        //               return
        //             }
        //             if (tipShow) {
        //               Toast.info("账户余额不足")
        //               return
        //             }

        //             params = {
        //               money: wantBagMoney,
        //               type: 3,
        //             }

        //             this.cashApi(params)
        //           }
        //         },
        //         (err) => {
        //           console.error(err)
        //         }
        //       )
        //     }
        //   },
        //   (err) => {
        //     console.error(err)
        //   },
        //   () => {}
        // )
      } else {
        if (Number(wantBagMoney) < Number(10)) {
          Toast.info("最低提现额度为10元")
          return
        }
        if (tipShow) {
          Toast.info("账户余额不足")
          return
        }

        if (radioValue == 1) {
          params = {
            money: wantBagMoney,
            evidence: fpUrl,
            type: 1,
          }
        } else if (radioValue == 2) {
          params = {
            money: wantBagMoney,
            type: 2,
          }
        }
        this.cashApi(params)
      }
    } else if (name === "wantBankMoney") {
      if (bankTipShow) {
        Toast.info("账户余额不足")
        return
      }

      if (Number(wantBankMoney) == Number(0) || wantBankMoney == "0.") {
        Toast.info("请输入有效金额")
        return
      }
      let isGo = this.estlintData()
      // console.log(isGo)
      if (!isGo) {
        return
      }

      this.setState({
        isMemodalVisible: true,
      })

      // console.log("点击点击")
    }
  }

  cashApi = async (params) => {
    util.postYangAxios(
      api.cashMoney,
      params,
      async (res) => {
        this.props.history.push("/cashSuccess")
      },
      (err) => {
        console.log(err)
      }
    )
  }

  zhuanchuFn = () => {}

  estlintData = () => {
    let flag = true
    const { bankInfo, wantBankMoney } = this.state
    if (isNull(bankInfo.bankName)) {
      Toast.info("请选择银行卡！")
      return (flag = false)
    }
    if (isNull(wantBankMoney)) {
      Toast.info("请输入提现金额！")
      return (flag = false)
    }
    return flag
  }

  toSelectCard = () => {
    this.setState({
      cardModal: true,
    })
  }

  sureBankCard = (v) => {
    // console.log("银行卡-v", v)
    this.setState({
      bankInfo: {
        bankAcctNo: v.bankAcctNo,
        bankName: v.bankName,
        bankLogo:
          !isNull(v.bankinfo) && !isNull(v.bankinfo.logo_url)
            ? "https://cdn.ellll.com/" + v.bankinfo.logo_url
            : null,
      },
    })
    this.modalClose("cardModal")
  }

  cashMoneyToBank = async () => {
    // console.log("去提现")
    // return
    await this.confirm()
  }

  cashBankApi = () => {
    const { bankInfo, wantBankMoney } = this.state
    this.setState({
      isMemodalVisible: false,
    })
    let data = {
      bankAcctName: bankInfo.bankName, //银行名称
      bankAcctNo: bankInfo.bankAcctNo, //卡号
      amount: wantBankMoney
    }
    util.postYangAxios(
      api.getBankMoney,
      data,
      async (res) => {
        Toast.info("提现成功", 1)

        setTimeout(() => {
          this.props.history.push("cashBankSuccess")
        }, 1000)
      },
      (err) => {
        console.error(err)
      },
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
    tabSetIndex,
  }
)(CashMoney)
