import React, { Component } from "react"
import "./css/member.css"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import { Toast, } from "antd-mobile"
import search_img from "../../image/search.png"
import tips_img from "../../image/tips.png"
import open from "../../image/open.png"
import close_img from "../../image/close.png"
import line_img from "../../image/line.jpg"

const memberList1 = [
  {
    name: '张三丰', phone: '158566693512', type: '1',
    children: [{ id: 1, name: '张曦', childShow: true, phone: '158566693512', type: '1', children: [] },
    { id: 2, name: '许倩倩', childShow: true, phone: '158566693512', type: '1', children: [] },
    {
      id: 3, name: '张曦', phone: '158566693512', type: '1', childShow: true, children: [
        { name: '许倩倩', phone: '158566693512', type: '1', children: [] },
        { name: '许倩倩', phone: '158566693512', type: '1', children: [] },
        { name: '许倩倩', phone: '158566693512', type: '1', children: [] }
      ]
    },
    {
      id: 4, name: '张曦', childShow: true, phone: '158566693512', type: '1', children: [
        { name: '许倩倩', phone: '158566693512', type: '1', children: [] },
        { name: '许倩倩', phone: '158566693512', type: '1', children: [] }
      ]
    }
    ]
  }
]

export default class Memberx extends Component {
  constructor(props) {
    super(props)
    this.state = {
      is_on: true,
      memberList: [],
      performanceData: {},
      tips: '',
      phone: ''
    }
  }

  componentWillMount() {
    this.handleGetCustomRelation()
  }
  handleGetCustomRelation() {
    util.getYangAxios(
      api.customRelation,
      {
        phone: this.state.phone
      },
      async (res) => {

        const list = this.handTree(res.list)
        this.setState({
          memberList: list,
          performanceData: res.info,
          tips: res.tip
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }
  onPhoneChange = (event) => {
    this.setState({
      phone: event.target.value
    })

  }
  handSearch = () => {
    const phoneValue = this.state.phone
    if (phoneValue && !/^1[0-9]{10}$/.test(phoneValue)) {
      Toast.info("手机号格式错误", 1)
      return null
    }
    this.handleGetCustomRelation()
  }
  handTree(arr) {
    for (const item of arr) {
      item.childShow = true
      if (item.children && item.children.length > 0) {
        this.handTree(item.children)
      }
    }
    return arr
  }
  // 跳转到详情
  handleSkipDetails = (item) => {
    this.props.history.push({
      pathname: "/customer/details",
      state: {
        user_id: item.id,
      },
    })

    sessionStorage.setItem("selectedActived", 2)
  }
  userOnOff = (child, parentIndex) => {
    // console.log('userOnOff:',child)
    if (parentIndex !== '') {
      let memberChildArr = this.state.memberList[parentIndex].children
      let updatedMemberChildArr = memberChildArr.map(item => {
        if (item.id === child.id) {
          item.childShow = !item.childShow
          return item
        } else {
          return item
        }
      })
      let updatedMemberList = this.state.memberList.map((item, index) => {
        if (index == parentIndex) {
          let obj = { ...item, children: updatedMemberChildArr }
          return obj
        } else {
          return item
        }

      })
      this.setState({
        ...this.state.memberList,
        memberList: updatedMemberList,
      })
    } else {
      let updatedMemberChildArr = this.state.memberList.map(item => {
        if (item.id === child.id) {
          item.childShow = !item.childShow
          return item
        } else {
          return item
        }
      })
      this.setState({
        ...this.state.memberList,
        memberList: updatedMemberChildArr,
      })
    }

  }

  render() {
    const { memberList, tips, performanceData, phone } = this.state
    return (
      <div className={"members"} style={{ height: window.innerHeight + "px" }}>
        <div className={"search"}>
          <img src={search_img} alt="" className={"search_img"} onClick={this.handSearch} />
          <input className={"search_input"} placeholder={"请输入手机号"} value={phone} onChange={this.onPhoneChange} type="number" />
        </div>
        <div className="memberx_user_count_contianer">
          <div className="memberx_user_count_info">
            <div className="memberx_user_count_number">¥ {performanceData.my_performance}万</div>
            <div className="memberx_user_count_name">我的总业绩</div>
          </div>
          <div className="memberx_user_count_info">
            <div className="memberx_user_count_number">¥  {performanceData.direct_performance}万</div>
            <div className="memberx_user_count_name">直属业绩</div>
          </div>
          <div className="memberx_user_count_info">
            <div className="memberx_user_count_number">¥  {performanceData.direct_max}万</div>
            <div className="memberx_user_count_name">直属最高业绩</div>
          </div>
        </div>
        {tips && (<div className={"tips"}>
          <img src={tips_img} alt="" className={"tips_img"} />
          {<span>{tips}</span>}
        </div>)}
        {memberList.length>0&&(<div className={""}>
          {memberList.map((item, parentIndex) => {
            return <div key={item.id}>
              <div className={"user-item"} style={{ marginLeft: '12px' }}>
                <div className={"user-info"} onClick={()=>this.handleSkipDetails(item)}>
                  <div className={"user-title"}>{item.name} {item.phone}</div>
                  <div className={"user-level"}>{this.renderUser(item)}</div>
                </div>
                {item.children && item.children.length > 0 && item.childShow && <div className={"user-on-off"}><img src={open} alt="" className={"on-off"} onClick={() => this.userOnOff(item, '')} /></div>}
                {item.children && item.children.length > 0 && item.childShow === false && <div className={"user-on-off"}><img src={close_img} alt="" className={"on-off"} onClick={() => this.userOnOff(item, '')} /></div>}
              </div>
              {item.children && item.children.length > 0 && item.childShow && (item.children.map((childrenItem, index) => {
                return <div key={childrenItem.id} style={{ background: index + 1 < item.children.length ? `url(${line_img}) no-repeat` : 'transparent', backgroundPosition: '38px 0px' }}>
                  <div className={"user-item user-item-child"} ref="22">
                    <div className={"user-info"} onClick={()=>this.handleSkipDetails(childrenItem)}>
                      {this.renderIcon()}
                      <div className={"user-title"}>{childrenItem.name} {childrenItem.phone}</div>
                      <div className={"user-level"}>{this.renderUser(childrenItem)}</div>
                    </div>

                    {childrenItem.children && childrenItem.children.length > 0 && childrenItem.childShow === false && (<div className={"user-on-off"}><img src={close_img} alt="" className={"on-off"} onClick={() => this.userOnOff(childrenItem, parentIndex)} /></div>)}
                    {childrenItem.children && childrenItem.children.length > 0 && childrenItem.childShow && (<div className={"user-on-off"}><img src={open} alt="" className={"on-off"} onClick={() => this.userOnOff(childrenItem, parentIndex)} /></div>)}
                  </div>
                  {childrenItem.childShow && childrenItem.children && (childrenItem.children.map((childrenThirdItem, thirdIndex) => {
                    return <div key={childrenThirdItem.id} style={{ background: thirdIndex + 1 < childrenItem.children.length ? `url(${line_img}) no-repeat` : 'transparent', backgroundPosition: '78px 0', backgroundSize: '1 100%' }}>
                      <div className={"user-item user-item-thiird"}>
                        <div className={"user-info"} onClick={()=>this.handleSkipDetails(childrenThirdItem)}>
                          {this.renderIcon()}
                          <div className={"user-title"}>{childrenThirdItem.name} {childrenThirdItem.phone}</div>
                          <div className={"user-level"}>{this.renderUser(childrenThirdItem)}</div>
                        </div>
                      </div>
                    </div>

                  }))}
                </div>

              }))}
            </div>
          })}

        </div>)}
      </div>
    )
  }

  renderUser(item) {
    // item.role_type=3
    if (item.role_type == 1) {
      return (<span className={"level1"}>{item.role_name}</span>)
    } else if (item.role_type == 2) {
      return (<span className={"level2"}>{item.role_name}</span>)
    } else if (item.role_type == 3) {
      return (<span className={"level3"}>{item.role_name}</span>)
    } else {
      return (<span className={"level3"}>{item.role_name}</span>)
    }
  }

  renderIcon() {
    return (
      <div className={"user-icon-first"}>
        <div className={"user-icon-end"}>
        </div>
        <div className={"user-icon-space"}></div>
        <div className={"user-icon-ring user-top"}></div>
      </div>
    )
  }



}
