import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import "./css/header.css"
import backBlackIcon from "../../image/back_black_icon.png"
import { isNull } from "utils/isNull"

class HeaderView extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {}

  render() {
    return (
      <div className={"row_between header_text"}>
        <div className="backBlackIcon_view">
          <img
            src={backBlackIcon}
            alt=""
            className={"back_black_icon"}
            onClick={this.goBackF}
          />
        </div>
        <div>{this.props.title}</div>
        <img
          src={backBlackIcon}
          alt=""
          className={"back_black_icon"}
          style={{ opacity: 0 }}
        />
      </div>
    )
  }

  goBackF = () => {
    let page = this.props.page
    if (!isNull(page)) {
      // console.log("有page")
      this.props.history.push(page)
    } else {
      // console.log("无page")
      this.props.history.goBack()
    }
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default withRouter(HeaderView)
