import React, { Component } from "react"
import Style from  "./css/active.module.css"
import { Toast } from "antd-mobile"
import { util } from "../../common/util"
import { api } from "../../common/Config"

class MemberActive extends Component {
    constructor(props) {
        super(props)
        this.state = {
            phoneValue: "",
            codeValue: "",
            codeNum: 60,
            codeType: 0,
            active_code:""
        }
    }
    componentDidMount() {

    }
    onPhoneChange = (event) => {
        this.setState({
            phoneValue: event.target.value,
        })
    }
    onCodeChange = (event) => {
        this.setState({
            codeValue: event.target.value,
        })
    }
    onActiveCodeChange=(event)=>{
        this.setState({
            active_code: event.target.value,
        })
    }

    // 获取手机号登陆验证短信
    handlePhoneLoginNote = () => {
        const { phoneValue } = this.state
        if (phoneValue.length < 11) {
            Toast.info("手机号格式错误", 1)
            return null
        }
        if (!/^1[0-9]{10}$/.test(phoneValue)) {
            Toast.info("手机号格式错误", 1)
            return null
        }
        util.getYangAxios(
            api.loginCode,
            {
                phone: phoneValue,
            },
            (res) => {
                this.setState(
                    {
                        codeType: 1,
                        codeNum: 60,
                    },
                    () => {
                        const add = setInterval(() => {
                            const { codeNum } = this.state
                            if (codeNum == 1) {
                                this.setState({
                                    codeType: 2,
                                })
                                clearInterval(add)
                            }
                            this.setState({
                                codeNum: codeNum - 1,
                            })
                        }, 1000)
                    }
                )
            },
            (err) => {
                console.log(err)
                // Toast.fail('服务器异常,请稍后重试')
            }
        )
    }


    // 登陆
    handleLogin = () => {
        const { phoneValue, codeValue,active_code } =this.state
    
        if (phoneValue == "") {
            Toast.info("请输入手机号")
            return
        }
        if (!/^1[0-9]{10}$/.test(phoneValue)) {
            Toast.info("手机号格式错误", 1)
            return null
        }
        if (codeValue == "") {
            Toast.info("请输入验证码")
            return
        }
        if (active_code == "") {
            Toast.info("激活码")
            return
        }
        util.postYangAxios(
            api.agentsCodeActive,
            {
                phone: phoneValue,
                verifycode: codeValue,
                active_code: active_code,
                no_settle: "settle" 
            },
            async (res) => {
                console.log(res,4444)
                this.props.history.replace({
                    pathname: "/member/activeOk",
                    state: {
                        product_name:res.product_name,
                    },
                  })
                // this.props.history.replace("/member/activeOk")
            },
            (err) => {
                console.log(err)
            }
        )
    }
    render() {
        const { codeType, codeNum,phoneValue,codeValue,active_code} = this.state
        return (
            <div className={Style.login_view}>
                <div className={Style.title_text}>
                    会员激活
                </div>
                <div className={Style.input_view}>
                    <div className={Style.input_box}
                        onClick={() => {
                            this.phoneInputs.focus()
                        }}
                    >   
                        <span className={Style.input_lable}>手机号</span>
                        <input  type="number" value={phoneValue} onChange={this.onPhoneChange}  className={Style.input_text}  placeholder={"请输入手机号"}
                            ref={(c) => {
                                this.phoneInputs = c
                            }}
                        />
                    </div>
                    <div
                        className={"row_between input_box"}
                        onClick={() => {
                            this.codeInputs.focus()
                        }}
                    >  
                         <span className={Style.input_lable}>验证码</span>
                        <input
                            type="text"
                            value={codeValue}
                            onChange={this.onCodeChange}
                            className={Style.input_text}
                            placeholder={"请输入验证码"}
                            ref={(c) => {
                                this.codeInputs = c
                            }}
                        />
                        {codeType === 0 && (
                            <div className={Style.code_text} onClick={this.handlePhoneLoginNote}>
                                获取
                            </div>
                        )}

                        {codeType === 1 && <div className={Style.code_text}> {codeNum}秒</div>}
                        {codeType === 2 && (
                            <div className={Style.code_text} onClick={this.handlePhoneLoginNote}>
                                重新获取
                            </div>
                        )}
                    </div>
                    <div className={Style.input_box}
                        onClick={() => {
                            this.ativeCodeInputs.focus()
                        }}
                    >   
                        <span className={Style.input_lable}>激活码</span>
                        <input  type="text" value={active_code} onChange={this.onActiveCodeChange} className={Style.input_text}  placeholder={"请输入激活码"}
                            ref={(c) => {
                                this.ativeCodeInputs = c
                            }}
                        />
                    </div>
                </div>
                <div className={Style.login_btn_big_view}>
                    <div
                        className={Style.login_btn_view}
                        onClick={this.handleLogin}
                    >
                        立即激活
                    </div>
                </div>
            </div>
        )
    }
}

export default MemberActive 
