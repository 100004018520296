import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet } from "../../actions/XggsUserInformation"
import "./css/channel.css"
import {  Toast, ListView, Picker, List } from "antd-mobile"
import HeaderView from "../../component/header/headerView"
import { Tabs } from "antd"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import { cityData } from "../../common/cityMock"
import grayRightIcon from "../../image/gray_right_icon.png"
const { TabPane } = Tabs
class Channel extends Component {
  constructor(props) {
    super(props)
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
    })
    this.state = {
      activeKey: "2",
      dataSource,
      datas: [],
      pageNo: 1,
      pageSize: 2,
      hasMore: true,
      refreshing: true,
      isLoading: true,
      dataArr: [],
      totalPage: 1,
      lookDetails: "", //代表从主页学校数点击进入 只可看到客户菜单
      HeaderTitle: "",
      zhishutype: "", // 1直接 2全部 3间接
      level: "", //商家统计 - 省级 市级等类型
      pickerValue: null,
      mode: 1,
      year: null,
      month: null,
    }
  }

  componentDidMount() {
    console.log("商家接收到的数据", this.props.history.location.state)
    this.setState(
      {
        activeKey: this.props.history.location.state.key,
        lookDetails: this.props.history.location.state.lookDetails ?? "",
        HeaderTitle: this.props.history.location.state.HeaderTitle,
        zhishutype: this.props.location.state.zhishutype ?? "",
        level: this.props.location.state.level ?? "",
        typeKey: this.props.location.state.typeKey ?? "",
        year: this.props.location.state.year ?? null,
        month: this.props.location.state.month ?? null,
      },
      () => {
        const typeKey = this.state.typeKey
        let mode = ""
        switch (typeKey) {
          case 1: {
            mode = 1
            break
          }
          case 2: {
            mode = 4
            break
          }
          case 3: {
            mode = 6
            break
          }
          case 4: {
            mode = 2
            break
          }
          case 5: {
            mode = 3
            break
          }
          case 6: {
            mode = 5
            break
          }
          default: {
            mode = 1
            break
          }
        }

        this.setState(
          {
            mode,
          },
          () => this.handleGetOrder()
        )
      }
    )
  }

  // 总接口
  handleGetOrder = () => {
    const { activeKey } = this.state

    if (this.state.pageNo > this.state.totalPage) {
      this.setState({
        refreshing: false,
        isLoading: false,
        hasMore: false,
      })
      Toast.info("没有数据了~", 1)
      return false
    }

    if (activeKey == "1") {
      this.handleGetMarketOrder()
    } else if (activeKey == "2") {
      this.getSchool()
    }
  }

  // 获取商家列表
  handleGetMarketOrder(ref = false) {
    const { pageNo, lookDetails, zhishutype, level, pickerValue } = this.state

    if (ref) {
      this.setState({
        pageNo: 1,
        dataArr: [], // 保存新数据进state
      })
    }

    let search_data = { level_gt: 0 }
    if (lookDetails == "lookDetails" && level >= 20) {
      //大于等于20 level_egt  否则level
      search_data = {
        ...search_data,
        level_egt: level,
      }
    } else if (lookDetails == "lookDetails") {
      search_data = {
        ...search_data,
        level: level,
      }
    }

    console.log("pickerValue", pickerValue)
    if (pickerValue) {
      search_data = {
        ...search_data,
        province_code: pickerValue[0],
        city_code: pickerValue[1],
        area_code: pickerValue[2],
      }
    }

    let data = {
      currentPage: pageNo,
      zhishutype: zhishutype,
      search_data,
      mode: this.state.mode,
    }
    if (this.state.mode == 6 && this.state.year) {
      data.year = this.state.year
    }
    if (this.state.mode == 6 && this.state.month) {
      data.month = this.state.month
    }

    util.getYangAxios(
      api.channelsList,
      data,
      async (res) => {
        const dataList = res.list

        this.setState({
          totalPage: res.totalPage,
        })

        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  //获取用户统计和习惯使用统计
  getSchool(ref = false) {
    util.getYangAxios(
      api.schoolStatistics,
      {
        zhishutype: 1,
        mode: 1,
      },
      async (res) => {
        const dataList = res.data.school
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          dataArr: dataList,
          dataSource: this.state.dataSource.cloneWithRows(dataArr),
          refreshing: false,
          isLoading: false,
          hasMore: false,
          // dataArr: dataArr,
        })

        /* this.setState({
			totalPage: res.data.school_sum,
		  })
		  var dataArr = this.state.dataArr.concat(dataList) //关键代码
		  this.setState({
			pageNo: this.state.pageNo,
			dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
			refreshing: false,
			isLoading: false,
			dataArr: dataArr, // 保存新数据进state
		  }) */
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 获取用户列表
  handleGetPurchaseOrder(ref = false) {
    util.getYangAxios(
      api.channelsUsersList,
      {
        currentPage: this.state.pageNo,
      },
      async (res) => {
        const dataList = res.list
        const len = dataList.length
        if (len <= 0) {
          // 判断是否已经没有数据了
          this.setState({
            refreshing: false,
            isLoading: false,
            hasMore: false,
          })
          Toast.info("没有数据了~", 1)
          return false
        }
        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 获取提取订单
  handleGetExtractOrder(ref = false) {
    util.getYangAxios(
      api.extractOrder,
      {
        currentPage: this.state.pageNo,
      },
      async (res) => {
        const dataList = res.list
        const len = dataList.length
        if (len <= 0) {
          // 判断是否已经没有数据了
          this.setState({
            refreshing: false,
            isLoading: false,
            hasMore: false,
          })
          Toast.info("没有数据了~", 1)
          return false
        }
        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 获取库存订单
  handleGetInventoryOrder(ref = false) {
    util.getYangAxios(
      api.financialFlowList,
      {
        currentPage: this.state.pageNo,
      },
      async (res) => {
        const dataList = res.list
        const len = dataList.length
        if (len <= 0) {
          // 判断是否已经没有数据了
          this.setState({
            refreshing: false,
            isLoading: false,
            hasMore: false,
          })
          Toast.info("没有数据了~", 1)
          return false
        }
        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 滑动到底部时加载更多
  onEndReached = (event) => {
    // 加载中或没有数据了都不再加载
    if (this.state.isLoading || !this.state.hasMore) {
      return
    }
    this.setState(
      {
        isLoading: true,
        pageNo: this.state.pageNo + 1, // 加载下一页
      },
      () => {
        this.handleGetOrder()
      }
    )
  }

  // 支付
  handlePay = () => {
    this.props.history.push("payResult")
  }

  // 订单文字渲染
  handleOrderTextRender = (title, content, isLook = false) => {
    return (
      <div className={"row_align order_text_div"} style={{ width: "100%" }}>
        <div className={"row_align"}>
          <div className={"order_title_text"}>{title}</div>
          <div className={"order_content_text"}>{content}</div>
        </div>
        {isLook && (
          <div className={"order_look_text row_end"}>
            查看
            <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
          </div>
        )}
      </div>
    )
  }

  // 渲染订单状态
  handleStatusRender = (num) => {
    if (num == 0) {
      return "未激活"
    } else if (num == 10) {
      return "已激活"
    } else if (num == 20) {
      return "已过期"
    } else {
      return "未知"
    }
  }

  // 库存订单
  handleInventoryBox = (title, num) => {
    return (
      <div className={"inventory_order_box column_center"}>
        <div className={"inventory_order_num"}>{num}</div>
        <div className={"inventory_order_title"}>{title}</div>
      </div>
    )
  }

  // 跳转到订单详情
  handleSkipDetails = (obj, num) => {
    if (num == 1) {
      this.props.history.push({
        pathname: "/agentDetails",
        state: {
          order: obj,
        },
      })
    } else if (num == 2) {
      this.props.history.push({
        pathname: "/purchaseOrder",
        state: {
          order: obj,
        },
      })
    }
  }

  handleJibie = (num) => {
    if (num == "11") {
      return "省代理"
    } else if (num == "12") {
      return "市代理"
    } else if (num == "13") {
      return "区/县代理"
    } else {
      return "合伙人"
    }
  }

  handleStatus = (num) => {
    if (num == "0") {
      return "未交费"
    } else if (num == "1") {
      return "代理中"
    } else {
      return "停用"
    }
  }

  /* PickerView值改变 */
  onSelectChange = (v) => {
    this.setState({ pickerValue: v }, () => this.handleGetMarketOrder(true))
  }

  // 渲染消息
  handleMsgRender = (title, num) => {
    const { HeaderTitle, pickerValue, lookDetails } = this.state

    let row
    if (num == 1) {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            <div
              className={"order_card"}
              key={index}
              onClick={() => this.handleSkipDetails(item, num)}
            >
              {HeaderTitle == "合伙人"
                ? this.handleOrderTextRender("合伙人编码：", item.id, true)
                : this.handleOrderTextRender("商家编码：", item.id, true)}
              {this.handleOrderTextRender("名称：", item.name)}
              {this.handleOrderTextRender("联系人：", item.linkman)}
              {this.handleOrderTextRender("手机号码：", item.phone)}
              {this.handleOrderTextRender("邮箱：", item.email)}
              {this.handleOrderTextRender("微信号：", item.wechat_code)}
              {this.handleOrderTextRender(
                "上级：",
                item.parent ? item.parent.name : ""
              )}
              {this.handleOrderTextRender(
                "商家性质：",
                item.type == 1 ? "企业" : "个人"
              )}
              {this.handleOrderTextRender(
                "渠道级别：",
                this.handleJibie(item.level)
              )}
              {this.handleOrderTextRender(
                "渠道范围：",
                (item.province ? item.province : "") +
                  (item.city ? item.city : "") +
                  (item.area ? item.area : "")
              )}
              {this.handleOrderTextRender(
                "状态：",
                this.handleStatus(item.status)
              )}
              {this.handleOrderTextRender("加入时间：", item.join_at)}
              {this.handleOrderTextRender("合同金额：", item.contract_money)}
              {/*{this.handleOrderTextRender('配货数额：',item.created_at)}*/}
            </div>
            <div className={"order_card_line"} />
          </>
        )
      }
    } else if (num == 2) {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            <div className={"order_card"} key={index}>
              {this.handleOrderTextRender("学校编码：", item.id)}
              {this.handleOrderTextRender("学校名称：", item.name)}
              {this.handleOrderTextRender(
                "学段：",
                item.type == 1 ? "幼儿园" : "小学"
              )}
              {this.handleOrderTextRender("加入时间：", item.created_at)}
            </div>
            <div className={"order_card_line"} />
          </>
        )
      }
    } else {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            <div className={"order_card"} key={index}>
              {this.handleOrderTextRender("产品名称：", item.product_name)}
              {this.handleOrderTextRender("学校码：：", item.cdk)}
              {this.handleOrderTextRender(
                "状态：",
                this.handleStatusRender(item.status)
              )}
              {this.handleOrderTextRender("学校名称：", item.school_name)}
              {this.handleOrderTextRender("创建日期：", item.created_at)}
            </div>
            <div className={"order_card_line"} />
          </>
        )
      }
    }
    return (
      <div>
        <div className={"order_top_line row_align"}>{title}</div>
        {num == 1 && lookDetails == "lookDetails" && (
          <div>
            <Picker
              title="选择地区"
              extra="请选择(可选)"
              data={cityData}
              value={pickerValue}
              onChange={(v) => this.setState({ pickerValue: v })}
              onOk={this.onSelectChange}
            >
              <List.Item arrow="horizontal" onClick={this.onSelectChange}>
                筛选
              </List.Item>
            </Picker>
          </div>
        )}
        <ListView
          contentContainerStyle={{ backgroundColor: "#F6F7FB" }}
          ref={(el) => (this.lv = el)}
          dataSource={this.state.dataSource}
          renderFooter={() => (
            <div className="footer">
              {this.state.isLoading ? "加载中..." : "暂无更多数据"}
            </div>
          )}
          renderRow={row}
          useBodyScroll
          onEndReachedThreshold={10}
          onEndReached={this.onEndReached}
          pageSize={10}
        />
      </div>
    )
  }

  // TAB切换
  changeTabs = (key) => {
    console.log(key)
    this.setState(
      {
        activeKey: key,
        datas: [],
        pageNo: 1,
        pageSize: 2,
        hasMore: true,
        refreshing: true,
        isLoading: true,
        dataArr: [],
        dataSource: new ListView.DataSource({
          rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
        }),
        totalPage: 1,
      },
      () => {
        this.handleGetOrder()
      }
    )
  }

  render() {
    const { activeKey, HeaderTitle, lookDetails } = this.state
    return (
      <div
        className={"pay_result_view"}
        style={{ height: window.innerHeight + "px" }}
      >
        <HeaderView title={HeaderTitle} />
        <Tabs
          activeKey={activeKey}
          onChange={this.changeTabs}
          tabBarStyle={{
            marginBottom: 0,
            color: "#34373D",
            paddingTop: "13px",
            paddingLeft: "40px",
          }}
          centered
          tabBarGutter={40}
        >
          <TabPane tab="商家" key="1">
            {this.handleMsgRender("商家信息", 1)}
          </TabPane>
          {lookDetails !== "lookDetails" && (
            <TabPane tab="客户" key="2">
              {this.handleMsgRender("直属客户", 2)}
            </TabPane>
          )}
        </Tabs>
      </div>
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
  }
)(Channel)
