import React, { Component } from "react"
import { Toast } from "antd-mobile"
import { HomeOutlined, RollbackOutlined } from "@ant-design/icons"
import { Breadcrumb, } from "antd"
import { util } from "common/util"
import { api } from "common/Config"
import { isNull } from "utils/isNull"
import HeaderView from "../../component/header/headerView"
import "./css/dlsCourse.css"
import grayRightIcon from "image/gray_right_icon.png"
import Vconsole from "common/Vconsole"
import excel_img from "image/excel.png"
import fileview_img from "image/fileview.png"
import word_img from "image/word.png"
import txt_img from "image/txt.png"
import pdf_img from "image/pdf.png"
import ppt_img from "image/ppt.png"
import image_img from "image/image.png"
import mp4_img from "image/mp4.png"
import commonfile_img from "image/commonfile.png"
const fileTypeArr = [
  "xlsx",
  "docx",
  "fileview",
  "txt",
  "pdf",
  "pptx",
  "jpg",
  "png",
  "jpeg",
  "mp4",
]

let fileTypeArr2 = [
  "docx",
  "txt",
  "pdf",
  "jpg",
  "png",
  "jpeg",
  "gif",
  "mp3",
  "mp4",
]

/* 商家学院 */
export default class DlsCourse extends Component {
  state = {
    debuggerCount: 1,
    fileList: [],
    path: [],
  }
  render() {
    const { fileList, path, roleType } = this.state
    let dlsFromPage = sessionStorage.getItem("dlsFromPage")
    console.log("dlsFromPage", dlsFromPage)
    return (
      <div className={"dlsCourse_view"}>
        {dlsFromPage == "my" && (
          <HeaderView
            title={roleType && roleType == 1 ? "商家学院" : "合伙人学院"}
            page="/"
          ></HeaderView>
        )}

        {dlsFromPage == "self" && (
          <div className={"row_between title_text"}>
            <div style={{ opacity: 0 }}>-</div>
            <div>{roleType && roleType == 1 ? "商家学院" : "合伙人学院"}</div>
            <div style={{ opacity: 0 }}>-</div>
          </div>
        )}

        <div className={"gray_line_view"} onClick={this.showDebugger}></div>

        <div className={"breadcrumb_view"}>
          <Breadcrumb separator=">">
            <Breadcrumb.Item onClick={this.returnFile}>
              <HomeOutlined />{" "}
              <span>
                {roleType && roleType == 1 ? "商家学院" : "合伙人学院"}
              </span>
            </Breadcrumb.Item>

            {path.map((item) => {
              return (
                <Breadcrumb.Item
                  key={item.id}
                  onClick={this.enterHierarchy.bind(this, item)}
                >
                  {item.name}
                </Breadcrumb.Item>
              )
            })}
          </Breadcrumb>
        </div>

        {path.length > 0 && (
          <RollbackOutlined
            className={"rollbackOutlined"}
            onClick={this.returnBackFn}
          />
        )}

        {fileList.map((item) => {
          return (
            <div
              className={"row_between user_box posiNull "}
              key={item.id}
              onClick={this.enterFile.bind(this, item)}
            >
              <div>
                {item.ftype == "xlsx" && (
                  <img src={excel_img} className={"doc_img"} alt="" />
                )}
                {item.ftype == "fileview" && (
                  <img src={fileview_img} className={"doc_img"} alt="" />
                )}
                {item.ftype == "docx" && (
                  <img src={word_img} className={"doc_img"} alt="" />
                )}
                {item.ftype == "txt" && (
                  <img src={txt_img} className={"doc_img"} alt="" />
                )}
                {item.ftype == "pdf" && (
                  <img src={pdf_img} className={"doc_img"} alt="" />
                )}
                {item.ftype == "pptx" && (
                  <img src={ppt_img} className={"doc_img"} alt="" />
                )}
                {item.ftype == "jpg" && (
                  <img src={image_img} className={"doc_img"} alt="" />
                )}
                {item.ftype == "png" && (
                  <img src={image_img} className={"doc_img"} alt="" />
                )}
                {item.ftype == "mp4" && (
                  <img src={mp4_img} className={"doc_img"} alt="" />
                )}
                {item.ftype == "commonfile" && (
                  <img src={commonfile_img} className={"doc_img"} alt="" />
                )}
                {item.name}
              </div>
              <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
            </div>
          )
        })}

        {/* <div
          className={"row_between user_box posiNull"}
          onClick={this.ToWarrant}
          onClick={() => {
            this.props.history.push("warrant")
          }}
        >
          <div>
            <img src={shouquanImg} className={"doc_img"} />
            运营中心授权
          </div>
          <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
        </div> */}
      </div>
    )
  }

  componentDidMount() {
    this.init()
    // new Vconsole()
  }

  init = async () => {
    await sessionStorage.setItem(
      "dlsFromPage",
      this.props.location.state ? this.props.location.state.page : "self"
    )

    let token = localStorage.getItem("login_token")
    if (isNull(token)) {
      this.props.history.push({
        pathname: "/login",
        state: {
          page: "dlsCourse",
        },
      })
    } else {
      util.postYangAxios(
        api.info,
        {},
        async (res) => {
          let dlsFromPage = sessionStorage.getItem("dlsFromPage")
          if (res && res.status == 0 && dlsFromPage == "self") {
            Toast.info("您暂时还不是合伙人，请成为合伙人后再次访问合伙人学院！")
            this.props.history.push("/")
          }
        },
        (err) => {
          console.log(err)
        }
      )
    }

    this.getfileList({ pagesize: 80, search_data: { p_id: 0 } })
    this.handleGetData()
    localStorage.setItem("reloadpage", 1)
  }

  // 获取合伙人信息
  handleGetData = () => {
    util.getYangAxios(
      api.infoDetails,
      {},
      async (res) => {
        this.setState({
          roleType: res.type,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  getfileList(params) {
    util.getYangAxios(
      api.fileList,
      params,
      async (res) => {
        // console.log("res--文件", res)
        res.list.forEach((item) => {
          if (item.type == 2) {
            let ftype = item.name.split(".")[1]
            item.ftype = fileTypeArr.includes(ftype) ? ftype : "commonfile"
          } else {
            item.ftype = "fileview"
          }
        })

        this.setState({
          fileList: res.list,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  showDebugger = () => {
    this.setState(
      {
        debuggerCount: this.state.debuggerCount + 1,
      },
      () => {
        if (this.state.debuggerCount == 13) {
          new Vconsole()
        }
      }
    )
  }

  /*  1doc 2xls */
  toProps = (filetype, filename, fileurl, isCanLook) => {
    this.props.history.push({
      pathname: "fileview",
      state: {
        filetype,
        filename,
        fileurl,
        isCanLook,
      },
    })
  }

  enterFile = (item) => {
    console.log("item", item)
    if (item.type == 2) {
      /* 文件点击 */
      this.toProps(
        item.ftype,
        item.name,
        item.path,
        fileTypeArr2.includes(item.ftype) ? true : false
      )
      return
    }

    /* 文件夹点击 */
    this.setState(
      {
        path: [...this.state.path, item],
      },
      () => {
        console.log(this.state.path, "----")
      }
    )
    this.getfileList({ pagesize: 80, search_data: { p_id: item.id } })
  }
  /* 返回顶层 */
  returnFile = () => {
    // console.log("item-返回顶层")
    // return
    this.setState({
      path: [],
    })
    this.getfileList({ pagesize: 80, search_data: { p_id: 0 } })
  }
  /* 层级点击 */
  enterHierarchy = (item) => {
    console.log("item-层级点击", item)
    // return
    const { id,  } = item
    let oldpath = this.state.path
    let newpath = []
    let currentIndex = ""

    oldpath.forEach((item, index) => {
      if (item.id === id) {
        currentIndex = index
      }
    })

    oldpath.forEach((item, index) => {
      if (index <= currentIndex) {
        newpath = [...newpath, item]
      }
    })

    // console.log("newpath", newpath)
    this.setState({
      path: newpath,
    })
    this.getfileList({ pagesize: 80, search_data: { p_id: id } })
  }

  /* 返回上一级 */
  returnBackFn = () => {
    let oldpath = this.state.path
    let newpath = oldpath.filter((item, index) => index < oldpath.length - 1)

    this.setState({
      path: newpath,
    })

    this.getfileList({
      pagesize: 80,
      search_data: {
        p_id: newpath.length > 0 ? oldpath[oldpath.length - 2].id : 0,
      },
    })
  }
}
