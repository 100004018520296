import { XGGS_APP_ID} from "../common/Config"
import { Toast} from 'antd-mobile';
/**
   * 获取url参数
   * @param name（code）
   */
 export const getQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg); //获取url中"?"符后的字符串并正则匹配
    var context = "";
    if (r != null)
        context = decodeURIComponent(r[2]);
    reg = null;
    r = null;
    return context == null || context == "" || context == "undefined" ? "" : context;
}
//微信授权获取code
export const getWeChatcode=(redirect_uri,queryString)=>{
    let appId=XGGS_APP_ID
    // let productId=this.state.productId?this.state.productId:""
    // let redirect_uri=window.location.origin+window.location.pathname+'?productId='+productId
     window.location.replace(`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(redirect_uri)}&response_type=code&scope=snsapi_base&state=${queryString}#wechat_redirect`) 
    }

/**
   * 拉起微信公众号支付
   * @param response
   */
export const onBridgeReady = (response) => {
    if (response.package === undefined) {
        return;
    }
    return new Promise((resolve,reject)=>{
        window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
            "appId": response.appId,     //公众号名称，由商户传入
            "timeStamp": response.timeStamp,         //时间戳，自1970年以来的秒数
            "nonceStr": response.nonceStr, //随机串
            "package": response.package,
            "signType": response.signType,         //微信签名方式：
            "paySign": response.paySign //微信签名
        },
            function (res) {
                if (res.err_msg === "get_brand_wcpay_request:ok") {
                    resolve()
                } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
                    Toast.info("支付取消");
                    reject()
                } else if (res.err_msg === "get_brand_wcpay_request:fail") {
                    Toast.info("支付失败");
                    reject()
                } else {
                    Toast.info(res.err_msg);
                    reject()
                }
                window.WeixinJSBridge.log(response.err_msg);
            }
        );
    })
  
};