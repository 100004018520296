import React, { Component } from "react"
import HeaderCloseView from "component/header/headerCloseView"
import ss from "page/bankCard/css/successView.module.css"
import failImg from "image/pay_select_red_icon.png"

export default class speedResult extends Component {
  state = {}
  render() {
    const { msg, url } = this.props.location.state
    return (
      <div className={ss.successView}>
        <HeaderCloseView page="/"></HeaderCloseView>

        <div className={ss.tip_view}>
          <img className={ss.img_bottom} src={failImg} alt="" />
          <p> {msg && <div>{msg}</div>} </p>
        </div>

        <span
          className={ss.edit_btn}
          onClick={() => {
            window.location.replace(url)
          }}
        >
          修改资料
        </span>

        <span
          className={ss.home_btn}
          onClick={() => {
            this.props.history.push("/")
          }}
        >
          返回首页
        </span>
      </div>
    )
  }

  componentDidMount() {
    this.init()
  }

  init = () => {
    console.log(this.props.location.state)
  }
}
