
import React, { Component } from "react"
import pay_select_blue_icon_img from '../../../image/pay_select_blue_icon.png'
import ss from "./css/commonstep.module.css"
const list=[
    {title:' <div>客户下单</div> <div>手机号：13888858693</div>',des:'2023-03-06 15:25:23'},
    {title:'平台直采',des:'2023-03-06 15:25:23'},
    {title:'自动发货',des:'2023-03-06 15:25:23'},
    {title:'确认收货',des:'2023-03-06 15:25:23'},
]
 class CommSteps extends Component {
    constructor(props) {
        super(props)
        this.state = { }
    }
    handRenderSteps=(index)=>{
        let  {currentStep}=this.props
        currentStep=5
        if(index+1<currentStep){
            return <img src={pay_select_blue_icon_img} alt="" className={ss.info_check_pic} />
        }else {
            return <div className={[`${ss.step_icons}`,`${index+1===currentStep?ss.step_active:''}`].join(' ')}>{index+1}</div>
        }
    }
    render() {
        let  {items,currentStep}=this.props
        items=list
        currentStep=5
        return (
            <div className={ss.common_steps_container}>
              
               {items.map((info,index)=>{
                return <div className={ss.info_box} key={index}>
                    <div className={ss.left_icons}>
                         {this.handRenderSteps(index)}
                         {index>0&&<div className={[`${ss.vertial_line}`,`${index<currentStep?ss.step_active:''}`].join(' ')}></div>}
                    </div>
                   
                    <div className={ss.info_content}>
                        {/* <div className={[`${ss.info_title}`,`${index<currentStep?ss.step_title_active:''}`].join(' ')}>{info.title}</div> */}
                        <div dangerouslySetInnerHTML={{__html:info.title}} className={[`${ss.info_title}`,`${index<currentStep?ss.step_title_active:''}`].join(' ')}></div>
                        <div className={ss.info_des}>{info.des}</div>
                    </div>
                </div>
               })}
                
            </div>
        )
    }
}

export default CommSteps