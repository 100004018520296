
import React, { Component } from "react"
import { Toast} from 'antd-mobile';
import HeaderView from "../../component/header/headerView"
import ss from "./css/details.module.css"
import { util } from "../../common/util"
import { api} from "../../common/Config"
import { getQueryString ,onBridgeReady,getWeChatcode} from "../../common/index"
class Order extends Component {
    constructor(props) {
        super(props)
        this.state = {
            type: 1,
            phone: '',
            list: [],
            key:"",
            productId:""
        }
    }
    componentDidMount() {
        var weChatCode=""
        var state=""
        weChatCode=getQueryString("code")
        state=getQueryString("state")
        var productId=getQueryString("productId")
        this.setState({productId:productId},()=>{ this.handleGetUserproductsList()})
        if(weChatCode){
            this.getPayParams(weChatCode,state)
        }
    }
    componentWillUnmount() {
        this.setState = () => false;
     }
    handGoPurchase(item) {
         if(!item.purhaseNum){
            Toast.info('请输入采购数量')
            return
         }
         if (!/^\+?[1-9][0-9]*$/.test(item.purhaseNum) && item.purhaseNum) {
            Toast.info('请输入正整数')
            return
        }
         if(item.stock<0&&Math.abs(item.stock)>item.purhaseNum){
            Toast.info('输入采购数量必须大于负库存的数量')
            return
         }
        util.getYangAxios(
            api.buyProductorders,
            {
                product_id:item.id,
                number:item.purhaseNum 
            },
            async (res) => {
                this.setState({key:res.key})
                this.getWeChatcode2(res.key)
                // this.handleWxPay(res.key)
            },
            (err) => {
                console.log(err)
            }
        )
    }
    
  handleWxPay = (key) => {
    let bound_url = api.links + `bottomTab`
    let dlsFromPage = sessionStorage.getItem("dlsFromPage")
    bound_url = bound_url + `?dlsFromPage=${dlsFromPage}`
    let params = {
      key: key,
      bound_url,
    }
    util.getYangAxios(
      api.cashierDesk,
      params,
      async (res) => {
        window.location.href = res.url
      },
      (err) => {
        console.log(err)
      }
    )
  }
  //授权获取code
   getWeChatcode2=(key)=>{
    let productId=this.state.productId?this.state.productId:""
    let redirect_uri=window.location.origin+window.location.pathname+'?productId='+productId
    getWeChatcode(redirect_uri,key)
   }
  //获取支付参数
  getPayParams=(code,key)=>{
    let that=this
    util.getYangAxios(
        api.wxDesk,
        {code,key},
        async (res) => {
            console.log(res,'获取支付参数')
            onBridgeReady(res).then(()=>{
                that.props.history.push("/")
            }).catch(()=>{
                that.props.history.go(-1)
            })
        },
        (err) => {
          console.log(err)
        }
      )
  }
    handPurchaseNum(event, index) {
        let num =event.target.value
          const nextList = this.state.list.map((c, i) => {
            if (i === index) {
                c.purhaseNum = num
                c.totalPrice =Number(c.price)*num
              return c;
            } else {
                // 其余部分不发生变化
                return c;
            }
        });
        this.setState({
            list: nextList
        })

    }
    // 库存清单
    handleGetUserproductsList = (productId) => {
        console.log(this.state.productId,'productId')
        util.getYangAxios(
            api.userproductsList,
            {},
            async (res) => {
               if(this.state.productId){
                    let prodcutObj=res.find((item)=>item.id==this.state.productId)||{}
                    console.log(prodcutObj,'prodcutObj')
                    if(prodcutObj.stock<0){
                        prodcutObj.purhaseNum=Math.abs(prodcutObj.stock)
                    }
                    this.setState({
                        list: [prodcutObj]
                    })
                }else{
                    let listArr=res
                    for(const item of listArr){
                        if(item.stock<0){
                            item.purhaseNum=Math.abs(item.stock)
                        }
                    }
                    this.setState({
                        list: listArr
                    })
                }
            },
            (err) => {
                console.log(err)
            }
        )
    }
    // 订单文字渲染
    handleOrderTextRender = (title, content, showInput = false, index) => {
        return (
            <div className={[`${ss.row_align}`, `${ss.order_text_div}`].join(' ')} style={{ width: "100%" }}>
                <div className={ss.row_align}>
                    <div className={ss.order_title_text}>{title}</div>
                    {!showInput && <div className={ss.order_content_text}>{content}</div>}
                </div>
                {showInput && (
                    <div className={ss.modal_input_wrap}>
                        <input type="number" className={ss.modal_input} value={content || ''} placeholder="请输入采购量" onChange={(e) => { this.handPurchaseNum(e, index) }} />
                    </div>
                )}
            </div>
        )
    }
    // 渲染消息
    handleMsgRender = (title, num) => {
        const { list } = this.state
        let row
        row = (item, index) => {
            // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
            return (
                <>
                    <div
                        className={ss.order_card}
                        key={index}
                    >

                        {this.handleOrderTextRender("产品名称：", item.name)}
                        {this.handleOrderTextRender("采购价：", item.price)}
                        {this.handleOrderTextRender("我的库存：", item.stock)}
                        {this.handleOrderTextRender("采购量：", item.purhaseNum, 'showInput', index)}
                        {this.handleOrderTextRender("订单总额：", item.totalPrice)}
                        <div className={ss.send_btn} onClick={() => { this.handGoPurchase(item) }}>立即采购</div>
                        <div className={ss.send_btn_tips}>采购说明：本产品采购后，可在库存中直接查看，采购成功后可通过短信通知使用人进行激活使用。</div>

                    </div>
                    <div className={ss.order_card_line} />
                </>
            )
        }
        return (
            <div>
                {list.map((item, key) => {
                    return <div key={key}>{row(item, key)}</div> 
                })}
            </div>
        )
    }
    render() {
        return (
            <div className={ss.order_container}>
                <HeaderView title={'采购'} />
                <div className={ss.order_card_line} />
                <div className={ss.purchase_wrap}>{this.handleMsgRender("采购", 1)}</div>

            </div>
        )
    }
}

export default Order