import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet } from "../../actions/XggsUserInformation"
import "./css/invite.css"
import HeaderView from "../../component/header/headerView"
import invite_bg_img from "../../image/invite_bg_img.png"
import invite_bg_partor from "../../image/partor.jpg"
import invite_bg_shoper from "../../image/shoper.jpg"
import xiaoBoShi_pic_01 from "../../image/xiaoBoShi_01.jpg"
import xiaoBoShi_pic_02 from "../../image/xiaoBoShi_02.jpg"
import xiaoBoShi_pic_03 from "../../image/xiaoBoShi_03.jpg"
import xiaoBoShi_pic_04 from "../../image/xiaoBoShi_04.jpg"
import xiaoBoShi_pic_05 from "../../image/xiaoBoShi_05.jpg"
import xiaoBoShi_pic_06 from "../../image/xiaoBoShi_06.jpg"
import xiaoBoShi_pic_07 from "../../image/xiaoBoShi_07.jpg"
import xiaoBoShi_pic_08 from "../../image/xiaoBoShi_08.jpg"
import jiaoPeiJia_pic from "../../image/jiaoPeiJia.jpg"

import { yangXinIp } from "../../common/Config"
import { Toast } from "antd-mobile"
class InviteDes extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inviteObj: null,
      navTitle:''
    }
  }

  componentDidMount() {
    let obj = this.getUrlParams()
    let  inviteMap={1:'习惯公社合伙人',2:'习惯公社习小店店主',3:'习惯公社习惯小博士',5:'教培+'}
    // "type": 1 //权益类型 1开通合伙人 2开通习小店店主 3开通习惯小博士
    if (obj) {
      this.setState({
        inviteObj: obj,
        navTitle:inviteMap[parseInt(obj.type)]
      })
      document.title=inviteMap[parseInt(obj.type)]
    }
   
  }

  getUrlParams = () => {
    let url = window.location.search //?articleId=1&&articleNum=1&opt=edit
    let index = url.indexOf("?")
    let obj = {}
    if (index !== -1) {
      let str = url.substr(1)
      let arr = str.split("&")
      for (let i = 0; i < arr.length; i++) {
        obj[arr[i].split("=")[0]] = arr[i].split("=")[1]
      }
    }
    return obj //{articleId: "1", articleNum: "1", opt: "edit"}
  }

  handleJoin = () => {
    const isLogin =window.localStorage.getItem("login_token")
    const search=window.location.search
    const queryParams=search.split('?')[1]
    if (isLogin==null||isLogin==='null'||isLogin===''||isLogin===undefined||isLogin==='undefined') {
      const pathname =  (window.location.pathname).split('/')[1]
      // this.props.history.replace('/login?redirectPath='+pathname+'&'+queryParams)
      let redirect=pathname+'&'+queryParams
       window.location.href = yangXinIp+'ambassador/share/' + this.state.inviteObj.agentId+'?redirectPath='+encodeURI(redirect)
    } else {
      const { inviteObj} = this.state
      const { userData } = this.props.UserDataReducer
      const inviteType=parseInt(inviteObj.type)
      if(userData.ambassador_status===1&&inviteType===1){
          Toast.info("您已经是合伙人", 1)
      }else if(userData.is_shopkeeper===1&&inviteType===2){
         Toast.info("您已经是习小店店主了", 1)
      }else if(userData.is_member===1&&inviteType===3){
        Toast.info("您已经是习惯小博士了", 1)
      }else{
         let queryStrying=`name=${inviteObj.name}&agentId=${inviteObj.agentId}&type=${inviteObj.type}`
          this.props.history.push("/payNew?"+queryStrying)
      }
    }
  }
  handShowPic=(inviteObj)=>{
    if(inviteObj){
      const inviteType=parseInt(inviteObj.type)
      if(inviteType===1){
        return  <img src={invite_bg_partor} alt="" className={"invite_bg_img_part"} />
      }else if(inviteType===2){
        return  <img src={invite_bg_shoper} alt="" className={"invite_bg_img_part"} />
      }else if(inviteType===3){
        return  <div>
            <img src={xiaoBoShi_pic_01} alt="" className={"invite_bg_img_part"} />
            <img src={xiaoBoShi_pic_02} alt="" className={"invite_bg_img_part"} />
            <img src={xiaoBoShi_pic_03} alt="" className={"invite_bg_img_part"} />
            <img src={xiaoBoShi_pic_04} alt="" className={"invite_bg_img_part"} />
            <img src={xiaoBoShi_pic_05} alt="" className={"invite_bg_img_part"} />
            <img src={xiaoBoShi_pic_06} alt="" className={"invite_bg_img_part"} />
            <img src={xiaoBoShi_pic_07} alt="" className={"invite_bg_img_part"} />
            <img src={xiaoBoShi_pic_08} alt="" className={"invite_bg_img_part"} />
        </div>
       
      }else if(inviteType===5){
        return  <img src={jiaoPeiJia_pic} alt="" className={"invite_bg_img_part"} />
      } else{
        return  <img src={invite_bg_img} alt="" className={"invite_bg_img_part"} />
      }
    }
   
  }

  render() {
    const { inviteObj } = this.state
    return (
      <div
        style={{ 'paddingBottom':'100px' }}
        className={"invite_des_view_container_new"}
      >
         <HeaderView title={''} />
        {/* <img src={invite_bg_partor} alt="" className={"invite_bg_img_part"} /> */}
        {this.handShowPic(inviteObj)}
        <div className={"row_center invite_btn_view_container"}>
          <div
            className={"row_center invite_btn_view_des"}
            onClick={this.handleJoin}
          >
            下一步
          </div>
        </div>
      </div>
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
  }
)(InviteDes)
