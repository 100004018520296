import React, { Component } from "react"
import { ListView, Modal, Toast } from "antd-mobile"
import { Drawer } from "antd"
import RoleModal from "component/roleModal/roleModal"
import AntModal from "component/antModal/antModal"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import ss from "./css/home.module.css"
import { connect } from "react-redux"
import Statics from "./components/Statics"
import RecentActive from "./components/RecentActive"
import IncomeList from "./components/IncomeList"
import { userDataSet, tabSetIndex, infoTabSet } from "../../actions/XggsUserInformation"
import ambassador_company_img from "../../image/ambassador_company_img.png"
import msg_white_icon from "../../image/msg_white_icon.png"
import cash_icon from "../../image/cash_icon.png"
import invite_icon from "../../image/invite_icon.png"
import purch_icon from "../../image/purch_icon.png"
import stock_icon from "../../image/stock_icon.png"
import out_login_icon from "../../image/out_login_icon.png"
import close_white_icon from "../../image/close_white_icon.png"
import xggs_logo from "../../image/xggs_logo.png"
import { isNull } from "utils/isNull"
import NoMoneyUser from "component/noMoneyUser/noMoneyUser"
import Accord from "component/accord/accord"
class Home extends Component {
    constructor(props) {
        super(props)
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
        })
        this.state = {
            id: "",
            dataSource,
            datas: [],
            pageNo: 1,
            pageSize: 2,
            hasMore: true,
            refreshing: true,
            isLoading: true,
            dataArr: [],
            totalPage: 1,
            msgNum: '',
            incomeObj: {},
            toggleRoleIsShow: false,
            attestation: '',
            roleType: '',
            hephone: '',
            isModalVisible: false,
            medata: {
                isModalVisible: true,
            },
            outLogin: false,
            infosTag: false,
            visible: false
        }
    }

    componentDidMount() {
        this.handleGetAgentsIncome()
        this.handleGetData()
        this.handleGetMsg()
    }
    // 获取消息列表
    handleGetMsg = () => {
        util.getYangAxios(
            api.msgList,
            {
                search_data: { is_read: 0 },
            },
            async (res) => {
                const len = res.total
                this.setState({
                    msgNum: len,
                })
            },
            (err) => {
                console.log(err)
            }
        )
    }
    // 获取合伙人信息
    handleGetData = () => {
        util.getYangAxios(
            api.infoDetails,
            {},
            async (res) => {
                this.setState({
                    attestation: res.is_realname,
                    roleType: res.type ? res.type : "",
                    hephone: res.phone,
                    infosTag: true
                })

                let range = {
                    province: res.province,
                    city: res.city,
                    area: res.area,
                    type: res.type,
                }
                sessionStorage.setItem("range", JSON.stringify(range))
                sessionStorage.setItem("hephone", JSON.stringify(res.phone))
            },
            (err) => {
                console.log(err)
            }
        )
    }
    // 个人收益信息
    handleGetAgentsIncome = () => {
        util.getYangAxios(
            api.agentsIncome,
            {},
            async (res) => {
                this.setState({
                    incomeObj: res
                })
            },
            (err) => {
                console.log(err)
            }
        )
    }
    goBackF = () => {
        this.props.history.replace({
            pathname: "/",
        })
    }

    // 总接口
    handleGetOrder = () => {
        if (this.state.pageNo > this.state.totalPage) {
            this.setState({
                refreshing: false,
                isLoading: false,
                hasMore: false,
            })
            return false
        }
    }
    onClose = (key) => () => {
        this.setState({
            [key]: false,
        })
    }
    toggleRole = (id) => {
        if (isNull(id)) {
            Toast.info("选择身份错误,请重试")
            return
        }
        this.toggleRoleApi(id)
    }
    toggleRoleApi = (role) => {
        util.postYangAxios(
            api.toggleRoleSure + role,
            {
                role,
            },
            async (res) => {
                this.setState({
                    toggleRoleIsShow: false,
                })
                Toast.info("切换成功", 0.5)
                setTimeout(() => {
                    window.location.reload()
                }, 0.6)
            },
            (err) => {
                console.log(err)
            }
        )
    }
    toToggle = () => {
        this.setState({
            toggleRoleIsShow: true,
        })
    }
    cashMoney = async () => {
        const { attestation, roleType, infosTag } = this.state
        if (!infosTag) {
            return
        }
        if (!attestation && roleType != 1) {
            this.setState({
                isModalVisible: true,
            })
            return
        }
        this.props.history.push({
            pathname: "cashBag",
            state: {
                hasMoney: "",
                enterPageType: 2,
            },
        })
    }
    cancleFn = ({ type }) => {
        type == 1 && this.props.history.push("authentication")
        type == 2 &&
            this.setState({
                isModalVisible: false,
            })
    }
    handGoDetail = (type) => {
        this.props.history.push({
            pathname: "/balance/list",
            state: {
                type: type,
            },
        })
    }
    handGoBalance = () => {
        this.props.history.push({
            pathname: "/balance",
        })
    }
    showModal = (key) => (e) => {
        console.log(key, 333)
        e.preventDefault() // 修复 Android 上点击穿透
        this.setState({
            outLogin: true,
        })
    }
    onModalClose = (key) => () => {
        this.setState({
            [key]: false,
        })
    }
    handleOutLogin = async () => {
        util.getYangAxios(
            api.logout,
            {},
            async (res) => { },
            (err) => {
                console.log(err)
            }
        )
        await window.localStorage.removeItem("login_token")
        await window.localStorage.removeItem("login_long_token")
        await window.sessionStorage.removeItem("userData")
        await window.sessionStorage.removeItem("range")
        await window.sessionStorage.removeItem("hasName")
        await window.sessionStorage.removeItem("tabStr")
        await window.sessionStorage.removeItem("hephone")
        await window.localStorage.removeItem("isT")
        this.props.tabSetIndex("")
        await this.onModalClose("outLogin")
        await this.props.history.replace("/")
    }

    showDrawer = (ref) => {
        ref &&
            this.setState({
                visible: true,
            })
    }


    render() {
        const { outLogin, incomeObj, toggleRoleIsShow, attestation, roleType, isModalVisible, msgNum, infosTag, visible } = this.state
        const { userData } = this.props.UserDataReducer
        return (
            <div className={ss.home_container}>
                <div className={ss.home_top_contianer}>
                <div onClick={this.showDrawer.bind(
                            this,
                            userData.role == 5 && userData.role != 99 && userData.role != 88 ? true : false
                        )}
                        className={
                            userData.role == 5 && userData.role != 99 && userData.role != 88
                                ? "home_header_menu_box row_center home_header_menu_box_new"
                                : "home_header_menu_box row_center opacityZero home_header_menu_box_new"
                        }
                    ></div>
                    {userData && userData.status != 0 ? (<div className={ss.message_icon_container} onClick={() => this.props.history.push("msg")}>
                        <img src={msg_white_icon} alt="" className={ss.message_icon} />
                        {msgNum > 0 && (<div className={ss.msg_counts}>{msgNum}</div>)}
                    </div>) : (<div className={ss.message_icon_container} onClick={this.showModal("outLogin")}>
                        <img src={out_login_icon} alt="" className={ss.message_icon} />
                    </div>)}

                    <div className={ss.user_container}>
                        <div className={ss.user_img_contaner}>
                            <img src={
                                userData && userData.head_portrait
                                    ? `https://cdn.ellll.com/${userData.head_portrait}`
                                    : ambassador_company_img
                            } alt="" className={ss.user_img} />
                        </div>
                        <div className={ss.user_info_container}>
                            <div className={ss.user_info}>
                                <span className={ss.user_name}> {userData ? userData.name : ""}</span>
                                {/* <span className={ss.user_phone}>1870214594</span> */}
                            </div>
                            <div className={ss.user_tag}> {userData && userData.status == 1 ? userData.levelname : "待激活合伙人"}</div>
                        </div>
                        <div className={ss.toggle_user_btn} onClick={this.toToggle}>切换身份</div>
                    </div>
                    <div className={ss.user_account_info_container}>
                        <div className={ss.user_account_item}>
                            <div className={ss.user_account_value}>{incomeObj.total_balance}</div>
                            <div className={ss.user_account_name}>总收益</div>
                        </div>
                        <div className={ss.user_account_item} onClick={() => this.handGoDetail(3)}>
                            <div className={ss.user_account_value}>{incomeObj.allocating}</div>
                            <div className={ss.user_account_name}>待分配</div>
                        </div>
                        <div className={ss.user_account_item} onClick={() => this.handGoBalance()}>
                            <div className={ss.user_account_value}>{incomeObj.surplus}</div>
                            <div className={ss.user_account_name}>余额</div>
                        </div>
                    </div>
                    {userData && userData.status != 0 && <div className={ss.icon_container}>
                        <div className={ss.icon_item} onClick={() => {
                            // if (!infosTag) {
                            //     return
                            // }
                            // if (!attestation && roleType != 1) {
                            //     this.setState({
                            //         isModalVisible: true,
                            //     })
                            //     return null
                            // }
                            this.props.history.push("qrCode")
                        }}>
                            <div className={ss.icon_wrap}>
                                <img src={invite_icon} alt="" className={ss.icon_pic} />
                            </div>
                            <div className={ss.icon_name}>我的邀请码</div>
                        </div>
                        <div className={ss.icon_item} onClick={() => this.props.history.push("/stock/purchase")}>
                            <div className={ss.icon_wrap}>
                                <img src={purch_icon} alt="" className={ss.icon_pic} />
                            </div>
                            <div className={ss.icon_name}>采购</div>
                        </div>
                        <div className={ss.icon_item} onClick={() => this.props.history.push("/stock/stock")}>
                            <div className={ss.icon_wrap}>
                                <img src={stock_icon} alt="" className={ss.icon_pic} />
                            </div>
                            <div className={ss.icon_name}>库存</div>
                        </div>
                        {userData.role != 4 &&
                            userData.role != 6 &&
                            userData.role != 5 &&
                            userData.role != 7 && (<div className={ss.icon_item} onClick={this.cashMoney} >
                                <div className={ss.icon_wrap}>
                                    <img src={cash_icon} alt="" className={ss.icon_pic} />
                                </div>
                                <div className={ss.icon_name}>提现</div>
                            </div>)}
                    </div>}
                </div>
                {userData && userData.status == 0 &&
                    // 没付钱用户，目前改为可以使用渠道功能，但不能提现，用户类别为待激活合伙人
                    <NoMoneyUser history={this.props.history}></NoMoneyUser>
                }
                {userData && userData.status != 0 && (<div>
                    {/* 数据统计 */}
                    <Statics props={this.props}></Statics>
                    {/* 最新活动 */}
                    {/* <RecentActive ></RecentActive> */}
                    {/* 收益明细（全部） */}
                    <IncomeList></IncomeList>
                    {/* 切换身份  */}
                </div>)}
                <Modal
                    visible={toggleRoleIsShow}
                    transparent
                    maskClosable
                    className="ModalCss"
                    wrapClassName="warpCss"
                    onClose={this.onClose("toggleRoleIsShow")}
                >
                    <RoleModal toggleRole={this.toggleRole}></RoleModal>
                </Modal>
                {/* 退出登陆 */}
                <Modal
                    visible={outLogin}
                    transparent
                    maskClosable
                    onClose={() => this.onModalClose("outLogin")}
                >
                    <div className={"out_login_title"}>是否退出登录</div>
                    <div className={"row_between"}>
                        <div
                            className={"row_center close_btn"}
                            onClick={this.onModalClose("outLogin")}
                        >
                            取消
                        </div>
                        <div className={"row_center yes_btn"} onClick={this.handleOutLogin}>
                            确定
                        </div>
                    </div>
                </Modal>
                <Drawer
                    placement={"left"}
                    closable={false}
                    onClose={this.onClose("visible")}
                    visible={visible}
                    key={"left"}
                    contentWrapperStyle={{ padding: 0 }}
                    bodyStyle={{ padding: 0 }}
                >
                    <div className={"drawer_top_div"}>
                        <div className={"drawer_top_close_div"}>
                            <img
                                src={close_white_icon}
                                alt=""
                                className={"close_white_icon"}
                                onClick={this.onClose("visible")}
                            />
                        </div>
                        <div className={"drawer_top_logo_div"}>
                            <img src={xggs_logo} alt="" className={"xggs_logo"} />
                        </div>
                    </div>
                    <Accord history={this.props.history}></Accord>
                </Drawer>

                {isModalVisible === true && (
                    <AntModal
                        data={this.state.medata}
                        cancleFn={this.cancleFn}
                    ></AntModal>
                )}
            </div>
        )
    }
}

export default connect(
    (state) => {
        const { UserDataReducer } = state
        return {
            UserDataReducer,
        }
    },
    {
        userDataSet,
        tabSetIndex,
        infoTabSet,
    }
)(Home)
