import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet } from "../../actions/XggsUserInformation"
import "./css/order.css"
import {
  Toast,
  ListView,
  Button,
  Modal,
  Picker,
  List,
} from "antd-mobile"
import HeaderView from "../../component/header/headerView"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import grayRightIcon from "../../image/gray_right_icon.png"

class Order extends Component {
  constructor(props) {
    super(props)
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
    })
    this.state = {
      dataSource,
      pageNo: 1,
      pageSize: 2,
      hasMore: true,
      refreshing: true,
      isLoading: true,
      dataArr: [],
      TQModal: false,
      selectData: [],
      currentV: {
        name: "",
        id: "",
      },
      sValue: "",
      totalPage: 1,
      HeaderTitle: "学校码",
      mode: 1,
    }
  }

  componentDidMount() {
    // console.log("order接收到的参数", this.props.history.location.state)
    this.handleGetOrder()
    this.handleGetProList() // 获取产品列表
  }

  // 总接口
  handleGetOrder = () => {
    if (this.state.pageNo > this.state.totalPage) {
      this.setState({
        refreshing: false,
        isLoading: false,
        hasMore: false,
      })
      // Toast.info("没有数据了~", 1)
      return false
    }
    this.handleGetExtractOrder() //学校码
  }

  // 获取提取订单
  handleGetExtractOrder(ref = false) {
    if (ref) {
      this.setState({
        pageNo: 1,
        dataArr: [], // 保存新数据进state
      })
    }
    util.getYangAxios(
      api.extractOrder,
      {
        currentPage: this.state.pageNo,
      },
      async (res) => {
        const dataList = res.list
        this.setState({
          totalPage: res.totalPage,
        })
        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }
  // 获取产品名称列表
  handleGetProList() {
    util.getYangAxios(
      api.productionList,
      {
        search_data: {
          type: 1,
        },
      },
      async (res) => {
        const dataList = res.list.map((item) => {
          return {
            label: item.name,
            value: item.id,
          }
        })

        this.setState({
          selectData: dataList,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 滑动到底部时加载更多
  onEndReached = (event) => {
    // 加载中或没有数据了都不再加载
    if (this.state.isLoading || !this.state.hasMore) {
      return
    }
    this.setState(
      {
        isLoading: true,
        pageNo: this.state.pageNo + 1, // 加载下一页
      },
      () => {
        this.handleGetOrder()
      }
    )
  }


  // 订单文字渲染
  handleOrderTextRender = (title, content, isLook = false) => {
    return (
      <div className={"row_align order_text_div"} style={{ width: "100%" }}>
        <div className={"row_align"}>
          <div className={"order_title_text"}>{title}</div>
          <div className={"order_content_text"}>{content}</div>
        </div>
        {isLook && (
          <div className={"order_look_text row_end"}>
            查看
            <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
          </div>
        )}
      </div>
    )
  }

  // 渲染订单状态
  handleStatusRender = (num) => {
    if (num == 0) {
      return "未激活"
    } else if (num == 10) {
      return "已激活"
    } else if (num == 20) {
      return "已过期"
    } else {
      return "未知"
    }
  }

  // 渲染消息
  handleMsgRender = (title, num) => {
    let row
    let { TQModal, selectData,  } = this.state
    const { type, province, city, area } = JSON.parse( sessionStorage.getItem("range"))
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            <div className={"order_card"} key={index}>
              {this.handleOrderTextRender("产品名称：", item.product_name)}
              {this.handleOrderTextRender("学校码：", item.cdk)}
              {this.handleOrderTextRender(
                "状态：",
                this.handleStatusRender(item.status)
              )}
              {this.handleOrderTextRender("学校名称：", item.school_name)}
              {this.handleOrderTextRender("创建日期：", item.created_at)}
            </div>
            <div className={"order_card_line"} />
          </>
        )
      }
  
    return (
      <div>
        <div className={"order_top_line row_align"}>{title}</div>
        {/* 提取 */}
        {num == 4 && (
          <div className={"btn_Box"}>
            <Button
              size="small"
              className={"btn_tq"}
              type="primary"
              onClick={this.clickTQ}
            >
              提取
            </Button>
          </div>
        )}

        <Modal
          visible={TQModal}
          transparent
          maskClosable
          className="ModalCss"
          wrapClassName="warpCss"
        >
          <div className={"Modal_Box"}>
            <div className={"Modal_content"}>
              <List
                style={{ backgroundColor: "white" }}
                className="picker-list"
                styles={{ Line: { borderBottomWidth: 0 } }}
              >
                <Picker
                  data={selectData}
                  cols={1}
                  value={this.state.sValue}
                  className="forss"
                  onChange={this.handleChange}
                  onOk={this.handleChange}
                >
                  <List.Item
                    arrow="horizontal"
                    styles={{ Line: { borderBottomWidth: 0 } }}
                  >
                    产品名称：
                  </List.Item>
                </Picker>
              </List>

              {province && type == 1 && (
                <span className={"tiquTip"}>
                  *提取码只能在{province ? province : ""}
                  {city ? city : ""}
                  {area ? area : ""}范围内使用
                </span>
              )}
            </div>

            <footer className={"Modal_footer"}>
              <div className={"btns_common sure_btn"} onClick={this.TQSure}>
                确定
              </div>
              <div
                className={"btns_common cancal_btn"}
                onClick={() => {
                  this.setState({
                    TQModal: false,
                  })
                }}
              >
                取消
              </div>
            </footer>
          </div>
        </Modal>

        <ListView
          contentContainerStyle={{ backgroundColor: "#F6F7FB" }}
          ref={(el) => (this.lv = el)}
          dataSource={this.state.dataSource}
          renderFooter={() => (
            <div className="footer">
              {this.state.isLoading ? "加载中..." : "暂无更多数据"}
            </div>
          )}
          renderRow={row}
          useBodyScroll
          onEndReachedThreshold={10}
          onEndReached={this.onEndReached}
          pageSize={10}
        />
      </div>
    )
  }

  //提取
  clickTQ = () => {
    this.setState({
      TQModal: true,
      sValue: "",
    })
  }
  TQSure = () => {
    const { sValue } = this.state
    if (sValue == "") {
      Toast.info("请选择")
      return
    }

    this.requestApiSure()
  }
  requestApiSure = () => {
    const { id, name } = this.state.currentV
    util.postYangAxios(
      api.createSure,
      {
        product_id: id,
        product_name: name,
      },
      async (res) => {
        this.setState({
          TQModal: false,
        })
        this.handleGetExtractOrder(true)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  handleChange = (value) => {
    this.setState=({sValue :value})
    let obj = {}
    this.state.selectData.forEach((item) => {
      if (item.value == value[0]) {
        obj.name = item.label
        obj.id = item.value
      }
    })
    this.setState({
      currentV: {
        ...obj,
      },
    })
  }

  // 获取用户信息
  handleGetUserData = () => {
    util.postYangAxios(
      api.info,
      {},
      async (res) => {
        this.props.userDataSet(res)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  render() {
    const {HeaderTitle,  } = this.state
    return (
      <div
        className={"pay_result_view"}
        style={{ height: window.innerHeight + "px" }}
      >
        <HeaderView title={HeaderTitle} />
        {this.handleMsgRender("提取信息", 4)}
      </div>
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
  }
)(Order)
