import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet } from "../../actions/XggsUserInformation"
import "./css/msg.css"
import {  Toast, ListView } from "antd-mobile"
import HeaderView from "../../component/header/headerView"
import { Tabs } from "antd"
import { util } from "../../common/util"
import { api } from "../../common/Config"
const { TabPane } = Tabs
class Msg extends Component {
  constructor(props) {
    super(props)
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
    })
    this.state = {
      activeKey: "1",
      dataSource,
      datas: [],
      pageNo: 1,
      pageSize: 2,
      hasMore: true,
      refreshing: true,
      isLoading: true,
      dataArr: [],
      msgNum: 0,
    }
  }

  componentDidMount() {
    this.getData(true)
  }

  getData(ref = false) {
    const { activeKey } = this.state
    let obj = {}
    if (activeKey === "1") {
      obj = {}
    } else {
      obj = {
        search_data: { is_read: activeKey == "2" ? 1 : 0 },
      }
    }
    util.getYangAxios(
      api.msgList,
      {
        search_data: { is_read: 0 },
      },
      async (res) => {
        const len = res.total
        this.setState({
          msgNum: len,
        })
      },
      (err) => {
        console.log(err)
      }
    )
    util.getYangAxios(
      api.msgList,
      {
        currentPage: this.state.pageNo,
        ...obj,
      },
      async (res) => {
        const dataList = res.list
        const len = dataList.length
        if (len <= 0) {
          // 判断是否已经没有数据了
          this.setState({
            refreshing: false,
            isLoading: false,
            hasMore: false,
          })
          Toast.info("没有数据了~", 1)
          return false
        }
        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 滑动到底部时加载更多
  onEndReached = (event) => {
    // 加载中或没有数据了都不再加载
    if (this.state.isLoading || !this.state.hasMore) {
      return
    }
    this.setState(
      {
        isLoading: true,
        pageNo: this.state.pageNo + 1, // 加载下一页
      },
      () => {
        this.getData(false)
      }
    )
  }

  // 支付
  handlePay = () => {
    this.props.history.push("payResult")
  }

  handleClickMsg = (obj) => {
    console.log(obj)
    if (obj.is_read == 0) {
      util.postYangAxios(
        api.readMsg,
        {
          id: obj.id,
        },
        async (res) => {
          this.changeTabs(this.state.activeKey)
        },
        (err) => {
          console.log(err)
        }
      )
    }
  }

  // 一键已读
  handleAllRead = () => {
    const { msgNum } = this.state
    if (msgNum == 0) {
      return null
    }
    util.postYangAxios(
      api.readAllMsg,
      {},
      async (res) => {
        this.changeTabs(this.state.activeKey)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 渲染消息
  handleMsgRender = () => {
    const row = (item, sectionID, index) => {
      // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
      return (
        <div
          className={"msg_content_view"}
          key={index}
          onClick={() => this.handleClickMsg(item)}
        >
          <div className={"msg_card_view"}>
            <div className={"row_between"}>
              <div className={"row_align msg_card_title_text"}>
                <div
                  className={
                    item.is_read == 0
                      ? "msg_card_red_circle"
                      : "msg_card_gray_circle"
                  }
                />
                {item.name}
              </div>
              <div className={"msg_card_date_text"}>{item.created_at}</div>
            </div>
            <div className={"msg_card_content_text"}>{item.tits}</div>
          </div>
        </div>
      )
    }
    return (
      <div>
        <div className={"msg_top_line"} />
        <ListView
          ref={(el) => (this.lv = el)}
          dataSource={this.state.dataSource}
          renderFooter={() => (
            <div className="footer">
              {this.state.isLoading ? "加载中..." : "暂无更多数据"}
            </div>
          )}
          renderRow={row}
          useBodyScroll
          onEndReachedThreshold={10}
          onEndReached={this.onEndReached}
          pageSize={10}
        />
      </div>
    )
  }

  // TAB切换
  changeTabs = (key) => {
    console.log(key)
    this.setState(
      {
        activeKey: key,
        datas: [],
        pageNo: 1,
        pageSize: 2,
        hasMore: true,
        refreshing: true,
        isLoading: true,
        dataArr: [],
        dataSource: new ListView.DataSource({
          rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
        }),
      },
      () => {
        this.getData(true)
      }
    )
  }

  render() {
    const { activeKey, msgNum } = this.state
    return (
      <div
        className={"pay_result_view"}
        style={{ height: window.innerHeight + "px" }}
      >
        <HeaderView title={"消息"} />
        <div style={{ position: "relative" }}>
          <Tabs
            activeKey={activeKey}
            onChange={this.changeTabs}
            tabBarStyle={{
              paddingLeft: 30,
              marginBottom: 0,
              color: "#34373D",
              marginTop: "13px",
            }}
          >
            <TabPane tab="全部" key="1">
              {this.handleMsgRender()}
            </TabPane>
            <TabPane tab="已读" key="2">
              {this.handleMsgRender()}
            </TabPane>
            <TabPane tab="未读" key="3">
              {this.handleMsgRender()}
            </TabPane>
          </Tabs>
          <div
            className={msgNum != 0 ? "been_read_btn" : "been_read_gray_btn"}
            onClick={this.handleAllRead}
          >
            一键已读
          </div>
        </div>
      </div>
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
  }
)(Msg)
