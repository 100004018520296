import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/purchaseOrder.css'

import HeaderView from "../../component/header/headerView";
import { Steps} from "antd-mobile";
import {util} from "../../common/util";
import {api} from "../../common/Config";
import order_default_img from "../../image/order_default_img.png";
const Step = Steps.Step;
class PurchaseOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            order: null,
            orderDetails: null,
        };
    }

    componentDidMount() {
        console.log(this.props.history.location.state);
        this.setState({
            order: this.props.history.location.state.order
        },()=>this.handleGetDetails())
    }

    // 获取详情接口
    handleGetDetails=()=>{
        const { order }=this.state;
        util.getYangAxios(
            api.purchaseDetails,
            {
                order_sn: order.order_sn
            },
            async (res) => {
                this.setState({
                    orderDetails: res
                })
            },
            (err) => {
                console.log(err);
            },
        );
    }

    // 订单卡片渲染
    handleOrderCardRender=(title,content)=>{
        return(
            <div className={'purchase_content_card column_center'}>
                <div className={'purchase_title_text'}>
                    {content}
                </div>
                <div className={'purchase_content_text'}>
                    {title}
                </div>
            </div>
        )
    }

    render() {
        const { orderDetails}=this.state
        return (
            <div className={"pay_result_view"} style={{height: window.innerHeight + 'px',background: '#F6F7FB'}}>
                <HeaderView
                    title={'订单详情'}
                />
                {
                    orderDetails&& <>
                        <div className={'order_header_card row_align'}>
                            <img src={order_default_img} alt="" className={'order_default_img'}/>
                            <div>
                                <div className={'order_num_text'}>订单号：{orderDetails.order_sn}</div>
                                <div className={'order_date_text'}>{orderDetails.created_at}</div>
                            </div>
                        </div>
                        <div className={'purchase_data_card'}>
                            {this.handleOrderCardRender('产品名称',orderDetails.product_name)}
                            {this.handleOrderCardRender('产品数量',orderDetails.number)}
                            {this.handleOrderCardRender('产品金额',orderDetails.unit_price)}
                            {this.handleOrderCardRender('折扣率',orderDetails.discount+'%')}
                            {this.handleOrderCardRender('订单总价',"¥"+orderDetails.total_price)}
                            {this.handleOrderCardRender('实付金额',"¥"+orderDetails.actual_price)}
                        </div>
                        <div className={'purchase_order_flow_card'}>
                            <div className={'purchase_order_flow_card_title'}>订单流程</div>
                            <Steps current={orderDetails.purchaseorderprocess.length-1}>
                                {
                                    orderDetails.purchaseorderprocess.length>0&&orderDetails.purchaseorderprocess.map((item,index)=>{
                                        return (
                                            <Step title={item.workflowlinks.name} description={item.workflowlinks.tits} key={index}/>
                                        )
                                    })
                                }
                            </Steps>
                        </div>
                    </>
                }


            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(PurchaseOrder)
