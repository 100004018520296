import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet } from "../../actions/XggsUserInformation"
import "./css/pay.css"
import { Toast, Checkbox } from "antd-mobile"
import pay_select_blue_icon from "../../image/pay_select_blue_icon.png"
import pay_select_gray_icon from "../../image/pay_select_gray_icon.png"
import HeaderView from "../../component/header/headerView"
import { util } from "../../common/util"
import { api } from "../../common/Config"
class Pay extends Component {
  constructor(props) {
    super(props)
    this.state = {
      agentList: [],
      agentIndex: 0,
      agreeIsClick: false,
      agreenIsShow: true,
    }
  }

  componentDidMount() {
    this.handleGetAgent()
    // new Vconsole()
  }

  // 分享人选择列表
  handleGetAgent = () => {
    util.getYangAxios(
      api.shareAgent,
      {},
      async (res) => {
        this.setState({
          agentList: res,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 支付
  handlePay = () => {
    const { agentList, agentIndex, agreeIsClick, agreenIsShow } = this.state
    // this.props.history.push('payResult')
    if (!agreeIsClick && agreenIsShow) {
      Toast.info("请先阅读并同意协议")
      return
    }

    let agent_id = Number(sessionStorage.getItem("qd_agent_id"))
    let params = {
      shareId: agentList.length != 0 ? agentList[agentIndex].id : 0,
    }

    if (agent_id > 0) {
      params.agent_id = agent_id
    }

    util.getYangAxios(
      api.ambassadorvip,
      params,
      async (res) => {
        this.handleWxPay(res.key)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  handleWxPay = (key) => {
    let bound_url = api.links + `payResult`
    let agent_id = Number(sessionStorage.getItem("qd_agent_id"))
    let dlsFromPage = sessionStorage.getItem("dlsFromPage")
    if (agent_id > 0) {
      bound_url = bound_url + `?agent_id=${agent_id}&dlsFromPage=${dlsFromPage}`
    } else {
      // bound_url =
      //   "http://192.168.1.100:3000/" +
      //   `payResult` +
      //   `?dlsFromPage=${dlsFromPage}`
      bound_url = bound_url + `?dlsFromPage=${dlsFromPage}`
    }

    let params = {
      key: key,
      bound_url,
    }

    if (agent_id > 0) {
      params.agent_id = agent_id
    }

    util.getYangAxios(
      api.cashierDesk,
      params,
      async (res) => {
        window.location.href = res.url
      },
      (err) => {
        console.log(err)
      }
    )
  }

  agreenChange = ({ target }) => {
    this.setState({
      agreeIsClick: target.checked,
    })
  }

  render() {
    const { userData } = this.props.UserDataReducer
    const { agentList, agentIndex, agreenIsShow } = this.state
    const { AgreeItem } = Checkbox
    return (
      <div className={"pay_view"}>
        <HeaderView title={"付款"} />
        {agentList.length > 0 && (
          <div className={"pay_card_view"}>
            <div className={"pay_card_box row_between"}>
              <div className={"pay_card_title_text"}>推荐人</div>
            </div>
            {agentList.map((item, index) => {
              return (
                <div
                  className={"pay_card_box row_between pl_two"}
                  key={index}
                  onClick={() => {
                    this.setState({
                      agentIndex: index,
                    })
                  }}
                >
                  <div className={"pay_card_content_text"}>
                    {item.shareagent && item.shareagent.name}
                  </div>
                  <img
                    src={
                      agentIndex == index
                        ? pay_select_blue_icon
                        : pay_select_gray_icon
                    }
                    alt=""
                    className={"pay_select_blue_icon"}
                  />
                </div>
              )
            })}
          </div>
        )}
        <div className={"pay_card_view"}>
          <div className={"pay_card_box row_between"}>
            <div className={"pay_card_title_text"}>产品名称</div>
          </div>
          <div className={"pay_card_box row_between pl_two"}>
            <div className={"pay_card_content_text"}>推广合伙人</div>
            <div className={" pay_card_content_text pay_t_num"}>x1</div>
          </div>
        </div>
        <div className={"pay_card_view"}>
          <div className={"pay_card_box row_between"}>
            <div className={"pay_card_content_text"}>支付金额</div>
            <div className={"pay_card_money_text"}>¥{3650.00 - userData.contract_money}</div>
          </div>
        </div>
        {/*<div className={"pay_card_view"}>*/}
        {/*    <div className={"pay_card_box row_between"}>*/}
        {/*        <div className={"pay_card_title_text"}>支付方式</div>*/}
        {/*    </div>*/}
        {/*    <div className={"pay_card_box row_between"}>*/}
        {/*        <div className={'row_align'}>*/}
        {/*            <img src={wx_pay_icon} alt="" className={'wx_pay_icon'}/>*/}
        {/*            <div className={"pay_card_content_text"}>微信支付</div>*/}
        {/*        </div>*/}
        {/*        <img src={pay_select_blue_icon} alt="" className={'pay_select_blue_icon'}/>*/}
        {/*    </div>*/}
        {/*    <div className={"pay_card_box row_between"}>*/}
        {/*        <div className={'row_align'}>*/}
        {/*            <img src={zfb_pay_icon} alt="" className={'wx_pay_icon'}/>*/}
        {/*            <div className={"pay_card_content_text"}>支付宝支付</div>*/}
        {/*        </div>*/}
        {/*        <img src={pay_select_blue_icon} alt="" className={'pay_select_blue_icon'}/>*/}
        {/*    </div>*/}
        {/*</div>*/}

        {/* 协议： 合伙人需要选择 其他角色无此项 */}
        {agreenIsShow && (
          <div className={"agreen_box"}>
            <AgreeItem data-seed="logId" onChange={this.agreenChange}>
              <span className={"gray_box"}>
                点击按钮即表示你同意并愿意遵守习惯公社
              </span>
              <div>
                <a
                  
                  onClick={(e) => {
                    e.preventDefault()
                    this.props.history.push("agreement")
                  }}
                >
                  《习惯公社推广合伙人协议》
                </a>
              </div>
            </AgreeItem>
          </div>
        )}

        <div
          className={"row_center"}
          style={{ width: "100%" }}
          onClick={this.handlePay}
        >
          <div className={"row_center pay_btn_div"}>支付</div>
        </div>
      </div>
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
  }
)(Pay)
