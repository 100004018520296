import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet, tabSetIndex } from "../../actions/XggsUserInformation"
import { Carousel, Button } from "antd-mobile"
import "../../page/home/css/home.css"
import homeProfit from "image/home_profit.png"
import home_pay__img1 from "image/nomimg1.png"
import home_pay__img2 from "image/nomimg2.png"
import home_pay__img3 from "image/nomimg3.png"
import home_pay__img5 from "image/nomimg4.png"

/* 没交钱的普通用户  */
class NoMoneyUser extends Component {
  state = {
    imgList: [home_pay__img5, home_pay__img1, home_pay__img3, home_pay__img2],
  }
  render() {
    return (
      <div className={"pay_img_div"}>
        <img
          src={homeProfit}
          alt=""
          className={"home_profit"}
          onClick={() => this.props.history.push("profit")}
        />
        <Carousel
          className="space-carousel"
          frameOverflow="visible"
          cellSpacing={10}
          slideWidth={0.8}
          autoplay
          infinite
          afterChange={(index) => {
            this.setState({ slideIndex: index })
          }}
          dots={false}
        >
          {this.state.imgList.map((val, index) => (
            <img
              src={val}
              alt=""
              className={"pay_img"}
              key={index}
              onClick={() => this.props.history.push("pay")}
            />
          ))}
        </Carousel>

        <div className={"add_menmber_view"}>
          {/* <Button
            className={"add_menmber_button"}
            type="primary"
            onClick={() => this.props.history.push("pay")}
          >
            立即加入
          </Button> */}
          <Button
            className={"add_menmber_button"}
            type="primary"
            onClick={() => this.props.history.push("/payNew?type=1")}
          >
            立即加入
          </Button>
        </div>
      </div>
    )
  }
}

export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
    tabSetIndex,
  }
)(NoMoneyUser)
