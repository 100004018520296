import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet } from "../../actions/XggsUserInformation"
import ss from "./css/cashDetails.module.css"
import HeaderView from "../../component/header/headerView"
import { Toast, Steps, Button } from "antd-mobile"
import { Modal, Input } from "antd"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import { isNull } from "utils/isNull"
import { PhotoSlider } from "react-photo-view"
import "react-photo-view/dist/index.css"
import moneyBlue from "image/money_blue.png"
const Step = Steps.Step
const { confirm } = Modal

class CashDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      order: null,
      orderDetails: null,
      tType: "",
      dialogVisible: false,
      photoModal: false,
      photoIndex: 0,
      photoUrl: [],
      toApplyApiV: "",
    }
  }

  componentDidMount() {
    // console.log(this.props.history.location.state);
    this.setState(
      {
        order: this.props.history.location.state.order,
      },
      () => this.handleGetDetails()
    )
  }

  // 获取详情接口
  handleGetDetails = () => {
    const { order } = this.state
    util.getYangAxios(
      `${api.cashTablelist}/${order}`,
      {},
      async (res) => {
        let obj = {
          ...res,
          process: res.process.reverse(),
        }

        this.setState({
          orderDetails: obj,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 订单卡片渲染
  handleOrderCardRender = (title, content) => {
    return (
      <div className={ss.line_row}>
        <div className={ss.cash_title}>{title}</div>
        <div>{content}</div>
      </div>
    )
  }

  setPhotoIndex = (index) => {
    this.setState({
      photoIndex: index,
    })
  }

  toExamine = (t, end_type) => {
    t === 1
      ? this.setState({
          tType: 1,
          dialogVisible: true,
          end_type,
        })
      : this.setState({
          tType: 2,
          dialogVisible: true,
          end_type,
        })
  }

  toApi = (v) => {
    const { order, reasonValue } = this.state
    let data = {
      id: order,
    }
    if (v === 1) {
      data.type = 1
    } else if (v === 2) {
      data.type = 2
      data.remark = reasonValue
    }
    this.toApplyApi(data)
    
  }

  toApplyApi = (data) => {
    const { order } = this.state
    util.putYangAxios(
      `${api.cashTablelist}/${order}`,
      data,
      async (res) => {
        Toast.info("操作成功")
        this.setState({
          dialogVisible: false,
        })
        await this.handleGetDetails()
        // this.handleGetChannel({ mode: 1 })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  meClose = () => {
    const { order } = this.state
    util.postYangAxios(
      `${api.meCloseOrder}/${order}`,
      {},
      async (res) => {
        Toast.info("订单关闭成功", 1)

        setTimeout(() => {
          this.handleGetDetails()
        }, 1100)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  render() {
    const {
      orderDetails,
      dialogVisible,
      tType,
      reasonValue,
      photoModal,
      photoIndex,
      photoUrl,
    } = this.state
    return (
      <div className={ss.cash_view}>
        <HeaderView title={"提现详情"} />
        {orderDetails && (
          <>
            <div className={ss.cashTop_view}>
              <img className={ss.tip_img} src={moneyBlue}  alt=""/>
              <div className={ss.cash_titleP}>余额提现至银联钱包</div>
              <div className={ss.cash_number}>{`¥ ${orderDetails.money}`}</div>
            </div>
            <div className={ss.cash_details}>
              {this.handleOrderCardRender(
                "提现金额",
                "¥ " + orderDetails.money
              )}
              {this.handleOrderCardRender(
                "服务费",
                "¥ " + orderDetails.all_service_money
              )}
              {orderDetails.tax_money &&
                Number(orderDetails.tax_money) > 0 &&
                this.handleOrderCardRender(
                  "所得税",
                  "¥ " + orderDetails.tax_money
                )}
              {this.handleOrderCardRender(
                "实际到账",
                "¥ " + orderDetails.real_money
              )}
              {this.handleOrderCardRender("订单号", orderDetails.order_sn)}
              {this.handleOrderCardRender(
                "交易单号",
                orderDetails.exterior_trade_no ?? "-"
              )}
              {this.handleOrderCardRender("提现时间", orderDetails.created_at)}
            </div>
            <div className={"purchase_order_flow_card"}>
              <div className={"purchase_order_flow_card_title"}>订单流程</div>
              <Steps current={orderDetails.process.length - 1}>
                {orderDetails.process.length > 0 &&
                  orderDetails.process.map((item, index) => {
                    return (
                      <Step
                        key={item.id}
                        title={
                          <div>
                            <span>{item.apply_name} </span>
                            {item.workflowlinks && (
                              <span>{item.workflowlinks.name}</span>
                            )}

                            <div>
                              {item.apply_phone
                                ? item.apply_phone
                                : item.agent_user && item.agent_user.phone
                                ? item.agent_user.phone
                                : item.agent
                                ? item.agent.phone
                                : ""}
                            </div>
                          </div>
                        }
                        description={
                          <div className={"row_between"}>
                            <div>
                              {
                                <div>
                                  {item.workflowlinks
                                    ? item.workflowlinks.tits
                                    : item.tits}
                                </div>
                              }

                              {item.remark && (
                                <div>
                                  {item.remark && (
                                    <div>驳回原因：{item.remark}</div>
                                  )}
                                </div>
                              )}

                              <div className={ss.timer}>{item.created_at}</div>

                              {!isNull(item.evidence) &&
                                item.evidence.split(",").map((items, index) => {
                                  return (
                                    <img
                                    alt=""
                                      className={ss.img_view_detail}
                                      src={"https://cdn.ellll.com/" + items}
                                      onClick={() => {
                                        let arr = item.evidence.split(",")
                                        arr.forEach((itemc, index) => {
                                          arr[index] =
                                            "https://cdn.ellll.com/" + itemc
                                        })
                                        this.setState({
                                          photoIndex: 0,
                                          photoModal: !this.state.photoModal,
                                          photoUrl: arr,
                                        })
                                      }}
                                    />
                                  )
                                })}
                            </div>

                            <div className={ss.display_view}>
                              {item.link &&
                                item.link.map((items) => {
                                  return (
                                    <Button
                                      key={items.type}
                                      type="primary"
                                      className={ss.cash_button}
                                      onClick={this.toExamine.bind(
                                        this,
                                        Number(items.type),
                                        String(items.end_type)
                                      )}
                                    >
                                      {items.type == 1 ? "确认" : "驳回"}
                                    </Button>
                                  )
                                })}
                            </div>

                            {orderDetails.is_close === 1 && index == 0 && (
                              <div className={ss.display_view}>
                                <Button
                                  key={1}
                                  type="primary"
                                  className={ss.cancle_button}
                                  onClick={() => {
                                    let that = this
                                    confirm({
                                      title: "是否取消此订单?",
                                      icon: <ExclamationCircleOutlined />,
                                      content: "",
                                      okText: "确定",
                                      cancelText: "取消",
                                      onOk() {
                                        that.meClose()
                                      },
                                      onCancel() {},
                                    })
                                  }}
                                >
                                  取消提现申请
                                </Button>
                              </div>
                            )}
                          </div>
                        }
                      />
                    )
                  })}
              </Steps>
            </div>
          </>
        )}

        <Modal
          visible={dialogVisible}
          title="提现审核"
          className={ss.modal_view}
          maskClosable={false}
          onCancel={() => {
            this.setState({ dialogVisible: false })
          }}
          footer={
            tType == 1
              ? [
                  <Button
                    key={1}
                    type="primary"
                    className={ss.cash_button}
                    onClick={this.toApi.bind(this, 1)}
                  >
                    确认
                  </Button>,

                  <Button
                    key={2}
                    type="primary"
                    className={ss.cash_button}
                    onClick={() => {
                      this.setState({ dialogVisible: false })
                    }}
                  >
                    返回
                  </Button>,
                ]
              : [
                  <Button
                    key={1}
                    type="primary"
                    className={ss.cash_button}
                    onClick={this.toApi.bind(this, 2)}
                  >
                    驳回
                  </Button>,
                  <Button
                    key={2}
                    type="primary"
                    className={ss.cash_button}
                    onClick={() => {
                      this.setState({ dialogVisible: false })
                    }}
                  >
                    返回
                  </Button>,
                ]
          }
        >
          {tType == 1 && (
            <div>
              <p>您确定通过审核吗？</p>

              {this.state.end_type == "30" && (
                <p className={ss.red_font}>
                  * 该提现会在凌晨发起转账
                </p>
              )}
            </div>
          )}

          {tType == 2 && (
            <div>
              <p>您确定驳回审核吗？</p>
              <p>
                <span>驳回理由：</span>
                <Input
                  placeholder="请输入驳回理由"
                  value={reasonValue}
                  className={ss.inputStyle}
                  onChange={(e) => {
                    this.setState({
                      reasonValue: e.target.value,
                    })
                  }}
                ></Input>
              </p>
            </div>
          )}
        </Modal>

        <PhotoSlider
          images={photoUrl.map((item) => ({ src: item }))}
          visible={photoModal}
          onClose={() => {
            this.setState({ photoModal: !this.state.photoModal })
          }}
          index={photoIndex}
          onIndexChange={this.setPhotoIndex}
        />
      </div>
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
  }
)(CashDetails)
