import Login from "./page/users/login"
import BottomTab from "./page/tabs/bottomTab"
import Invite from "./page/invite/invite"
import InviteNew from "./page/inviteNew/invite"
import InviteDes from "./page/inviteDes/index"
import My from "./page/users/my"
import Pay from "./page/pay/pay"
import Msg from "./page/msg/msg"
import PayResult from "./page/pay/payResult"
import PersonalData from "./page/users/personalData"
import Authentication from "./page/users/authentication"
import AuthenticationSuccess from "./page/users/authenticationSuccess"
import Order from "./page/order/order"
import OrderSchoolCode from "./page/order/schoolCode"
import MarketOrder from "./page/order/marketOrder"
import PurchaseOrder from "./page/order/purchaseOrder"
import QrCode from "./page/users/qrCode"
import Financial from "./page/financial/financial"
import Channel from "./page/channel/channel"
import AgentDetails from "./page/channel/agentDetails"
import Consumer from "./page/consumer/consumer"
import ConsumerDetails from "./page/consumer/consumerDetails"
import Agreement from "./page/users/agreement"
import JpjAgreement from "./page/users/jpjAgreement"
import Profit from "./page/users/profit"
import WithdrawalDetails from "./page/financial/withdrawalDetails"
import Member from "./page/member/member"
import MemberDetails from "./page/member/memberDetails"
import CashMoney from "./page/cashMoney/cashMoneyNew"
import CashBag from "./page/cashMoney/cashBag"
import BankCard from "./page/bankCard/bankCard"
import AddBankCard from "./page/bankCard/addBankCard"
import SuccessView from "./page/bankCard/successView"
import SpeedResult from "./page/cashMoney/speedResult"
import CashDetails from "./page/order/cashDetails"
import CashSuccess from "./page/cashMoney/cashSuccess"
import CashBankSuccess from "./page/cashMoney/cashBankSuccess"
import Pdf from "./component/pdf/pdf"
import FiveDetails from "./page/home/fiveDetails"
import ZlDetails from "./page/home/zlDetails"
import NewYear from "./page/home/NewYear"
import DlsCourse from "./page/dlsCourse/dlsCourse"
import Warrant from "./page/dlsCourse/warrant"
import Fileview from "./page/dlsCourse/fileview"
import onlineLook from "./page/dlsCourse/onlineLook"
import poster from "./page/poster/poster"
import posterLogin from "./page/poster/posterLogin"
import shareBill from "./page/poster/shareBill"
import PaySuccessJoin from "./page/pay/successJoin"
import PayNew from "./page/payNew/pay"
import PayNewResult from "./page/payNew/payResult"
import PayNewSuccessJoin from "./page/payNew/successJoin"
import Download from "page/downLoad/download"
import HomeNew from "page/homeNew/home"
import Live from "page/live/index"
import Balance from "page/balance/index"
import BalanceList from "page/balance/list"
import Withdrawal from "page/withdrawal/index"
import Stock from "page/stock/stock"
import StockDetails from "page/stock/details"
import StockPurchase from "page/stock/purchase"
import MemberActive from "page/member/active"
import MemberActiveOk from "page/member/ativeOk"
import OrderNew from "page/orderNew/order"
import OrderNewDetails from "page/orderNew/details"
import CustomerCount from "page/customer/count"
import CustomerDetails from "page/customer/details"
const routes = [
  { path: "/homeNew", name: "HomeNew", component: HomeNew, auth: true },
  { path: "/live", name: "Live", component: Live, auth: true },
  { path: "/balance", name: "Balance", component: Balance, auth: true },
  { path: "/balance/list", name: "BalanceList", component: BalanceList, auth: true },
  { path: "/withdrawal", name: "Withdrawal", component: Withdrawal, auth: true },
  { path: "/stock/stock", name: "Stock", component: Stock, auth: true },
  { path: "/stock/details", name: "StockDetails", component: StockDetails, auth: true },
  { path: "/stock/purchase", name: "StockPurchase", component: StockPurchase, auth: true },
  { path: "/member/active", name: "MemberActive", component: MemberActive, auth: false },
  { path: "/member/activeOk", name: "MemberActiveOk", component: MemberActiveOk, auth: false },
  { path: "/orderNew/order", name: "orderNew", component: OrderNew, auth: true },
  { path: "/orderNew/details", name: "OrderNewDetails", component: OrderNewDetails, auth: true },
  { path: "/order/schoolCode", name: "OrderSchoolCode", component: OrderSchoolCode, auth: true },
  { path: "/customer/count", name: "CustomerCount", component: CustomerCount, auth: true },
  { path: "/customer/details", name: "CustomerDetails", component: CustomerDetails, auth: true },
  { path: "/", name: "home", component: BottomTab, auth: true },
  { path: "/login", name: "login", component: Login, auth: false,title:'登录' },
  { path: "/bottomTab", name: "bottomTab", component: BottomTab, auth: true, },
  { path: "/invite", name: "invite", component: Invite, auth: false, },
  { path: "/inviteDes", name: "inviteDes", component: InviteDes, auth: false },
  { path: "/my", name: "my", component: My, auth: true },
  { path: "/pay", name: "pay", component: Pay, auth: true },
  { path: "/msg", name: "msg", component: Msg, auth: true },
  { path: "/payResult", name: "payResult", component: PayResult, auth: true, },
  { path: "/paySuccessJoin", name: "paySuccessJoin", component: PaySuccessJoin, auth: true },
  { path: "/inviteNew", name: "InviteNew", component: InviteNew, auth: false,title:'邀请' },
  { path: "/payNew", name: "payNew", component: PayNew, auth: true ,title:'支付',},
  { path: "/payNewResult", name: "payNewResult", component: PayNewResult, auth: true,title:'支付结果' },
  { path: "/payNewSuccessJoin", name: "payNewSuccessJoin", component: PayNewSuccessJoin, auth: true },
  { path: "/downLoad", name: "download", component: Download, auth: false },
  
  {
    path: "/personalData",
    name: "personalData",
    component: PersonalData,
    auth: true,
  },
  {
    path: "/authentication",
    name: "authentication",
    component: Authentication,
    auth: true,
  },
  {
    path: "/authenticationSuccess",
    name: "authenticationSuccess",
    component: AuthenticationSuccess,
    auth: true,
  },
  { path: "/order", name: "order", component: Order, auth: true },
  { path: "/financial", name: "financial", component: Financial, auth: true },
  { path: "/channel", name: "channel", component: Channel, auth: true },
  {
    path: "/agentDetails",
    name: "agentDetails",
    component: AgentDetails,
    auth: true,
  },
  {
    path: "/marketOrder",
    name: "marketOrder",
    component: MarketOrder,
    auth: true,
  },
  {
    path: "/purchaseOrder",
    name: "purchaseOrder",
    component: PurchaseOrder,
    auth: true,
  },
  { path: "/qrCode", name: "qrCode", component: QrCode, auth: true ,title:'我的'},
  { path: "/consumer", name: "consumer", component: Consumer, auth: true },
  {
    path: "/consumerDetails",
    name: "consumerDetails",
    component: ConsumerDetails,
    auth: true,
  },
  { path: "/agreement", name: "agreement", component: Agreement, auth: false },
  { path: "/jpjAgreement", name: "JpjAgreement", component: JpjAgreement, auth: false },
  { path: "/profit", name: "profit", component: Profit, auth: true },
  {
    path: "/withdrawalDetails",
    name: "withdrawalDetails",
    component: WithdrawalDetails,
    auth: true,
  },
  { path: "/member", name: "member", component: Member, auth: true },
  {
    path: "/memberDetails",
    name: "memberDetails",
    component: MemberDetails,
    auth: true,
  },
  {
    path: "/cashMoney",
    name: "cashMoney",
    component: CashMoney,
    auth: true,
  },
  {
    path: "/cashBag",
    name: "cashBag",
    component: CashBag,
    auth: true,
  },
  {
    path: "/bankCard",
    name: "bankCard",
    component: BankCard,
    auth: true,
  },
  {
    path: "/addBankCard",
    name: "addBankCard",
    component: AddBankCard,
    auth: true,
  },
  {
    path: "/successView",
    name: "successView",
    component: SuccessView,
    auth: true,
  },
  {
    path: "/speedResult",
    name: "speedResult",
    component: SpeedResult,
    auth: true,
  },
  {
    path: "/cashDetails",
    name: "cashDetails",
    component: CashDetails,
    auth: true,
  },
  {
    path: "/cashSuccess",
    name: "cashSuccess",
    component: CashSuccess,
    auth: true,
  },
  {
    path: "/cashBankSuccess",
    name: "cashBankSuccess",
    component: CashBankSuccess,
    auth: true,
  },
  {
    path: "/pdf",
    name: "pdf",
    component: Pdf,
    auth: true,
  },
  {
    path: "/fiveDetails",
    name: "fiveDetails",
    component: FiveDetails,
    auth: true,
  },
  {
    path: "/zlDetails",
    name: "zlDetails",
    component: ZlDetails,
    auth: true,
  },
  {
    path: "/newYear",
    name: "newYear",
    component: NewYear,
    auth: true,
  },
  {
    path: "/dlsCourse",
    name: "dlsCourse",
    component: DlsCourse,
    auth: false,
  },
  {
    path: "/warrant",
    name: "warrant",
    component: Warrant,
    auth: true,
  },
  {
    path: "/fileview",
    name: "fileview",
    component: Fileview,
    auth: true,
  },

  {
    path: "/onlineLook",
    name: "onlineLook",
    component: onlineLook,
    auth: true,
  },
  {
    path: "/poster",
    name: "poster",
    component: poster,
    auth: false,
  },
  {
    path: "/posterLogin",
    name: "posterLogin",
    component: posterLogin,
    auth: false,
  },
  {
    path: "/shareBill",
    name: "shareBill",
    component: shareBill,
    auth: false,
  },
]
// auth 是否需要登录
export default routes
