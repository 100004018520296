import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
// import './css/payResult.css'
import pay_select_blue_icon from "../../image/pay_select_blue_icon.png";
import HeaderCloseView from "../../component/header/headerCloseView";
class AuthenticationSuccess extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            num: 3,
        };
    }

    componentDidMount() {
        this.inter=setInterval(()=>{
            if(this.state.num==0){
                clearInterval(this.inter);
                this.props.history.replace('/personalData');
                return null;
            }
            this.setState({
                num: this.state.num-1
            })
        },1000)
    }

    componentWillUnmount() {
        this.inter&&clearInterval(this.inter);
    }


    // 订单文字渲染
    handleOrderTextRender=(title,content)=>{
        return (
            <div className={'pay_order_box row_between'}>
                <div>{title}</div>
                <div>{content}</div>
            </div>
        )

    }

    render() {
        const { num }=this.state
        return (
            <div className={"pay_result_view"} style={{height: window.innerHeight + 'px'}}>
                <HeaderCloseView
                    title={'认证结果'}
                />
                <div className={"pay_result_box column_align"}>
                    <img src={pay_select_blue_icon} alt="" className={'pay_ok_img'}/>
                    <div className={'pay_ok_text'}>恭喜您，认证成功！</div>
                    <div className={'pay_des_text'}>
                        您将在<div style={{color:'#0E6CFF',display: 'inline'}}>{num}S</div>后，<br/>自动跳转到个人资料页
                    </div>

                </div>
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(AuthenticationSuccess)
