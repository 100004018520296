import React, { Component } from 'react'
import Style from './css/activeOk.module.css'
import pay_member_Ok_icon from "../../image/memberOk.png";
export default class SusscessJoin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product_name:''
        };
    }
    componentDidMount() {
        console.log(this.props.history.location.state.product_name,'this.props.history.location.state.product_name')
        this.setState({
            product_name:this.props.history.location.state.product_name,
        })
    }
     /* 点击下载按钮 */
downloadApp= ()=> {
    this.props.history.replace('/downLoad')
  }
  

    render() {
        return (
            <div className={Style.activeOk_container} style={{ height: window.innerHeight + 'px' }}>
                <div className={Style.title_text} >
                    激活结果
                </div>
                <div className={Style.img_wrap}>
                    <img src={pay_member_Ok_icon} alt="" className={Style.activeOk_img} />
                </div>
                <div className={Style.ok_txt}>恭喜您</div>
                <div className={Style.ok_txt_des}>习惯公社{this.state.product_name}激活成功</div>
                <div className={Style.ok_stpes_wrap}>
                    <div className={Style.ok_step_one}>1.关注《习惯公社商家号》，查看我的权益</div>
                    <div className={Style.ok_step_one}>2.下载习惯公社APP进入私教班进行学习并享受权益</div>
                </div>
                <div className={Style.download_btn} onClick={this.downloadApp}>下载App</div>
            </div>
        )
    }
}

