import React, { Component } from "react"
import CommonTitle from "./CommonTitle"
import { util } from "../../../common/util"
import { api } from "../../../common/Config"
import ss from "./css/statics.module.css"
import Bonus_tag_img from '../../../image/bonus_tag.png'
import Bonus_over_img from '../../../image/bonus_over.png'
import Bonus_fail_img from '../../../image/bonus_fail.png'
import tips_gray_img from '../../../image/tips_gray.png'
import { Toast } from 'antd-mobile';
const tabs = [
    { labe: '全部数据', value: 2 },
    { labe: '个人数据', value: 4 },
    { labe: '直接数据', value: 1 },
    { labe: '间接数据', value: 3 },
]
const timeFilterList = [
    { labe: '全部', value: 1 },
    { labe: '本周', value: 6 },
    { labe: '本月', value: 4 },
    { labe: '本年', value: 5 },
]
const statisDataList = [
    { label: '业绩', key: 'performance', unit: '万' }, { label: '收入', key: 'total_balance', unit: '万' }, { label: '订单', key: 'order', unit: '' },
    { label: '合伙人', key: 'partner', unit: '人' }, { label: '店主', key: 'shopkeeper', unit: '人' }, { label: '会员', key: 'fellow', unit: '人' },
    { label: '代理商', key: 'agent', unit: '个' }, { label: '学校', key: 'school', unit: '个' }, { label: '教培', key: 'educate', unit: '个' },
    { label: '私有流量', key: 'privateTraffic', unit: '人' }, { label: '付费用户', key: 'subscriber', unit: '人' },
]
class Statics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            actived: 2,
            filterActived: 1,
            staticsObj: {},
            recentIncomeList: [],
            stockList: [],
            bonusPool: {pool:[],score:[]},
            top: 0,
            transition: null,
        }
    }
    componentDidMount() {
        this.handleGetAgentsNewStatisticse()
        this.handleGetAgentsTransactionFlow()
        this.handleGetAgentsBonusPool()
        this.handleGetUserproductsList()

    }
    componentWillUnmount() {
        this.timer && clearInterval(this.timer);
    }
    initMove = () => {
        if (this.state.recentIncomeList.length <= 0) {
            return false
        }
        this.timer = setInterval(() => {
            this.setState({
                transition: 'all 0.5s linear',
                top: '-24px'
            })
            setTimeout(() => {
                const firtsItem = this.state.recentIncomeList[0]
                const shiftArr = this.state.recentIncomeList.filter((item, index) => index !== 0)
                this.setState({
                    transition: 'none',
                    top: '0px',
                    recentIncomeList: shiftArr.concat([firtsItem])
                })
            }, 500)
        }, 1500)
    }
    // 个人收益信息
    handleGetAgentsNewStatisticse = () => {
        util.getYangAxios(
            api.agentsNewStatisticse,
            {
                zhishutype: this.state.actived,
                mode: this.state.filterActived
            },
            async (res) => {
                this.setState({
                    staticsObj: res
                })
            },
            (err) => {
                console.log(err)
            }
        )
    }
    // 最新收益（10条）
    handleGetAgentsTransactionFlow = () => {
        util.getYangAxios(
            api.agentsTransactionFlow,
            {},
            async (res) => {
                for (const item of res) {
                    item.mobile = this.geTel(item.phone)
                }
                this.setState({
                    recentIncomeList: res
                }, () => {
                    this.initMove()
                }
                )
            },
            (err) => {
                console.log(err)
            }
        )
    }
    // 奖金池
    handleGetAgentsBonusPool = () => {
        util.getYangAxios(
            api.agentsBonusPool,
            {},
            async (res) => {
                this.setState({
                    bonusPool: res
                })
            },
            (err) => {
                console.log(err)
            }
        )
    }

    // 库存清单
    handleGetUserproductsList = () => {
        util.getYangAxios(
            api.userproductsList,
            {},
            async (res) => {
                this.setState({
                    stockList: res
                })
            },
            (err) => {
                console.log(err)
            }
        )
    }
    geTel = (tel) => {
        var reg = /^(\d{3})\d{4}(\d{4})$/;
        return tel.replace(reg, "$1****$2");
    }
    handTabs(item) {
        this.setState({
            actived: item.value
        }, () => {
            this.handleGetAgentsNewStatisticse()
        })

    }
    handFilters(item) {
        this.setState({
            filterActived: item.value
        }, () => {
            this.handleGetAgentsNewStatisticse()
        })
    }
    goPurhaseDetails = (item) => {
        // this.props.props.history.push({
        //     pathname: '/stock/purchase',
        //     state: {
        //         item
        //     }
        // });
        this.props.props.history.push("/stock/purchase?productId="+item.id)
    }
    showTips=()=>{
        Toast.info('由于存在退换货，所以当前的金额会存在浮动', 2, null, false);
    }
    showBonusItemRender = ( item) => {
        return (
            <div>
                <div className={ss.bonus_top_container}>
                    <div className={ss.bonus_img_wrap}>
                        <img src={Bonus_tag_img} alt="" className={ss.bonus_img} />
                    </div>
                    <div className={ss.bonus_details_wrap}>
                        <div className={ss.bonus_title}>{item.title}</div>
                        <div className={ss.bonus_time_wrap}>
                            <span className={ss.bonus_type}>({item.date_name})</span>
                            <span className={ss.bonus_time}>截止{item.date}</span>
                        </div>
                    </div>
                </div>
                <div className={ss.bonus_section_container}>
                    <div className={ss.bonus_box}>
                        <div className={ss.bonus_box_title}><span className={ss.award_tit}>{item.income}元</span> <img src={tips_gray_img} alt="" onClick={this.showTips} className={ss.recive_tags_img} /></div>
                        <div className={`${ss.bonus_box_title_label}${ss.home_reward_content_label}`}>我的收入</div>
                    </div>
                    <div className={ss.bonus_box}>
                        <div className={ss.bonus_box_title}>{item.stock}{item.key==='JE'?'元':'份'}</div>
                       <div className={`${ss.bonus_box_title_label}${ss.home_reward_content_label}`}> {item.key==='JE'?'已结算':'我的份额'}</div>
                    </div>
                    <div className={ss.bonus_box}>
                        <div className={ss.bonus_box_title}>{item.total_amount}元</div>
                        <div className={`${ss.bonus_box_title_label}${ss.home_reward_content_label}`}> {item.key==='JE'?'未结算':'总额'}</div>
                    </div>
                </div>
            </div>
        )
    }
    showBonusSoreItemRender = (item) => {
        return (
            <div className={ss.bonus_line}>
                <span className={ss.bonus_line_name1}>({item.date_name})  {item.title}</span>
                <img src={item.is_standard==1?Bonus_over_img:Bonus_fail_img} alt="" className={ss.bonus_line_name2} />
                <span className={item.is_standard==1?ss.bonus_line_name4:ss.bonus_line_name3}>{item.strip}条{item.finish_point}分线</span>
                <span className={item.is_standard==1?ss.bonus_line_name4:ss.bonus_line_name3}>当前总分{item.point}分</span>
            </div>
        )
    }
    render() {
        const { actived, filterActived, staticsObj, stockList, bonusPool, recentIncomeList, top, transition } = this.state
        return (
            <div className={ss.home_statics_container}>
                <div className={ss.home_statics_tabs_container}>
                    {tabs.map((item) => {
                        return <div className={actived == item.value ? ss.active : ss.tab_info} key={item.labe} onClick={() => { this.handTabs(item) }} >{item.labe}</div>
                    })}
                </div>
                <div className={ss.home_statics_filter_container}>
                    {timeFilterList.map((item) => {
                        return <div className={[`${ss.statics_filter}`, `${filterActived == item.value ? ss.statics_filter_active : ''}`].join(' ')} key={item.labe} onClick={() => { this.handFilters(item) }}>{item.labe}</div>
                    })}
                </div>
                <div className={ss.home_statics_content_container}>
                    {statisDataList.map((item, index) => {

                        return <div className={[`${ss.home_statics_content_info}`, `${index < 3 ? ss.statics_filter_width : ''}`].join(' ')} key={item.label} >
                            <div className={ss.home_statics_content_number}>{staticsObj[item.key]} <span className={ss.home_statics_content_unit}>{item.unit}</span></div>
                            <div className={ss.home_statics_content_label}>{item.label}</div>
                        </div>
                    })}
                </div>
                {recentIncomeList.length > 0 && (<div className={ss.title_wrap}>
                    <CommonTitle showMoreBtn={true} path={'/withdrawal'} ><div>最新收益（10条）</div></CommonTitle>
                </div>)}
                {recentIncomeList.length > 0 && (<div className={ss.income_container}>
                    <div className={ss.income_slide_container}>
                        <div className={ss.icome_content_wrap} style={{ 'top': top, 'transition': transition }}>
                            {this.state.recentIncomeList.map((item, index) => (
                                <div className={ss.icome_content_info} key={index}>
                                    <div className={ss.income_info_wrap}>
                                        <div className={ss.income_name}>{item.mobile}</div>
                                        {/* <div className={ss.income_phone}>{item.mobile}</div> */}
                                        <div className={ss.income_des}>{item.source}</div>
                                    </div>
                                    <div className={ss.income_time}>{item.created_at}</div>
                                    <div className={[`${ss.income_money}`, `${(item.status === 1 || item.status === 2) ? ss.icome_color_red : ''}`].join(' ')}>{(item.status === 1 || item.status === 2) ? '+' : '-'}{item.sales_price}元</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>)}

                <div className={ss.title_wrap}>
                    <CommonTitle ><div><span className={ss.award_tit}>奖金池</span> <span className={ss.award_title_tag}>截止昨天</span></div></CommonTitle>
                </div>
                <div className={ss.my_month_score}>我当前的份值：{bonusPool.point}</div>
                {bonusPool.score.map((item, index) => {
                        return <div key={item.key} style={{marginLeft:'15px'}}>
                            {this.showBonusSoreItemRender( item)}
                        </div>
                    })}
                <div className={ss.home_reward_container}>
                    {bonusPool.pool.map((item, index) => {
                        return <div key={item.key} className={[`${ss.home_reward_content_info}`].join(' ')}>
                            {this.showBonusItemRender( item)}
                        </div>
                    })}
                </div>
                <div className={ss.title_wrap} style={{ marginTop: '0px' }}>
                    <CommonTitle ><div>库存清单</div></CommonTitle>
                </div>
                <div className={ss.home_stock_container}>
                    {stockList.map((item, index) => {
                        return <div className={[`${ss.home_stock_content_info}`].join(' ')} key={item.name} onClick={() => this.goPurhaseDetails(item)}>
                            <div className={ss.home_statics_content_number}>{item.stock}</div>
                            <div className={`${ss.home_statics_content_label}${ss.home_stock_label}`}>{item.name}</div>
                            <div className={ss.stock_buy_btn}>立即采购</div>
                        </div>
                    })}
                </div>
            </div>
        )
    }
}

export default Statics