// ActionTypes里面存放着App中可能发生的情况
import * as types from "../constant/UserTypes"

const userData = JSON.parse(sessionStorage.getItem("userData"))
// 初始化值
const initialState = {
  userData: null || { ...userData },
  schoolObj: null,
  tabStr: "",
  citypersonKey: "1",
}

// 导出ShiTuReducer。
export default function UserDataReducer(state = initialState, action) {
  // console.log(action)
  // 通过switch来判断types的值，在action中实现功能
  switch (action.type) {
    // 当type=USER_TOKEN_SUCCESS时，会将action中的值，
    // 赋给userToken，在ShiTu.js中就能拿到userToken的值。
    case types.USER_DATA_SET:
      sessionStorage.setItem("userData",JSON.stringify(action.obj) )
      return Object.assign({}, state, {
        ...state,
        userData: action.obj,
      })
    case types.TAB_SET_INDEX:
      // console.log(action);
      return Object.assign({}, state, {
        ...state,
        tabStr: action.obj,
      })
    case types.SCHOOL_DATA_SET:
      // console.log(action);
      return Object.assign({}, state, {
        ...state,
        schoolObj: action.obj,
      })
    case types.OUT_LOGIN:
      return Object.assign({}, state, {
        ...state,
        ...initialState,
      })
    case types.INFO_TAB_SET:
      return Object.assign({}, state, {
        ...state,
        citypersonKey: action.obj,
      })
    default:
      return state
  }
}
