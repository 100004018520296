
import React, { Component } from "react"
import HeaderView from "../../component/header/headerView"
import CommSteps from "./components/CommSteps"
import ss from "./css/details.module.css"
import { util } from "../../common/util"
import { api } from "../../common/Config"
class OrderDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            actived: 0,
        }
    }
    componentDidMount() {
        this.handleGetOrder()
    }
    handFilter(type) {
        this.setState({
            actived: type
        })
    }
    // 总接口
    handleGetOrder = () => {
        this.handleGetInventoryOrder() //库存
    }
    // 获取库存订单
    handleGetInventoryOrder(ref = false) {
        util.getYangAxios(
            api.inventoryOrder,
            {
                currentPage: this.state.pageNo,
            },
            async (res) => {
                this.setState({
                    totalPage: res.totalPage,
                })

            },
            (err) => {
                console.log(err)
            }
        )
    }
    // 订单
    handleOrdeBox = (title, num) => {
        return (
            <div className={[`${ss.inventory_order_box}`].join(' ')}>
                <div className={ss.inventory_order_num}>{title}</div>
                <div className={ss.inventory_order_title}>{num}</div>
            </div>
        )
    }


    render() {
        return (
            <div className={ss.order_details_container}>
                <HeaderView title={'订单详情'} />
                <div className={ss.order_section}>
                    <div className={ss.order_contianer}>
                        <img src="" alt="" className={ss.order_pic} />
                        <div className={ss.order_content}>
                            <div className={ss.order_row}>订单号：2023022315194185</div>
                            <div className={ss.order_row}>订单总金额：￥2000.00</div>
                            <div className={ss.order_row}>2022年9月31日 19:28:30</div>
                        </div>
                    </div>
                </div>
                <div className={ss.order_section}>
                    <div className={ss.order_box_continer}>
                        {this.handleOrdeBox('实物商品', 'DK海洋大百科 第20220…')}
                        {this.handleOrdeBox('30%', '折扣率')}
                        {this.handleOrdeBox('13625893652', '收货人手机号')}
                        {this.handleOrdeBox('30%', '我的分润比例')}
                    </div>
                </div>
                <div className={ss.order_section}>
                    <div className={ss.order_title}>订单汇总</div>
                    <CommSteps></CommSteps>
                </div>



            </div>
        )
    }
}

export default OrderDetails