import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet } from "../../actions/XggsUserInformation"
import Vconsole from "common/Vconsole"
import "./css/login.css"
import logoImg from "../../image/logo_img.png"
import { Toast, Checkbox } from "antd-mobile"
import { util } from "../../common/util"
import { api } from "../../common/Config"

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nameValue: "",
      phoneValue: "",
      codeValue: "",
      codeNum: 60,
      loginNum: 0,
      codeType: 0,
      agreeIsClick: false,
      agreenIsShow: false,
      debuggerCount: 0,
      redirectPath:'',
      otherQuery:''
    }
  }

  async componentDidMount() {
    document.title='登录'
    let obj = this.getUrlParams()
    console.log(obj)
    if (obj.loginToken) {
      await window.localStorage.setItem("login_long_token", obj.loginToken)
    }
    if (obj.token) {
      await window.localStorage.setItem("login_token", obj.token)
      await this.handleGetUserData()
      this.props.history.replace("/bottomTab")
    }
    if (obj.redirectPath) {
       this.setState({
        redirectPath:obj.redirectPath,
        otherQuery:this.getOtherQuery(obj)
       })
    }
  }

  showDebugger = () => {
    this.setState(
      {
        debuggerCount: this.state.debuggerCount + 1,
      },
      () => {
        if (this.state.debuggerCount == 13) {
          new Vconsole()
        }
      }
    )
  }
  getOtherQuery=(query)=> {
    let obj={}
    let queryString=''
    for(const key in  query){
      if (key !== 'redirectPath'&&key) {
           obj[key] = query[key]
          }
    }
    for(const key in  obj){
      queryString=queryString+key+'='+obj[key]+'&'
    }
    return queryString
  }

  getUrlParams = () => {
    let url = window.location.search //?articleId=1&&articleNum=1&opt=edit
    let index = url.indexOf("?")
    let obj = {}
    if (index !== -1) {
      let str = url.substr(1)
      let arr = str.split("&")
      for (let i = 0; i < arr.length; i++) {
        obj[arr[i].split("=")[0]] = arr[i].split("=")[1]
      }
    }
    return obj //{articleId: "1", articleNum: "1", opt: "edit"}
  }
 

  onNameChange = (event) => {
    this.setState({
      nameValue: event.target.value,
    })
  }

  onPhoneChange = (event) => {
    this.setState({
      phoneValue: event.target.value,
    })
  }

  onCodeChange = (event) => {
    this.setState({
      codeValue: event.target.value,
    })
  }

  // 获取手机号登陆验证短信
  handlePhoneLoginNote = () => {
    const { phoneValue } = this.state
    if (phoneValue.length < 11) {
      Toast.info("手机号格式错误", 1)
      return null
    }
    if (!/^1[0-9]{10}$/.test(phoneValue)) {
      Toast.info("手机号格式错误", 1)
      return null
    }
    util.getYangAxios(
      api.loginCode,
      {
        phone: phoneValue,
      },
      (res) => {
        this.setState(
          {
            codeType: 1,
            codeNum: 60,
          },
          () => {
            const add = setInterval(() => {
              const { codeNum } = this.state
              if (codeNum == 1) {
                this.setState({
                  codeType: 2,
                })
                clearInterval(add)
              }
              this.setState({
                codeNum: codeNum - 1,
              })
            }, 1000)
          }
        )
      },
      (err) => {
        console.log(err)
        // Toast.fail('服务器异常,请稍后重试')
      }
    )
  }


  // 登陆
  handleLogin = () => {
    const { phoneValue, codeValue, agreeIsClick, agreenIsShow, nameValue,redirectPath ,otherQuery} =
      this.state
    if (!agreeIsClick && agreenIsShow) {
      Toast.info("请先阅读并同意协议")
      return
    }
    if (nameValue == "") {
      // Toast.info("请输入姓名")
      // return
    }
    if (phoneValue == "") {
      Toast.info("请输入手机号")
      return
    }
    if (codeValue == "") {
      Toast.info("请输入验证码")
      return
    }
    util.postYangAxios(
      api.login,
      {
        phone: phoneValue,
        verifycode: codeValue,
        name: nameValue,
      },
      async (res) => {
        await window.localStorage.setItem("login_token", res.token)
        await this.handleGetUserData()
        if(redirectPath){
          this.props.history.replace('/'+decodeURI(redirectPath)+'?'+decodeURI(otherQuery))
        }else{
          this.props.history.replace("/bottomTab")
        }
       
      },
      (err) => {
        console.log(err)
      }
    )
  }

  onChange = (val) => {
    console.log("val", val)
  }

  // 获取用户信息
  handleGetUserData = () => {
    util.postYangAxios(
      api.info,
      {},
      async (res) => {
        this.props.userDataSet(res)
      },
      (err) => {
        console.log(err)
      }
    )
  }
  agreenChange = ({ target }) => {
    this.setState({
      agreeIsClick: target.checked,
    })
  }
  render() {
    const {
      codeType,
      codeNum,
      phoneValue,
      codeValue,
      agreenIsShow,
    } = this.state
    const { AgreeItem } = Checkbox
    return (
      <div className={"login_view"}>
        <div className={"title_text"} onClick={this.showDebugger}>
          {/* 登录 */}
        </div>
        <div className={"row_center"}>
          <img src={logoImg} alt="" className={"logo_img"} />
        </div>
        <div className={"input_view"}>
          {/* <div
            className={"input_box"}
            onClick={() => {
              this.phoneInputn.focus()
            }}
          >
            <input
              type="text"
              value={nameValue}
              onChange={this.onNameChange}
              className={"input_text"}
              placeholder={"请输入姓名"}
              ref={(c) => {
                this.phoneInputn = c
              }}
            />
          </div> */}

          <div
            className={"input_box"}
            onClick={() => {
              this.phoneInputs.focus()
            }}
          >
            <input
              type="text"
              value={phoneValue}
              onChange={this.onPhoneChange}
              className={"input_text"}
              placeholder={"请输入手机号"}
              ref={(c) => {
                this.phoneInputs = c
              }}
            />
          </div>
          <div
            className={"row_between input_box input_box_last"}
            onClick={() => {
              this.codeInputs.focus()
            }}
          >
            <input
              type="text"
              value={codeValue}
              onChange={this.onCodeChange}
              className={"input_text"}
              placeholder={"请输入验证码"}
              ref={(c) => {
                this.codeInputs = c
              }}
            />
            {codeType === 0 && (
              <div className={"code_text"} onClick={this.handlePhoneLoginNote}>
                获取
              </div>
            )}

            {codeType === 1 && <div className={"code_text"}> {codeNum}秒</div>}
            {codeType === 2 && (
              <div className={"code_text"} onClick={this.handlePhoneLoginNote}>
                重新获取
              </div>
            )}
          </div>
        </div>
        {/* 协议： 合伙人需要选择 其他角色无此项 */}
        {agreenIsShow && (
          <div className={"agreen_box"}>
            <AgreeItem data-seed="logId" onChange={this.agreenChange}>
              <span className={"gray_box"}>
                点击按钮即表示你同意并愿意遵守习惯公社
              </span>
              <div>
                <a
                  onClick={(e) => {
                    e.preventDefault()
                    this.props.history.push("agreement")
                  }}
                >
                  《习惯公社推广合伙人协议》
                </a>
              </div>
            </AgreeItem>
          </div>
        )}
        <div className={"row_center login_btn_big_view"}>
          <div
            className={"row_center login_btn_view"}
            onClick={this.handleLogin}
          >
            登录
          </div>
        </div>
      </div>
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
  }
)(Login)
