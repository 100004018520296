
import React, { Component } from "react"
import copy from 'copy-to-clipboard';
import { Toast, Modal, } from 'antd-mobile';
import HeaderView from "../../component/header/headerView"
import ss from "./css/details.module.css"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import edit_icon_img from "../../image/editor_blue_icon.png"
import copy_icon_img from "../../image/copy_icon.png"
import empty_img from "../../image/empty.png"
class Order extends Component {
    constructor(props) {
        super(props)
        this.state = {
            type: 1,
            showModal: false,
            phone: '',
            list: [],
            product_id: '',
            residue_times: '',
            item: {}
        }
    }
    componentDidMount() {
        this.setState({
            type: parseInt(this.props.history.location.state.type),
            product_id: this.props.history.location.state.product_id
        }, () => {
            this.handleGetUserproductsDetails()
        })

    }
    // 库存清单
    handleGetUserproductsDetails = () => {
        util.getYangAxios(
            api.userproductsDetail,
            {
                product_id: this.state.product_id,
                status: this.state.type
            },
            async (res) => {
                this.setState({
                    list: res
                })
            },
            (err) => {
                console.log(err)
            }
        )
    }
    handCopy(cotent) {
        copy(cotent)
        Toast.success('复制成功', 1)
    }
    handModalShow(bool, item) {
        this.setState({
            showModal: bool,
            phone: '',
            residue_times: item.residue_times,
            item: item
        })
    }
    async handSendCode(item) {
        // return
        if (!/^1[0-9]{10}$/.test(this.state.phone)) {
            Toast.info("手机号格式错误", 1)
            return null
        }
        util.getYangAxios(
            api.userproductsActive,
            {
                detail_id: item.id,
                phone: this.state.phone
            },
            async (res) => {
                this.setState({
                    phone: ''
                })
                //   await  this.handleGetUserproductsDetails()
                window.location.reload()
            },
            (err) => {
                console.log(err)
            }
        )
    }
    handChangeCode() {
        if (!/^1[0-9]{10}$/.test(this.state.phone)) {
            Toast.info("手机号格式错误", 1)
            return null
        }
        util.getYangAxios(
            api.userproductsActive,
            {
                detail_id: this.state.item.id,
                phone: this.state.phone
            },
            async (res) => {
                this.handModalShow(false, {})
                this.handleGetUserproductsDetails()
            },
            (err) => {
                console.log(err)
            }
        )


    }
    setPhone(e) {
        let value = e.target.value;
        this.setState({
            phone: value
        })

    }

    // 订单文字渲染
    handleOrderTextRender = (title, content, showEdit = false, showCopy = false, showInput = false, item) => {
        return (
            <div className={[`${ss.row_align}`, `${ss.order_text_div}`].join(' ')} style={{ width: "100%" }}>
                <div className={ss.row_align} style={{width:'100%'}}>
                    <div className={ss.order_title_text}>{title}</div>
                    <div className={ss.order_content_text}>
                        {content == '已激活' && <span style={{ color: '#38B336' }}>{content}</span>}
                        {content == '未激活' && <span style={{ color: '#FD6B02' }}>{content}</span>}
                        {content !== '未激活' && content !== '已激活' && <span>{content}</span>}
                    </div>
                    {showInput && (
                        <div className={ss.modal_input_wrap}>
                            <input type="text" className={ss.modal_input} placeholder="请输入手机号" onChange={(e) => { this.setPhone(e, item) }} />
                        </div>
                    )}
                    {showEdit && (
                        <div className={[`${ss.order_edit_text}`, `${ss.row_end}`].join(' ')}>
                            <img src={edit_icon_img} alt="" className={ss.edit_icon_img} onClick={() => this.handModalShow(true, item)} />
                        </div>
                    )}
                    {showCopy && (
                        <div className={[`${ss.order_edit_text}`, `${ss.row_end}`].join(' ')}>
                            <img src={copy_icon_img} alt="" className={ss.edit_icon_img} onClick={() => this.handCopy(content)} />
                        </div>
                    )}
                </div>

            </div>
        )
    }
    // 渲染消息
    handleMsgRender = (title, num) => {
        const { type, list } = this.state
        let row
        row = (item, sectionID, index) => {
            // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
            return (
                <>

                    <div
                        className={ss.order_card}
                        key={index}
                    >
                        {this.handleOrderTextRender("产品名称：", item.product_name)}
                        {this.handleOrderTextRender("激活码：", item.activation_code, '', 'showCopy')}
                        {this.handleOrderTextRender("状态：", item.status_name)}
                        {type === 1 && this.handleOrderTextRender("所属人名称：", item.belong_user_name)}
                        {type === 1 && this.handleOrderTextRender("创建日期：", item.created_at)}
                        {type === 1 && this.handleOrderTextRender("激活人手机号：", '', '', '', 'showInput', item)}
                        {type === 2 && this.handleOrderTextRender("所属人名称：", item.belong_user_name)}
                        {type === 2 && this.handleOrderTextRender("出库日期：", item.bind_phone_time)}
                        {type === 3 && this.handleOrderTextRender("所属人名称：", item.belong_user_name)}
                        {type === 3 && this.handleOrderTextRender("激活人手机号：", item.phone, 'showEdit', '', '', item)}
                        {type === 3 && this.handleOrderTextRender("出库日期：", item.bind_phone_time)}
                        {type === 4 && this.handleOrderTextRender("所属人名称：", item.belong_user_name)}
                        {type === 4 && this.handleOrderTextRender("过期日期：", item.expiration_time)}
                        {
                            type === 1 && <div>
                                <div className={ss.send_btn} onClick={() => { this.handSendCode(item) }}>发送</div>
                                <div className={ss.send_btn_tips}>激活码可通过短信发送给使用人，短信发送成功后视为出库，可在未激活中在次使用</div>
                            </div>
                        }
                    </div>
                    {/* if(title==='库存'){
                            type=1
                        }else if(title==='出库'){
                            type=2
                        }else if(title==='未激活'){
                            type=3
                        }else if(title==='已过期'){
                            type=4
                        } */}
                </>
            )
        }
        return (
            <div style={{ padding: '0 10px' }}>
                {list.map((item, key) => {
                    return <div key={key}>{row(item, key)}</div>
                })}
            </div>
        )
    }
    render() {
        const { showModal, phone, residue_times, list } = this.state
        return (
            <div className={ss.order_container}>
                <HeaderView title={'库存详情'} />
                <div className={ss.order_card_line} />
                {this.handleMsgRender("库存详情", 1)}
                {list.length < 1 && (<div className={ss.empty_container}>
                    <img src={empty_img} className={ss.empty_pic} alt="" />
                    <div className={ss.empty_txt}>暂无数据</div>
                </div>)}
                <Modal
                    visible={showModal}
                    transparent
                    maskClosable={false}
                    title="更改激活人"
                    className={ss.wrapClassName}
                    closable={true}
                    onClose={() => this.handModalShow(false, {})}
                >
                    <div className={ss.modal_contianer}>
                        <div className={ss.modal_title}>激活人手机号：</div>
                        <div className={ss.modal_input_wrap} style={{width:'100%'}}>
                            <input type="text" className={ss.modal_input} value={phone} onChange={(e) => { this.setPhone(e) }} />
                        </div>
                        <div className={ss.modal_error_tips}>剩余{residue_times}次更改机会</div>
                        <div className={ss.modal_btn} onClick={() => { this.handChangeCode() }}>更改</div>
                        <div className={ss.modal_btn_tips}>注：更改后会重新发送激活短信，单个最多可更改3次</div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default Order