// 用户资料配置
export const USER_DATA_SET = "USER_DATA_SET"
// 退出登陆
export const OUT_LOGIN = "OUT_LOGIN"
// 学校信息设置
export const SCHOOL_DATA_SET = "SCHOOL_DATA_SET"
//
export const TAB_SET_INDEX = "TAB_SET_INDEX"
//城市合伙人主页的tab
export const INFO_TAB_SET = "INFO_TAB_SET"
