import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet } from "../../actions/XggsUserInformation"
import "./css/authentication.css"
import HeaderView from "../../component/header/headerView"
import { util, inst } from "../../common/util"
import { api } from "../../common/Config"
import { Toast, ImagePicker } from "antd-mobile"
class Authentication extends Component {
  constructor(props) {
    super(props)
    this.state = {
      qiToken: "",
      frontImgUrl: null,
      reverseImgUrl: null,
      nameValue: "",
      codeValue: "",
      addrValue: "",
      backIs: false,
      // test
      files: [],
      filesBack: [],
    }
  }

  componentDidMount() {
    this.handleGetQiNiu()
  }

  render() {
    const { nameValue, codeValue, addrValue } =
      this.state
    return (
      <div className={"pay_result_view authentication_div"}>
        <HeaderView title={"认证结果"} />
        <div className={"authentication_line_div"} />
        <div className={"column_align Image_box"} id="imageBox">
          <ImagePicker
            files={this.state.files}
            onChange={this.onFrontChange}
            selectable={this.state.files.length < 1}
            multiple={false}
            className={"front_card_img_mobeil"}
            length={1}
          />
          <div className={"front_card_img_text"}>上传身份证人像面</div>
          <ImagePicker
            files={this.state.filesBack}
            onChange={this.onBackChange}
            selectable={this.state.filesBack.length < 1}
            multiple={false}
            className={"front_card_img_mobeil back_mobil"}
            length={1}
          />
          <div className={"front_card_img_text"}>上传身份证国徽面</div>
        </div>
        <div className={"authentication_check_div"}>请核对您的个人信息</div>
        <div className={"authentication_input_div"}>
          <input
            type="text"
            value={nameValue}
            onChange={this.onNameChange}
            className={"authentication_input_text"}
            placeholder={"姓名"}
            ref={(c) => {
              this.nameInputs = c
            }}
            disabled="disabled"
          />
        </div>
        <div className={"authentication_input_div"}>
          <input
            type="text"
            value={codeValue}
            onChange={this.onCodeChange}
            className={"authentication_input_text"}
            placeholder={"身份证号"}
            ref={(c) => {
              this.codeInputs = c
            }}
            disabled="disabled"
          />
        </div>
        <div className={"authentication_input_div"}>
          <input
            type="text"
            value={addrValue}
            onChange={this.onAddrChange}
            className={"authentication_input_text"}
            placeholder={"地址"}
            ref={(c) => {
              this.codeInputs = c
            }}
            disabled="disabled"
          />
        </div>
        <div className={"row_center"}>
          <div
            className={"authentication_btn_blue row_center"}
            onClick={this.handleSubmit}
          >
            提交
          </div>
        </div>
      </div>
    )
  }

  // token
  handleGetQiNiu = () => {
    util.postYangAxios(
      api.qiNiuToken,
      {},
      async (res) => {
        this.setState({
          qiToken: res.token,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // token
  handleIdCard = (image, side) => {
    util.getYangAxios(
      api.idcard,
      {
        image,
        side,
      },
      async (res) => {
        // console.log(res, "res--认证")
        if (side == "front") {
          this.setState({
            nameValue: res.words_result["姓名"].words,
            codeValue: res.words_result["公民身份号码"].words,
            addrValue: res.words_result["地址"].words,
          })
        } else {
          this.setState({
            backIs: true,
          })
        }

        Toast.info("认证成功！", 1.5)
      },
      (err) => {
        console.log(err)
      },
      (res) => {
        // console.log("res--认证", res)
        this.setState({
          backIs: false,
        })
      }
    )
  }
  /**
   * 习惯公社上传图片随机名 xggs_********
   */

  randomImageNum = () => {
    let r = Math.floor(Math.random() * 2021)
    let f = Math.floor(Math.random() * 2021)
    let d = Date.parse(new Date())
    // console.log(`xg_GS${d}${r}${f}`)
    return `xg_GS${d}${r}${f}`
  }

  onNameChange = (event) => {
    this.setState({
      nameValue: event.target.value,
    })
  }

  onCodeChange = (event) => {
    this.setState({
      codeValue: event.target.value,
    })
  }

  onAddrChange = (event) => {
    this.setState({
      addrValue: event.target.value,
    })
  }  

  // 提交资料
  handleSubmit = () => {
    const {
      frontImgUrl,
      reverseImgUrl,
      nameValue,
      codeValue,
      addrValue,
      backIs,
      files,
      filesBack,
    } = this.state
    if (!frontImgUrl || files.length < 1) {
      Toast.info("请上传身份证正面", 1.5)
      return null
    }
    if (!reverseImgUrl || filesBack.length < 1) {
      Toast.info("请上传身份证反面", 1.5)
      return null
    }
    if (!nameValue) {
      Toast.info("姓名错误，请重新认证", 1.5)
      return null
    }
    if (!codeValue) {
      Toast.info("身份证号码错误，请重新认证", 1.5)
      return null
    }
    if (!addrValue) {
      Toast.info("身份证地址错误，请重新认证", 1.5)
      return null
    }

    if (!backIs) {
      Toast.info("请上传正确的证件照片", 1.5)
      return
    }
    util.postYangAxios(
      api.realname,
      {
        id_photo: frontImgUrl,
        id_photo_reverse: reverseImgUrl,
        name: nameValue,
        idcard: codeValue,
        address: addrValue,
      },
      async (res) => {
        this.props.history.replace("/authenticationSuccess")
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // test start
  onFrontChange = async (files, type, index) => {
    console.log("ImagePicker", files)
    this.setState({
      files,
    })
    if (files.length > 0) {
      var file = new FormData()
      file.append("file", files[0].file)
      file.append("token", this.state.qiToken)
      file.append("key", this.randomImageNum())

      Toast.loading("上传中", 0)
      const res = await inst({
        method: "post",
        url: "http://upload.qiniu.com",
        data: file,
      })

      if (res.status === 200) {
        this.setState(
          {
            frontImgUrl: res.data.key,
          },
          () => {
            Toast.hide()
            Toast.info("上传成功，认证中！", 1.5)
            this.handleIdCard(this.state.frontImgUrl, "front")
          }
        )
      }
    }
  }
  onBackChange = async (files, type, index) => {
    console.log("ImagePicker", files)
    this.setState({
      filesBack: files,
    })
    if (files.length > 0) {
      var file = new FormData()
      file.append("file", files[0].file)
      file.append("token", this.state.qiToken)
      file.append("key", this.randomImageNum())

      Toast.loading("上传中", 0)
      const res = await inst({
        method: "post",
        url: "http://upload.qiniu.com",
        data: file,
      })

      if (res.status === 200) {
        this.setState(
          {
            reverseImgUrl: res.data.key,
          },
          () => {
            Toast.hide()
            Toast.info("上传成功，认证中！", 1.5)
            this.handleIdCard(this.state.reverseImgUrl, "back")
          }
        )
      }
    }
  }
  // test end
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
  }
)(Authentication)
