import * as types from '../constant/UserTypes'


// 用户信息设置
export function userDataSet(obj) {
  return {
    // type是必要参数，通过type值判断
    type: types.USER_DATA_SET,
    obj,
  }
}

// 退出登陆
export function outLogin() {
  return {
    // type是必要参数，通过type值判断
    type: types.OUT_LOGIN,
  }
}


// 学校信息设置
export function tabSetIndex(obj) {
  return {
    // type是必要参数，通过type值判断
    type: types.TAB_SET_INDEX,
    obj,
  }
}

// 学校信息设置
export function infoTabSet(obj) {
  return {
    // type是必要参数，通过type值判断
    type: types.INFO_TAB_SET,
    obj,
  }
}



