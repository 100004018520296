import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet, tabSetIndex } from "../../actions/XggsUserInformation"
import { TabBar } from "antd-mobile"
import "antd-mobile/dist/antd-mobile.css"
import Home from "../home/home"
import HomeNew from "page/homeNew/home"
// import Member from "../member/memberx"
import Member from "../customer/affiliation"
import My from "../users/my"
import { util } from "../../common/util"
import { api } from "../../common/Config" // or 'antd-mobile/dist/antd-mobile.less'
import { isNull } from "utils/isNull"
import "./css/bottomTab.css"

class BottomTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: "blueTab",
      hidden: false,
      fullScreen: false,
    }
  }

  componentDidMount() {
    // const {tabStr} =this.props.UserDataReducer;
    // if(tabStr){
    //     this.setState({
    //         selectedTab: tabStr,
    //     })
    // }

    let tabStr = sessionStorage.getItem("tabStr")
    if (!isNull(tabStr)) {
      this.setState({
        selectedTab: tabStr,
      })
    }

    this.handleGetUserData()
  }

  // 获取用户信息
  handleGetUserData = () => {
    util.postYangAxios(
      api.info,
      {},
      async (res) => {
        this.props.userDataSet(res)
        sessionStorage.setItem("userData", JSON.stringify(res))
      },
      (err) => {
        console.log(err)
      }
    )
  }

  renderContent(pageText) {
   
    if (pageText === "Life") {
      // return <Home history={this.props.history} />
      return <HomeNew history={this.props.history} />
    } else if (pageText === "member") {
      return <Member history={this.props.history} />
    } else {
      return <My history={this.props.history} />
    }
  }

  render() {
    const { userData } = this.props.UserDataReducer
    return (
      <div
        style={{
          position: "fixed",
          height: "100%",
          width: "100%",
          top: 0,
          background: "#fff",
        }}
      >
        <TabBar
          unselectedTintColor="#949494"
          tintColor="#33A3F4"
          barTintColor="white"
          hidden={userData && userData.status == 0}
        >
          <TabBar.Item
            title="首页"
            key="Life"
            icon={
              <div
                style={{
                  width: "22px",
                  height: "22px",
                  background:
                    "url(http://agent.xzwl100.com/agentclient/home_bottom_hose_dark.png) center center /  21px 21px no-repeat",
                }}
              />
            }
            selectedIcon={
              <div
                style={{
                  width: "22px",
                  height: "22px",
                  background:
                    "url(http://agent.xzwl100.com/agentclient/home_bottom_hose.png) center center /  21px 21px no-repeat",
                }}
              />
            }
            selected={this.state.selectedTab === "blueTab"}
            // badge={1}
            onPress={() => {
              this.setState({
                selectedTab: "blueTab",
              })

              sessionStorage.setItem("tabStr", "blueTab")
              this.props.tabSetIndex("blueTab")
            }}
            data-seed="logId"
          >
            {this.renderContent("Life")}
          </TabBar.Item>

          <TabBar.Item
            title="所属"
            key="member"
            icon={
              <div
                style={{
                  width: "22px",
                  height: "22px",
                  background: "url(http://agent.xzwl100.com/agentclient/home_bottom_member_dark.png) center center /  21px 21px no-repeat"
                }}
              />
            }
            selectedIcon={
              <div
                style={{
                  width: "22px",
                  height: "22px",
                  background: "url(http://agent.xzwl100.com/agentclient/home_bottom_member.png) center center /  21px 21px no-repeat",
                }}
              />
            }
            selected={this.state.selectedTab === "yellowTab"}

            onPress={() => {
              this.setState({
                selectedTab: "yellowTab",
              })

              sessionStorage.setItem("tabStr", "yellowTab")
              this.props.tabSetIndex("yellowTab")
            }}
            data-seed="logId2"
          >
            {this.renderContent("member")}
            {/* <div style={{'textAlign':'center',paddingTop:'80px',fontSize:'20px'}}>正在开发中。。。</div> */}
          </TabBar.Item>

          <TabBar.Item
            icon={
              <div
                style={{
                  width: "22px",
                  height: "22px",
                  background:
                    "url(http://agent.xzwl100.com/agentclient/home_bottom_user_dark.png) center center /  21px 21px no-repeat",
                }}
              />
            }
            selectedIcon={
              <div
                style={{
                  width: "22px",
                  height: "22px",
                  background:
                    "url(http://agent.xzwl100.com/agentclient/home_bottom_user.png) center center /  21px 21px no-repeat",
                }}
              />
            }
            title="我的"
            key="Koubei"
            // badge={'new'}
            selected={this.state.selectedTab === "redTab"}
            onPress={() => {
              this.setState({
                selectedTab: "redTab",
              })
              sessionStorage.setItem("tabStr", "redTab")
              this.props.tabSetIndex("redTab")
            }}
            data-seed="logId1"
          >
            {this.renderContent("Koubei")}
          </TabBar.Item>
        </TabBar>
      </div >
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
    tabSetIndex,
  }
)(BottomTab)
