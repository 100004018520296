import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet } from "../../actions/XggsUserInformation"
import "./css/agentDetails.css"
import ambassador_company_gray_img from "../../image/ambassador_company_gray_img.png"
import business_license_img from "../../image/business_license_img.png"
import HeaderView from "../../component/header/headerView"
import { Toast,   ListView } from "antd-mobile"
import { Tabs } from "antd"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import grayRightIcon from "../../image/gray_right_icon.png"
const { TabPane } = Tabs
class AgentDetails extends Component {
  constructor(props) {
    super(props)
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
    })
    this.state = {
      order: null,
      orderDetails: null,
      activeKey: "1",
      dataSource,
      datas: [],
      pageNo: 1,
      pageSize: 2,
      hasMore: true,
      refreshing: true,
      isLoading: true,
      dataArr: [],
    }
  }

  componentDidMount() {
    console.log(this.props.history.location.state)
    this.setState(
      {
        order: this.props.history.location.state.order,
      },
      () => this.handleGetOrder()
    )
  }
  // 总接口
  handleGetOrder = () => {
    const { activeKey } = this.state

    if (this.state.pageNo > this.state.totalPage) {
      this.setState({
        refreshing: false,
        isLoading: false,
        hasMore: false,
      })
      Toast.info("没有数据了~", 1)
      return false
    }

    if (activeKey == "1") {
      this.handleGetDetails()
    } else if (activeKey == "2") {
      this.handleSchoolDetails()
    }
  }

  // 滑动到底部时加载更多
  onEndReached = (event) => {
    // 加载中或没有数据了都不再加载
    if (this.state.isLoading || !this.state.hasMore) {
      return
    }
    this.setState(
      {
        isLoading: true,
        pageNo: this.state.pageNo + 1, // 加载下一页
      },
      () => {
        this.handleGetOrder()
      }
    )
  }

  // 获取详情接口
  handleGetDetails = () => {
    const { order } = this.state
    util.getYangAxios(
      api.channelsList,
      {
        currentPage: this.state.pageNo,
        search_data: {
          parent_id: order.id,
        },
      },
      async (res) => {
        const dataList = res.list
        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 获取直属学校数据
  handleSchoolDetails = () => {
    const { order } = this.state
    util.getYangAxios(
      api.schoolStatistics,
      {
        zhishutype: 1,
        mode: 1,
        parent_id: order.id,
        // currentPage: this.state.pageNo,
      },
      async (res) => {
        const dataList = res.data.school
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          dataArr: dataList,
          dataSource: this.state.dataSource.cloneWithRows(dataArr),
          refreshing: false,
          isLoading: false,
          hasMore: false,
          // dataArr: dataArr,
        })

        /* this.setState({
            totalPage: res.data.school_sum,
          })
          var dataArr = this.state.dataArr.concat(dataList) //关键代码
          this.setState({
            pageNo: this.state.pageNo,
            dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
            refreshing: false,
            isLoading: false,
            dataArr: dataArr, // 保存新数据进state
          }) */
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 订单卡片渲染
  handleOrderCardRender = (title, content, classname) => {
    return (
      <div className={"purchase_content_card column_center"}>
        <div
          className={
            classname && classname == "shangji"
              ? "purchase_title_text shangji"
              : "purchase_title_text"
          }
        >
          {content}
        </div>
        <div className={"purchase_content_text"}>{title}</div>
      </div>
    )
  }

  handleStatus = (num) => {
    if (num == "0") {
      return "未交费"
    } else if (num == "1") {
      return "代理中"
    } else {
      return "停用"
    }
  }

  // TAB切换
  changeTabs = (key) => {
    this.setState(
      {
        activeKey: key,
        datas: [],
        pageNo: 1,
        pageSize: 2,
        hasMore: true,
        refreshing: true,
        isLoading: true,
        dataArr: [],
        dataSource: new ListView.DataSource({
          rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
        }),
      },
      () => {
        if (key == 1) {
          this.handleGetDetails()
        } else {
          this.handleSchoolDetails()
        }
      }
    )
  }

  // 跳转到订单详情
  handleSkipDetails = (obj, num) => {
    if (num == 1) {
      this.props.history.push({
        pathname: "/agentDetails",
        state: {
          order: obj,
        },
      })
    } else if (num == 2) {
      this.props.history.push({
        pathname: "/purchaseOrder",
        state: {
          order: obj,
        },
      })
    }
  }

  // 渲染消息
  handleMsgRender = (num) => {
    let row
    if (num == 1) {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <div key={index} className={"agent_card_div"}>
            <div className={"row_between"}>
              <div className={"agent_card_name_div"}>{item.name}</div>
              <div
                className={"agent_card_look_div row_align"}
                onClick={() => {
                  this.setState(
                    {
                      order: item,
                    },
                    () => this.changeTabs(this.state.activeKey)
                  )
                }}
              >
                查看
                <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
              </div>
            </div>
            <div className={"agent_card_small_div"}>
              {this.handleOrderCardRender("编号", item.id)}
              {this.handleOrderCardRender("手机号码", item.phone)}
              {this.handleOrderCardRender("联系人", item.linkman)}
              {this.handleOrderCardRender(
                "上级",
                item.parent ? item.parent.name : "",
                "shangji"
              )}
              {this.handleOrderCardRender("邮箱", item.email)}
              {this.handleOrderCardRender(
                "渠道范围",
                (item.province ? item.province : "") +
                  (item.city ? item.city : "") +
                  (item.area ? item.area : "")
              )}
              {this.handleOrderCardRender(
                "性质",
                item.type == 1 ? "企业" : "个人"
              )}
              {this.handleOrderCardRender("加入时间", item.join_at)}
              {this.handleOrderCardRender(
                "状态",
                this.handleStatus(item.status)
              )}
            </div>
          </div>
        )
      }
    } else if (num == 2) {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            <div key={index} className={"agent_card_div"}>
              <div className={"row_between"}>
                <div className={"agent_card_name_div"}>{item.name}</div>
                <div
                  className={"agent_card_look_div row_align"}
                  onClick={() => {
                    this.setState(
                      {
                        order: item,
                      },
                      () => this.changeTabs(this.state.activeKey)
                    )
                  }}
                >
                  {/* 查看
                  <img
                    src={grayRightIcon}
                    alt=""
                    className={"gray_right_icon"}
                  /> */}
                </div>
              </div>
              <div className={"agent_card_small_div"}>
                {this.handleOrderCardRender("学校编码", item.id)}
                {this.handleOrderCardRender("学校名称", item.name, "shangji")}
                {this.handleOrderCardRender(
                  "学段",
                  item.type == 1 ? "幼儿园" : "小学"
                )}
                {this.handleOrderCardRender("加入时间", item.created_at)}
              </div>
            </div>
          </>
        )
      }
    }
    return (
      <>
        <ListView
          contentContainerStyle={{ backgroundColor: "#fff" }}
          ref={(el) => (this.lv = el)}
          dataSource={this.state.dataSource}
          renderFooter={() => (
            <div className="footer">
              {this.state.isLoading ? "加载中..." : "暂无更多数据"}
            </div>
          )}
          renderRow={row}
          useBodyScroll
          onEndReachedThreshold={10}
          onEndReached={this.onEndReached}
          pageSize={10}
        />
      </>
    )
  }

  render() {
    const { order, activeKey } = this.state
    return (
      <div
        className={"pay_result_view"}
        style={{ height: window.innerHeight + "px", background: "#F6F7FB" }}
      >
        <HeaderView title={"商家详情"} />
        {order && (
          <>
            <div className={"agent_detail_div"}>
              <div className={"agent_detail_name_div"}>{order.name}</div>
              <div className={"row_between agent_detail_img_div"}>
                <img
                  src={
                    order.head_portrait
                      ? `https://cdn.ellll.com/${order.head_portrait}`
                      : ambassador_company_gray_img
                  }
                  alt=""
                  className={"business_license_img"}
                />
                <img
                  src={
                    order.id_photo
                      ? `https://cdn.ellll.com/${order.id_photo}`
                      : business_license_img
                  }
                  alt=""
                  className={"business_license_img"}
                />
              </div>
            </div>
            <div className={"purchase_data_card"}>
              {this.handleOrderCardRender("联系人", order.linkman)}
              {this.handleOrderCardRender("邮箱", order.email)}
              {this.handleOrderCardRender("手机号码", order.phone)}
              {this.handleOrderCardRender(
                "渠道范围",
                (order.province ? order.province : "") +
                  (order.city ? order.city : "") +
                  (order.area ? order.area : "")
              )}
              {this.handleOrderCardRender(
                "性质",
                order.type == 1 ? "企业" : "个人"
              )}
              {this.handleOrderCardRender(
                "渠道状态",
                this.handleStatus(order.status)
              )}
              {this.handleOrderCardRender("加入时间", order.join_at)}
            </div>
            <div className={"agent_detail_list_div"}>
              <Tabs
                activeKey={activeKey}
                onChange={this.changeTabs}
                tabBarStyle={{
                  marginBottom: 0,
                  color: "#34373D",
                  paddingLeft: "16px",
                }}
              >
                <TabPane tab="下级商家" key="1">
                  {this.handleMsgRender(1)}
                </TabPane>
                <TabPane tab="直属学校" key="2">
                  {this.handleMsgRender(2)}
                </TabPane>
              </Tabs>
            </div>
          </>
        )}
      </div>
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
  }
)(AgentDetails)
