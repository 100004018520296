import React, { Component } from "react"
import moment from "moment"

export default class xieyi extends Component {
  render() {
    return (
      <div>
        提示条款：
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;欢迎您与我司【上海形者网络科技有限公司】（下称“甲方”）签署本《自由职业者服务协议》（下称“本协议”）。
        <br />
        为维护乙方的自身权益，在乙方签署本协议之前，请认真阅读本协议，务必审慎阅读、充分理解各条款内容，特别是权利义务条款、法律适用和管辖条款。
        <br />
        【合作关系】甲方与乙方通过本协议建立服务关系，适用《中华人民共和国合同法》、《中华人民共和国民法总则》等民事法律、法规。双方不构成任何劳动/劳务合同关系或其他类似劳动法律关系不适用《中华人民共和国劳动法》《中华人民共和国劳动合同法》等劳动关系的法律、法规。
        <br />
        <br />
        协议条款：
        <br />
        第一条 服务工作内容 <br />
        1.1
        甲方将项目委托乙方提供服务。
        <br />
        1.2 乙方应按照甲方要求的服务标准来提供服务。
        <br /> 1.3
        乙方承诺和保证，乙方有提供本协议约定服务所对应的相应资质和专业能力，乙方依据本协议约定收取相关服务费用不违反任何法律法规和政策规定。
        <br />
        <br />
        第二条 协议效力 <br />
        2.1
        乙方承诺并保证所提供的签约信息（包括姓名、身份证号码、银行信息等信息）真实、准确，以免影响本协议效力。
        <br />
        2.2
        本协议一式贰份，自甲、乙双方盖章之日起生效，甲、乙双方各执一份，具有同等法律效力。
        <br />
        2.3 本协议有效期限1年。期限届满，双方应另行签署新的协议；若双方未签署新的协议，而以实际行动继续合作的，视为双方同意本协议期限顺延直至按照本协议约定终止或解除时为止。
        <br />
        <br />
        第三条 我司的权利和义务 <br />
        3.1
        乙方应根据甲方的安排和要求完成相关的服务，服务期间乙方应遵守本合同及附件约定，遵守甲方的服务规范。
        <br />
        3.2
        甲方将对乙方提供的服务成果进行验收。
        <br />
        3.3
        甲方应按约定及时向乙方支付费用。
        <br />
        3.4
        乙方同意，乙方不可撤销地授权甲方为支付服务费用的需要获取乙方的相关信息（包括但不限于个人身份信息、实名登记手机号码、银行账户相关信息等），并将乙方的相关信息提交第三方机构进行身份验证。
        <br />
        <br />
        第四条 您的权利和义务 <br />
        4.1
        乙方应按时尽责地完成甲方要求的服务内容，并达到规定的质量标准，不得有侵犯他人知识产权、财产权、人身权、肖像权、隐私权、商业秘密或其他合法权益以及其他违反国家法律法规、国家政策、或有悖于公序良俗的内容。
        <br />
        4.2
        协议期内，乙方有义务对双方合作的有关协议及合作过程中知悉的甲方的相关信息予以保密，未经甲方书面许可，乙方不得以任何方式向其它方泄漏、给予或转让该等保密信息。否则，因此导致甲方的损失，由乙方负责赔偿。
        <br />
        4.3
        乙方不得将甲方委托事项进行转包或分包。
        <br />
        <br />
        第五条 服务费用的支付
        <br />
        5.1 
        甲方将应按照本协议附件约定的项目总费用向乙方支付服务费用。甲方以人民币形式向乙方支付服务费用。乙方同意，应缴纳的（生产经营所得）个人所得税及其他相关税费由甲方委托有资质的的单位代征。
        <br />
        5.2
        甲方发放服务费的目标账户以乙方提供的银行账户为准。因乙方提供的银行账帐户不实/有误/信息不符等造成的一切损失由乙方自行承担。如乙方账户变更或发生不可用等情况时，应及时进行告知甲方，否则，由此造成的一切损失由乙方自行负责。
        <br />
        <br />
        第六条 知识产权 <br />
        6.1
        乙方依照本协议和附件约定向甲方交付的服务成果及提供服务过程中新产生相关知识产权的，该知识产权中的人身权利根据国家相关法律法规确定，该知识产权中的财产权利归甲方。
        <br />
        <br />
        第七条 违约责任 <br />
        7.1 
        双方应按本协议约定履行，如有违反，守约方有权要求对方及时改正；造成对方损失的，守约方有权要求违约方赔偿。
        <br />
        7.2 
        因不可抗力造成损失的，彼此不负赔偿责任，但发生不可抗力一方应及时将有关情况通知另一方，并尽最大努力进行补救。本协议所称不可抗力是指不能预见、不能克服并不能避免且对一方当事人造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、火灾和风暴等以及社会事件如战争、动乱、政府行为、黑客事件、大范围电信事故等。
        <br />
        <br />
        第八条 其他
        <br />
        8.1
        因履行本协议发生的纠纷，双方应友好协商解决，协商不成的，向甲方住所地人民法院诉讼解决。
        <br />
        8.2
        在履行本协议过程中，一方向对方发出的一切通知（包括电子邮件、手机短信、挂号信、EMS或快递），均以本协议文尾所列的联系信息及乙方签约注册时提供的信息为准。该等信息发生变更时，一方应立即以书面形式通知另一方变更后的信息，否则仍以原信息为准。以电子邮件、手机短信发出的，在发出日的次日视为送达；以挂号信、EMS或快递发出的，在邮戳日/妥投日的5日后视为送达。
        <br />
        （以下无正文）
        <br />
        <br /> 甲方：上海形者网络科技有限公司
        <br />
        签署日期： {moment(new Date()).format("YYYY年MM月DD日")}
        <br />
        <br /> 乙方：{this.props.name}
        <br /> 签署日期： {moment(new Date()).format("YYYY年MM月DD日")}
        <br />
        <br />
      </div>
    )
  }
}
