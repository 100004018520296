import React, { Component } from "react"
import HeaderCloseView from "component/header/headerCloseView"
import SuccessImg from "image/pay_select_blue_icon.png"
import ss from "./css/cashMoney.module.css"

export default class cashSuccess extends Component {
  render() {
    return (
      <div className={`${ss.cashMoney_success} ${ss.cashSuccess}`}>
        <HeaderCloseView page="/"></HeaderCloseView>
        <div className={ss.tip_view}>
          <img className={ss.img_bottom} src={SuccessImg} alt="" />
          <p>恭喜您！转出成功！</p>
        </div>
      </div>
    )
  }
}
