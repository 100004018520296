import React, { Component } from 'react'
import './css/successJoin.css'
import pay_select_blue_icon from "../../image/successImg2.png";
import HeaderView from "../../component/header/headerView";
export default class SusscessJoin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paramObj: {}
        };
    }
    componentDidMount() {
        let obj = this.getUrlParams()
        if (obj.type) {
            this.setState({
                paramObj: obj
            })
        }
    }
    goHome = () => {
        this.props.history.replace("/")
    }
    // 获取url参数
    getUrlParams = () => {
        let url = window.location.search //?articleId=1&&articleNum=1&opt=edit
        let index = url.indexOf("?")
        let obj = {}
        if (index !== -1) {
            let str = url.substr(1)
            let arr = str.split("&")
            for (let i = 0; i < arr.length; i++) {
                obj[arr[i].split("=")[0]] = arr[i].split("=")[1]
            }
        }
        return obj //{articleId: "1", articleNum: "1", opt: "edit"}
    }

    render() {
        const { paramObj } = this.state
        return (
            <div className={"pay_result_view"} style={{ height: window.innerHeight + 'px' }}>
                <HeaderView
                    title={paramObj.type==1?'合伙人进群':'私教会员进群'}
                />
                <div className={"pay_result_box column_align"}>
                    <div className={'success_class_top_content row_center'}>
                        <img src={pay_select_blue_icon} alt="" className={'success_class_top_content_img'} />
                        <div className={'success_class_top_content_tag'}>恭喜您购买成功，请尽快加入{paramObj.type==1?'合伙人':'私教会员'}内部群</div>
                    </div>
                    <div className={'success_class_actvar_content'}>
                        <img src={'https://cdn.ellll.com/1683599246856fkys183972592612'} alt="" className={'success_class_actvar'} />
                        <div className={'success_class_name'}>习惯公社{paramObj.type==1?'合伙人':'私教会员'}内部群</div>
                    </div>
                    <div className={'success_class_actvar_content'}>
                    {paramObj.type==1?<img src={'https://cdn.ellll.com/1683696798145fkys317589826259'} alt="" className={'success_class_wechat'} />:<img src={'https://cdn.ellll.com/1683623181370fkys155364579218'} alt="" className={'success_class_wechat'} />}
                        
                        <div className={'success_class_top_content_tag2'}>长按二维码扫码进群</div>
                        <div className={'success_class_top_content_tag3'}>或</div>
                        <div className={'success_class_top_content_tag2'}>截图本页面，使用微信扫一扫进群</div>
                    </div>
                    <div className={'add_group_btn'} onClick={this.goHome}> 完成</div>
                </div>
            </div>
        )
    }
}

