
import React, { Component } from "react"
import ss from "./css/details.module.css"
import HeaderView from "../../component/header/headerView"
import { util } from "../../common/util"
import { api } from "../../common/Config"

const performanceList = [
    { name: '总业绩', key: 'all' ,tag:'¥'},
    { name: '本周业绩', key: 'week',tag:'¥' },
    { name: '本月业绩', key: 'month' ,tag:'¥'},
]
const orderList = [
    { name: '总订单', key: 'all' },
    { name: '本周订单', key: 'week' },
    { name: '本月订单', key: 'month' },
]
const agentList = [
    { name: '全部', key: 'all' },
    { name: '本周新增', key: 'week' },
    { name: '本月新增', key: 'month' },
    { name: '本年新增', key: 'year' },
]
class CustomerDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            actived:1,
            user_id:'',
            info:{userinfo:{},agent:{},pay:{},private:{},shopkeeper:{},partner:{},order:{},performance:{}}
        }
    }
    handTabs=(type)=>{
        this.setState({
            actived:type
        },()=>{
            this.handleGetDetails()
        })
    }
    componentDidMount() {
        this.setState({
            user_id: parseInt(this.props.history.location.state.user_id),
        }, () => {
            this.handleGetDetails()
        })
    }
    handleGetDetails() {
        const { actived, user_id} = this.state
        util.getYangAxios(
            api.customInfo,
            {
                user_id: user_id,
                // develop_type: actived,
            },
            async (res) => {
                this.setState({
                    info: res, 
                })
            },
            (err) => {
                console.log(err)
            }
        )
    }
    render() {
        const {actived,info}=this.state
        return (
            <div className={ss.customer_details_container}>
                 <HeaderView title={"客户详情"} />
                <div className={ss.customer_name_container}>
                    <span>客户姓名：{info.userinfo.name}</span>
                    <span className={ss.customer_type}>{info.userinfo.role_name}</span>
                </div>
                {/* <div className={ss.customer_consumer_contianer}>
                    <div className={ss.tabs_contianer}>
                        <span onClick={()=>this.handTabs(1)} className={[`${ss.tab}`,`${actived===1?ss.tab_active:''}`].join(' ')}>直接</span>
                        <span onClick={()=>this.handTabs(2)} className={[`${ss.tab}`,`${actived===2?ss.tab_active:''}`].join(' ')}>间接</span>
                    </div>
                </div> */}
                <div className={ss.customer_count_container}>
                    {performanceList.map((item) => {
                        return <div className={ss.customer_count_info} key={item.key}>
                            <div className={ss.count_number}>{item.tag}{info.performance[item.key]}</div>
                            <div className={ss.count_title}>{item.name}</div>
                        </div>
                    })}
                     {orderList.map((item) => {
                        return <div className={ss.customer_count_info} key={item.key}>
                            <div className={ss.count_number}>{item.tag}{info.order[item.key]}</div>
                            <div className={ss.count_title}>{item.name}</div>
                        </div>
                    })}
                </div>
                <div className={ss.customer_section}>
                    <div className={ss.customer_detais_title}>代理商</div>
                    <div className={ss.customer_count_container}>
                        {agentList.map((item) => {
                            return <div className={ss.customer_count_item} key={item.key}>
                                <div className={ss.count_number}>{info.agent[item.key]}</div>
                                <div className={ss.count_title}>{item.name}</div>
                            </div>
                        })}
                    </div>
                </div>
                <div className={ss.customer_section}>
                    <div className={ss.customer_detais_title}>合伙人</div>
                    <div className={ss.customer_count_container}>
                        {agentList.map((item) => {
                            return <div className={ss.customer_count_item} key={item.key}>
                                <div className={ss.count_number}>{info.partner[item.key]}</div>
                                <div className={ss.count_title}>{item.name}</div>
                            </div>
                        })}
                    </div>
                </div>
                <div className={ss.customer_section}>
                    <div className={ss.customer_detais_title}>店主</div>
                    <div className={ss.customer_count_container}>
                        {agentList.map((item) => {
                            return <div className={ss.customer_count_item} key={item.key}>
                                <div className={ss.count_number}>{info.shopkeeper[item.key]}</div>
                                <div className={ss.count_title}>{item.name}</div>
                            </div>
                        })}
                    </div>
                </div>
                <div className={ss.customer_section}>
                    <div className={ss.customer_detais_title}>私有流量</div>
                    <div className={ss.customer_count_container}>
                        {agentList.map((item) => {
                            return <div className={ss.customer_count_item} key={item.key}>
                                <div className={ss.count_number}>{info.private[item.key]}</div>
                                <div className={ss.count_title}>{item.name}</div>
                            </div>
                        })}
                    </div>
                </div>
                <div className={ss.customer_section}>
                    <div className={ss.customer_detais_title}>付费用户</div>
                    <div className={ss.customer_count_container}>
                        {agentList.map((item) => {
                            return <div className={ss.customer_count_item} key={item.key}>
                                <div className={ss.count_number}>{info.pay[item.key]}</div>
                                <div className={ss.count_title}>{item.name}</div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default CustomerDetails