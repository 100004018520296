import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet, tabSetIndex } from "../../actions/XggsUserInformation"
import { Tabs } from "antd"
import grayRightIcon from "../../image/gray_right_icon.png"
import ss from "page/home/css/home.module.css"

const { TabPane } = Tabs

/* 城市合伙人（资深合伙人） */
class CityPerson extends Component {
  state = {}
  componentDidMount() {
    this.init()
  }
  render() {
    const { lively, nolively, citypersonKey } = this.props
    return (
      <div>
        <div className={ss.down_city_person}>下级合伙人信息</div>
        <Tabs
          activeKey={citypersonKey}
          onChange={(key) => {
            this.changeTabs(key, "citypersonKey")
          }}
          tabBarStyle={{ paddingLeft: 16, marginBottom: 0 }}
        >
          <TabPane tab="五星合伙人（活跃）" key="1">
            {this.cityPersonRender(lively)}
          </TabPane>
          <TabPane tab="五星合伙人（非活跃）" key="2">
            {this.cityPersonRender(nolively)}
          </TabPane>
        </Tabs>
      </div>
    )
  }
  init = () => {
    // console.log(this.props, "-------------------------")
  }

  // TAB切换
  changeTabs = (key, tabtype) => {
    this.props.cityTabChange({ [tabtype]: key })
  }

  cityPersonRender = (arr = this.props.lively) => {
    return (
      <div className={ss.city_person_view}>
        {arr.length > 0 ? (
          arr.map((item, index) => {
            return (
              <div key={index}>
                <div className={"order_card"}>
                  {this.fiveLineRow(item.id, "编码：", item.id, true)}
                  {this.fiveLineRow(item.id, "姓名：", item.name)}
                  {this.fiveLineRow(item.id, "联系电话：", item.phone)}
                  {this.fiveLineRow(
                    item.id,
                    "身份：",
                    item.ambassador_level == 3
                      ? "三星合伙人"
                      : item.ambassador_level == 4
                      ? "四星合伙人"
                      : "五星合伙人"
                  )}
                </div>
                <div className={"order_card_line"} />
                {index == arr.length - 1 && (
                  <p style={{ textAlign: "center" }}>我是有底线的~</p>
                )}
              </div>
            )
          })
        ) : (
          <p style={{ textAlign: "center" }}>暂无数据~</p>
        )}
      </div>
    )
  }

  fiveLineRow = (id, l, r, isShow = false) => {
    return (
      <div className={`${ss.order_text_div} row_between`}>
        <div className={"row_align"}>
          <div className={ss.order_title_text}>{l}</div>
          <div className={ss.order_content_text}>{r}</div>
        </div>
        {isShow && (
          <div
            className={`${ss.order_look_text} row_end`}
            onClick={this.lookFiveDeatils.bind(this, id)}
          >
            查看
            <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
          </div>
        )}
      </div>
    )
  }

  lookFiveDeatils = (id) => {
    // console.log("id-当前id", id)
    this.props.history.push({
      pathname: "fiveDetails",
      state: {
        id: id,
        isaddParams: true,
        citypersonKey: this.props.citypersonKey,
      },
    })
  }
}

export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
    tabSetIndex,
  }
)(CityPerson)
