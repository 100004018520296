import React, { Component } from "react"
import "./css/agreement.css"
import closeButton from "../../image/closebtn.png"

export default class agreement extends Component {
  render() {
    return (
      <div className={"agreenment"}>
        <div className={"header_info"}>
          <img
            alt=""
            className={"close_button"}
            src={closeButton}
            onClick={() => this.props.history.go(-1)}
          ></img>
          <span className={"header_title"}>习惯公社推广合伙人协议</span>
        </div>

        <div
          className={"content"}
          style={{ padding: "5px 16px", fontSize: "14px" }}
        >
          <div className={"agreen_line_row"}>
            习惯公社推广协议（以下简称“本协议”）由以下双方根据《中华人民共和国合同法》等相关法律法规签署并遵照执行。
            <br />
            平台方上海形者网络科技发展有限公司以下称“习惯公社”
          </div>
          <br />
          <div className={"agreen_line_row"}>
            1. 推广合伙人主体即可以是个人，也可以公司。其中个人
            须上传并验证个人身份证信息，公司须上传公司营业 执照和法人信息。
          </div>
          <div className={"agreen_line_row"}>
            2. 推广合伙人主体所有信息必须真实有效，如因为虚假
            信息导致的损失有推广合伙人承担。
          </div>

          <div className={"agreen_line_row"}>
            3. 推广合伙人在平台缴纳相关费用后即可开展相关务。
          </div>

          <div className={"agreen_line_row"}>
            4. 推广合伙人拥有唯一账户信息（注册手机号），习惯
            公社应保证其信息隐私安全，但由于推广合伙人个人
            原因导致的信息外露造成的损失有推广合伙人承担。
          </div>

          <div className={"agreen_line_row"}>
            5. 推广合伙人有权使用指定名称在习惯公社授权期限内
            进行推广、服务会员。
          </div>

          <div className={"agreen_line_row"}>
            6. 推广合伙人有权发展下级推广合伙人，并享受各级别的
            招商奖金。推广合伙人也可以协助习惯公社拓展其他
            业务线，并按标准获得相应业务奖金。
          </div>

          <div className={"agreen_line_row"}>
            7. 推广合伙人依照代理人机制获取相关收益权利。
          </div>

          <div>
            8. 习惯公社应按要求支付推广合伙人获得的收益，推广
            合伙人可在平台发起提现请求。
          </div>

          <div className={"agreen_line_row"}>
            9. 推广合伙人及从业人员须接受习惯公社的职能培训，
            以掌握相应专业的技巧和服务标准。
          </div>

          <div className={"agreen_line_row"}>
            10. 当推广合伙人遇到执行困难时，在双方约定的范围
            内，有权要求习惯公社提供相应支持。
          </div>

          <div className={"agreen_line_row"}>
            11. 推广合伙人有权在授权区域内举办符合习惯公社规范 的线下活动。
          </div>

          <div className={"agreen_line_row"}>
            12. 推广合伙人及下属运营中心不得随意遮盖、修改“习惯
            公社”的名称及LOGO，未经习惯公社书面允许不得
            自行申请一切与“习惯公社”、“形者”、“影响力”等
            有关的商标、商号、著作权等知识产权。推广合伙人
            同意将已经或正在注册、登记的及其下属运营中心
            向其转让的与上述有关的全部知识产权无偿转让给 习惯公社。
          </div>

          <div className={"agreen_line_row"}>
            13. 推广合伙人应当保证其所有销售、宣传、开展活动等
            行为符合法律规定，不得从事任何违法事宜或者侵
            犯任何第三方的权益，如因此发生任何争议与纠纷
            的由推广合伙人承担责任。如造成严重后果，习惯公
            社保留进一步追责的权。
          </div>

          <div className={"agreen_line_row"}>
            14. 推广合伙人及推广合伙人所属授权区域内的运营中心应
            当自觉维护“习惯公社”的品牌形象，保护“习惯公
            社”的内容知识产权、商标等，发现第三方侵权应
            当及时予以制止，并在发现后二十四小时内向习惯 公社予以通报。
          </div>

          <div className={"agreen_line_row"}>
            15. 推广合伙人仅可以在本区域发展下属运营中心及开展
            会员业务时使用“习惯公社”商标，如推广合伙人需在
            上述用途以外或在第三方推广渠道使用，需在使用
            前另行获得习惯公社及习惯公社书面授权。
          </div>

          <div className={"agreen_line_row"}>
            16. 双方在履行本协议的过程中对所获取的商业机密，
            均承担严格保密义务，在未取得习惯公社书面许可
            的情况下，代理商不得擅自以任何形式向任何第三
            方披露该合作协议中的任何条款及相关用户信息。
          </div>

          <div className={"agreen_line_row"}>
            17. 此保密义务无期限限制，且不因本协议的终止、无 效或撤销而失效。
          </div>
        </div>
      </div>
    )
  }
}
