import { isNull } from "utils/isNull"
import { Toast } from "antd-mobile"

/* 不为空 */
export function stopToApi(arr) {
  console.log("arr", arr)
  let flag = true
  for (let i = 0; i < arr.length; i++) {
    if (isNull(arr[i].data)) {
      // console.log("arr", arr[i])
      Toast.info(arr[i].title)
      flag = false
      break
    }
  }
  return flag
}

/* 获取七牛云token */
/* export async function getQiNiu() {
  const res = await postInst(api.qiNiuToken)
  if (res) {
    return res
  } else {
    return false
  }
} */

/* 习惯公社上传图片随机名 xggs_******** */
export function randomCount() {
  let r = Math.floor(Math.random() * 30020)
  let f = Math.floor(Math.random() * 30250)
  let d = Date.parse(new Date())
  return `xg_GS${d}${r}${f}`
}

/* 图片上传 - 前置钩子 */
export function beforeUpload(file) {
  const isJpgOrPng =
    file.type == "image/jpeg" ||
    file.type == "image/jpg" ||
    file.type == "image/png"
  if (!isJpgOrPng) {
    Toast.info("您只能上传jpg/png格式的文件")
  }
  const isLt5M = file.size / 1024 / 1024 < 20
  if (!isLt5M) {
    Toast.info("图片大小不能超过20M")
  }
  return isJpgOrPng && isLt5M
}


//时间转化格式，可以将2012-03-04转化为2012年03月04日
export function parseTime(time, cFormat) {
  let dateFormat  = new Date(time.replace(/-/g, '/'));
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    const formatObj = {
        y: dateFormat.getFullYear(),
        m: dateFormat.getMonth() + 1,
        d: dateFormat.getDate(),
        h: dateFormat.getHours(),
        i: dateFormat.getMinutes(),
        s: dateFormat.getSeconds(),
        a: dateFormat.getDay()
    }
    const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
        const value = formatObj[key]
        return value.toString().padStart(2, '0')
    })
    return time_str

  // return time_str
}
