import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet, tabSetIndex } from "actions/XggsUserInformation"
import { util } from "common/util"
import { api } from "common/Config"
import { isNull } from "utils/isNull"
import "./css/my.css"
import { Modal, Toast } from "antd-mobile"
import AntModal from "component/antModal/antModal"
import userImg from "image/user_img.png"
import editorIcon from "image/editor_icon.png"
import erweimaImg from "image/erweima_img.png"
import purch_icon_img from "image/purch_icon.png"
import stock_icon_img from "image/stock_icon.png"
import cash_iconn_img from "image/cash_icon.png"
import grayRightIcon from "image/gray_right_icon.png"
import RoleModal from "component/roleModal/roleModal"
import dlsImg from "image/dls_img.png"

class My extends Component {
  constructor(props) {
    super(props)
    this.state = {
      outLogin: false,
      isModalVisible: false,
      attestation: false,
      roleType: "",
      toggleRoleIsShow: false,
      sValue: "",
      selectData: [],
      medata: {
        isModalVisible: true,
      },
      phone: null,
      innerHeight:'',
      infosTag:false
    }
  }

  componentDidMount() {
    this.handleGetData()
    this.setState({
      innerHeight:window.innerHeight
    })
  }

  // 获取合伙人信息
  handleGetData = () => {
    util.getYangAxios(
      api.infoDetails,
      {},
      async (res) => {
        this.setState({
          attestation: res.is_realname,
          roleType: res.type ? res.type : "",
          hephone: res.phone,
          infosTag:true
        })

        let range = {
          province: res.province,
          city: res.city,
          area: res.area,
          type: res.type,
        }
        sessionStorage.setItem("range", JSON.stringify(range))
        sessionStorage.setItem("hephone", JSON.stringify(res.phone))
      },
      (err) => {
        console.log(err)
      }
    )
  }

  handleOutLogin = async () => {
    util.getYangAxios(
      api.logout,
      {},
      async (res) => { },
      (err) => {
        console.log(err)
      }
    )
    await window.localStorage.removeItem("login_token")
    await window.localStorage.removeItem("login_long_token")
    await window.sessionStorage.removeItem("userData")
    await window.sessionStorage.removeItem("range")
    await window.sessionStorage.removeItem("tabStr")
    await window.sessionStorage.removeItem("hephone")
    await window.localStorage.removeItem("isT")
    this.props.tabSetIndex("")
    await this.onClose("outLogin")
    await this.props.history.replace("/")
  }

  showModal = (e) => {
    e.preventDefault() // 修复 Android 上点击穿透
    this.setState({
      outLogin: true,
    })
  }

  onClose = (key) => () => {
    this.setState({
      [key]: false,
    })
  }
  outLogin = (e) => {
    console.log("clickFn")
    this.showModal(e)
  }

  toToggle = () => {
    this.setState({
      sValue: "",
      toggleRoleIsShow: true,
    })
  }

  toggleRole = (id) => {
    if (isNull(id)) {
      Toast.info("选择身份错误,请重试")
      return
    }

    this.toggleRoleApi(id)
  }

  roleChange = (value) => {
    this.setState({
      sValue: value
    })
    let obj = {}
    this.state.selectData.forEach((item) => {
      if (item.value == value[0]) {
        obj.name = item.label
        obj.id = item.value
        document.querySelector(".am-list-extra").innerHTML = obj.name
      }
    })
  }
  toggleRoleApi = (role) => {
    util.postYangAxios(
      api.toggleRoleSure + role,
      {
        role,
      },
      async (res) => {
        this.setState({
          toggleRoleIsShow: false,
        })
        Toast.info("切换成功", 0.5)
        setTimeout(() => {
          window.location.reload()
        }, 0.6)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  cashMoney = async () => {
    const { attestation, roleType,infosTag } = this.state
      if(!infosTag){
        return
    }
    if (!attestation && roleType != 1) {
      this.setState({
        isModalVisible: true,
      })
      return
    }
    this.props.history.push({
      pathname: "cashBag",
      state: {
        hasMoney: "",
        enterPageType: 2,
      },
    })
  }

  bankCard = () => {
    this.props.history.push({
      pathname: "bankCard",
      state: {},
    })
  }

  cancleFn = ({ type }) => {
    type == 1 && this.props.history.push("authentication")
    type == 2 &&
      this.setState({
        isModalVisible: false,
      })
  }

  todls = () => {
    this.props.history.push({
      pathname: "dlsCourse",
      state: {
        page: "my",
      },
    })
  }
  render() {
    const {
      outLogin,
      attestation,
      isModalVisible,
      roleType,
      toggleRoleIsShow,
      innerHeight,
      infosTag
    } = this.state
    const { userData } = this.props.UserDataReducer
    return (
      <div style={{ position: "relative", flex: 1, height: "100%" }}>
        {/* <div className={"title_text"}>我的</div> */}
        <div className={"row_between user_data_view"}>
          <div className={"row_align"}>
            <div className={"my_user_img"}>
              <img
                src={
                  userData && userData.head_portrait
                    ? `https://cdn.ellll.com/${userData.head_portrait}`
                    : userImg
                }
                alt=""
                className={"my_user_img"}
              />
              <div
                className={"row_align user_editor_name"}
                onClick={() => this.props.history.push("personalData")}
              >
                <img src={editorIcon} alt="" className={"editor_icon"} />
              </div>
            </div>
            <div className={"user_name"}>
              {userData && userData.name ? userData.name : "未设置用户名"}
              {/* <span className={"user_phone"}>18516639260</span> */}
              <div className="toggle_user_btn" onClick={this.toToggle}>切换身份</div>

            </div>
          </div>
          <img
            src={erweimaImg}
            alt=""
            className={"erweima_img"}
            onClick={() => {
              // if(!infosTag){
              //   return
              // }
              // if (!attestation && roleType != 1) {
              //   this.setState({
              //     isModalVisible: true,
              //   })
              //   return null
              // }
              this.props.history.push("qrCode")
            }}
          />
        </div>
        {userData && userData.status != 0 && <div className="user_icons_container">
          <div className="user_icon_info"  onClick={() => this.props.history.push("/stock/purchase")}>
            <img src={purch_icon_img} alt="" className="user_icon_type" />
            <div className="user_icon_title">采购</div>
          </div>
          <div className="user_icon_info"  onClick={() => this.props.history.push("/stock/stock")}>
            <img src={stock_icon_img} alt="" className="user_icon_type" />
            <div className="user_icon_title">库存</div>
          </div>
          {userData.role != 4 &&
            userData.role != 6 &&
            userData.role != 5 &&
            userData.role != 7 && (<div className="user_icon_info" onClick={this.cashMoney}>
              <img src={cash_iconn_img} alt="" className="user_icon_type" />
              <div className="user_icon_title">提现</div>
            </div>)}
        </div>}
        <div className={"gray_line"} />

        {userData.role != 9 && userData.role != 8 && (
          <div
            className={"row_between user_box"}
            onClick={() => this.props.history.push("personalData")}
          >
            <div>个人资料</div>
            <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
          </div>
        )}
        {/* <div className={"row_between user_box"} onClick={() => this.props.history.push("/orderNew/order")}>
          <div>订单中心</div>
          <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
        </div>
        <div className={"row_between user_box"} >
          <div>收益明细</div>
          <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
        </div> */}

        {/* 提现注释 */}
        {userData.role != 4 &&
          userData.role != 6 &&
          userData.role != 5 &&
          userData.role != 7 && (
            <>
              {/* <div className={"row_between user_box"} onClick={this.cashMoney}>
                <div>提现</div>
                <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
              </div> */}
              <div className={"row_between user_box"} onClick={this.bankCard}>
                <div>银行卡</div>
                <img
                  src={grayRightIcon}
                  alt=""
                  className={"gray_right_icon"}
                />
              </div>
            </>
          )}

        <div className={"row_between user_box posiNull user_my_people_bg"} onClick={this.todls}>
          <div className={"college_text_font"}>
            {roleType && roleType == 1 ? "代理商学院" : "合伙人学院"}
          </div>
          <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
          <img src={dlsImg} className={"dls_Img"} alt="" />
        </div>
        {roleType &&
          roleType == 2 &&
          userData.role != 9 &&
          userData.role != 8 ? (
          <>
            {/* <div
              className={"row_between user_box"}
              onClick={() => this.props.history.push("agreement")}
            >
              <div>习惯公社推广合伙人协议</div>
              <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
            </div> */}

            <div
              className={"row_between user_box"}
              onClick={() => this.props.history.push("profit")}
            >
              <div>合伙人收益机制</div>
              <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
            </div>
          </>
        ) : (
          ""
        )}
         {
          userData.role != 4 &&
          userData.role != 6 &&
          userData.role != 5 &&
          userData.role != 7? (
          <>
            <div
              className={"row_between user_box"}
              onClick={() => this.props.history.push("/order/schoolCode")}
            >
              <div>学校码</div>
              <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
            </div>
          </>
        ) : (
          ""
        )}
        <div className={"row_between user_box"} onClick={this.outLogin}>
          <div>退出登录</div>
          <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
        </div>
        {innerHeight>667&&roleType &&
          roleType == 2 &&
          userData.role != 9 &&
          userData.role != 8 && <div className="promot_aggrement_link" onClick={() => this.props.history.push("agreement")}>习惯公社推广合伙人协议</div>
        }
         {innerHeight<667&&roleType &&
          roleType == 2 &&
          userData.role != 9 &&
          userData.role != 8 && <div className="promot_aggrement_link2" onClick={() => this.props.history.push("agreement")}>习惯公社推广合伙人协议</div>
        }
        <Modal
          visible={outLogin}
          transparent
          maskClosable
          onClose={this.onClose("outLogin")}
        >
          <div className={"out_login_title"}>是否退出登录</div>
          <div className={"row_between"}>
            <div
              className={"row_center close_btn"}
              onClick={this.onClose("outLogin")}
            >
              取消
            </div>
            <div className={"row_center yes_btn"} onClick={this.handleOutLogin}>
              确定
            </div>
          </div>
        </Modal>

        {isModalVisible === true && (
          <AntModal
            data={this.state.medata}
            cancleFn={this.cancleFn}
          ></AntModal>
        )}

        {/* 切换身份 */}
        <Modal
          visible={toggleRoleIsShow}
          transparent
          maskClosable
          className="ModalCss"
          wrapClassName="warpCss"
          onClose={this.onClose("toggleRoleIsShow")}
        >
          <RoleModal toggleRole={this.toggleRole}></RoleModal>
        </Modal>
      </div>
    )
  }
}

export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
    tabSetIndex,
  }
)(My)
