import React, { Component } from "react"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import ss from "./css/role.module.css"

export default class RoleModal extends Component {
  state = {
    selectData: null,
  }
  render() {
    const { selectData } = this.state
    return (
      <>
        <div>
          {selectData &&
            selectData.map((item) => {
              return (
                <div
                  className={`${"row_between"} ${ss.item_view}`}
                  key={item.id}
                  onClick={() => {
                    this.clickFn(item.id)
                  }}
                >
                  <div className={ss.dis_view}>
                    <img
                      className={ss.img_view}
                      src={"https://cdn.ellll.com/" + item.agents.head_portrait} 
                      alt=""
                    />
                    <span className={ss.name_view}>{item.agents.name}</span>
                  </div>

                  <span className={ss.roleBlue_view}>
                    {item.adminrolegroups.name}
                  </span>
                </div>
              )
            })}
        </div>
      </>
    )
  }

  componentDidMount() {
    this.init()
  }
  init = () => {
    this.getRoleList()
  }

  // 获取身份列表
  getRoleList() {
    util.getYangAxios(
      api.getRoleList,
      {},
      async (res) => {
        this.setState({
          selectData: res.list,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  clickFn = (id) => {
    //   console.log("当前id", id)
    this.props.toggleRole(id)
  }
}
