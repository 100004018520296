import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet, tabSetIndex } from "../../actions/XggsUserInformation"
import { Progress } from "antd-mobile"
import moment from "moment"
import ss from "page/home/css/home.module.css"

/* 战略合伙人 */
class ZlPerson extends Component {
  state = {}
  componentDidMount() {
    this.init()
  }
  render() {
    const { zlPersonList } = this.props
    return (
      <div className={`${ss.zlPerson_view} zlPerson_view`}>
        <p className={ss.all_title_view}>任务信息</p>
        <div className={ss.gray_line_view}></div>

        {zlPersonList &&
          zlPersonList.map((item, index) => {
            return (
              <>
                <div className={ss.oneStage_item} key={index}>
                  <p>
                    <span className={ss.stage_title_view}>
                      {item.schedule_name}
                    </span>
                    <span className={ss.timee_title_view}>
                      {moment(item.start_at).format("MM月DD日")} -{" "}
                      {moment(item.end_at).format("MM月DD日")}
                    </span>
                  </p>
                  <div className={ss.show_info}>
                    <div
                      className={ss.show_progress}
                      onClick={() => {
                        this.props.history.push({
                          pathname: "zlDetails",
                          state: {
                            id: "",
                            stage: item.schedule_name,
                            count: item.max,
                            completeCount: item.now,
                          },
                        })
                      }}
                    >
                      <Progress
                        percent={(item.now / item.max) * 100}
                        position="normal"
                      />
                    </div>
                    <div className={ss.count_view}>
                      {item.now}/{item.max}
                    </div>
                  </div>
                </div>
                <div className={ss.gray_line_view}></div>
              </>
            )
          })}
      </div>
    )
  }
  init = () => {
    // console.log(this.props, "-------------------------")
  }
}

export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
    tabSetIndex,
  }
)(ZlPerson)
