
import React, { Component } from "react"
import { connect } from "react-redux"
import AntModal from "component/antModal/antModal"
import ss from "./css/index.module.css"
import HeaderView from "../../component/header/headerView"
import money_blue_img from '../../image/money_blue.png'
import { util } from "../../common/util"
import { api } from "../../common/Config"
import { userDataSet} from "../../actions/XggsUserInformation"
 class Balance extends Component {
    constructor(props) {
        super(props)
        this.state = {
            incomeObj:{},
            isModalVisible: false,
            attestation: '',
            roleType: '',
            hephone: '',
            medata: {
                isModalVisible: true,
            },
            infosTag:false
     }
    }
    componentDidMount() {
        this.handleGetAgentsIncome()
        this.handleGetData()
    }
    // 获取合伙人信息
    handleGetData = () => {
        util.getYangAxios(
            api.infoDetails,
            {},
            async (res) => {
                this.setState({
                    attestation: res.is_realname,
                    roleType: res.type ? res.type : "",
                    hephone: res.phone,
                    infosTag:true
                })

                let range = {
                    province: res.province,
                    city: res.city,
                    area: res.area,
                    type: res.type,
                }
                sessionStorage.setItem("range", JSON.stringify(range))
                sessionStorage.setItem("hephone", JSON.stringify(res.phone))
            },
            (err) => {
                console.log(err)
            }
        )
    }
     // 个人收益信息
     handleGetAgentsIncome = () => {
        util.getYangAxios(
            api.agentsIncome,
            {},
            async (res) => {
                this.setState({
                    incomeObj: res
                })
            },
            (err) => {
                console.log(err)
            }
        )
    }
    handGoDetail=(type)=>{
        this.props.history.push({
            pathname: "/balance/list",
            state: {
                type: type,
            },
        })
    }
    cashMoney = async () => {
        const { attestation, roleType ,infosTag} = this.state
        if(!infosTag){
            return
        }
        if (!attestation && roleType != 1) {
            this.setState({
                isModalVisible: true,
            })
            return
        }
        this.props.history.push({
            pathname: "cashBag",
            state: {
                hasMoney: "",
                enterPageType: 2,
            },
        })
    }
    cancleFn = ({ type }) => {
        type == 1 && this.props.history.push("authentication")
        type == 2 &&
            this.setState({
                isModalVisible: false,
            })
    }
    render() {
        const {incomeObj,isModalVisible}=this.state
        const { userData } = this.props.UserDataReducer
        return (
            <div className={ss.balance_container}>
                <HeaderView title={""} />
                <div className={ss.balance_amount_container}>
                    <img src={money_blue_img} alt="" className={ss.balance_blue_pic} />
                    <div className={ss.balance_blue_txt}>我的余额</div>
                    <div className={ss.balance_amount_number_container}>
                        <span className={ss.balance_amount_number_tag}>¥</span>
                        <span className={ss.balance_amount_number}>{incomeObj.surplus}</span>
                    </div>
                    <div className={ss.balance_btn} onClick={()=>this.handGoDetail(2)}>查看明细</div>
                    {userData.role != 4 &&
                            userData.role != 6 &&
                            userData.role != 5 &&
                            userData.role != 7 && (<div className={ss.balance_btn2} onClick={this.cashMoney} >提现</div>)}
                </div>
                {isModalVisible === true && (
                    <AntModal
                        data={this.state.medata}
                        cancleFn={this.cancleFn}
                    ></AntModal>
                )}
            </div>
        )
    }
}

export default connect(
    (state) => {
        const { UserDataReducer } = state
        return {
            UserDataReducer,
        }
    },
    {
        userDataSet,
    }
)(Balance)