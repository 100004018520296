import React, { Component } from "react";
import { connect } from "react-redux";
import { userDataSet } from "../../actions/XggsUserInformation";
import "./css/consumerDetails.css";
import userImg from "../../image/user_img.png";
import HeaderView from "../../component/header/headerView";
import { ListView } from "antd-mobile";
class ConsumerDetails extends Component {
	constructor(props) {
		super(props);
		const dataSource = new ListView.DataSource({
			rowHasChanged: (row1, row2) => row1 !== row2 // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
		});
		this.state = {
			order: null,
			orderDetails: null,
			activeKey: "1",
			dataSource,
			datas: [],
			pageNo: 1,
			pageSize: 2,
			hasMore: true,
			refreshing: true,
			isLoading: true,
			dataArr: []
		};
	}

	componentDidMount() {
		console.log(this.props.history.location.state);
		this.setState({
			order: this.props.history.location.state.order
		});
	}

	// 订单卡片渲染
	handleOrderCardRender = (title, content) => {
		return (
			<div className={"item_div"}>
				<div className={"flex_line"}>
					<div className={"lick_photoW"}>{title}</div>
					<div>{content}</div>
				</div>
			</div>
		);
	};

	render() {
		const { order } = this.state;
		return (
			<div
				className={"pay_result_view"}
				style={{ height: window.innerHeight + "px", background: "#F6F7FB" }}
			>
				<HeaderView title={"用户详情"} />
				{order && (
					<>
						<div className={"header_line item_div"}>
							<div className={"flex_line"}>
								<img
									src={
										order.head_portrait
											? `https://cdn.ellll.com/${order.head_portrait}`
											: userImg
									}
									alt=""
									className={"header_img"}
								/>
								<div className={"header_Info_center"}>
									<div>{order.name}</div>
									<div>{order.id}</div>
								</div>
							</div>
						</div>

						{this.handleOrderCardRender("微信：", order.wechat_code)}
						{this.handleOrderCardRender("手机号码：", order.phone)}
						{this.handleOrderCardRender("邮箱：", order.email)}
						{this.handleOrderCardRender(
							"用户职位：",
							order.agentusergrouptypes.name
						)}
						{this.handleOrderCardRender(
							"备注：",
							order.remark ? order.remark : ""
						)}
					</>
				)}
			</div>
		);
	}
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
	state => {
		const { UserDataReducer } = state;
		return {
			UserDataReducer
		};
	},
	{
		userDataSet
	}
)(ConsumerDetails);
