import React, { Component } from "react"
import { Modal as AntModal } from "antd"
import pay_perfect_modal_bg from "../../image/pay_perfect_modal_bg.png"
import blue_icon_small from "../../image/blue_icon_small.png"
import ss from "./css/antModal.module.css"

export default class antModal extends Component {
  render() {
    const { isModalVisible } = this.props.data
    return (
      <div>
        <AntModal
          visible={isModalVisible}
          footer={null}
          width={304}
          closable={false}
          maskClosable
          wrapClassName={ss.pay_perfect_modal}
        >
          <img
            src={pay_perfect_modal_bg}
            alt=""
            className={ss.pay_perfect_modal_bg}
          />
          <div className={ss.pay_perfect_modal_text}>
            请完善您的个人资料，进行实名认证
            <div style={{ color: "#ED3A35" }}>否则您将无法使用以下功能</div>
          </div>
          <div className={"row_between"}>
            <div className={`row_align ${ss.blue_icon_small_text}`}>
              <img
                src={blue_icon_small}
                alt=""
                className={ss.blue_icon_small}
              />
              付款功能
            </div>
            <div className={`row_align ${ss.blue_icon_small_text}`}>
              <img
                src={blue_icon_small}
                alt=""
                className={ss.blue_icon_small}
              />
              推广功能
            </div>
            <div className={`row_align ${ss.blue_icon_small_text}`}>
              <img
                src={blue_icon_small}
                alt=""
                className={ss.blue_icon_small}
              />
              提现功能
            </div>
          </div>
          <div className={`row_between ${ss.pay_perfect_btn_div}`}>
            <div
              className={`row_center ${ss.pay_perfect_close_btn}`}
              onClick={() => this.props.cancleFn({ type: 2 })}
            >
              取消
            </div>
            <div
              className={`row_center ${ss.pay_perfect_yes_btn}`}
              onClick={() => this.props.cancleFn({ type: 1 })}
            >
              确定
            </div>
          </div>
        </AntModal>
      </div>
    )
  }
}
