import axios from "axios"
import { Toast } from "antd-mobile"
import { createBrowserHistory } from "history"

const history = createBrowserHistory()
// const axios = require('axios').create({ timeout: 10000 })

// let axios = axios.create({ timeout: 1000 });
// 过滤状态码
const filterCode = (res, successCallback, catchCallback) => {
  // console.log("res1", res)
  if (res.data.code == 20000 || res.data.code == 0) {
    successCallback(res.data.data)
  } else if (res.data.code == 3) {
    // NavigationService.navigate('bindingPhone');
  } else if (res.data.code == 50014) {
    window.localStorage.removeItem("login_token")
    window.localStorage.removeItem("login_long_token")
    Toast.info(res.data.message, 1.5, () => {
      // NavigationService.navigate('loginPage');
      // window.location.replace(`${api.links}`);
      history.push("/login")
      window.location.reload()
    })
  } else if (res.data.code == 1000) {
    Toast.info(res.data.message, 2)
    catchCallback(res.data)
  } else {
    Toast.info(res.data.message, 1.5)
    catchCallback(res.data)
  }
}

// 过滤错误吗
const filterErrCode = (err, failCallback) => {
  // console.log("err", err)
  if (err.message && err.message == "Network Error") {
    Toast.info("网络异常，请检查网络后再试", 1.5)
    failCallback(err)
  } else {
    Toast.info("服务器出了点小差，请耐心等待。", 1.5)
    failCallback(err)
  }
}

// 获取本地token
const getTokenHeader = async () => {
  let login_token = window.localStorage.getItem("login_token")
    ? window.localStorage.getItem("login_token")
    : ""
  let login_long_token = window.localStorage.getItem("login_long_token")
    ? window.localStorage.getItem("login_long_token")
    : ""
  // console.log(login_token)
  // let reg_id = window.localStorage.getItem("reg_id")?window.localStorage.getItem("reg_id"):'';
  const header = {
    "X-Token": login_token, // login_token
    "login-token": login_long_token,
    "Access-Control-Allow-Origin": "*",
    // app_type: 3,
    // reg_id: reg_id,
  }
  return header
}

export const util = {
  // Fetch
  getYangAxios: async (
    url,
    data,
    successCallback,
    failCallback,
    catchCallback = () => {},
    notLoading
  ) => {
    let key = null
    if (!notLoading) {
      key = Toast.loading("加载中", 0)
    }
    let headerObj = {}
    await getTokenHeader().then((header) => {
      headerObj = header
    })
    axios
      .get(url, {
        params: data,
        headers: headerObj,
      })
      .then((res) => {
        // console.log(`${url}返回数据————————————————————————`)
        // console.log(res)
        notLoading == undefined && Toast.hide()
        filterCode(res, successCallback, catchCallback)
      })
      .catch((err) => {
        notLoading == undefined && Toast.hide()
        filterErrCode(err, failCallback)
      })
  },
  postYangAxios: async (
    url,
    data,
    successCallback,
    failCallback,
    catchCallback = () => {},
    notLoading
  ) => {
    let key = null
    if (!notLoading) {
      key = Toast.loading("加载中", 0)
    }
    let headerObj = {}
    await getTokenHeader().then((header) => {
      headerObj = header
    })
    // console.log(data)
    await axios
      .post(url, data, {
        headers: headerObj,
      })
      .then((res) => {
        // console.log(`${url}返回数据————————————————————————`)
        // console.log(res)
        notLoading == undefined && Toast.hide()
        filterCode(res, successCallback, catchCallback)
      })
      .catch((err) => {
        notLoading == undefined && Toast.hide()
        filterErrCode(err, failCallback)
      })
  },
  putYangAxios: async (
    url,
    data,
    successCallback,
    failCallback,
    catchCallback = () => {},
    notLoading
  ) => {
    let key = null
    if (!notLoading) {
      key = Toast.loading("加载中", 0)
    }
    let headerObj = {}
    await getTokenHeader().then((header) => {
      headerObj = header
    })
    // console.log(data)
    await axios
      .put(url, data, {
        headers: headerObj,
      })
      .then((res) => {
        // console.log(`${url}返回数据————————————————————————`)
        // console.log(res)
        notLoading == undefined && Toast.hide()
        filterCode(res, successCallback, catchCallback)
      })
      .catch((err) => {
        notLoading == undefined && Toast.hide()
        filterErrCode(err, failCallback)
      })
  },
  deleteYangAxios: async (
    url,
    data,
    successCallback,
    failCallback,
    catchCallback = () => {},
    notLoading
  ) => {
    let key = null
    if (!notLoading) {
      key = Toast.loading("加载中", 0)
    }
    let headerObj = {}
    await getTokenHeader().then((header) => {
      headerObj = header
    })
    // console.log(data)
    await axios
      .delete(url, {
        data: data,
        headers: headerObj,
      })
      .then((res) => {
        // console.log(`${url}返回数据————————————————————————`)
        // console.log(res)
        notLoading == undefined && Toast.hide()
        filterCode(res, successCallback, catchCallback)
      })
      .catch((err) => {
        notLoading == undefined && Toast.hide()
        filterErrCode(err, failCallback)
      })
  },
}

export const inst = axios.create({
  baseURL: "https://some-domain.com/api/",
  timeout: 100000,
  headers: {
    "X-Token": window.localStorage.getItem("login_token") ?? "", // login_token
    "login-token": window.localStorage.getItem("login_long_token") ?? "",
    "Content-Type": "multipart/form-data;charset=UTF-8",
  },
  transformRequest: [
    function (data, config) {
      if (!config["Content-Type"]) return data
      switch (config["Content-Type"].toLowerCase()) {
        case "application/json;charset=UTF-8": {
          return data
        }
        case "multipart/form-data;charset=UTF-8": {
          return data
        }
        default: {
          return data
        }
      }
    },
  ],
})
