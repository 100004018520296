import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet } from "../../actions/XggsUserInformation"
import "./css/invite.css"
import invite_bg_img from "../../image/invite_bg_img.png"
import close_white_icon from "../../image/close_white_icon.png"
import {  Toast, } from "antd-mobile"
class Invite extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inviteObj: null,
    }
  }

  componentDidMount() {
    let obj = this.getUrlParams()
    if (obj) {
      this.setState({
        inviteObj: obj,
      })
    } else {
      this.props.history.replace("/")
    }
    window.localStorage.setItem("login_token", '')
  }

  getUrlParams = () => {
    let url = window.location.search //?articleId=1&&articleNum=1&opt=edit
    let index = url.indexOf("?")
    let obj = {}
    if (index !== -1) {
      let str = url.substr(1)
      let arr = str.split("&")
      for (let i = 0; i < arr.length; i++) {
        obj[arr[i].split("=")[0]] = arr[i].split("=")[1]
      }
    }
    return obj //{articleId: "1", articleNum: "1", opt: "edit"}
  }

  handleJoin = () => {
    const { inviteObj } = this.state
    if (inviteObj && inviteObj.agentId) {
      let queryStrying=`name=${inviteObj.name}&agentId=${inviteObj.agentId}&type=${inviteObj.type}`
      this.props.history.push("/inviteDes?"+queryStrying)
    } else {
      Toast.info("加入失败,商家id无效", 1.5)
    }
  }

  render() {
    const { inviteObj } = this.state
    return (
      <div
        style={{ height: window.innerHeight + "px" }}
        className={"invite_view"}
      >
        <div className={"invite_top_view row_between"}>
          <img
            src={close_white_icon}
            alt=""
            className={"invite_close_white_icon"}
            onClick={() => {
              this.props.history.push("qrCode")
            }}
            style={{ opacity: 0 }}
          />
          {/* <div className={"invite_title_text"}>邀请</div> */}
          <img
            src={close_white_icon}
            alt=""
            className={"invite_close_white_icon"}
            style={{ opacity: 0 }}
          />
        </div>
        <img src={invite_bg_img} alt="" className={"invite_bg_img"} />
        <div className={"column_center"}>
          <div className={"invite_name_text"}>
            {inviteObj ? decodeURI(inviteObj.name) : ""}
          </div>
          <div className={"invite_des_text"}>邀请您加入习惯公社</div>
        </div>
        <div className={"row_center"}>
          <div
            className={"row_center invite_btn_view"}
            onClick={this.handleJoin}
          >
            加入
          </div>
        </div>
      </div>
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
  }
)(Invite)
