import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet } from "../../actions/XggsUserInformation"
import "./css/pay.css"
import { Toast, Checkbox } from "antd-mobile"
import pay_select_blue_icon from "../../image/pay_select_blue_icon.png"
import pay_select_gray_icon from "../../image/pay_select_gray_icon.png"
import HeaderView from "../../component/header/headerView"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import { getQueryString, onBridgeReady, getWeChatcode } from "../../common/index"
class Pay extends Component {
  constructor(props) {
    super(props)
    this.state = {
      agentList: [],
      agentIndex: 0,
      agreeIsClick: false,
      agreenIsShow: true,
      productData: {},
      inviteObj: {},
      type: null,
      pay_type: null,
      payTypeObj: {},
      levelList: [],
      key: ""
    }
  }

  componentDidMount() {
    document.title = '付款'
    let obj = this.getUrlParams()
    var weChatCode = ""
    var state = ""
    weChatCode = getQueryString("code")
    state = getQueryString("state")
    if (obj) {
      //当obj.type===1的时候，参数传4
      let type = parseInt(obj.type) === 1 ? 4 : parseInt(obj.type)
      this.handleGetProductInterest(type).then(() => {
        this.setState({
          type: type,
          inviteObj: obj,
        }, () => {
          
          if (weChatCode) {
            this.setState({
              pay_type: parseInt(obj.pay_type),
              payTypeObj: { pay_type: parseInt(obj.pay_type), price: obj.price,name:decodeURI(obj.describeName)}
            }, () => {
              this.getPayParams(weChatCode, state)
            })

          }
        })
      })
    }
    this.handleGetAgent()
  }
  getUrlParams = () => {
    let url = window.location.search //?articleId=1&&articleNum=1&opt=edit
    let index = url.indexOf("?")
    let obj = {}
    if (index !== -1) {
      let str = url.substr(1)
      let arr = str.split("&")
      for (let i = 0; i < arr.length; i++) {
        obj[arr[i].split("=")[0]] = arr[i].split("=")[1]
      }
    }
    return obj //{articleId: "1", articleNum: "1", opt: "edit"}
  }
  //获取详情
  handleGetProductInterest = (type) => {
    return new Promise((resolve, reject) => {
      util.getYangAxios(
        api.getProductInterest,
        { type: type },
        async (res) => {
          this.setState({
            productData: res[0],
            levelList: res,
            pay_type: res[0].pay_type,
            payTypeObj: res[0]
          })
          resolve()
        },
        (err) => {
          console.log(err)
          reject()
        }
      )
    })

  }
  // 分享人选择列表
  handleGetAgent = () => {
    util.getYangAxios(
      api.shareAgent,
      {},
      async (res) => {
        this.setState({
          agentList: res,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }
  //选择级别
  handSelectdPayType(item) {
    this.setState({
      pay_type: item.pay_type,
      payTypeObj: item
    })
  }
  // 支付
  handlePay = () => {
    const { agentList, agentIndex, agreeIsClick, agreenIsShow, inviteObj, pay_type } = this.state
    if (!agreeIsClick && agreenIsShow) {
      Toast.info("请先阅读并同意协议")
      return
    }
    let params = {
      shareId: agentList.length ? agentList[agentIndex].id : 0,
    }
    params.agent_id = inviteObj.agentId
    params.type = pay_type

    util.postYangAxios(
      api.purchaseProductInterest,
      params,
      async (res) => {
        // this.handleWxPay(res.key)
        this.setState({ key: res.key })
        this.getWeChatcode2(res.key)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  handleWxPay = (key) => {
    const { inviteObj, productData, agentList, agentIndex, payTypeObj, type } = this.state

    let bound_url = api.links + `payNewResult`
    let agent_id = parseInt(inviteObj.agentId)
    let dlsFromPage = sessionStorage.getItem("dlsFromPage")
    if (agent_id > 0) {
      bound_url = bound_url + `?agent_id=${agent_id}&dlsFromPage=${dlsFromPage}&type=${inviteObj.type}&price=${payTypeObj.price}`
    } else {
      // bound_url =
      //   "http://192.168.1.100:3000/" +
      //   `payResult` +
      //   `?dlsFromPage=${dlsFromPage}`
      bound_url = bound_url + `?dlsFromPage=${dlsFromPage}`
    }

    let params = {
      key: key,
      bound_url,
    }
    if (agent_id > 0) {
      params.agent_id = agent_id
    } else {
      params.agent_id = agentList.length ? agentList[agentIndex].shareagent.id : 0
    }


    util.getYangAxios(
      api.cashierDesk,
      params,
      async (res) => {
        window.location.href = res.url
      },
      (err) => {
        console.log(err)
      }
    )
  }
  //授权获取code
  getWeChatcode2 = (key) => {
    let inviteObj = this.state.inviteObj ? this.state.inviteObj : {}
    let price = this.state.payTypeObj.price
    let pay_type = this.state.payTypeObj.pay_type
    let describeName = this.state.payTypeObj.name
    let queryStrying = `=name=${inviteObj.name}&agentId=${inviteObj.agentId}&type=${inviteObj.type}&price=${price}&pay_type=${pay_type}&describeName=${describeName}`
    let redirect_uri = window.location.origin + window.location.pathname + '?' + queryStrying
    getWeChatcode(redirect_uri, key)
  }
  //获取支付参数
  getPayParams = (code, key) => {
    let that = this
    const { inviteObj, productData, agentList, agentIndex, payTypeObj, type } = this.state
    let base_url = api.links + `payNewResult`
    let bound_url = ''
    var agent_id = ''
    var inviteAgentId = parseInt(inviteObj.agentId)
    let dlsFromPage = sessionStorage.getItem("dlsFromPage")
    if (inviteAgentId > 0) {
      bound_url = base_url + `?agent_id=${inviteAgentId}&type=${parseInt(inviteObj.type)}&price=${payTypeObj.price}`
    } else {
      bound_url = base_url + `?dlsFromPage=${dlsFromPage}`
    }
    if (inviteAgentId > 0) {
      agent_id = inviteAgentId
    } else {
      agent_id = agentList.length > 0 ? agentList[agentIndex].shareagent.id : 0
    }
    util.getYangAxios(
      api.wxDesk,
      { code, key, agent_id },
      async (res) => {
        onBridgeReady(res).then(() => {
          window.location.replace(bound_url + '&orderId=' + res.order_id)
        }).catch(() => {
          that.props.history.go(-1)
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  agreenChange = ({ target }) => {
    this.setState({
      agreeIsClick: target.checked,
    })
  }

  render() {
    const { agentList, agentIndex, agreenIsShow, productData, type, pay_type, levelList, payTypeObj } = this.state
    const { AgreeItem } = Checkbox
    return (
      <div className={"pay_view"}>
        <HeaderView title={""} />
        {agentList.length > 0 && (
          <div className={"pay_card_view"}>
            <div className={"pay_card_box row_between"}>
              <div className={"pay_card_title_text"}>特约服务人</div>
            </div>
            {agentList.map((item, index) => {
              return (
                <div
                  className={"pay_card_box row_between pl_two"}
                  key={index}
                  onClick={() => {
                    this.setState({
                      agentIndex: index,
                    })
                  }}
                >
                  <div className={"pay_card_content_text"}>
                    {item.shareagent && item.shareagent.name}
                  </div>
                  <img
                    src={
                      agentIndex == index
                        ? pay_select_blue_icon
                        : pay_select_gray_icon
                    }
                    alt=""
                    className={"pay_select_blue_icon"}
                  />
                </div>
              )
            })}
          </div>
        )}
        <div className={"pay_card_view"}>
          <div className={"pay_card_box row_between"}>
            <div className={"pay_card_title_text"}>产品名称</div>
          </div>
          <div className={"pay_card_box row_between pl_two"}>
            <div className={"pay_card_content_text"}>{type == 4 ? payTypeObj.name : productData.name}</div>
            <div className={" pay_card_content_text pay_t_num"}>x1</div>
          </div>
        </div>
        {type == 4 ? <div className={"pay_card_view"}>
          <div className={"pay_card_box row_between"}>
            <div className={"pay_card_title_text"}>选择级别</div>
          </div>
          <div className={"pay_card_box row"}>
            {levelList && levelList.length > 0 && levelList.map((item) => {
              return <div key={item.id} onClick={() => this.handSelectdPayType(item)} className={[`pay_card_content_level`, `${pay_type === item.pay_type ? 'pay_card_content_active' : ''}`].join(' ')}>{item.describe}</div>
            })}
          </div>
        </div> : null}
        <div className={"pay_card_view"}>
          <div className={"pay_card_box row_between"}>
            <div className={"pay_card_content_text"}>支付金额</div>

            <div className={"pay_card_money_text"}>¥{type == 4 ? payTypeObj.price : productData.price}</div>
          </div>
        </div>

        {/* 协议： 合伙人需要选择 其他角色无此项 */}
        {agreenIsShow && (
          <div className={"agreen_box"}>
            <AgreeItem data-seed="logId" onChange={this.agreenChange}>
              <span className={"gray_box"}>
                点击按钮即表示你同意并愿意遵守习惯公社
              </span>
              <div>
                {type == 5 ? <a onClick={(e) => { e.preventDefault(); this.props.history.push("/jpjAgreement") }}> 《习惯公社教培+协议》</a> : <a onClick={(e) => { e.preventDefault(); this.props.history.push("agreement") }}> 《习惯公社推广合伙人协议》</a>}
              </div>
            </AgreeItem>
          </div>
        )}

        <div
          className={"row_center"}
          style={{ width: "100%" }}
          onClick={this.handlePay}
        >
          <div className={"row_center pay_btn_div"}>支付</div>
        </div>
      </div>
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
  }
)(Pay)
