import React, { Component } from "react"
import { connect } from "react-redux"
import {
  userDataSet,
  tabSetIndex,
  infoTabSet,
} from "../../actions/XggsUserInformation"
import { util } from "../../common/util"
import { api, yearMonthDate, monthDate } from "../../common/Config"
import { isNull } from "utils/isNull"
import Vconsole from "common/Vconsole"
import "./css/home.css"
import out_login_icon from "../../image/out_login_icon.png"
import msg_white_icon from "../../image/msg_white_icon.png"
import ambassador_company_img from "../../image/ambassador_company_img.png"
import green_up_icon from "../../image/green_up_icon.png"
import down_gray_icon from "../../image/down_gray_icon.png"
import down_white_icon from "../../image/down_white_icon.png"
import close_white_icon from "../../image/close_white_icon.png"
import xggs_logo from "../../image/xggs_logo.png"
import { Drawer, Tabs, } from "antd"
import { Toast, Modal, PickerView, WingBlank } from "antd-mobile"
import RoleModal from "component/roleModal/roleModal"
import Accord from "component/accord/accord"
import NoMoneyUser from "component/noMoneyUser/noMoneyUser"
import ZlPerson from "component/zlPerson/zlPerson"
import CityPerson from "component/cityPerson/cityPerson"
import ZlPersonImg from "../../image/zhanlue_person.png"
import CityPersonImg from "../../image/city_person.png"
const { TabPane } = Tabs
class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      activeKey: "2",
      channelObj: null,
      dateValue: null,
      agentObj: [],
      msgNum: 0,
      typeKey: 1,
      outLogin: false,
      dateModal: false,
      dateKey: 1,
      year: null,
      month: null,
      showIndex: 1,
      startX: 0,
      endX: 0,
      startTime: 0,
      headerActiveKey: "1",
      headerDateModal: false,
      headerYear: null,
      headerMonth: null,
      headerDateKey: 1,
      headerDateValue: null,
      tabName: "年度",
      schoolStatistics: {},
      membersNum: "",
      toggleRoleIsShow: false,
      sValue: "",
      selectData: [],
      debuggerCount: 0,
      lively: null,
      nolively: null,
      zlPersonList: null,
      modalTip: false,
    }
  }

  componentDidMount() {
    // console.log(this.props)
    this.handleGetChannel({ mode: this.state.headerActiveKey })
    this.handleGetAgent()
    this.handleGetMsg()
    this.getCurrentRole()
    this.getzlPersonList()
    this.getCityPersonList()
    sessionStorage.removeItem("fiveId")

    this.handleGetUserData()
    this.handleGetData()
  }

  handleGetUserData = () => {
    util.postYangAxios(
      api.info,
      {},
      async (res) => {
        console.log("res----", res)
        let dlsFromPage = sessionStorage.getItem("dlsFromPage")
        console.log("首页的--", dlsFromPage)
        if (res && res.status != 0 && dlsFromPage == "self") {
          this.props.history.push("/dlsCourse")
        }

        if (res && res.status == 0 && dlsFromPage == "self") {
          setTimeout(() => {
            Toast.info("您暂时还不是合伙人，请成为合伙人后再次访问合伙人学院！")
          }, 1200)
        }
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 获取合伙人信息
  handleGetData = () => {
    util.getYangAxios(
        api.infoDetails,
        {},
        async (res) => {
          this.setState({
            attestation: res.is_realname,
            roleType: res.type ? res.type : "",
            hephone: res.phone,
          })

          let range = {
            province: res.province,
            city: res.city,
            area: res.area,
            type: res.type,
          }
          sessionStorage.setItem("range", JSON.stringify(range))
          sessionStorage.setItem("hephone", JSON.stringify(res.phone))
        },
        (err) => {
          console.log(err)
        }
    )
  }

  showDebugger = () => {
    this.setState(
      {
        debuggerCount: this.state.debuggerCount + 1,
      },
      () => {
        if (this.state.debuggerCount == 13) {
          new Vconsole()
        }
      }
    )
  }

  // 获取当前人的身份
  getCurrentRole = () => {
    const { userData } = this.props.UserDataReducer
    const { role } = userData
    return role
  }
  // 获取消息列表
  handleGetMsg = () => {
    util.getYangAxios(
      api.msgList,
      {
        search_data: { is_read: 0 },
      },
      async (res) => {
        const len = res.total
        this.setState({
          msgNum: len,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 商家账户统计
  handleGetChannel = (data) => {
    util.getYangAxios(
      api.accountstatis,
      data,
      async (res) => {
        this.setState({
          channelObj: res,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 首页代理商统计
  handleGetAgent = () => {
    const { activeKey, year, month, typeKey } = this.state
    let obj = {}
    let mode = 1
    /* typeKey 全部1 本月2 年度3 今日4 上月5 本年6 */
    if (typeKey == 3) {
      obj = {
        year,
        month,
      }
      mode = 6
    }
    if (typeKey == 2) {
      var myDate = new Date()
      var tYear = myDate.getFullYear().toString()
      var tMonth = myDate.getMonth() + 1
      if (tMonth.toString().length == 1) {
        tMonth = "0" + tMonth
      }
      obj = {
        year: tYear,
        month: tMonth,
      }
      mode = 4
    }
    if (typeKey == 4) {
      mode = 2
    }
    if (typeKey == 5) {
      mode = 3
    }
    if (typeKey == 6) {
      mode = 5
    }

    //获取商家统计数据
    util.getYangAxios(
      api.statistics,
      {
        zhishutype: parseInt(activeKey), //  1直属 2全部 3所属
        ...obj,
        mode,
      },
      async (res) => {
        this.setState({
          agentObj: res,
        })
      },
      (err) => {
        console.log(err)
      }
    )
    //获取用户统计和习惯数据统计
    util.getYangAxios(
      api.schoolStatistics,
      {
        zhishutype: parseInt(activeKey), //  1直属 2全部 3所属
        ...obj,
        mode,
      },
      async (res) => {
        this.setState({
          schoolStatistics: res.data,
        })
      },
      (err) => {
        console.log(err)
      }
    )
    //获取用户统计-会员数
    util.getYangAxios(
      api.memberStatistics,
      {
        zhishutype: parseInt(activeKey), //  1直属 2全部 3所属
        ...obj,
        mode,
      },
      async (res) => {
        this.setState({
          membersNum: res.count,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // TAB切换
  changeTabs = (key, tabtype) => {
    this.setState(
      {
        [tabtype]: key,
      },
      () => {
        tabtype == "activeKey" && this.handleGetAgent()
      }
    )
  }

  getzlPersonList = () => {
    util.getYangAxios(
      api.getzlPersonList,
      {},
      async (res) => {
        this.setState({
          zlPersonList: res.list,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  getCityPersonList = () => {
    util.getYangAxios(
      api.getCityPersonList,
      {},
      async (res) => {
        this.setState({
          lively: res.lively,
          nolively: res.no_lively,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 渲染
  handlePayRender = () => {
    const { activeKey, zlPersonList, lively, nolively } = this.state
    const { userData, citypersonKey } = this.props.UserDataReducer

    if (userData && userData.status == 0) {
      // 没付钱用户，目前改为可以使用渠道功能，但不能提现，用户类别为待激活合伙人
      return <NoMoneyUser history={this.props.history}></NoMoneyUser>
    }
    return (
      <div>
        {userData.role == 88 && zlPersonList != null && (
          <ZlPerson
            history={this.props.history}
            zlPersonList={zlPersonList}
          ></ZlPerson>
        )}
        {userData.role == 99 && nolively != null && lively != null && (
          <CityPerson
            history={this.props.history}
            lively={lively}
            nolively={nolively}
            cityTabChange={(key) => {
              this.props.infoTabSet(key.citypersonKey)
            }}
            citypersonKey={citypersonKey}
          ></CityPerson>
        )}
        {userData.role != 88 && userData.role != 99 && (
          <Tabs
            activeKey={activeKey}
            onChange={(key) => {
              this.changeTabs(key, "activeKey")
            }}
            tabBarStyle={{ paddingLeft: 16, marginBottom: 0 }}
          >
            <TabPane tab="全部数据" key="2">
              {this.handleDataRender()}
            </TabPane>
            <TabPane tab="直接数据" key="1">
              {this.handleDataRender()}
            </TabPane>
            <TabPane tab="间接数据" key="3">
              {this.handleDataRender()}
            </TabPane>
          </Tabs>
        )}
      </div>
    )
  }

  // 修改数据下标
  handleChangeData = (num) => {
    if (num == 3) {
      this.setState({
        dateModal: true,
        dateValue: null,
        year: null,
        month: null,
      })
    } else {
      this.setState({ typeKey: num }, () => {
        this.handleGetAgent()
      })
    }
  }

  // 渲染数据
  handleDataRender = () => {
    const {
      agentObj,
      typeKey,
      year,
      month,
      schoolStatistics,
      membersNum,
    } = this.state
    const {
      class_sum,
      finish_sum,
      parent_sum,
      remind_sum,
      school_sum,
      show_sum,
      sign_sum,
      student_sum,
      teacher_sum,
    } = schoolStatistics
    // console.log("schoolStatistics", schoolStatistics)
    return (
      <div className={"home_tab_view"}>
        <div className={"row_between first_view"}>
          <div
            className={
              typeKey == 1 ? "home_date_select_div" : "home_date_default_div"
            }
            onClick={() => this.handleChangeData(1)}
          >
            全部
          </div>
          <div
            className={
              typeKey == 4 ? "home_date_select_div" : "home_date_default_div"
            }
            onClick={() => this.handleChangeData(4)}
          >
            今日
          </div>
          <div
            className={
              typeKey == 5 ? "home_date_select_div" : "home_date_default_div"
            }
            onClick={() => this.handleChangeData(5)}
          >
            上月
          </div>
        </div>
        <div className={"row_between"}>
          <div
            className={
              typeKey == 2 ? "home_date_select_div" : "home_date_default_div"
            }
            onClick={() => this.handleChangeData(2)}
          >
            本月
          </div>
          <div
            className={
              typeKey == 6 ? "home_date_select_div" : "home_date_default_div"
            }
            onClick={() => this.handleChangeData(6)}
          >
            本年
          </div>
          <div
            className={
              typeKey == 3 ? "home_date_select_div" : "home_date_default_div"
            }
            onClick={() => this.handleChangeData(3)}
          >
            {year ? `${year}年${month ? month + "月" : ""}` : "年度"}
            <WingBlank size="sm">
              <img
                src={typeKey == 3 ? down_white_icon : down_gray_icon}
                alt=""
                className={"down_gray_icon"}
              />
            </WingBlank>
          </div>
        </div>
        <div className={"home_tab_title"}>商家统计</div>
        {agentObj && agentObj.length > 1 ? (
          <div className={"user_tab_view"}>
            {agentObj.map((item, index) => {
              return this.handleBlueView(item.name, item.count, 104, item.level)
            })}
          </div>
        ) : (
          <div className={"home_tab_blue_view row_between"}>
            <div style={{ opacity: 0 }}>
              <div className={"row_align green_up_view"}>
                <img src={green_up_icon} alt="" className={"green_up_icon"} />
                104
              </div>
              <div className={"green_up_yesterday"}>昨日</div>
            </div>
            <div
              className={"column_center"}
              onClick={() => {
                this.goDetails({
                  title: agentObj[0].name,
                  isCreateNewPage: false,
                  path: "",
                  key: "1",
                  level: agentObj[0].level,
                })
              }}
            >
              <div className={"ambassador_num"}>
                {agentObj[0] ? agentObj[0].count : 0}
              </div>
              <div className={"ambassador_title"}>
                {agentObj[0] ? agentObj[0].name : ""}
              </div>
            </div>
            <div style={{ opacity: 0 }}>
              <div className={"row_align green_up_view"}>
                <img src={green_up_icon} alt="" className={"green_up_icon"} />
                104
              </div>
              <div className={"green_up_yesterday"}>昨日</div>
            </div>
          </div>
        )}
        <div className={"home_tab_title"}>个人用户统计</div>
        <div className={"user_tab_view"}>
          {this.handleBlueView(
            "会员总数",
            membersNum && membersNum ? membersNum : "0",
            104
          )}
          {/* {this.handleBlueView(
            "私教会员数",
            membersNum && membersNum ? membersNum : "0",
            104
          )}
          {this.handleBlueView(
            "普通会员数",
            membersNum && membersNum ? membersNum : "0",
            104
          )} */}
        </div>
        <div className={"home_tab_title"}>学校用户统计</div>
        <div className={"user_tab_view"}>
          {this.handleBlueView(
            "学校数(所)",
            school_sum && school_sum ? school_sum : "0",
            104
          )}
          {this.handleBlueView(
            "班级数(个)",
            class_sum && class_sum ? class_sum : "0",
            104
          )}
          {this.handleBlueView(
            "学生数(位)",
            student_sum && student_sum ? student_sum : "0",
            104
          )}
          {this.handleBlueView(
            "家长数(位)",
            parent_sum && parent_sum ? parent_sum : "0",
            104
          )}
          {this.handleBlueView(
            "教师数(位)",
            teacher_sum && teacher_sum ? teacher_sum : "0",
            104
          )}
        </div>

        <div className={"home_tab_title"}>习惯数据统计</div>
        <div className={"user_tab_view"}>
          {this.handleBlueView(
            "打卡数(次)",
            sign_sum && sign_sum ? sign_sum : "0",
            104
          )}
          {this.handleBlueView(
            "督导数(次)",
            remind_sum && remind_sum ? remind_sum : "0",
            104
          )}
          {this.handleBlueView(
            "秀秀数(次)",
            show_sum && show_sum ? show_sum : "0",
            104
          )}
          {this.handleBlueView(
            "练成数(个)",
            finish_sum && finish_sum ? finish_sum : "0",
            104
          )}
          {/* {this.handleBlueView("督导率", "-", "0.4%")} */}
        </div>
      </div>
    )
  }

  // 渲染蓝色方块
  handleBlueView = (title, sum, num, level) => {
    return (
      <div
        className={"user_tab_blue_view row_between"}
        key={title}
        onClick={this.goDetails.bind(this, {
          title,
          isCreateNewPage: false,
          path: "",
          key: "1",
          level,
        })}
      >
        <div className={"column_center"} style={{ alignItems: "flex-start" }}>
          <div className={"ambassador_num"}>{sum}</div>
          <div className={"ambassador_title"}>{title}</div>
        </div>
        <div style={{ opacity: 0 }}>
          <div className={"row_align green_up_view"}>
            <img src={green_up_icon} alt="" className={"green_up_icon"} />
            {num}
          </div>
          <div className={"green_up_yesterday"}>昨日</div>
        </div>
      </div>
    )
  }

  showDrawer = (ref) => {
    ref &&
      this.setState({
        visible: true,
      })
  }

  onClose = () => {
    this.setState({
      visible: false,
    })
  }

  // 手风情菜单跳转
  handleSkip = (path, key, sourcePage, HeaderTitle) => {
    this.props.history.push({
      pathname: `/${path}`,
      state: {
        key: key,
        sourcePage,
        HeaderTitle,
      },
    })
  }

  handleOutLogin = async () => {
    util.getYangAxios(
      api.logout,
      {},
      async (res) => {},
      (err) => {
        console.log(err)
      }
    )
    await window.localStorage.removeItem("login_token")
    await window.localStorage.removeItem("login_long_token")
    await window.sessionStorage.removeItem("userData")
    await window.sessionStorage.removeItem("range")
    await window.sessionStorage.removeItem("hasName")
    await window.sessionStorage.removeItem("tabStr")
    await window.sessionStorage.removeItem("hephone")
    await window.localStorage.removeItem("isT")
    this.props.tabSetIndex("")
    await this.onModalClose("outLogin")
    await this.props.history.replace("/")
  }

  showModal = (key) => (e) => {
    e.preventDefault() // 修复 Android 上点击穿透
    this.setState({
      [key]: true,
    })
  }

  onModalClose = (key) => () => {
    this.setState({
      [key]: false,
    })
  }

  // 时间修改
  onDateChange = (value) => {
    console.log(value)
    this.setState({
      dateValue: value,
    })
  }

  // 时间key修改
  handleDateKeyChange = (num) => {
    this.setState({
      dateKey: num,
      dateValue: null,
    })
  }

  // 确认时间
  onhandleSubmitDate = () => {
    const { dateValue, dateKey } = this.state
    if (dateValue) {
      if (dateKey == 1) {
        this.setState(
          {
            dateModal: false,
            year: dateValue[0],
            typeKey: 3,
          },
          () => {
            this.handleGetAgent()
          }
        )
      } else {
        this.setState(
          {
            dateModal: false,
            year: dateValue[0],
            month: dateValue[1],
            typeKey: 3,
          },
          () => {
            this.handleGetAgent()
          }
        )
      }
    } else {
      if (dateKey == 1) {
        this.setState(
          {
            dateModal: false,
            year: "2020",
            typeKey: 3,
          },
          () => {
            this.handleGetAgent()
          }
        )
      } else {
        this.setState(
          {
            dateModal: false,
            year: "2020",
            month: "01",
            typeKey: 3,
          },
          () => {
            this.handleGetAgent()
          }
        )
      }
    }
  }

  toToggle = () => {
    this.setState({
      sValue: "",
      toggleRoleIsShow: true,
    })
  }

  toggleRole = (id) => {
    if (isNull(id)) {
      Toast.info("选择身份错误,请重试")
      return
    }

    this.toggleRoleApi(id)
  }

  roleChange = (value) => {
    // this.state.sValue = value
    this.setState=({sValue :value})
    let obj = {}
    this.state.selectData.forEach((item) => {
      if (item.value == value[0]) {
        obj.name = item.label
        obj.id = item.value
        document.querySelector(".am-list-extra").innerHTML = obj.name
      }
    })
  }
  toggleRoleApi = (role) => {
    util.postYangAxios(
      api.toggleRoleSure + role,
      {
        role,
      },
      async (res) => {
        this.setState({
          toggleRoleIsShow: false,
        })
        Toast.info("切换成功", 0.5)
        setTimeout(() => {
          window.location.reload()
        }, 0.6)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  /*手接触屏幕*/
  handleTouchStart = (e) => {
    const startTime = new Date().getTime()
    this.setState({
      startX: e.touches[0].clientX,
      startTime,
    })
  }
  /*手在屏幕上移动*/
  handleTouchMove = (e) => {
    this.setState({
      endX: e.touches[0].clientX,
    })
  }
  /*手离开屏幕*/
  handleTouchEnd = (e) => {
    const { userData } = this.props.UserDataReducer
    let ref = userData.role != 99 && userData.role != 88 ? true : false
    e.preventDefault()
    const { startX, endX, startTime } = this.state
    const now = new Date().getTime()
    if (now - startTime < 120) {
      this.showDrawer(ref)
    }
    // 获取滑动范围
    else if (startX > -1 && endX > -1) {
      let distance = Math.abs(startX - endX)
      if (distance > 80) {
        this.showDrawer(ref)
      }
    }
    this.setState({
      startX: 0,
      endX: 0,
    })
  }

  /* header -  tab栏 */
  headerScreen = () => {
    const { headerActiveKey, tabName,} = this.state
    return (
      <>
        <div className={"header_tabs"}>
          <Tabs
            activeKey={headerActiveKey}
            onChange={this.HeaderchangeTabs}
            tabBarStyle={{ paddingLeft: 4, marginBottom: 0 }}
            centered={true}
          >
            <TabPane tab="全部" key="1"></TabPane>
            <TabPane tab="今日" key="4"></TabPane>
            <TabPane tab="上月" key="5"></TabPane>
            <TabPane tab="本月" key="2"></TabPane>
            <TabPane tab="本年" key="6"></TabPane>
            <TabPane
              tab={
                <span>
                  {tabName}
                  <img
                    src={down_gray_icon}
                    alt=""
                    className={"down_gray_icon arrow"}
                    style={{ opacity: tabName && tabName.length > 2 ? 0 : 1 }}
                  />
                </span>
              }
              key="3"
            ></TabPane>
          </Tabs>
        </div>
        <div className={"row_between order_big_view"}>{this.threeItem()}</div>
      </>
    )
  }
  threeItem = () => {
    const { channelObj } = this.state
    return (
      <>
        <div
          className={"column_center order_view"}
          onClick={this.goDetails.bind(this, {
            title: "总订单量",
            isCreateNewPage: false,
            path: "order",
            key: "1",
            HeaderTitle: "订单",
            headerActiveKey: this.state.headerActiveKey,
          })}
        >
          <div className={"order_title"}>
            {channelObj ? channelObj.ordercount : 0}
          </div>
          <div className={"order_content"}>总订单量</div>
        </div>
        <div className={"shu_line"}></div>
        <div
          className={"column_center order_view"}
          onClick={this.goDetails.bind(this, {
            title: "总收益",
            isCreateNewPage: false,
            path: "financial",
            key: "3",
            HeaderTitle: "总收益",
            headerActiveKey: this.state.headerActiveKey,
          })}
        >
          <div className={"order_title"}>
            {channelObj ? channelObj.agent.total_balance : 0}
          </div>
          <div className={"order_content"}>总收益</div>
        </div>
      </>
    )
  }

  //快速查看详情
  goDetails = (v) => {
    const {
      title,
      isCreateNewPage,
      path,
      key,
      HeaderTitle,
      level,
      headerActiveKey,
    } = v
    const { headerYear, headerMonth } = this.state
    const role = this.getCurrentRole()
    // console.log(headerActiveKey, "--headerActiveKey")
    const headerMode = this.getMode(headerActiveKey, false)
    // console.log("点击时候的data", headerMode)
    let state = {
      title,
      isCreateNewPage,
      path,
      key,
      typeKey: this.state.typeKey,
      year: this.state.year ?? null,
      month: this.state.month ?? null,
    }

    if (path == "order") {
      this.props.history.push({
        pathname: path,
        state: {
          ...state,
          HeaderTitle,
          headerMode,
          headerYear,
          headerMonth,
        },
      })
    }
    if (path == "financial") {
      if (role != 4 && role != 6 && title == "总收益") {
        this.props.history.push({
          pathname: path,
          state: {
            ...state,
            HeaderTitle,
            lookDetails: "lookDetails",
            datatype: 0,
            headerMode,
            headerYear,
            headerMonth,
          },
        })
      } else if (role != 4 && role != 6 && title == "账户余额") {
        const hasName = sessionStorage.getItem("hasName")
        const { userData } = this.props.UserDataReducer
        // if (userData.role == 9 || userData.role == 8) {
        //   return
        // }
        if (hasName == "false" || userData.role == 5 || userData.role == 7) {
          this.props.history.push({
            pathname: path,
            state: {
              ...state,
              HeaderTitle,
              lookDetails: "lookDetails",
              datatype: 1,
              key: "8",
              nohasmoney: "nohasmoney",
            },
          })
        } else {
          this.props.history.push({
            pathname: "cashBag",
            state: {
              ...state,
              HeaderTitle,
              lookDetails: "lookDetails",
              datatype: 1,
              enterPageType: 1,
            },
          })
        }
      }
    } else {
      if (title == "会员总数") {
        if (role != 5 && role != 7) {
          this.props.history.push({
            pathname: "member",
            state: {
              ...state,
              key: "1",
              zhishutype: this.state.activeKey,
            },
          })
        }
      } else if (title == "学校数(所)") {
        if (role != 5 && role != 7) {
          this.props.history.push({
            pathname: "order",
            state: {
              ...state,
              key: "5",
              lookDetails: "lookDetails",
              sourcePage: "business",
              HeaderTitle: "学校数",
              zhishutype: this.state.activeKey,
            },
          })
        }
      } else if (title == "省级代理") {
        if (role != 4 && role != 5 && role != 6 && role != 7) {
          this.props.history.push({
            pathname: "channel",
            state: {
              ...state,
              key: "1",
              lookDetails: "lookDetails",
              zhishutype: this.state.activeKey,
              level,
              HeaderTitle: "省级代理",
            },
          })
        }
      } else if (title == "市级代理") {
        if (role != 4 && role != 5 && role != 6 && role != 7) {
          this.props.history.push({
            pathname: "channel",
            state: {
              ...state,
              key: "1",
              lookDetails: "lookDetails",
              zhishutype: this.state.activeKey,
              level,
              HeaderTitle: "市级代理",
            },
          })
        }
      } else if (title == "区/县级代理") {
        if (role != 4 && role != 5 && role != 6 && role != 7) {
          this.props.history.push({
            pathname: "channel",
            state: {
              ...state,
              key: "1",
              lookDetails: "lookDetails",
              zhishutype: this.state.activeKey,
              level,
              HeaderTitle: "区县级代理",
            },
          })
        }
      } else if (title == "合伙人") {
        if (role != 4 && role != 5 && role != 6 && role != 7) {
          this.props.history.push({
            pathname: "channel",
            state: {
              ...state,
              key: "1",
              lookDetails: "lookDetails",
              zhishutype: this.state.activeKey,
              level,
              HeaderTitle: "合伙人",
            },
          })
        }
      }
    }
  }

  /* tab栏发生改变 */
  /* 全部1 今日4 上月5 本月2 本年6 年度3 */
  HeaderchangeTabs = (key) => {
    this.setState({
      headerActiveKey: key,
      tabName: "年度",
    })
    const data = this.getMode(key, true)
    this.handleGetChannel(data)
  }

  getMode = (key, headerDateModalIsShow) => {
    if (key == 1) {
      //全部
      return {
        mode: 1,
      }
    } else if (key == 2) {
      //本月
      return {
        mode: 4,
      }
    } else if (key == 3) {
      //年度
      this.setState({
        headerDateModal: headerDateModalIsShow,
      })
      return { mode: 6 }
    } else if (key == 4) {
      //今日
      return {
        mode: 2,
      }
    } else if (key == 5) {
      //上月
      return {
        mode: 3,
      }
    } else if (key == 6) {
      //本年
      return {
        mode: 5,
      }
    }
  }

  /*header -   获取header账户数据 */
  getHeaderData = (obj) => {
    const { headerDateKey } = this.state
    let data = { mode: 6, year: obj.year }
    if (headerDateKey == 2) {
      data = {
        ...data,
        month: obj.month,
      }
    }
    this.handleGetChannel(data)
  }

  /* header - 时间key修改*/
  headerDateKeyChange = (num) => {
    this.setState({
      headerDateKey: num, //1按年  2按年月
      headerDateValue: null,
    })
  }
  /* 时间选择器的时间改变 */
  headerDateChange = (value) => {
    this.setState({
      headerDateValue: value,
    })
  }
  /* `${headerDateValue[0]}年${headerDateValue[1]}月` */
  /* header - 时间选择器的确定事件 */
  headerSubmitDate = () => {
    const { headerDateValue, headerDateKey } = this.state
    let obj = {}
    if (headerDateValue) {
      if (headerDateKey == 1) {
        this.setState(
          {
            headerDateModal: false,
            headerYear: headerDateValue[0],
            typeKey: 3,
          },
          () => {
            obj = {
              year: this.state.headerYear,
            }
            this.getHeaderData(obj)
          }
        )
      } else {
        this.setState(
          {
            headerDateModal: false,
            headerYear: headerDateValue[0],
            headerMonth: headerDateValue[1],
            typeKey: 3,
          },
          () => {
            obj = {
              year: this.state.headerYear,
              month: this.state.headerMonth,
            }
            this.getHeaderData(obj)
          }
        )
      }
    } else {
      if (headerDateKey == 1) {
        this.setState(
          {
            headerDateModal: false,
            headerYear: "2020",
            typeKey: 3,
          },
          () => {
            obj = {
              year: "2020",
            }
            this.getHeaderData(obj)
          }
        )
      } else {
        this.setState(
          {
            headerDateModal: false,
            headerYear: "2020",
            headerMonth: "01",
            typeKey: 3,
            // tabName: "2020-01",
          },
          () => {
            obj = {
              year: "2020",
              month: "01",
            }
            this.getHeaderData(obj)
          }
        )
      }
    }
  }

  onClose = (key) => () => {
    this.setState({
      [key]: false,
    })
  }

  setRole = (v) => {
    const { userData } = this.props.UserDataReducer

    this.props.userDataSet({ ...userData, role: v })
  }

  render() {
    const {
      visible,
      channelObj,
      msgNum,
      outLogin,
      dateModal,
      dateValue,
      dateKey,
      headerDateModal,
      headerDateKey,
      headerDateValue,
      toggleRoleIsShow,
    } = this.state
    const { userData } = this.props.UserDataReducer
    const is_partner = userData && !isNull(userData) ? userData.is_partner : "0"
    const is_personage_vip =userData && !isNull(userData) ? userData.is_personage_vip : "0"
    // const  is_personage_vip='1'
    return (
      <div className={"home_background"}>
        <div className={"home_header_view"}>
          {userData && userData.status != 0 ? (
            <div className={"row_between home_header_box"}>
              <div
                onClick={this.showDrawer.bind(
                  this,
                  userData.role != 99 && userData.role != 88 ? true : false
                )}
                className={
                  userData.role != 99 && userData.role != 88
                    ? "home_header_menu_box row_center"
                    : "home_header_menu_box row_center opacityZero"
                }
                onTouchStart={this.handleTouchStart}
                onTouchMove={this.handleTouchMove}
                onTouchEnd={this.handleTouchEnd}
              ></div>

              <div className={"msg_white_view"}>
                <img
                  src={msg_white_icon}
                  alt=""
                  className={"msg_white_icon"}
                  onClick={() => this.props.history.push("msg")}
                />
                {msgNum > 0 && (
                  <div
                    className={"msg_white_big_view"}
                    style={{
                      paddingLeft: msgNum >= 100 ? "3px" : "7px",
                      paddingRight: msgNum >= 100 ? "3px" : "7px",
                    }}
                  >
                    {msgNum >= 1000 ? "···" : msgNum}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className={"row_between header_view"}>
              <div
                className={"home_header_em_box toggle"}
                onClick={this.toToggle}
              >
                {/* 切换身份 */}
              </div>
              <div className={"home_header_em_box"}>
                <img
                  src={out_login_icon}
                  alt=""
                  className={"out_login_icon"}
                  onClick={this.showModal("outLogin")}
                />
              </div>
            </div>
          )}
          <div className={"ambassador_company_view"}>
            <div className={"row_align"}>
              <img
                src={
                  userData && userData.head_portrait
                    ? `https://cdn.ellll.com/${userData.head_portrait}`
                    : ambassador_company_img
                }
                alt=""
                className={"ambassador_company_img"}
              />
              <div style={{ width: "100%" }}>
                <div
                  className={"ambassador_company_title userName_view_box"}
                  onClick={this.showDebugger}
                >
                  {userData ? userData.name : ""}
                </div>
                <div
                  className={
                    "ambassador_company_content company_content_margin right_box_v"
                  }
                >
                  {userData && userData.status == 1 ? userData.levelname : "待激活合伙人"}
                  {userData.contract_money != 3650 ? ( <span className={"levelUp"} onClick={() => this.props.history.push("pay")}>升级权益</span> ) : ""}
                  <span className={"toggleRole"} onClick={this.toToggle}>
                    切换身份
                  </span>
                </div>

                <div>
                  {is_personage_vip == "1" && (
                    <img
                       alt=""
                      className={"person_flag"}
                      src={ZlPersonImg}
                      onClick={this.setRole.bind(this, 88)}
                    />
                  )}
                  {is_partner == "1" && (
                    <img
                      className={"person_flag"}
                      src={CityPersonImg}
                      onClick={this.setRole.bind(this, 99)}
                      alt=''
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={"line"}></div>
            <div className={"row_center"}>
              <div className={"column_center two_view_tab"}>
                <div className={"single_big_title"}>
                  {channelObj ? channelObj.agent.total_balance : 0}
                </div>
                <div className={"single_yue"}>总收益</div>
              </div>

              <div className={"column_center two_view_tab"}>
                <div className={"single_big_title"}>
                  {channelObj ? channelObj.agent.wait_balance : 0}
                </div>
                <div className={"single_yue"}>待分配</div>
              </div>

              <div
                className={"column_center two_view_tab"}
                onClick={this.goDetails.bind(this, {
                  title: "余额",
                  isCreateNewPage: false,
                  path: "financial",
                  key: "3",
                  HeaderTitle: "余额",
                })}
              >
                <div className={"single_big_title"}>
                  {/* {channelObj ? (Number(channelObj.agent.balance) + Number(channelObj.agent.freeze_balance)).toFixed(2) : 0} */}
                  {channelObj ? channelObj.agent.balance : 0}
                </div>
                <div className={"single_yue"}>余额</div>
              </div>
            </div>
          </div>
        </div>
        {/* 筛选 */}
        {/* {this.headerScreen()} */}
        <div className={"card_line"}></div>
        {this.handlePayRender()}
        <Drawer
          placement={"left"}
          closable={false}
          onClose={this.onClose("visible")}
          visible={visible}
          key={"left"}
          contentWrapperStyle={{ padding: 0 }}
          bodyStyle={{ padding: 0 }}
        >
          <div className={"drawer_top_div"}>
            <div className={"drawer_top_close_div"}>
              <img
                src={close_white_icon}
                alt=""
                className={"close_white_icon"}
                onClick={this.onClose("visible")}
              />
            </div>
            <div className={"drawer_top_logo_div"}>
              <img src={xggs_logo} alt="" className={"xggs_logo"} />
            </div>
          </div>
          <Accord history={this.props.history}></Accord>
        </Drawer>
        <Modal
          visible={outLogin}
          transparent
          maskClosable
          onClose={this.onModalClose("outLogin")}
        >
          <div className={"out_login_title"}>是否退出登录</div>
          <div className={"row_between"}>
            <div
              className={"row_center close_btn"}
              onClick={this.onModalClose("outLogin")}
            >
              取消
            </div>
            <div className={"row_center yes_btn"} onClick={this.handleOutLogin}>
              确定
            </div>
          </div>
        </Modal>
        {/* contentModal */}
        <Modal
          popup
          visible={dateModal}
          onClose={this.onModalClose("dateModal")}
          animationType="slide-up"
        >
          <div className={"date_modal_top_view row_between"}>
            <div onClick={this.onModalClose("dateModal")}>取消</div>
            <div onClick={this.onhandleSubmitDate}>确定</div>
          </div>
          <div className={"date_modal_center_view row_center"}>
            <div
              className={`${
                dateKey == 1
                  ? "date_modal_center_select_box"
                  : "date_modal_center_default_box"
              } date_modal_center_left row_center`}
              onClick={() => this.handleDateKeyChange(1)}
            >
              按年
            </div>
            <div
              className={`${
                dateKey == 2
                  ? "date_modal_center_select_box"
                  : "date_modal_center_default_box"
              } date_modal_center_right row_center`}
              onClick={() => this.handleDateKeyChange(2)}
            >
              按月
            </div>
          </div>
          <PickerView
            onChange={this.onDateChange}
            value={dateValue}
            data={dateKey == 1 ? monthDate : yearMonthDate} //monthDate
            cascade={false}
          />
        </Modal>
        {/* headerModal monthDate年  yearMonthDate月 */}
        <Modal
          popup
          visible={headerDateModal}
          onClose={this.onModalClose("headerDateModal")}
          animationType="slide-up"
        >
          <div className={"date_modal_top_view row_between"}>
            <div onClick={this.onModalClose("headerDateModal")}>取消</div>
            <div onClick={this.headerSubmitDate}>确定</div>
          </div>
          <div className={"date_modal_center_view row_center"}>
            <div
              className={`${
                headerDateKey == 1
                  ? "date_modal_center_select_box"
                  : "date_modal_center_default_box"
              } date_modal_center_left row_center`}
              onClick={() => this.headerDateKeyChange(1)}
            >
              按年
            </div>
            <div
              className={`${
                headerDateKey == 2
                  ? "date_modal_center_select_box"
                  : "date_modal_center_default_box"
              } date_modal_center_right row_center`}
              onClick={() => this.headerDateKeyChange(2)}
            >
              按月
            </div>
          </div>
          <PickerView
            onChange={this.headerDateChange}
            value={headerDateValue}
            data={headerDateKey == 1 ? monthDate : yearMonthDate} //monthDate
            cascade={false}
          />
        </Modal>

        {/* 切换身份  */}
        <Modal
          visible={toggleRoleIsShow}
          transparent
          maskClosable
          className="ModalCss"
          wrapClassName="warpCss"
          onClose={this.onClose("toggleRoleIsShow")}
        >
          <RoleModal toggleRole={this.toggleRole}></RoleModal>
        </Modal>
      </div>
    )
  }
}

export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
    tabSetIndex,
    infoTabSet,
  }
)(Home)
