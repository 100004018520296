import React from "react"
import HeaderView from "component/header/headerView"
// import Pdfh5 from "pdfh5"
// import "pdfh5/css/pdfh5.css"

import FileViewer from "react-file-viewer"
import "./css/pdf.css"

export default class Pdf extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  componentDidMount() {
    // this.pdfh5 = new Pdfh5("#demo", {
    //   pdfurl: this.props.location.state.url,
    // })

    console.log("url--1", this.props.location.state.url)
  }
  render() {
    return (
      <div className={'pdf_view'}>
        <HeaderView title="预览"></HeaderView>

        {/* <div id="app" className={"file_app_view"}>
          <div id="demo" className={"file_app_view"}></div>
        </div> */}

        <FileViewer
          fileType={this.props.location.state.type}
          filePath={this.props.location.state.url}
          // errorComponent={CustomErrorComponent}
          onError={this.onError}
        />
      </div>
    )
  }

  onError = (err) => {
    console.log("err--", err)
  }
}
