import React, { Component } from "react"
import HeaderCloseView from "component/header/headerCloseView"
import { Button } from "antd-mobile"
import ss from "./css/successView.module.css"
import SuccessImg from "image/pay_select_blue_icon.png"

export default class successView extends Component {
  render() {
    return (
      <div className={ss.successView}>
        <HeaderCloseView page="bankCard"></HeaderCloseView>

        <div className={ss.tip_view}>
          <img className={ss.img_bottom} src={SuccessImg}  alt="" />
          <p>恭喜您，绑定成功！</p>
        </div>

        <div className={ss.successBtn_view}>
          <Button
            className={ss.addSure}
            onClick={() => this.props.history.push("bankCard")}
          >
            完成
          </Button>
        </div>
        
      </div>
    )
  }
}
