import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet } from "../../actions/XggsUserInformation"
import "./css/my.css"
import userImg from "../../image/user_img.png"
import qr_code_bg_orgin from "../../image/qr_code_bg_orgin.png"
import HeaderView from "../../component/header/headerView"
import { Tabs,} from "antd-mobile"
import qr_code_bg_blue from "../../image/qr_code_bg_blue.png"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import QRCode from "qrcode.react"
class QrCode extends Component {
  constructor(props) {
    super(props)
    this.state = {
      qrname: null,
      qrid: null,
    }
  }

  componentDidMount() {
    this.getqrCodeParams() //二维码参数
  }
  getqrCodeParams = () => {
    util.getYangAxios(
      api.qrCode,
      {},
      async (res) => {
        // console.log("res", res)
        this.setState({
          qrname: res.name,
          qrid: res.shareid,
        })
      },
      (err) => {
        console.error(err)
      }
    )
  }

  // 渲染二维码
  handleCodeRender = (num) => {
    const { qrname, qrid } = this.state
    let url=''
    if(num==0){
       url= `${api.links}inviteNew?name=${qrname}&agentId=${qrid}&type=1`
    }else{
       url= `${api.links}inviteNew?name=${qrname}&agentId=${qrid}&type=2`
    }
    
    return (
      <div className={"column_align code_card"}>
        <div className={"code_title"}>欢迎加入习惯公社</div>
        {/* {qrname && qrid && (
          <QRCode
            value={`${
              num === 0 ? api.links : api.twoLinks
            }invite?name=${qrname}&agentId=${qrid}`} //value参数为生成二维码的链接
            size={150} //二维码的宽高尺寸
            fgColor="#000000" //二维码的颜色
          />
        )} */}
         {qrname && qrid && (
          <QRCode
            value={url} //value参数为生成二维码的链接
            size={150} //二维码的宽高尺寸
            fgColor="#000000" //二维码的颜色
          />
        )}
        <div className={num === 0 ? "code_name_blue" : "code_name_yellow"}>
          {num == 0 ? "合伙人" : "店主"}
        </div>
      </div>
    )
  }

  render() {
    const tabs = [
      { title: <div>合伙人推广</div> },
      { title: <div>店主推广</div> },
    ]
    const { userData } = this.props.UserDataReducer
    const { qrname, qrid } = this.state
    return (
      <div style={{ position: "relative", flex: 1 }}>
        <HeaderView title={""} />
        <div className={"row_between user_data_view"} style={{paddingTop:"8px",paddingBottom:"22px"}}>
          <div className={"row_align"}>
            <img
              src={
                userData && userData.head_portrait
                  ? `https://cdn.ellll.com/${userData.head_portrait}`
                  : userImg
              }
              alt=""
              className={"qr_user_img"}
            />
            <div className={"user_name"}>
              {userData && userData.name ? userData.name : "未设置用户名"}
              {/* <div className={'row_align user_editor_name'}>
                                <img src={editorIcon} alt="" className={'editor_icon'}/>
                                编辑个人资料
                            </div> */}
            </div>
          </div>
          {/* <img src={erweimaImg} alt="" className={'erweima_img'}/> */}
        </div>
        <div className={"gray_line"} />
        <Tabs
          tabs={tabs}
          initialPage={0}
          onChange={(tab, index) => {
            // console.log("onChange", index, tab)
          }}
          onTabClick={(tab, index) => {
            // console.log("onTabClick", index, tab)
          }}
          tabBarUnderlineStyle={{ color: "#0E6CFF" }}
          tabBarActiveTextColor={{ color: "#0E6CFF" }}
        >
          <div className={"column_align tab_view"}>
            <img src={qr_code_bg_blue} alt="" className={"qr_code_bg_orgin"} />
            {qrname && qrid && this.handleCodeRender(0)}
          </div>
          <div className={"column_align tab_view_two"}>
            <img src={qr_code_bg_orgin} alt="" className={"qr_code_bg_orgin"} />
            {qrname && qrid && this.handleCodeRender(1)}
          </div>
        </Tabs>
      </div>
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
  }
)(QrCode)
