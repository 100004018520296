import React, { Component } from "react"
import { Icon } from "antd-mobile"
import ss from "./css/jpModal.module.css"
import Delete from "image/delete.png"

export default class jpModal extends Component {
  render() {
    return (
      <div className={`${ss.jp}  ${ss.jpAnmation}`}>
        <div
          className={ss.jpClose}
          onTouchEnd={(e) => {
            e.preventDefault()
            this.props.cancleFn()
          }}
        >
          <Icon type="down" />
        </div>
        <div className={ss.l_view}>
          <div className={ss.line_div_view}>
            <span
              className={ss.item_view}
              onTouchEnd={(e) => {
                this.props.setValueFn("1")
              }}
            >
              1
            </span>
            <span
              className={ss.item_view}
              onTouchEnd={(e) => {
                this.props.setValueFn("2")
              }}
            >
              2
            </span>
            <span
              className={ss.item_view}
              onTouchEnd={(e) => {
                this.props.setValueFn("3")
              }}
            >
              3
            </span>
          </div>
          <div className={ss.line_div_view}>
            <span
              className={ss.item_view}
              onTouchEnd={(e) => {
                this.props.setValueFn("4")
              }}
            >
              4
            </span>
            <span
              className={ss.item_view}
              onTouchEnd={(e) => {
                this.props.setValueFn("5")
              }}
            >
              5
            </span>
            <span
              className={ss.item_view}
              onTouchEnd={(e) => {
                this.props.setValueFn("6")
              }}
            >
              6
            </span>
          </div>
          <div className={ss.line_div_view}>
            <span
              className={ss.item_view}
              onTouchEnd={(e) => {
                this.props.setValueFn("7")
              }}
            >
              7
            </span>
            <span
              className={ss.item_view}
              onTouchEnd={(e) => {
                this.props.setValueFn("8")
              }}
            >
              8
            </span>
            <span
              className={ss.item_view}
              onTouchEnd={(e) => {
                this.props.setValueFn("9")
              }}
            >
              9
            </span>
          </div>
          <div className={ss.line_div_view}>
            <span
              className={ss.item_2view}
              onTouchEnd={(e) => {
                this.props.setValueFn("0")
              }}
            >
              0
            </span>
            <span
              className={`${ss.item_view} ${ss.close_btn}`}
              onTouchEnd={(e) => {
                this.props.setValueFn(".")
              }}
            >
              .
            </span>
          </div>
        </div>
        <div className={ss.r_view}>
          <div
            className={`${ss.item_view} ${ss.close_btn_view}`}
            onTouchEnd={(e) => this.props.setValueFn("del")}
          >
            <img className={ss.delete_btn} src={Delete} alt="" />
          </div>
          {this.props.isClick == true && (
            <button
              className={`${ss.item_3view} ${ss.cash_btn}`}
              onTouchEnd={(e) => this.props.cashValue("tocash")}
            >
              {this.props.fontTip}
            </button>
          )}

          {this.props.isClick == false && (
            <button className={`${ss.item_3view}  ${ss.no_cash_btn}`}>
              {this.props.fontTip}
            </button>
          )}
        </div>
      </div>
    )
  }
}
