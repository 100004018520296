import React, { Component } from "react"
import {  ListView } from "antd-mobile"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import grayRightIcon from "../../image/gray_right_icon.png"
import backBlackIcon from "../../image/back_black_icon.png"
import ss from "./css/home.module.css"
import { isNull } from "utils/isNull"

export default class fiveDetails extends Component {
  constructor(props) {
    super(props)
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
    })
    this.state = {
      id: "",
      dataSource,
      datas: [],
      pageNo: 1,
      pageSize: 2,
      hasMore: true,
      refreshing: true,
      isLoading: true,
      dataArr: [],
      totalPage: 1,
    }
  }
  render() {
    return (
      <div className={ss.fiveDeatile_view}>
        <div className={ss.posiNull}>
          <div className={"row_between title_text"}>
            <img
              src={backBlackIcon}
              alt=""
              className={"back_black_icon"}
              onClick={this.goBackF}
            />
            <div>下级合伙人信息</div>
            <div style={{ opacity: 0 }}>-</div>
          </div>
        </div>

        {this.handleMsgRender("下级合伙人信息", 1)}
      </div>
    )
  }

  componentDidMount() {
    this.init()
  }

  componentWillReceiveProps(nextProps) {
    const id = nextProps.location.state.id
    this.setState(
      {
        id,
      },
      () => {
        this.clearData(id)
      }
    )
  }

  componentWillUnmount() {
    sessionStorage.removeItem("fiveId")
  }

  init = () => {
    this.clearData(this.props.location.state.id)
  }

  goBackF = () => {
    let arr = JSON.parse(sessionStorage.getItem("fiveId"))
    if (arr.length > 1) {
      let id = arr[arr.length - 2]
      arr.pop()
      sessionStorage.setItem("fiveId", JSON.stringify(arr))

      this.props.history.replace({
        pathname: "fiveDetails",
        state: {
          id,
          isaddParams: false,
        },
      })
    } else {
      this.props.history.replace({
        pathname: "/",
      })
    }
  }

  // 渲染消息
  handleMsgRender = (title, num) => {
    let row
    if (num == 1) {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            <div
              className={"order_card"}
              key={index}
              onClick={this.lookFiveDeatils.bind(this, item.id)}
            >
              {this.fiveLineRow(item.id, "编码：", item.id, true)}
              {this.fiveLineRow(
                item.id,
                "姓名：",
                item.name.length > 6
                  ? item.name.substr(0, 3) + "****" + item.name.substr(7)
                  : item.name
              )}
              {this.fiveLineRow(item.id, "联系电话：", item.phone)}
              {this.fiveLineRow(
                item.id,
                "身份：",
                item.ambassador_level == 3
                  ? "三星合伙人"
                  : item.ambassador_level == 4
                  ? "四星合伙人"
                  : "五星合伙人"
              )}
            </div>
            <div className={"order_card_line"} />
          </>
        )
      }
    }
    return (
      <div>
        <ListView
          contentContainerStyle={{ backgroundColor: "#F6F7FB" }}
          ref={(el) => (this.lv = el)}
          dataSource={this.state.dataSource}
          renderFooter={() => (
            <div className="footer">
              {this.state.isLoading ? "加载中..." : "暂无更多数据"}
            </div>
          )}
          renderRow={row}
          useBodyScroll
          onEndReachedThreshold={10}
          onEndReached={this.onEndReached}
          pageSize={10}
        />
      </div>
    )
  }

  fiveLineRow = (id, l, r, isShow = false) => {
    return (
      <div className={`${ss.order_text_div} row_between`}>
        <div className={"row_align"}>
          <div className={ss.order_title_text}>{l}</div>
          <div className={ss.order_content_text}>{r}</div>
        </div>
        {isShow && (
          <div
            className={`${ss.order_look_text} row_end`}
            onClick={this.lookFiveDeatils.bind(this, id)}
          >
            查看
            <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
          </div>
        )}
      </div>
    )
  }

  lookFiveDeatils = (id) => {
    // console.log("详情id-当前id", id)
    this.props.history.replace({
      pathname: "fiveDetails",
      state: {
        id: id,
        isaddParams: true,
      },
    })
  }

  // 总接口
  handleGetOrder = () => {
    if (this.state.pageNo > this.state.totalPage) {
      this.setState({
        refreshing: false,
        isLoading: false,
        hasMore: false,
      })
      return false
    }
    this.fiveDeatiles()
  }

  // 获取详情合伙人
  fiveDeatiles() {
    util.getYangAxios(
      api.channelsList,
      {
        currentPage: this.state.pageNo,
        search_data: {
          parent_id: this.state.id,
          // is_partner: 1
        },
      },
      async (res) => {
        const dataList = res.list
        this.setState({
          totalPage: res.totalPage,
        })
        let dataArr = this.state.dataArr.concat(dataList)
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr),
          refreshing: false,
          isLoading: false,
          dataArr: dataArr,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  clearData = (id) => {
    // console.log("详情id", this.props.location.state.id)
    let arr = []
    if (!isNull(sessionStorage.getItem("fiveId"))) {
      arr = JSON.parse(sessionStorage.getItem("fiveId"))
    }

    // console.log(arr, "arr1")
    if (this.props.location.state.isaddParams) {
      arr = [...arr, this.props.location.state.id]
    }
    // console.log(arr, "arr2")

    sessionStorage.setItem("fiveId", JSON.stringify(arr))
    id = arr[arr.length - 1]

    this.setState(
      {
        id,
        datas: [],
        pageNo: 1,
        pageSize: 2,
        hasMore: true,
        refreshing: true,
        isLoading: true,
        dataArr: [],
        dataSource: new ListView.DataSource({
          rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
        }),
        totalPage: 1,
        sValue: "",
      },
      () => {
        this.handleGetOrder()
      }
    )
  }
}
