
import React, { Component } from "react"
import HeaderView from "../../component/header/headerView"
import ss from "./css/live.module.css"
import ambassador_company_img from "../../image/profit_banner.png"
import live_img from "../../image/live.png"
const list = [
    { tilte: '合伙人权益最大化设计3' },
    { tilte: '合伙人权益最大化设计2' },
    { tilte: '合伙人权益最大化设计1' },
]
class Live extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }
    render() {
        return (
            <div className={ss.live_container}>
                 <HeaderView title={"直播列表"} />
                {list.map((item, index) => {
                    return <div className={ss.live_item} key={index}>
                        <div className={ss.title_contianer}>
                            <span className={ss.title_tag}></span>
                            <span className={ss.title}>{item.tilte}</span>
                        </div>
                        <div className={ss.live_img_contanier}>
                            <img src={ambassador_company_img} alt="" className={ss.live_img} />
                            {/* <div className={ss.live_doing_wrap}>
                                <img src={live_img} alt="" className={ss.live_img_tag_pic} />
                                <span className={ss.live_img_txt}>直播中</span>
                            </div> */}
                             <div className={ss.live_doing_pass}>
                                回放
                            </div>
                        </div>

                    </div>
                })}

            </div>
        )
    }
}

export default Live