import React, { Component } from "react"
import { Modal, Button } from "antd-mobile"
import { util } from "common/util"
import { api, viewFile } from "common/Config"
import "./css/dlsCourse.css"
import backBlackIcon from "../../image/back_black_icon.png"
import downImg from "../../image/downImg.png"
import threePoint from "../../image/threePoint.png"
import wx from "weixin-jsapi"
import "weixin-js-sdk"
import excel_img from "image/excel.png"
import fileview_img from "image/fileview.png"
import word_img from "image/word.png"
import txt_img from "image/txt.png"
import pdf_img from "image/pdf.png"
import ppt_img from "image/ppt.png"
import image_img from "image/image.png"
import mp4_img from "image/mp4.png"
import commonfile_img from "image/commonfile.png"

const fileTypeArr = [
  "docx",
  "txt",
  "pdf",
  "jpg",
  "png",
  "jpeg",
  "gif",
  "mp3",
  "mp4",
]

/*  文件浏览 */
export default class fileview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      popupShow: false,
      openTagList: ["wx-open-launch-weapp"],
    }

    if (!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
      let num = Number(localStorage.getItem("reloadpage"))
      if (num !== 9) {
        localStorage.setItem("reloadpage", 9)
        window.location.reload()
      }
    }
  }

  render() {
    const { filetype, filename, fileurl,  } = this.props.location.state
    console.log("参数--", this.props.location.state)
    return (
      <div className={"fileview_view"}>
        <div className={"row_between title_text"}>
          <img
            src={backBlackIcon}
            alt=""
            className={"back_black_icon"}
            onClick={() => {
              this.props.history.push("dlsCourse")
            }}
          />
          <div>文件浏览</div>

          {filetype && (
            <img
            alt=""
              src={threePoint}
              onClick={this.toDown}
              className={"three_point_view_o"}
            />
          )}
        </div>

        <div className={"file_look_posi"}>
          <div className={"file_type_view"}>
            {filetype == "xlsx" && (
              <img src={excel_img} className={"file_type_img"} alt="" />
            )}
            {filetype == "fileview" && (
              <img src={fileview_img} className={"file_type_img"} alt="" />
            )}
            {filetype == "docx" && (
              <img src={word_img} className={"file_type_img"} alt="" />
            )}
            {filetype == "txt" && (
              <img src={txt_img} className={"file_type_img"} alt="" />
            )}
            {filetype == "pdf" && (
              <img src={pdf_img} className={"file_type_img"} alt="" />
            )}
            {filetype == "pptx" && (
              <img src={ppt_img} className={"file_type_img"} alt="" />
            )}
            {filetype == "jpg" && (
              <img src={image_img} className={"file_type_img"} alt="" />
            )}
            {filetype == "png" && (
              <img src={image_img} className={"file_type_img"} alt="" />
            )}
            {filetype == "mp4" && (
              <img src={mp4_img} className={"file_type_img"} alt="" />
            )}
            {filetype == "commonfile" && (
              <img src={commonfile_img} className={"file_type_img"} alt="" />
            )}
          </div>
          <div className={"file_name_view"}>{filename}</div>
          {/* {isCanLook && (
            <div className={"down_tip_view"}>
              该文件可在下载前用在线预览提前查看
            </div>
          )}

          {!isCanLook && (
            <div className={"down_tip_view"}>该文件不支持预览</div>
          )} */}

          <div className={"wx_open_view"}>
            {/* 正式版release、开发版develop、体验版trial */}
            <wx-open-launch-weapp
              id="launch-btn"
              username="gh_547f4f21434e"
              path={`/view/F/pages/filetransfer/filetransfer.html?fname=${filename}&furl=${fileurl}&ftype=${filetype}`}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "block",
                border: "none",
              }}
              env-version="release"
            >
              <script type="text/wxtag-template">
                <Button
                  style={{
                    display: "block",
                    width: 310,
                    height: 46,
                    borderRadius: "46px",
                    lineHeight: "46px",
                    color: "#fff",
                    boxShadow: "0px -1px 13px 0px rgba(90,148,254,0.41)",
                    background: "#508afc",
                    textAlign: "center",
                    margin: "12px auto 6px auto",
                  }}
                >
                  打开文件
                </Button>
              </script>
            </wx-open-launch-weapp>
          </div>

          {/* {isCanLook && (
            <div onClick={this.toPreview} className={"online_look_view"}>
              在线预览
            </div>
          )} */}
        </div>

        <div className={"popup_modal_view"}>
          <Modal
            popup
            visible={this.state.popupShow}
            onClose={this.toDown}
            animationType="slide-up"
            className={"popup_modal"}
            maskClosable={false}
          >
            <div className={"down_modal_body"}>
              <div className={"downtop_view"}>
                <a href={api.fileUrl + fileurl} download={filename}>
                  <img src={downImg} className={"downImg_view"} alt="" />
                  <p className={"downImg_font"}>下载</p>
                </a>
              </div>
            </div>
            <div className={"popup_modal_cancle"} onClick={this.toDown}>
              取消
            </div>
          </Modal>
        </div>
      </div>
    )
  }

  componentDidMount() {
    // new Vconsole()
    this.getWxConfig()

    document.addEventListener("WeixinOpenTagsError", function (e) {
      console.error(e.detail.errMsg) // 无法使用开放标签的错误原因，需回退兼容。仅无法使用开放标签，JS-SDK其他功能不受影响
    })

    var btn = document.getElementById("launch-btn")
    btn.addEventListener("ready", function () {
      console.log("标签初始化完毕，可以进行点击操作")
    })
    btn.addEventListener("launch", function (e) {
      console.log("用户点击跳转按钮并对确认弹窗进行操作后触发", e)
    })
    btn.addEventListener("error", function (e) {
      console.log("用户点击跳转按钮后出现错误", e.detail)
    })
  }

  toDown = () => {
    this.setState({
      // popupShow: !this.state.popupShow,
    })
  }

  toPreview = () => {
    const { filetype, fileurl } = this.props.location.state

    if (fileTypeArr.includes(filetype)) {
      this.props.history.replace({
        pathname: "onlineLook",
        state: {
          filetype,
          fileurl,
          ...this.props.location.state,
        },
      })
      // } else if (fileTypeArr2.includes(filetype)) {
      //   window.open(
      //     "https://view.officeapps.live.com/op/view.aspx?src=" +
      //       encodeURIComponent(api.fileUrl + fileurl)
      //   )
    }
  }

  openFile = async () => {
    // const { fileurl, } = this.props.location.state
    // let url = api.fileUrl + fileurl
    //  `pages/filetransfer/filetransfer.html?fname=${filename}&furl=${fileurl}&ftype=${filetype}#fg`
  }

  getWxConfig() {
    util.getYangAxios(
      api.wxConfig,
      {
        url: viewFile,
        openTagList: this.state.openTagList,
      },
      async (res) => {
        // console.log("res----", res)
        // 请求后台接口拿到 data信息
        wx.config({
          debug: false,
          appId: res.appId, // 必填，公众号的唯一标识
          timestamp: res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonceStr, // 必填，生成签名的随机串
          signature: res.signature, // 必填，签名
          jsApiList: ["downloadFile", "openDocument"], // 必填，需要使用的JS接口列表
          // openTagList配置可使用的开放标签,
          openTagList: ["wx-open-launch-weapp"],
        })

        wx.ready((res) => {
          console.log("res-ready---成功", res)
        })

        wx.error((res) => {
          console.log("res-config---失败", res)
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  toDownFile = () => {
    const { fileurl, filename,  } = this.props.location.state
    let url = api.fileUrl + fileurl
    // console.log("下载-点击")
    var x = new XMLHttpRequest()
    x.open("GET", url, true)
    x.responseType = "blob"
    x.onload = function (e) {
      var url = window.URL.createObjectURL(x.response)
      var a = document.createElement("a")
      a.href = url
      a.download = filename
      a.click()
    }
    x.send()
  }
}

/* 
xlsx    xg_GS164430610800017889310242048304   
docx    xg_GS1644307291000809998229941334846  
png     front_164558854280727977.png
pdf    front_164551872082723276.

.ai     xg_GS164549166900031652671
pptx    xg_GS164549173400030584403
zip     front_16455809433432513.
mp4     front_164557945590728468.
*/
