import React, { Component } from "react"
import { api } from "common/Config"
import FileViewer from "react-file-viewer"
import backBlackIcon from "../../image/back_black_icon.png"
import "./css/dlsCourse.css"

export default class onlineLook extends Component {
  render() {
    const { filetype, fileurl } = this.props.location.state
    return (
      <div className="onLineLook_view">
        <div className={"row_between title_text"}>
          <img
            src={backBlackIcon}
            alt=""
            className={"back_black_icon"}
            onClick={this.goBackF}
          />
          <div>在线预览</div>
          <div style={{ opacity: 0 }}>-</div>
        </div>

        <FileViewer fileType={filetype} filePath={api.fileUrl + fileurl} />
      </div>
    )
  }
  goBackF = () => {
    console.log("this---", this.props.location)
    this.props.history.replace({
      pathname: "fileview",
      state: {
        ...this.props.location.state,
      },
    })
  }
}
