
import React, { Component } from "react"
import HeaderView from "../../component/header/headerView"
import ss from "./css/stock.module.css"
import { util } from "../../common/util"
import { api } from "../../common/Config"
class Stock extends Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
           
        }
    }
    componentDidMount() {
        this.handleGetUserproductsList()
    }
     // 库存清单
     handleGetUserproductsList = () => {
        util.getYangAxios(
            api.userproductsList,
            {},
            async (res) => {
                this.setState({
                    list: res
                })
            },
            (err) => {
                console.log(err)
            }
        )
    }
    // 跳转到详情
    handleSkipDetails = (title,item) => {
        let type=''
        if(title==='库存'){
            type=1
        }else if(title==='出库'){
            type=2
        }else if(title==='未激活'){
            type=3
        }else if(title==='已过期'){
            type=4
        }else{
            return
        }
        this.props.history.push({
            pathname: "/stock/details",
            state: {
                type: type,
                product_id:item.id
            },
        })
    }
    // 库存订单
    handleInventoryBox = (title, num,item) => {
        return (
            <div className={[`${ss.inventory_order_box}`, `${ss.column_center}`].join(' ')} onClick={()=>{this.handleSkipDetails(title,item)}}>
                <div className={ss.inventory_order_num}>{num}</div>
                <div className={ss.inventory_order_title}>{title}</div>
            </div>
        )
    }
    // 渲染消息
    handleMsgRender = (title, num) => {
        const { list } = this.state
        let row
        row = (item, sectionID, index) => {
            // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
            return (
                <>
                    <div className={ss.order_card} key={index}>
                        <div className={ss.inventory_order_title}>{item.name}</div>
                        <div className={ss.inventory_order_div}>
                            {this.handleInventoryBox("产品价格", item.price)}
                            {this.handleInventoryBox("产品属性", item.name)}
                            {this.handleInventoryBox("库存", item.stock,item)}
                            {this.handleInventoryBox("出库", item.outbound,item)}
                            {this.handleInventoryBox("未激活", item.unactive,item)}
                            {this.handleInventoryBox("已过期", item.expire,item)}
                        </div>
                    </div>
                </>
            )
        }
        return (
                <div>
                    
                {list.map((item, key) => {
                    return <div key={key}>{row(item, key)}</div> 
                })}
            </div>
        )
    }
    render() {
        return (
            <div >
                <HeaderView title={'库存清单'} />
                <div className={ss.order_card_line} />
                {this.handleMsgRender("库存管理", 3)}
            </div>
        )
    }
}

export default Stock