import React, { Component } from "react"
import "./css/agreement.css"
import closeButton from "../../image/closebtn.png"

export default class jpjAgreement extends Component {
    render() {
        return (
            <div className={"agreenment"}>
                <div className={"header_info"}>
                    <img
                        alt=""
                        className={"close_button"}
                        src={closeButton}
                        onClick={() => this.props.history.go(-1)}
                    ></img>
                    <span className={"header_title"}>习惯公社《教培+》合作协议</span>
                </div>

                <div
                    className={"content"}
                    style={{ padding: "5px 16px", fontSize: "14px" }}
                >
                    <div className={"agreen_line_row_title"}>
                        甲方：上海形者网络科技发展有限公司
                    </div>
                    <div className={"agreen_line_row"}>
                        基于甲方习惯公社项目良好的商业价值及购买者优秀的市场发展能力与优质的资源，现双方就习惯公社项目推广达成以下协议。
                    </div>
                    <div className={"agreen_line_row"}>
                        购买者购买甲方 “教培+”产品，甲方授予购买者习惯公社机构合伙人（以下简称：教培+合伙人）身份与权益，双方履行以下规则的相关约定和义务，享受相对应权利与利益。
                    </div>
                    <div>一、教培+合伙人可以获得：</div>
                    <div className={"agreen_line_row"}>
                        1. 开设自己的私教会员·习惯社
                    </div>
                    <div className={"agreen_line_row"}>
                        2.开设自己的机构习店铺
                    </div>
                    <div className={"agreen_line_row"}>
                        3. 机构学员全员可以开设自己的习小店
                    </div>
                    <div className={"agreen_line_row"}>
                        4. 可以在自己的校区中挂牌习惯公社《家长学堂》，邀请习惯教练驻场陪跑
                    </div>
                    <div className={"agreen_line_row"}>
                        5.可以挂牌《华师大家校共育课题实研点》
                    </div>
                    <div className={"agreen_line_row"}>
                        6.平台公域推流，（赠送精准客户680个）
                    </div>
                    <div className={"agreen_line_row"}>
                        7.私有流量分润（每单消费分润5-15%）
                    </div>
                    <div>
                        8. 全学员超级复购，永续分润
                    </div>
                    <div className={"agreen_line_row"}>
                        9. 本机构学员自动位本机构代言带货
                    </div>
                    <div className={"agreen_line_row"}>
                        10. 两重机构全部学员自动代言带货
                    </div>
                    <div className={"agreen_line_row_title"}>二、教培+合伙人资格获得</div>
                    <div className={"agreen_line_row"}>
                        购买者选择在习惯公社平台指定入口中缴纳费用： 6800元  ，自愿遵守《合伙人运营规则和利益机制》及本协议，即自动成为甲方的机构合伙人，详见附件5：《合伙人运营规则与利益机制》购买者同意费用一经缴纳不予退还。
                    </div>
                    <div className={"agreen_line_row_title"}>三、其他</div>
                    <div className={"agreen_line_row"}>
                        1. 以上条款未尽事宜均按甲方公开统一的规则执行，未尽事宜解释权归甲方；
                    </div>
                    <div className={"agreen_line_row"}>
                        2. 本协议为电子协议，一经同意即刻生效；
                    </div>
                    <div className={"agreen_line_row"}>
                        3. 附件《合伙人运营规则与利益机制》作为本协议的附件，与本协议同样具有法律效力。
                    </div>
                </div>
            </div>
        )
    }
}
