
import React, { Component, Fragment } from "react"
import { Slider, Breadcrumb } from 'antd';
import ss from "./css/count.module.css"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import top1_img from "../../image/top1.png"
import top2_img from "../../image/top2.png"
import top3_img from "../../image/top3.png"
import open_img from "../../image/open.png"
import open_selected_img from "../../image/open_selected.png"


const filterList = [
    { name: '业绩', value: 1 },
    { name: '订单', value: 2 },
    { name: '人数', value: 3 },
]

const roleList = [
    { name: '全部', value: 0 },
    { name: '代理商', value: 1 },
    { name: '合伙人', value: 2 },
    { name: '店主', value: 3 },
]

const relationList = [
    { name: '全部', value: 1 },
    { name: '直接', value: 2 },
    { name: '间接', value: 3 },
]
const timeList = [
    { name: '全部', value: 0 },
    { name: '本周', value: 6 },
    { name: '本月', value:4 },
    { name: '本年', value:5 },
]
class CustomerCount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataArr: [],
            actived: 1,
            openActived: false,
            roleActived: 0,
            relationActived: 1,
            timeActived: 0,
            showModal: false,
            min_price:'',
            max_price:'',
            filterObj: {
                performance: 0,
                fromTypeName: '业绩',
                timeName: '全部',
                roleName: '全部',
                relationName: '全部',
            }
        }
    }
    componentDidMount() {
        this.handleGetOrder()
    }
    handleGetOrder(ref = false) {
        const { actived, roleActived, relationActived, timeActived,min_price,max_price } = this.state
        util.getYangAxios(
            api.customIndex,
            {
                role_type: roleActived,
                date_type: timeActived,
                from_type: actived,
                develop_type: relationActived,
                min_price:min_price,
                max_price:max_price
            },
            async (res) => {
                const dataList = res
                var dataArr = this.state.dataArr.concat(dataList) //关键代码
                this.setState({
                    dataArr: dataArr, // 保存新数据进state
                })
            },
            (err) => {
                console.log(err)
            }
        )
    }
    // 跳转到详情
    handleSkipDetails = (item) => {
        this.props.history.push({
            pathname: "/customer/details",
            state: {
                user_id: item.id,
            },
        })
        sessionStorage.setItem("selectedActived", 1)
    }
    handTopFilter = (item, index) => {
        let obj = Object.assign({}, this.state.filterObj, { fromTypeName: item.name })
        this.setState(
            {
                dataArr: [], // 保存新数据进state
                actived: item.value,
                filterObj: obj,
            },
            () => {
                this.handleGetOrder()
            }
        )
    }
    onChangeSlider = (value) => {
        let obj = Object.assign({}, this.state.filterObj, { performance: value })
        this.setState({
            filterObj: obj,
        })
    }
    handpFilterModal = (obj, index, boole) => {
        this.setState({
            showModal: !this.state.showModal,
            openActived: !this.state.openActived
        })
    }
    confirmFilter = () => {
        // let min_price=''
        // let max_price=''
        // if(this.state.min_price&&this.state.max_price&&this.state.max_price<this.state.min_price){
        //     min_price=this.state.max_price
        //     max_price=this.state.min_price
        // }else{
        //     min_price=this.state.min_price 
        //     max_price=this.state.max_price
        // }
        this.setState({
            showModal: false,
            dataArr: [], // 保存新数据进state
            // min_price:min_price,
            // max_price:max_price
        },
            () => {
                this.handleGetOrder()
            }
        )
    }
    confirmItemFilter = (type, item, index) => {
        if (type === 'role') {
            let obj = Object.assign({}, this.state.filterObj, { roleName: item.name })
            this.setState({
                roleActived: item.value,
                filterObj: obj
            })
        } else if (type === 'relation') {
            let obj = Object.assign({}, this.state.filterObj, { relationName: item.name })
            this.setState({
                relationActived: item.value,
                filterObj: obj
            })
        } else if (type === 'time') {
            let obj = Object.assign({}, this.state.filterObj, { timeName: item.name })
            this.setState({
                timeActived: item.value,
                filterObj: obj
            })
        }

    }
    resetFilter = () => {
       
        let obj = Object.assign({}, this.state.filterObj, { timeName: '全部', roleName: '全部', relationName: '全部' })
        this.setState({
            roleActived: 0,
            relationActived: 1,
            timeActived: 0,
            filterObj: obj,
            showModal: false,
            dataArr: [], 
            min_price:'',
            max_price:''
        },
            () => {
                this.handleGetOrder()
            }
        )
    }
    // 渲染消息
    handleMsgRender = (row, index) => {
        const {actived}=this.state
        return (
            <>
                <div className={ss.customer_count_card} key={index} onClick={() => this.handleSkipDetails(row)}>
                    <div className={ss.customer_count_index}>
                        {index == 0 && <img src={top1_img} alt="" className={ss.customer_top_img} />}
                        {index == 1 && <img src={top2_img} alt="" className={ss.customer_top_img} />}
                        {index == 2 && <img src={top3_img} alt="" className={ss.customer_top_img} />}
                        {index > 2 && <span>{index + 1}</span>}
                    </div>
                    <div className={ss.customer_count_content}>
                        <span className={ss.customer_count_name}>{row.name}</span>
                        <span className={ss.customer_count_phone}>{row.phone}</span>
                        <div className={ss.customer_count_type_contianer}>
                            {row.is_direct === 1 && (<span className={ss.customer_count_type}>直接</span>)}
                            {row.is_direct === 0 && (<span className={ss.customer_count_type_jian}>间接</span>)}
                            {row.role_type === 1 && (<span className={ss.customer_count_type_person}>代理商</span>)}
                            {/* <span className={ss.customer_count_type_person_small}>初级合伙人</span> */}
                            {row.role_type === 2 && (<span className={ss.customer_count_type_person_middle}>合伙人</span>)}
                            {row.role_type === 3 && (<span className={ss.customer_count_type_person_shoper}>店主</span>)}
                        </div>
                    </div>
                    <div className={ss.customer_count_money}>
                        {row.record}
                        {actived===1&&'万'}
                        {actived===2&&'单'}
                        {actived===3&&'人'}
                    </div>
                </div>

            </>
        )

    }
    onMinPriceChange=(event)=>{
        this.setState({
            min_price: event.target.value,
          })
    }
    onMaxPriceChange=(event)=>{
        this.setState({
            max_price: event.target.value,
          })
    }
    render() {
        const { dataArr, actived, showModal, filterObj, openActived, roleActived, relationActived, timeActived ,min_price,max_price} = this.state
        return (
            <div >
                <div className={ss.filter_main_container}>
                    <div className={ss.customer_filter_container}>
                        {filterList.map((item, index) => {
                            return <span key={index} onClick={() => { this.handTopFilter(item, index) }} className={[`${ss.customer_info_item}`, `${actived === item.value ? ss.customer_info_item_active : ''}`].join(' ')}>{item.name}</span>
                        })}
                        <span onClick={() => { this.handpFilterModal({}, 5, true) }} className={[`${ss.customer_info_item}`, `${openActived ? ss.customer_info_open_active : ''}`].join(' ')}>
                            筛选 {openActived === false && <img src={open_img} alt='' className={ss.customer_open_img} />}
                            {openActived && <img src={open_selected_img} alt='' className={ss.customer_open_img} />}
                        </span>
                    </div>
                    <div className={ss.breadcrumb_container}>
                        <span>当前选择：</span>
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item className={ss.breadcrumb}>{filterObj.fromTypeName}</Breadcrumb.Item>
                            <Breadcrumb.Item className={ss.breadcrumb}>{filterObj.roleName}</Breadcrumb.Item>
                            <Breadcrumb.Item className={ss.breadcrumb}>{filterObj.relationName}</Breadcrumb.Item>
                            <Breadcrumb.Item className={ss.breadcrumb}>{filterObj.timeName}</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>

                    <div className={ss.filter_content} style={{ height: window.innerHeight + 'px', display: showModal ? 'block' : 'none' }} >
                        <div className={ss.filter_content_main}>
                            <div className={ss.filter_content_title}>角色</div>
                            <div className={ss.filter_line}>
                                {roleList.map((role, index) => {
                                    return <span key={index} onClick={() => this.confirmItemFilter('role', role, index)} className={[`${ss.filter_role}`, `${roleActived === role.value ? ss.filter_role_active : ''}`].join(' ')}>{role.name}</span>
                                })}
                            </div>
                            {/* <div className={ss.filter_content_title}>业绩 </div>
                            <div className={"row_center"}>
                                <div  className={"input_box"} onClick={() => { this.minPrice.focus() }}  style={{'width':"80%",marginBottom:'16px'}}>
                                    <input  type="number" value={min_price} onChange={this.onMinPriceChange}  className={"input_text"}  placeholder={"最小业绩"} ref={(c) => {this.minPrice = c }} style={{'fontSize':'14px',textAlign:'center'}} />
                                </div>
                                <span style={{'margin':'0px 10px 16px'}}>至</span>
                                <div  className={"input_box"} onClick={() => { this.maxPrice.focus() }}  style={{'width':"80%",marginBottom:'16px'}}>
                                    <input  type="number" value={max_price} onChange={this.onMaxPriceChange}  className={"input_text"}  placeholder={"最大业绩"} ref={(c) => {this.maxPrice = c }} style={{'fontSize':'14px',textAlign:'center'}}  />
                                </div>
                            </div> */}
                            <div className={ss.filter_content_title}>关系</div>
                            <div className={ss.filter_line}>
                                {relationList.map((relation, index) => {
                                    return <span key={index} onClick={() => this.confirmItemFilter('relation', relation, index)} className={[`${ss.filter_role}`, `${relationActived === relation.value ? ss.filter_role_active : ''}`].join(' ')}>{relation.name}</span>
                                })}
                            </div>
                            <div className={ss.filter_content_title}>时间</div>
                            <div className={ss.filter_line}>
                                {timeList.map((time, index) => {
                                    return <span key={index} onClick={() => this.confirmItemFilter('time', time, index)} className={[`${ss.filter_role}`, `${timeActived === time.value ? ss.filter_role_active : ''}`].join(' ')}>{time.name}</span>
                                })}
                            </div>
                            <div className={ss.filter_btns}>
                                <div className={ss.filter_btn} onClick={() => this.resetFilter()}>重置</div>
                                <div className={ss.filter_btn} onClick={() => this.confirmFilter()}>确定</div>
                            </div>
                        </div>
                    </div>
                </div>
                {dataArr.map((item, index) => {
                    return <Fragment key={item.id}>
                        {this.handleMsgRender(item, index)}
                    </Fragment>
                })}


            </div>
        )
    }
}

export default CustomerCount