import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet } from "../../actions/XggsUserInformation"
import "./css/financial.css"
import {  Toast, ListView } from "antd-mobile"
import HeaderView from "../../component/header/headerView"
import { Tabs, Select } from "antd"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import grayRightIcon from "../../image/gray_right_icon.png"
import ss from "page/order/css/cash.module.css"
import AntModal from "component/antModal/antModal"

const { TabPane } = Tabs
class Financial extends Component {
  constructor(props) {
    super(props)
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
    })
    this.state = {
      medata: {
        isModalVisible: true,
      },
      activeKey: "2",
      dataSource,
      datas: [],
      pageNo: 1,
      pageSize: 2,
      hasMore: true,
      refreshing: true,
      isLoading: true,
      dataArr: [],
      totalPage: 1,
      typeKey: 1,
      selectList: [],
      selectCurrentKey: "",
      HeaderTitle: "",
      datatype: "",
      headerMode: 1,
      headerYear: null,
      headerMonth: null,
      cashActiveKey: "a",
      enterPageType: null, //1 代表从主页的账户余额进入
      enterActive: "2", //默认激活财务流水
      roleType: "",
      attestation: false,
      isModalVisible: false,
    }
  }

  componentDidMount() {
    console.log(this.props.history.location.state)
    this.setState(
      {
        activeKey: this.props.history.location.state.key,
        HeaderTitle: this.props.history.location.state.HeaderTitle,
        lookDetails: this.props.history.location.state.lookDetails ?? "",
        nohasmoney: this.props.history.location.state.nohasmoney ?? "",
        datatype: this.props.history.location.state.datatype ?? "",
        headerMode: this.props.location.state.headerMode ?? "",
        headerYear: this.props.location.state.headerYear ?? null,
        headerMonth: this.props.location.state.headerMonth ?? null,
        enterPageType: this.props.location.state.enterPageType ?? null,
      },
      () => {
        this.handleGetOrder()
      }
    )
    this.getSelectList()
    this.handleGetData()
  }

  // 获取合伙人信息
  handleGetData = () => {
    util.getYangAxios(
      api.infoDetails,
      {},
      async (res) => {
        this.setState({
          roleType: res.type ? res.type : "",
          attestation: res.is_realname,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  getSelectList = () => {
    util.getYangAxios(
      api.getSelectList,
      {},
      async (res) => {
        let item = { key: "", value: "全部" }
        this.setState({
          selectList: [item, ...res],
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 总接口
  handleGetOrder = () => {
    const { activeKey, cashActiveKey } = this.state

    if (this.state.pageNo > this.state.totalPage) {
      this.setState({
        refreshing: false,
        isLoading: false,
        hasMore: false,
      })
      // Toast.info("没有数据了~", 1)
      return false
    }

    if (activeKey == "1") {
      this.handleGetMarketOrder()
    } else if (activeKey == "2") {
      this.handleGetPurchaseOrder()
    } else if (activeKey == "3") {
      this.handleGetInventoryOrder()
    } else if (activeKey == "4") {
      this.handleGetInventoryOrder()
    } else if (activeKey == "6") {
      //提现
      if (cashActiveKey == "a") {
        this.handleGetCashBag()
      } else {
        this.handleGetCashBank()
      }
    }
  }

  // 获取销售订单
  handleGetMarketOrder(ref = false) {
    util.getYangAxios(
      api.salelist,
      {
        currentPage: this.state.pageNo,
      },
      async (res) => {
        const dataList = res.list

        this.setState({
          totalPage: res.totalPage,
        })

        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 获取采购订单
  handleGetPurchaseOrder(ref = false) {
    util.getYangAxios(
      api.purchaseOrders,
      {
        currentPage: this.state.pageNo,
      },
      async (res) => {
        const dataList = res.list

        this.setState({
          totalPage: res.totalPage,
        })

        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 获取提取订单
  handleGetExtractOrder(ref = false) {
    util.getYangAxios(
      api.extractOrder,
      {
        currentPage: this.state.pageNo,
      },
      async (res) => {
        const dataList = res.list
        const len = dataList.length
        if (len <= 0) {
          // 判断是否已经没有数据了
          this.setState({
            refreshing: false,
            isLoading: false,
            hasMore: false,
          })
          Toast.info("没有数据了~", 1)
          return false
        }
        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 获取财务流水数据
  handleGetInventoryOrder(ref = false) {
    const { activeKey, lookDetails, datatype } = this.state
    let obj = {
      currentPage: this.state.pageNo,
    }
    if (activeKey == 3) {
      obj = {
        ...obj,
        search_data: {
          type: this.state.selectCurrentKey,
        },
      }
      if (lookDetails == "lookDetails" && datatype == 0) {
        let search_data = {
          type: this.state.selectCurrentKey,
          change_balance_gt: 0,
        }
        obj = {
          ...obj,
          search_data,
          mode: this.state.headerMode.mode,
        }

        if (obj.mode == 6 && this.state.headerYear) {
          obj.year = this.state.headerYear
        }
        if (obj.mode == 6 && this.state.headerMonth) {
          obj.month = this.state.headerMonth
        }
      }
    }

    util.getYangAxios(
      api.financialFlowList,
      obj,
      async (res) => {
        let dataList = res.list
        dataList.forEach((item, index) => {
          if (item.change_balance > 0) {
            dataList[index].change_balance = "+" + String(item.change_balance)
          }
        })

        this.setState({
          totalPage: res.totalPage,
        })

        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  //获取转出至钱包的列表
  handleGetCashBag(ref = false) {
    let data = {
      currentPage: this.state.pageNo,
    }

    util.getYangAxios(
      api.cashTablelist,
      data,
      async (res) => {
        const dataList = res.list
        this.setState({
          totalPage: res.totalPage,
        })
        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  //获取转出至银行的列表
  handleGetCashBank(ref = false) {
    let data = {
      currentPage: this.state.pageNo,
    }
    util.getYangAxios(
      api.cashBanklist,
      data,
      async (res) => {
        const dataList = res.list
        this.setState({
          totalPage: res.totalPage,
        })
        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 滑动到底部时加载更多
  onEndReached = (event) => {
    // 加载中或没有数据了都不再加载
    if (this.state.isLoading || !this.state.hasMore) {
      return
    }
    this.setState(
      {
        isLoading: true,
        pageNo: this.state.pageNo + 1, // 加载下一页
      },
      () => {
        this.handleGetOrder()
      }
    )
  }

  // 订单文字渲染
  handleOrderTextRender = (title, content, isLook = false, classname) => {
    return (
      <div className={"row_align order_text_div"} style={{ width: "100%" }}>
        <div className={"row_align"}>
          <div className={"order_title_text"}>{title}</div>
          <div
            className={
              classname && classname
                ? "order_content_text laiyuan"
                : "order_content_text"
            }
          >
            {content}
          </div>
        </div>
        {isLook && (
          <div className={"order_look_text row_end"}>
            查看
            <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
          </div>
        )}
      </div>
    )
  }

  // 渲染订单状态
  handleStatusRender = (num) => {
    if (num == 0) {
      return "未激活"
    } else if (num == 10) {
      return "已激活"
    } else if (num == 20) {
      return "已过期"
    } else {
      return "未知"
    }
  }

  // 库存订单
  handleInventoryBox = (title, num) => {
    return (
      <div className={"inventory_order_box column_center"}>
        <div className={"inventory_order_num"}>{num}</div>
        <div className={"inventory_order_title"}>{title}</div>
      </div>
    )
  }

  // 跳转到订单详情
  handleSkipDetails = (obj, num) => {
    if (num == 1) {
      this.props.history.push({
        pathname: "/marketOrder",
        state: {
          order: obj,
        },
      })
    } else if (num == 2) {
      this.props.history.push({
        pathname: "/purchaseOrder",
        state: {
          order: obj,
        },
      })
    } else if (num == 4) {
      this.props.history.push({
        pathname: "/withdrawalDetails",
        state: {
          order: obj,
        },
      })
    } else if (num == 6) {
      this.props.history.push({
        pathname: "/cashDetails",
        state: {
          order: obj.id,
        },
      })
    }
  }

  // 修改数据下标
  handleChangeData = (num) => {
    this.setState({ typeKey: num }, () => {})
  }

  //选择框值发生变化
  handleChange = (value) => {
    this.setState(
      {
        selectCurrentKey: value,
        datas: [],
        pageNo: 1,
        pageSize: 2,
        hasMore: true,
        refreshing: true,
        isLoading: true,
        dataArr: [],
        totalPage: 1,
      },
      () => this.handleGetOrder()
    )
  }

  topTabDom = () => {
    const { enterActive } = this.state
    return (
      <>
        <Tabs
          activeKey={enterActive}
          onChange={(key) => {
            this.TopChangeTabs()
          }}
          tabBarStyle={{ paddingLeft: 16, marginBottom: 0 }}
        >
          <TabPane tab="提现" key="1"></TabPane>
          <TabPane tab="财务流水" key="2"></TabPane>
        </Tabs>
      </>
    )
  }

  // // TAB切换
  // TopChangeTabs = () => {
  //   this.props.history.push({
  //     pathname: "cashBag",
  //     state: { ...this.props.location.state, enterPageType: 1 },
  //   })
  // }

  // 渲染消息
  handleMsgRender = (title, num) => {
    const {
      selectList,
      selectCurrentKey,
      lookDetails,
      cashActiveKey,
    } = this.state
    const { Option } = Select
    let row
    if (num == 1) {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            <div
              className={"order_card"}
              key={index}
              onClick={() => this.handleSkipDetails(item, num)}
            >
              {this.handleOrderTextRender("订单号：", item.order_sn, true)}
              {this.handleOrderTextRender("产品名称：", item.product_name)}
              {this.handleOrderTextRender("产品数量：", item.number)}
              {this.handleOrderTextRender("产品金额：", item.total_price)}
              {this.handleOrderTextRender("订货方：", item.agent_name)}
              {this.handleOrderTextRender("发货方：", item.sale_agent_name)}
              {this.handleOrderTextRender("订单时间：", item.created_at)}
            </div>
            <div className={"order_card_line"} />
          </>
        )
      }
    } else if (num == 2) {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            <div
              className={"order_card"}
              key={index}
              onClick={() => this.handleSkipDetails(item, num)}
            >
              {this.handleOrderTextRender("订单号：", item.order_sn, true)}
              {this.handleOrderTextRender("产品名称：", item.product_name)}
              {this.handleOrderTextRender("产品数量：", item.number)}
              {this.handleOrderTextRender("产品金额：", item.total_price)}
              {this.handleOrderTextRender("订货方：", item.agent_name)}
              {this.handleOrderTextRender("订单状态：", item.statu_name)}
              {this.handleOrderTextRender("订单时间：", item.created_at)}
            </div>
            <div className={"order_card_line"} />
          </>
        )
      }
    } else if (num == 3) {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            <div className={"order_card"} key={index}>
              {lookDetails != "lookDetails"
                ? this.handleOrderTextRender("订单号：", item.order_sn)
                : ""}

              {this.handleOrderTextRender("订单类型：", item.tits)}

              {lookDetails != "lookDetails"
                ? item.explains && item.explains
                  ? this.handleOrderTextRender(
                      "订单来源：",
                      item.explains,
                      false,
                      "laiyuan"
                    )
                  : ""
                : ""}
              {this.handleOrderTextRender("收支金额：", item.change_balance)}
              {this.handleOrderTextRender("日期：", item.created_at)}
            </div>
            <div className={"order_card_line"} />
          </>
        )
      }
    } else if (num == 4) {
      row = (item, sectionID, index) => {
        return (
          <>
            <div
              className={"money_card"}
              key={index}
              onClick={() => this.handleSkipDetails(item, num)}
            >
              <h4 className={"money_result"}>提现到账</h4>
              <div className={"center_container"}>
                <p>提现金额</p>
                <p>
                  <span>¥</span>
                  <span>16000.00</span>
                </p>
              </div>
              <div className={"money_strip"}>
                {this.handleOrderTextRender("提现单号：", 111)}
                {this.handleOrderTextRender("提现银行：", 111)}
                {this.handleOrderTextRender("转出时间：", 111)}
                {this.handleOrderTextRender("未到账时间：", 11)}
              </div>
              <div className={"money_look row_between"}>
                <span>查看</span>
                <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
              </div>
            </div>
          </>
        )
      }
    } else if (num == 6) {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            {cashActiveKey == "a" && (
              <>
                <div className={"order_card"} key={index}>
                  <div>
                    {/* 
                    10 审核中 
                    20 审核失败
                    30 待提现
                    40 成功
                     */}
                    {item.status == 40 ? (
                      <span className={ss.red_font}>提现到账成功</span>
                    ) : item.status == 20 ? (
                      <span className={ss.green_font}>提现到账失败</span>
                    ) : (
                      <span className={ss.yellow_font}>提现审核中</span>
                    )}
                  </div>
                  <div className={"cashTop_view"}>
                    <div className={"cash_title"}>提现</div>
                    <div className={"cash_number"}>¥ {item.money}</div>
                  </div>
                  <div className={"cashMiddle_view"}>
                    {this.handleOrderTextRender("提现至", item.id > 802 ? "银行卡" : "银联钱包")}
                    {this.handleOrderTextRender("提现单号", item.order_sn)}
                    {this.handleOrderTextRender(
                      "服务费",
                      item.all_service_money
                    )}
                    {item.tax_money &&
                      Number(item.tax_money) > 0 &&
                      this.handleOrderTextRender("所得税", item.tax_money)}
                    {this.handleOrderTextRender("实际到账", item.real_money)}
                    {this.handleOrderTextRender("提现时间", item.created_at)}
                  </div>
                  <div
                    className={"cashBottom_view row_between"}
                    onClick={() => this.handleSkipDetails(item, num)}
                  >
                    <span>查看</span>
                    <img
                      src={grayRightIcon}
                      alt=""
                      className={"gray_right_icon"}
                    />
                  </div>
                </div>
                <div className={"order_card_line"} />
              </>
            )}

            {cashActiveKey == "b" && (
              <>
                <div className={"order_card"} key={index}>
                  <div>
                    <span className={ss.red_font}>转出到账成功</span>
                  </div>
                  <div className={"cashTop_view"}>
                    <div className={"cash_title"}>提现</div>
                    <div className={"cash_number"}>¥ {item.money}</div>
                  </div>
                  <div className={"bankMiddle_view"}>
                    {this.handleOrderTextRender("转出至", item.bankAcc_name)}
                    {this.handleOrderTextRender("转出卡号", item.bankAcct_no)}
                    {this.handleOrderTextRender(
                      "转出单号",
                      item.exterior_trade_no
                    )}
                    {this.handleOrderTextRender("转出时间", item.created_at)}
                  </div>
                  {/* <div
                    className={"cashBottom_view row_between"}
                    onClick={() => this.handleSkipDetails(item, num)}
                  >
                    <span>查看</span>
                    <img
                      src={grayRightIcon}
                      alt=""
                      className={"gray_right_icon"}
                    />
                  </div> */}
                </div>
                <div className={"order_card_line"} />
              </>
            )}
          </>
        )
      }
    } else {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            <div className={"order_card"} key={index}>
              {this.handleOrderTextRender("产品名称：", item.product_name)}
              {this.handleOrderTextRender("学校码：：", item.cdk)}
              {this.handleOrderTextRender(
                "状态：",
                this.handleStatusRender(item.status)
              )}
              {this.handleOrderTextRender("学校名称：", item.school_name)}
              {this.handleOrderTextRender("创建日期：", item.created_at)}
            </div>
            <div className={"order_card_line"} />
          </>
        )
      }
    }
    return (
      <div>
        <div className={"order_top_line row_align"}>{title}</div>
        {num == 3 && lookDetails != "lookDetails" ? (
          <>
            <div className={"home_tab_view space_between "}>
              <div className={"left_item"}>
                {/* <span>收入：+1000</span>
            <span>支出：-1000</span> */}
              </div>
              <div>
                <Select
                  value={selectCurrentKey}
                  style={{ width: 150 }}
                  onChange={this.handleChange}
                >
                  {selectList.map((item) => {
                    return (
                      <Option value={item.key} key={item.key}>
                        {item.value}
                      </Option>
                    )
                  })}
                </Select>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        {num == 6 && (
          <Tabs
            activeKey={cashActiveKey}
            onChange={this.cashChangeTabs}
            tabBarStyle={{
              marginBottom: 0,
              color: "#34373D",
              paddingLeft: "16px",
              background: "#F6F7FB",
            }}
          >
            <TabPane tab="提现" key="a"></TabPane>
            <TabPane tab="银联钱包" key="b"></TabPane>
          </Tabs>
        )}

        <ListView
          contentContainerStyle={{ backgroundColor: "#F6F7FB" }}
          ref={(el) => (this.lv = el)}
          dataSource={this.state.dataSource}
          renderFooter={() => (
            <div className="footer">
              {this.state.isLoading ? "加载中..." : "暂无更多数据"}
            </div>
          )}
          renderRow={row}
          useBodyScroll
          onEndReachedThreshold={10}
          onEndReached={this.onEndReached}
          pageSize={10}
        />
      </div>
    )
  }

  // TAB切换
  changeTabs = (key) => {
    this.setState(
      {
        activeKey: key,
        datas: [],
        pageNo: 1,
        pageSize: 2,
        hasMore: true,
        refreshing: true,
        isLoading: true,
        dataArr: [],
        dataSource: new ListView.DataSource({
          rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
        }),
        totalPage: 1,
        selectCurrentKey: "",
      },
      () => {
        if (key == 7) {
          this.props.history.push({
            pathname: "cashBag",
            state: { ...this.props.location.state, enterPageType: 1 },
          })
        } else {
          this.handleGetOrder()
        }
      }
    )
  }

  cashChangeTabs = (key) => {
    this.setState(
      {
        cashActiveKey: key,
        datas: [],
        pageNo: 1,
        pageSize: 2,
        hasMore: true,
        refreshing: true,
        isLoading: true,
        dataArr: [],
        dataSource: new ListView.DataSource({
          rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
        }),
        totalPage: 1,
        sValue: "",
      },
      () => {
        this.handleGetOrder()
      }
    )
  }

  cancleFn = ({ type }) => {
    type == 1 && this.props.history.push("authentication")
    type == 2 &&
      this.setState({
        isModalVisible: false,
      })
  }

  cashMoney = () => {
    const { roleType, attestation } = this.state
    if (!attestation && roleType != 1) {
      this.setState({
        isModalVisible: true,
      })
      return
    }

    if (roleType == 1) {
      //企业
    } else {
       //  个人
    }
  }

  render() {
    const {
      activeKey,
      HeaderTitle,
      lookDetails,
      enterPageType,
      nohasmoney,
      isModalVisible,
    } = this.state
    return (
      <div
        className={"pay_result_view"}
        style={{ height: window.innerHeight + "px" }}
      >
        <HeaderView title={HeaderTitle} />
        <Tabs
          activeKey={activeKey}
          onChange={this.changeTabs}
          tabBarStyle={{
            marginBottom: 0,
            color: "#34373D",
            paddingTop: "13px",
            paddingLeft: "40px",
          }}
          centered
          tabBarGutter={20}
        >
          {lookDetails != "lookDetails" ? (
            <TabPane tab="销售" key="1">
              {this.handleMsgRender("销售订单", 1)}
            </TabPane>
          ) : (
            ""
          )}
          {lookDetails != "lookDetails" ? (
            <TabPane tab="采购" key="2">
              {this.handleMsgRender("直属订单", 2)}
            </TabPane>
          ) : (
            ""
          )}

          {enterPageType && enterPageType == 1 && (
            <TabPane tab="提现" key="7"></TabPane>
          )}

          {nohasmoney && nohasmoney == "nohasmoney" && (
            <TabPane tab="提现" key="8">
              {this.handleMsgRender("提现", 8)}
            </TabPane>
          )}

          <TabPane tab="财务流水" key="3">
            {this.handleMsgRender("流水单", 3)}
          </TabPane>

          {/* 提现注释 */}
          {lookDetails != "lookDetails" ? (
            <TabPane tab="提现" key="6">
              {this.handleMsgRender("提现信息", 6)}
            </TabPane>
          ) : (
            ""
          )}
        </Tabs>

        {isModalVisible === true && (
          <AntModal
            data={this.state.medata}
            cancleFn={this.cancleFn}
          ></AntModal>
        )}
      </div>
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
  }
)(Financial)
