import React, { Component } from "react"
import { Button, Toast, Picker } from "antd-mobile"
import { util } from "common/util"
import { api } from "common/Config"
import * as y from "utils/methods"
import { cityTwoData } from "common/cityMock"
import ss from "./css/addBankCard.module.css"
import "./css/commonStyle.css"
import XiaArrow from "image/xia_arrow.png"
import backBlackIcon from "../../image/back_black_icon.png"

export default class AddBankCard extends Component {
  passRef = React.createRef()
  khyhRef = React.createRef()
  khdqRef = React.createRef()
  khzhRef = React.createRef()

  state = {
    userType: "", //1代理商 2合伙人
    value: "",
    mobileNo: "",
    idCard: "",
    bankAcctNo: "",
    bankAcctName: "",
    bankNo: "",
    bankCity: "",
    khBankList: [],
    cityLabel: "",
    bankLabel: "",
  }
  render() {
    // console.log(this.props, "--")
    const {
      userType,
      mobileNo,
      idCard,
      bankAcctNo,
      bankAcctName,
      bankNo,
      bankCity,
      khBankList,
    } = this.state
    return (
      <div className={`${ss.addBankCard}  ${"gray_input"}`}>
        <div className={"row_between title_text"}>
          <img
            src={backBlackIcon}
            alt=""
            className={"back_black_icon"}
            onClick={this.goBackF}
          />
          <div>添加银行卡</div>
          <div style={{ opacity: 0 }}>-</div>
        </div>

        <div className={ss.content_view}>
          <div className={ss.LineRow_view}>
            <div className={ss.left_title}>姓名</div>
            <input
              className={ss.addbankCard_Input}
              type="text"
              placeholder="请输入持卡人姓名"
              name="bankAcctName"
              value={bankAcctName}
              onChange={this.changeForm}
            />
          </div>

          <div className={ss.LineRow_view}>
            <div className={ss.left_title}>身份证号</div>
            <input
              className={ss.addbankCard_Input}
              type="text"
              placeholder="请输入身份证号码"
              name="idCard"
              value={idCard}
              onChange={this.changeForm}
            />
          </div>

          <div className={ss.LineRow_view}>
            <div className={ss.left_title}>手机号码</div>
            <input
              className={ss.addbankCard_Input}
              type="text"
              placeholder="请输入手机号码"
              name="mobileNo"
              value={mobileNo}
              onChange={this.changeForm}
            />
          </div>

          <div className={ss.LineRow_view}>
            <div className={ss.left_title}>卡号</div>
            <input
              className={ss.addbankCard_Input}
              type="text"
              placeholder="请输入银行卡卡号"
              name="bankAcctNo"
              value={bankAcctNo}
              onChange={this.changeForm}
            />
          </div>

          <div className={`${ss.LineRow_view} ${ss.posiNull}`}>
            <div className={ss.left_title}>开户银行</div>
            <Picker
              data={khBankList}
              value={bankNo}
              cols={1}
              onChange={(v) => {
                this.setState({ bankNo: v }, () =>
                  this.getPickerLabel(khBankList, v[0], "khyhRef")
                )
              }}
              onOk={(v) => {
                this.setState({ bankNo: v }, () =>
                  this.getPickerLabel(khBankList, v[0], "khyhRef")
                )
              }}
            >
              <input
                className={ss.addbankCard_Input}
                ref={this.khyhRef}
                readOnly
                placeholder="请选择开户银行"
              />
            </Picker>
            <img src={XiaArrow} className={ss.select_arrow} alt="" />
          </div>

          {userType == 1 && (
            <>
              <div className={`${ss.LineRow_view} ${ss.posiNull}`}>
                <div className={ss.left_title}>开户地区</div>
                <Picker
                  data={cityTwoData}
                  cols={2}
                  value={bankCity}
                  onChange={(v) => {
                    this.setState({ bankCity: v }, () =>
                      this.getPickerLabel(cityTwoData, v, "khdqRef")
                    )
                  }}
                  onOk={(v) => {
                    this.setState({ bankCity: v }, () => {
                      this.getPickerLabel(cityTwoData, v, "khdqRef")
                    })
                  }}
                >
                  <input
                    className={ss.addbankCard_Input}
                    ref={this.khdqRef}
                    readOnly
                    placeholder="请选择开户地区"
                  />
                </Picker>
                <img src={XiaArrow} className={ss.select_arrow} alt="" />
              </div>
            </>
          )}
        </div>

        <Button className={ss.addBank_btn} onClick={this.addSure}>
          确认
        </Button>
      </div>
    )
  }
  componentDidMount() {
    this.init()
  }

  init = () => {
    this.getUserType()
    this.getkhBankList()
  }

  getUserType = () => {
    let type = JSON.parse(sessionStorage.getItem("range")).type
    this.setState({
      userType: type,
    })
  }

  goBackF = () => {
    this.props.history.push({
      pathname: this.props.location.state
        ? this.props.location.state.page
        : "bankCard",
      state: {
        ...this.props.location.state,
      },
    })
  }

  getkhBankList = () => {
    util.getYangAxios(
      api.khBankList,
      {
        pagesize: 1000,
        search_data: {
          is_show: 1,
        },
      },
      async (res) => {
        // console.log("res", res)
        let arr = []
        if (res.list.length > 0) {
          arr = res.list.map((item) => {
            return {
              ...item,
              label: item.bank_name,
              value: item.bank_no,
            }
          })
        }

        this.setState({
          khBankList: arr,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  changeForm = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value,
    })
  }

  getPickerLabel = (arr, id, dom) => {
    console.log(id, "id")
    let label = ""
    if (dom === "khdqRef") {
      arr.forEach((item) => {
        if (item.value == id[0]) {
          label = item.label
          item.children.forEach((items) => {
            if (items.value == id[1]) {
              label += " - " + items.label
              this[dom].current.value = label

              this.setState(
                {
                  cityLabel: items.label,
                },
              )
            }
          })
        }
      })
    } else {
      arr.forEach((item) => {
        if (item.value == id) {
          this[dom].current.value = item.label
        }
      })
    }
  }

  addSure = async () => {
    await this.confirm()
  }

  // 不为空
  stoptoapi = (arr) => {
    const res = y.stopToApi(arr)
    return res
  }

  estlintArr = () => {
    const {
      userType,
      bankAcctName,
      mobileNo,
      idCard,
      bankAcctNo,
      bankNo,
      bankCity,
    } = this.state
    let arr = [
      { data: bankAcctName, title: "请输入持卡人姓名！" },
      { data: idCard, title: "请输入身份证号码！" },
      { data: mobileNo, title: "请输入手机号码！" },
      { data: bankAcctNo, title: "请输入银行卡卡号！" },
      { data: bankNo, title: "请选择开户银行！" },
    ]
    let typeOne = [
      { data: bankCity, title: "请选择开户地区！" },
    ]

    if (userType == 1) {
      arr = [...arr, ...typeOne]
    }

    return arr
  }

  confirm = async () => {
    let isGo = this.stoptoapi(this.estlintArr())
    if (!isGo) {
      return
    }
    await this.addBankCardApi()
  }

  addBankCardApi = () => {
    const {
      bankAcctName,
      mobileNo,
      idCard,
      bankAcctNo,
      bankNo,
    } = this.state

    let data = {
      bankAcctName, //姓名
      mobileNo, //手机号
      idCard, //身份证
      bankAcctNo, //卡号
      bankNo: bankNo[0], //开户银行 id
      bankName: this.khyhRef.current.value, //开户银行 name
    }
    console.log("参数data", data)

    /* let data = {
      bankAcctName: "马晓梅",
      bankName: "交通银行", //银行名称
      bankNo: "301", //开户银行 ID
      mobileNo: 18317830612,
      idCard: "342425197506150243",
      bankAcctNo: "6227008888855659000",
    }
    console.log("test-参数data", data) */

    // return
    util.postYangAxios(
      api.addBankToBag,
      data,
      async (res) => {
        Toast.info("添加成功", 1)
        setTimeout(() => {
          this.goBackF()
        }, 1100)
      },
      (err) => {
        console.error(err)
      },
    )
  }
}
