import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet } from "../../actions/XggsUserInformation"
import "./css/order.css"
import {
  Toast,
  ListView,
  Button,
  Modal,
  Picker,
  List,
} from "antd-mobile"
import HeaderView from "../../component/header/headerView"
import { Tabs, } from "antd"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import grayRightIcon from "../../image/gray_right_icon.png"
import ss from "./css/cash.module.css"

const { TabPane } = Tabs
class Order extends Component {
  constructor(props) {
    super(props)
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
    })
    this.state = {
      activeKey: "1",
      dataSource,
      datas: [],
      pageNo: 1,
      pageSize: 2,
      hasMore: true,
      refreshing: true,
      isLoading: true,
      dataArr: [],
      TQModal: false,
      selectData: [],
      currentV: {
        name: "",
        id: "",
      },
      sValue: "",
      totalPage: 1,
      sourcePage: null,
      lookDetails: "", //代表从主页学校数点击进入 只可看到客户菜单
      HeaderTitle: "",
      zhishutype: 1,
      mode: 1,
      year: null,
      month: null,
      headerMode: 1,
      headerYear: null,
      headerMonth: null,
      cashActiveKey: "a",
    }
  }

  componentDidMount() {
    // console.log("order接收到的参数", this.props.history.location.state)
    this.setState(
      {
        activeKey: this.props.history.location.state.key,
        sourcePage: this.props.history.location.state.sourcePage,
        lookDetails: this.props.history.location.state.lookDetails ?? "",
        HeaderTitle: this.props.history.location.state.HeaderTitle,
        zhishutype: this.props.location.state.zhishutype ?? 1,
        typeKey: this.props.location.state.typeKey ?? "",
        year: this.props.location.state.year ?? null,
        month: this.props.location.state.month ?? null,
        headerMode: this.props.location.state.headerMode ?? "",
        headerYear: this.props.location.state.headerYear ?? null,
        headerMonth: this.props.location.state.headerMonth ?? null,
      },
      () => {
        const typeKey = this.state.typeKey
        let mode = ""
        switch (typeKey) {
          case 1: {
            mode = 1
            break
          }
          case 2: {
            mode = 4
            break
          }
          case 3: {
            mode = 6
            break
          }
          case 4: {
            mode = 2
            break
          }
          case 5: {
            mode = 3
            break
          }
          case 6: {
            mode = 5
            break
          }
          default: {
            mode = 1
            break
          }
        }

        this.setState(
          {
            mode,
          },
          () => this.handleGetOrder()
        )
      }
    )
    // this.handleGetUserData()
    this.handleGetProList() // 获取产品列表
  }

  // 总接口
  handleGetOrder = () => {
    const { activeKey, cashActiveKey } = this.state
    // console.log("总接口里的activeKey", this.state.activeKey)

    if (this.state.pageNo > this.state.totalPage) {
      this.setState({
        refreshing: false,
        isLoading: false,
        hasMore: false,
      })
      // Toast.info("没有数据了~", 1)
      return false
    }
    console.log("第一次进来页面", activeKey)
    if (activeKey == "1") {
      this.handleGetMarketOrder() //销售
    } else if (activeKey == "2") {
      this.handleGetPurchaseOrder() //采购
    } else if (activeKey == "3") {
      this.handleGetInventoryOrder() //库存
    } else if (activeKey == "4") {
      this.handleGetExtractOrder() //学校码
    } else if (activeKey == "5") {
      this.handleGetSchoolOrder() //客户
    } else if (activeKey == "6") {
      //提现
      if (cashActiveKey == "a") {
        this.handleGetCashBag()
      } else {
        this.handleGetCashBank()
      }
    }
  }

  // 获取销售订单
  handleGetMarketOrder(ref = false) {
    let data = {
      currentPage: this.state.pageNo,
      mode: this.state.headerMode.mode,
    }

    // console.log(data,'参数data')

    if (data.mode == 6 && this.state.headerYear) {
      data.year = this.state.headerYear
    }
    if (data.mode == 6 && this.state.headerMonth) {
      data.month = this.state.headerMonth
    }

    util.getYangAxios(
      api.salelist,
      data,
      async (res) => {
        const dataList = res.list
        this.setState({
          totalPage: res.totalPage,
        })
        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 获取采购订单
  handleGetPurchaseOrder(ref = false) {
    let data = {
      currentPage: this.state.pageNo,
      mode: this.state.headerMode.mode,
    }
    if (data.mode == 6 && this.state.headerYear) {
      data.year = this.state.headerYear
    }
    if (data.mode == 6 && this.state.headerMonth) {
      data.month = this.state.headerMonth
    }

    util.getYangAxios(
      api.purchaseOrders,
      data,
      async (res) => {
        const dataList = res.list
        this.setState({
          totalPage: res.totalPage,
        })
        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 获取提取订单
  handleGetExtractOrder(ref = false) {
    if (ref) {
      this.setState({
        pageNo: 1,
        dataArr: [], // 保存新数据进state
      })
    }
    util.getYangAxios(
      api.extractOrder,
      {
        currentPage: this.state.pageNo,
      },
      async (res) => {
        const dataList = res.list
        this.setState({
          totalPage: res.totalPage,
        })
        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 获取学校客户列表
  handleGetSchoolOrder(ref = false) {
    let data = {
      currentPage: this.state.pageNo,
      zhishutype: this.state.zhishutype,
      mode: this.state.mode,
    }
    if (this.state.mode == 6 && this.state.year) {
      data.year = this.state.year
    }
    if (this.state.mode == 6 && this.state.month) {
      data.month = this.state.month
    }

    if (ref) {
      this.setState({
        pageNo: 1,
        dataArr: [], // 保存新数据进state
      })
    }

    util.getYangAxios(
      api.schoolStatistics,
      data,
      async (res) => {
        const dataList = res.data.school
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          dataArr: dataList,
          dataSource: this.state.dataSource.cloneWithRows(dataArr),
          refreshing: false,
          isLoading: false,
          hasMore: false,
          // dataArr: dataArr,
        })

        /* this.setState({
          totalPage: res.data.school_sum,
        })
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        }) */
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 获取库存订单
  handleGetInventoryOrder(ref = false) {
    util.getYangAxios(
      api.inventoryOrder,
      {
        currentPage: this.state.pageNo,
      },
      async (res) => {
        const dataList = res.list
        this.setState({
          totalPage: res.totalPage,
        })

        console.log("this.state.totalPage", this.state.totalPage)
        // if (this.state.pageNo == this.state.totalPage) {
        // 	this.setState({
        // 		refreshing: false,
        // 		isLoading: false,
        // 		hasMore: false
        // 	});
        // 	Toast.info("没有数据了~", 1);
        // 	return false;
        // }
        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  //获取转出至钱包的列表
  handleGetCashBag(ref = false) {
    let data = {
      currentPage: this.state.pageNo,
    }

    util.getYangAxios(
      api.cashTablelist,
      data,
      async (res) => {
        const dataList = res.list
        this.setState({
          totalPage: res.totalPage,
        })
        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  //获取转出至银行的列表
  handleGetCashBank(ref = false) {
    let data = {
      currentPage: this.state.pageNo,
    }
    util.getYangAxios(
      api.cashBanklist,
      data,
      async (res) => {
        const dataList = res.list
        this.setState({
          totalPage: res.totalPage,
        })
        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 获取产品名称列表
  handleGetProList() {
    util.getYangAxios(
      api.productionList,
      {
        search_data: {
          type: 1,
        },
      },
      async (res) => {
        const dataList = res.list.map((item) => {
          return {
            label: item.name,
            value: item.id,
          }
        })

        this.setState({
          selectData: dataList,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 滑动到底部时加载更多
  onEndReached = (event) => {
    // 加载中或没有数据了都不再加载
    if (this.state.isLoading || !this.state.hasMore) {
      return
    }
    this.setState(
      {
        isLoading: true,
        pageNo: this.state.pageNo + 1, // 加载下一页
      },
      () => {
        this.handleGetOrder()
      }
    )
  }

  // 支付
  handlePay = () => {
    this.props.history.push("payResult")
  }

  // 订单文字渲染
  handleOrderTextRender = (title, content, isLook = false) => {
    return (
      <div className={"row_align order_text_div"} style={{ width: "100%" }}>
        <div className={"row_align"}>
          <div className={"order_title_text"}>{title}</div>
          <div className={"order_content_text"}>{content}</div>
        </div>
        {isLook && (
          <div className={"order_look_text row_end"}>
            查看
            <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
          </div>
        )}
      </div>
    )
  }

  // 渲染订单状态
  handleStatusRender = (num) => {
    if (num == 0) {
      return "未激活"
    } else if (num == 10) {
      return "已激活"
    } else if (num == 20) {
      return "已过期"
    } else {
      return "未知"
    }
  }

  // 库存订单
  handleInventoryBox = (title, num) => {
    return (
      <div className={"inventory_order_box column_center"}>
        <div className={"inventory_order_num"}>{num}</div>
        <div className={"inventory_order_title"}>{title}</div>
      </div>
    )
  }

  // 跳转到订单详情
  handleSkipDetails = (obj, num) => {
    if (num == 1) {
      this.props.history.push({
        pathname: "/marketOrder",
        state: {
          order: obj,
        },
      })
    } else if (num == 2) {
      this.props.history.push({
        pathname: "/purchaseOrder",
        state: {
          order: obj,
        },
      })
    } else if (num == 6) {
      this.props.history.push({
        pathname: "/cashDetails",
        state: {
          order: obj.id,
        },
      })
    }
  }

  // 渲染消息
  handleMsgRender = (title, num) => {
    let row
    let { TQModal, selectData, cashActiveKey } = this.state
    const { type, province, city, area } = JSON.parse(
      sessionStorage.getItem("range")
    )
    const { userData } = this.props.UserDataReducer
    if (num == 1) {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            <div
              className={"order_card"}
              key={index}
              onClick={() => this.handleSkipDetails(item, num)}
            >
              {this.handleOrderTextRender("订单号：", item.order_sn, true)}
              {this.handleOrderTextRender("产品名称：", item.product_name)}
              {this.handleOrderTextRender("产品数量：", item.number)}
              {this.handleOrderTextRender("产品金额：", item.total_price)}
              {this.handleOrderTextRender("订货方：", item.agent_name)}
              {this.handleOrderTextRender("发货方：", item.sale_agent_name)}
              {this.handleOrderTextRender("订单时间：", item.created_at)}
            </div>
            <div className={"order_card_line"} />
          </>
        )
      }
    } else if (num == 2) {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            <div
              className={"order_card"}
              key={index}
              onClick={() => this.handleSkipDetails(item, num)}
            >
              {this.handleOrderTextRender("订单号：", item.order_sn, true)}
              {this.handleOrderTextRender("产品名称：", item.product_name)}
              {this.handleOrderTextRender("产品数量：", item.number)}
              {this.handleOrderTextRender("产品金额：", item.total_price)}
              {this.handleOrderTextRender("订货方：", item.agent_name)}
              {this.handleOrderTextRender("订单状态：", item.statu_name)}
              {this.handleOrderTextRender("订单时间：", item.created_at)}
            </div>
            <div className={"order_card_line"} />
          </>
        )
      }
    } else if (num == 3) {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            <div className={"order_card"} key={index}>
              <div className={"inventory_order_title"}>{item.name}</div>
              <div className={"inventory_order_div"}>
                {this.handleInventoryBox("产品价格", item.price)}
                {this.handleInventoryBox("产品属性", item.port)}
                {this.handleInventoryBox("总库存", item.kucun)}
                {this.handleInventoryBox("出库", item.delivery_number)}
                {this.handleInventoryBox("冻结库存", item.freeze_number)}
                {this.handleInventoryBox("未激活", item.noactivate_number)}
              </div>
            </div>
            <div className={"order_card_line"} />
          </>
        )
      }
    } else if (num == 4) {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            <div className={"order_card"} key={index}>
              {this.handleOrderTextRender("产品名称：", item.product_name)}
              {this.handleOrderTextRender("学校码：", item.cdk)}
              {this.handleOrderTextRender(
                "状态：",
                this.handleStatusRender(item.status)
              )}
              {this.handleOrderTextRender("学校名称：", item.school_name)}
              {this.handleOrderTextRender("创建日期：", item.created_at)}
            </div>
            <div className={"order_card_line"} />
          </>
        )
      }
    } else if (num == 5) {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            <div className={"order_card"} key={index}>
              {this.handleOrderTextRender("学校编码：", item.id)}
              {this.handleOrderTextRender("学校名称：", item.name)}
              {this.handleOrderTextRender(
                "学段：",
                item.type == 1 ? "幼儿园" : "小学"
              )}
              {this.handleOrderTextRender("加入时间：", item.created_at)}
            </div>
            <div className={"order_card_line"} />
          </>
        )
      }
    } else if (num == 6) {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            {cashActiveKey == "a" && (
              <>
                <div className={"order_card"} key={index}>
                  <div>
                    {/* 
                    10 审核中 
                    20 审核失败
                    30 待提现
                    40 成功
                     */}
                    {item.status == 40 ? (
                      <span className={ss.red_font}>提现到账成功</span>
                    ) : item.status == 20 ? (
                      <span className={ss.green_font}>提现到账失败</span>
                    ) : (
                      <span className={ss.yellow_font}>提现审核中</span>
                    )}
                  </div>
                  <div className={"cashTop_view"}>
                    <div className={"cash_title"}>提现</div>
                    <div className={"cash_number"}>¥ {item.money}</div>
                  </div>
                  <div className={"cashMiddle_view"}>
                    {this.handleOrderTextRender("提现至", item.id > 802 ? "银行卡" : "银联钱包")}
                    {this.handleOrderTextRender("提现单号", item.order_sn)}
                    {this.handleOrderTextRender(
                      "服务费",
                      item.all_service_money
                    )}
                    {item.tax_money &&
                      Number(item.tax_money) > 0 &&
                      this.handleOrderTextRender("所得税", item.tax_money)}
                    {this.handleOrderTextRender("实际到账", item.real_money)}
                    {this.handleOrderTextRender("提现时间", item.created_at)}
                  </div>
                  <div
                    className={"cashBottom_view row_between"}
                    onClick={() => this.handleSkipDetails(item, num)}
                  >
                    <span>查看</span>
                    <img
                      src={grayRightIcon}
                      alt=""
                      className={"gray_right_icon"}
                    />
                  </div>
                </div>
                <div className={"order_card_line"} />
              </>
            )}

            {cashActiveKey == "b" && (
              <>
                <div className={"order_card"} key={index}>
                  <div>
                    <span className={ss.red_font}>转出到账成功</span>
                  </div>
                  <div className={"cashTop_view"}>
                    <div className={"cash_title"}>提现</div>
                    <div className={"cash_number"}>¥ {item.money}</div>
                  </div>
                  <div className={"bankMiddle_view"}>
                    {this.handleOrderTextRender("转出至", item.bankAcc_name)}
                    {this.handleOrderTextRender("转出卡号", item.bankAcct_no)}
                    {this.handleOrderTextRender(
                      "转出单号",
                      item.exterior_trade_no
                    )}
                    {this.handleOrderTextRender("转出时间", item.created_at)}
                  </div>
                  {/* <div
                    className={"cashBottom_view row_between"}
                    onClick={() => this.handleSkipDetails(item, num)}
                  >
                    <span>查看</span>
                    <img
                      src={grayRightIcon}
                      alt=""
                      className={"gray_right_icon"}
                    />
                  </div> */}
                </div>
                <div className={"order_card_line"} />
              </>
            )}
          </>
        )
      }
    }
    return (
      <div>
        <div className={"order_top_line row_align"}>{title}</div>
        {/* 提取 */}
        {num == 4 && (
          <div className={"btn_Box"}>
            <Button
              size="small"
              className={"btn_tq"}
              type="primary"
              onClick={this.clickTQ}
            >
              提取
            </Button>
          </div>
        )}

        {num == 6 && (
          <Tabs
            activeKey={cashActiveKey}
            onChange={this.cashChangeTabs}
            tabBarStyle={{
              marginBottom: 0,
              color: "#34373D",
              paddingLeft: "16px",
              background: "#F6F7FB",
            }}
          >
            <TabPane tab="提现" key="a"></TabPane>
            {userData.role != 4 && userData.role != 6 && (
              <TabPane tab="银联钱包" key="b"></TabPane>
            )}
          </Tabs>
        )}

        <Modal
          visible={TQModal}
          transparent
          maskClosable
          className="ModalCss"
          wrapClassName="warpCss"
        >
          <div className={"Modal_Box"}>
            <div className={"Modal_content"}>
              <List
                style={{ backgroundColor: "white" }}
                className="picker-list"
                styles={{ Line: { borderBottomWidth: 0 } }}
              >
                <Picker
                  data={selectData}
                  cols={1}
                  value={this.state.sValue}
                  className="forss"
                  onChange={this.handleChange}
                  onOk={this.handleChange}
                >
                  <List.Item
                    arrow="horizontal"
                    styles={{ Line: { borderBottomWidth: 0 } }}
                  >
                    产品名称：
                  </List.Item>
                </Picker>
              </List>

              {province && type == 1 && (
                <span className={"tiquTip"}>
                  *提取码只能在{province ? province : ""}
                  {city ? city : ""}
                  {area ? area : ""}范围内使用
                </span>
              )}
            </div>

            <footer className={"Modal_footer"}>
              <div className={"btns_common sure_btn"} onClick={this.TQSure}>
                确定
              </div>
              <div
                className={"btns_common cancal_btn"}
                onClick={() => {
                  this.setState({
                    TQModal: false,
                  })
                }}
              >
                取消
              </div>
            </footer>
          </div>
        </Modal>

        <ListView
          contentContainerStyle={{ backgroundColor: "#F6F7FB" }}
          ref={(el) => (this.lv = el)}
          dataSource={this.state.dataSource}
          renderFooter={() => (
            <div className="footer">
              {this.state.isLoading ? "加载中..." : "暂无更多数据"}
            </div>
          )}
          renderRow={row}
          useBodyScroll
          onEndReachedThreshold={10}
          onEndReached={this.onEndReached}
          pageSize={10}
        />
      </div>
    )
  }

  // TAB切换
  changeTabs = (key) => {
    console.log("key", key)
    this.setState(
      {
        activeKey: key,
        datas: [],
        pageNo: 1,
        pageSize: 2,
        hasMore: true,
        refreshing: true,
        isLoading: true,
        dataArr: [],
        dataSource: new ListView.DataSource({
          rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
        }),
        totalPage: 1,
        sValue: "",
      },
      () => {
        this.handleGetOrder()
      }
    )
  }

  cashChangeTabs = (key) => {
    this.setState(
      {
        cashActiveKey: key,
        datas: [],
        pageNo: 1,
        pageSize: 2,
        hasMore: true,
        refreshing: true,
        isLoading: true,
        dataArr: [],
        dataSource: new ListView.DataSource({
          rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
        }),
        totalPage: 1,
        sValue: "",
      },
      () => {
        this.handleGetOrder()
      }
    )
  }

  //提取
  clickTQ = () => {
    this.setState({
      TQModal: true,
      sValue: "",
    })
  }
  TQSure = () => {
    const { sValue } = this.state
    if (sValue == "") {
      Toast.info("请选择")
      return
    }

    this.requestApiSure()
  }
  requestApiSure = () => {
    const { id, name } = this.state.currentV
    util.postYangAxios(
      api.createSure,
      {
        product_id: id,
        product_name: name,
      },
      async (res) => {
        this.setState({
          TQModal: false,
        })
        this.handleGetExtractOrder(true)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  handleChange = (value) => {
    this.setState=({sValue :value})
    let obj = {}
    this.state.selectData.forEach((item) => {
      if (item.value == value[0]) {
        obj.name = item.label
        obj.id = item.value
      }
    })
    this.setState({
      currentV: {
        ...obj,
      },
    })
  }

  // 获取用户信息
  handleGetUserData = () => {
    util.postYangAxios(
      api.info,
      {},
      async (res) => {
        this.props.userDataSet(res)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  render() {
    const { userData } = this.props.UserDataReducer
    const { activeKey, sourcePage, lookDetails, HeaderTitle, zhishutype } =
      this.state

    console.log("this.state.sourcePage", this.state.sourcePage)
    console.log("sourcePage", sourcePage)
    return (
      <div
        className={"pay_result_view"}
        style={{ height: window.innerHeight + "px" }}
      >
        <HeaderView title={HeaderTitle} />
        <Tabs
          activeKey={activeKey}
          onChange={this.changeTabs}
          tabBarStyle={{
            marginBottom: 0,
            color: "#34373D",
            // paddingTop: "13px",
            padding: "13px 0px 0px 30px",
          }}
          centered
          tabBarGutter={20}
        >
          {lookDetails != "lookDetails" ? (
            <TabPane tab="销售" key="1">
              {this.handleMsgRender("销售订单", 1)}
            </TabPane>
          ) : (
            ""
          )}
          {lookDetails != "lookDetails" ? (
            <TabPane tab="采购" key="2">
              {this.handleMsgRender("直属订单", 2)}
            </TabPane>
          ) : (
            ""
          )}

          {sourcePage &&
            sourcePage == "business" &&
            userData.role != 5 &&
            userData.role != 7 && (
              <TabPane tab="客户" key="5">
                {this.handleMsgRender(
                  zhishutype == 2
                    ? "全部客户"
                    : zhishutype == 3
                    ? "所属客户"
                    : "直属客户",
                  5
                )}
              </TabPane>
            )}

          {sourcePage &&
            sourcePage == "order" &&
            userData.role != 4 &&
            userData.role != 6 &&
            userData.role != 5 &&
            userData.role != 7 && (
              <TabPane tab="库存" key="3">
                {this.handleMsgRender("库存管理", 3)}
              </TabPane>
            )}
          {sourcePage &&
            sourcePage == "order" &&
            userData.role != 4 &&
            userData.role != 6 &&
            userData.role != 5 &&
            userData.role != 7 && (
              <TabPane tab="学校码" key="4">
                {this.handleMsgRender("提取信息", 4)}
              </TabPane>
            )}
          {/* 提现注释 */}
          {lookDetails != "lookDetails" ? (
            <TabPane tab="提现" key="6">
              {this.handleMsgRender("提现信息", 6)}
            </TabPane>
          ) : (
            ""
          )}
        </Tabs>
      </div>
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
  }
)(Order)
