import "./App.css"
// 你的高阶组件
import FrontendAuth from "./FrontendAuth"
// 你的路由表
import routes from "./routes"
import { BrowserRouter, Switch } from "react-router-dom"
import React, { Suspense} from "react"

import { Provider } from "react-redux"
import configureStore from "./store/ConfigureStore"
const store = configureStore()

const Loading = <></>
function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={Loading}>
          <Switch>
            <FrontendAuth routerConfig={routes} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </Provider>
  )
}

export default App
