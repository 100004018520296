import React, { Component } from 'react'
import './css/successJoin.css'
import pay_select_blue_icon from "../../image/successImg2.png";
import HeaderView from "../../component/header/headerView";
export default class SusscessJoin extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }
    goHome=()=>{
        this.props.history.replace("/")
    }
  
    render() {
        return (
            <div className={"pay_result_view"} style={{ height: window.innerHeight + 'px' }}>
                <HeaderView
                    title={'合伙人进群'}
                />
                <div className={"pay_result_box column_align"}>
                    <div  className={'success_class_top_content row_center'}>
                        <img src={pay_select_blue_icon} alt="" className={'success_class_top_content_img'} />
                        <div className={'success_class_top_content_tag'}>恭喜您购买成功，请尽快加入合伙人内部群</div>
                    </div>
                    <div className={'success_class_actvar_content'}>
                        <img src={'https://cdn.ellll.com/1683599246856fkys183972592612'} alt="" className={'success_class_actvar'} />
                        <div className={'success_class_name'}>习惯公社合伙人内部群</div>
                    </div>
                    <div className={'success_class_actvar_content'}>
                        <img src={'https://cdn.ellll.com/1683696798145fkys317589826259'} alt="" className={'success_class_wechat'} />
                        <div className={'success_class_top_content_tag2'}>长按二维码扫码进群</div>
                        <div className={'success_class_top_content_tag3'}>或</div>
                        <div className={'success_class_top_content_tag2'}>截图本页面，使用微信扫一扫进群</div>
                    </div>
                    <div className={'add_group_btn'}  onClick={this.goHome}> 完成</div>
                </div>
            </div>
        )
    }
}

