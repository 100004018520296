import React, { Component } from "react"
import { Image } from "antd"
import newYear from "../../image/newYear.png"
import newYear1 from "../../image/newYear1.png"
import newYear2 from "../../image/newYear2.png"
import newYear3 from "../../image/newYear3.png"

export default class NewYear extends Component {
  render() {
    return (
      <div>
        <Image
          preview={false}
          src={newYear}
          placeholder={<Image preview={false} src={newYear} />}
          onClick={() => {
            this.props.history.goBack()
          }}
        />
        <Image
          preview={false}
          src={newYear1}
          placeholder={<Image preview={false} src={newYear1} />}
        />
        <Image
          preview={false}
          src={newYear2}
          placeholder={<Image preview={false} src={newYear2} />}
        />
        <Image
          preview={false}
          src={newYear3}
          placeholder={<Image preview={false} src={newYear3} />}
        />
      </div>
    )
  }
}
