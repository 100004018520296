import React, { Component } from "react"
import ss from "./css/poster.module.css"
import logoImg from "../../image/logo_img.png"
import { Toast } from "antd-mobile"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import Vconsole from "common/Vconsole"

/* 统一登录接口 */
export default class posterLogin extends Component {
  state = {
    nameValue: "",
    phoneValue: "",
    codeValue: "",
    codeNum: 60,
    codeType: 0,
  }
  render() {
    const { codeType, codeNum, phoneValue, codeValue, nameValue } = this.state
    return (
      <div className={ss.page_view}>
        <div className={ss.title_text}>登录</div>
        <img src={logoImg} alt="" className={ss.logo_img} />

        <div className={"input_view"}>
          <div
            className={"input_box"}
            onClick={() => {
              this.phoneInputn.focus()
            }}
          >
            <input
              type="text"
              value={nameValue}
              onChange={this.onNameChange}
              className={"input_text"}
              placeholder={"请输入姓名"}
              ref={(c) => {
                this.phoneInputn = c
              }}
            />
          </div>

          <div
            className={"input_box"}
            onClick={() => {
              this.phoneInputs.focus()
            }}
          >
            <input
              type="text"
              value={phoneValue}
              onChange={this.onPhoneChange}
              className={"input_text"}
              placeholder={"请输入手机号"}
              ref={(c) => {
                this.phoneInputs = c
              }}
            />
          </div>
          <div
            className={"row_between input_box input_box_last"}
            onClick={() => {
              this.codeInputs.focus()
            }}
          >
            <input
              type="text"
              value={codeValue}
              onChange={this.onCodeChange}
              className={"input_text"}
              placeholder={"请输入验证码"}
              ref={(c) => {
                this.codeInputs = c
              }}
            />
            {codeType === 0 && (
              <div className={"code_text"} onClick={this.handlePhoneLoginNote}>
                获取
              </div>
            )}

            {codeType === 1 && <div className={"code_text"}> {codeNum}秒</div>}
            {codeType === 2 && (
              <div className={"code_text"} onClick={this.handlePhoneLoginNote}>
                重新获取
              </div>
            )}
          </div>
        </div>

        <div className={"row_center login_btn_big_view"}>
          <div
            className={"row_center login_btn_view"}
            onClick={this.handleLogin}
          >
            登录
          </div>
        </div>
      </div>
    )
  }

  componentDidMount() {
    new Vconsole()
    console.log(window.location.href, "login页面的地址")

    console.log(this.props.location.state.obj, "登陆页面-obj")
  }

  onNameChange = (event) => {
    this.setState({
      nameValue: event.target.value,
    })
  }

  onPhoneChange = (event) => {
    this.setState({
      phoneValue: event.target.value,
    })
  }

  onCodeChange = (event) => {
    this.setState({
      codeValue: event.target.value,
    })
  }

  // 获取手机号登陆验证短信
  handlePhoneLoginNote = (e) => {
    e.stopPropagation()
    const { phoneValue } = this.state
    if (phoneValue.length < 11) {
      Toast.info("手机号格式错误", 1)
      return null
    }
    if (!/^1[0-9]{10}$/.test(phoneValue)) {
      Toast.info("手机号格式错误", 1)
      return null
    }
    util.getYangAxios(
      api.posterCode,
      {
        phone: phoneValue,
      },
      (res) => {
        console.log(res)
        this.setState(
          {
            codeType: 1,
            codeNum: 60,
          },
          () => {
            const add = setInterval(() => {
              const { codeNum } = this.state
              if (codeNum == 1) {
                this.setState({
                  codeType: 2,
                })
                clearInterval(add)
              }
              this.setState({
                codeNum: codeNum - 1,
              })
            }, 1000)
          }
        )
      },
      (err) => {
        console.log(err)
        // Toast.fail('服务器异常,请稍后重试')
      }
    )
  }

  // 登陆
  handleLogin = () => {
    const { phoneValue, codeValue, nameValue } = this.state
    if (nameValue == "") {
      Toast.info("请输入姓名")
      return
    }
    if (phoneValue == "") {
      Toast.info("请输入手机号")
      return
    }
    if (codeValue == "") {
      Toast.info("请输入验证码")
      return
    }
    util.postYangAxios(
      api.postrLogin,
      {
        phone: phoneValue,
        code: codeValue,
      },
      async (res) => {
        await window.localStorage.setItem("posterToken", res.token)
        await window.localStorage.setItem("login_token", res.token)
        await window.localStorage.setItem("posterName", nameValue)
        this.songLogin(res.token)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  songLogin = (token) => {
    const { nameValue } = this.state
    const obj = this.props.location.state.obj
    let parmas = {
      token,
      name: nameValue,
    }
    if (obj.agent_id) {
      parmas.agent_id = obj.agent_id
    }
    if (obj.ambassador_general_id) {
      parmas.ambassador_general_id = obj.ambassador_general_id
    }

    util.postYangAxios(
      api.songBinding,
      parmas,
      async (res) => {
        console.log("res-宋登陆", res)
        this.props.history.push({
          pathname: "/poster",
          state: {
            obj,
          },
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }
}
