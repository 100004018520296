import React, { Component } from "react"
import HeaderCloseView from "component/header/headerCloseView"
import SuccessImg from "image/pay_select_blue_icon.png"
import ss from "./css/cashMoney.module.css"

export default class cashSuccess extends Component {
  render() {
    return (
      <div className={`${ss.cashMoney_success} ${ss.cashSuccess}`}>
        <HeaderCloseView page="/"></HeaderCloseView>

        <div className={ss.tip_view}>
          <img className={ss.img_bottom} src={SuccessImg} alt="" />
          <p>
            您已成功提交了提现申请
            <br />
            请等待审核结果
          </p>
        </div>
      </div>
    )
  }
}
