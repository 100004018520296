import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
class Download extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wxShow: false,
        };
    }

    componentDidMount(){
        // console.log(ua)
        let uc = window.navigator.userAgent.toLowerCase();
        if(uc.match(/MicroMessenger/i) == 'micromessenger'){      //判断是否是微信环境
            this.setState({
                wxShow: true,
            })
        }
    }


    // 下载按钮
    handleSkipDown=()=>{
        let ua = navigator.userAgent
        if (!!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
            console.log('苹果')
            window.location.href ="https://apps.apple.com/cn/app/%E4%B9%A0%E6%83%AF%E5%85%AC%E7%A4%BE/id1481559141";
        } else if (ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1) {
            console.log('安卓')
            window.location.href ="https://sj.qq.com/myapp/detail.htm?apkName=com.xz.xggs";
        }
    }


    render() {
        const { wxShow } = this.state;
        return (
            <div className={'down_div'} style={{overflow: wxShow?'hidden':'scroll'}}>
                <img src={'https://cdn.ellll.com/capphabit/background.jpg'} className="top_logo"  onClick={this.handleSkipDown} alt=""/>
                {
                    wxShow&& <img src={'https://cdn.ellll.com/capphabit/open_browser_img.png'} className="open_browser_img" alt="" />
                }
            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(Download)
