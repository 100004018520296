import React, { Component } from "react"
import ss from "./css/poster.module.css"
import "./css/poster.css"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import QRCode from "qrcode.react"
import html2canvas from "html2canvas"

/* 海报 */
export default class shareBill extends Component {
  state = {
    info: null,
    imgurl: null,
    qrcodeUrl: null,
  }
  render() {
    const { info, imgurl, qrcodeUrl } = this.state
    return (
      <div className={ss.shareBill_view}>
        <div className={ss.top_view} onClick={this.goBack}>
          返回
        </div>

        {info && info.poster_path && imgurl == null && (
          <div
            id="proMain"
            className={ss.middle_view}
            style={{
              background: `url( "${"https://cdn.ellll.com/"}${
                info.poster_path
              }")`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
          >
            {qrcodeUrl && (
              <QRCode
                className={ss.qrcode_view}
                value={qrcodeUrl} //value参数为生成二维码的链接
                size={150} //二维码的宽高尺寸
                fgColor="#000000" //二维码的颜色
              />
            )}
          </div>
        )}

        {imgurl && <img src={imgurl} className={ss.imhUrl_view} alt=""></img>}
      </div>
    )
  }

  componentDidMount() {
    console.log("info--海报页面", this.props.location.state.info)
    this.setState(
      {
        info: this.props.location.state.info,
      },
      () => {
        this.getShareQrCodeUrl()
      }
    )
  }

  getShareQrCodeUrl = async () => {
    console.log("this.state.info", this.state.info)
    util.getYangAxios(
      api.getShareQrCodeUrl + this.state.info.id,
      {
        name: localStorage.getItem("posterName"),
        token: localStorage.getItem("posterToken"),
      },
      async (res) => {
        console.log("res--获取 qrcode的路径", res)
        if (res) {
          this.setState(
            {
              // qrcodeUrl: "http://" + res.url,
              qrcodeUrl:
                "http://192.168.1.100:3000/poster?" + res.url.split("?")[1],
            },
            () => {
              this.savefn()
            }
          )
        }
      },
      (err) => {
        console.log(err)
      }
    )
  }

  savefn = () => {
    let that = this
    //#proMain:要截图的DOM元素
    //useCORS:true:解决跨域问题
    html2canvas(document.querySelector("#proMain"), { useCORS: true }).then(
      function (canvas) {
        //获取年月日作为文件名
        var timers = new Date()
        var fullYear = timers.getFullYear()
        var month = timers.getMonth() + 1
        var date = timers.getDate()
        var randoms = Math.random() + ""
        //年月日加上随机数
        var numberFileName = fullYear + "" + month + date + randoms.slice(3, 10)
        var imgData = canvas.toDataURL("image/png")
        //保存图片
        var saveFile = function (data, filename) {
          var save_link = document.createElementNS(
            "http://www.w3.org/1999/xhtml",
            "a"
          )
          save_link.href = data
          save_link.download = filename

          var event = document.createEvent("MouseEvents")
          event.initMouseEvent(
            "click",
            true,
            false,
            window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
          )
          save_link.dispatchEvent(event)
        }
        //最终文件名+文件格式
        // var filename = numberFileName + ".png"
        // 下载图片(保存图片)
        // console.log("imgData", imgData)
        that.setState({
          imgurl: imgData,
        })
        // saveFile(imgData, filename)
      }
    )
  }

  goBack = () => {
    this.props.history.goBack()
  }
}
