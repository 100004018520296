import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet } from "../../actions/XggsUserInformation"
import "./css/member.css"
import { Toast, ListView } from "antd-mobile"
import HeaderView from "../../component/header/headerView"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import grayRightIcon from "../../image/gray_right_icon.png"
import "./css/member.css"
class MemberDetails extends Component {
  constructor(props) {
    super(props)
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
    })
    this.state = {
      activeKey: "1",
      dataSource,
      datas: [],
      pageNo: 1,
      pageSize: 2,
      hasMore: true,
      refreshing: true,
      isLoading: true,
      dataArr: [],
      totalPage: 1,
      order: "",
    }
  }

  componentDidMount() {
    console.log(this.props.history.location.state)
    this.setState(
      {
        order: this.props.history.location.state.order.id,
      },
      () => {
        this.handleGetOrder()
      }
    )
  }

  // 总接口
  handleGetOrder = () => {
    const { activeKey } = this.state

    if (this.state.pageNo > this.state.totalPage) {
      this.setState({
        refreshing: false,
        isLoading: false,
        hasMore: false,
      })
      Toast.info("没有数据了~", 1)
      return false
    }

    if (activeKey == "1") {
      this.handleGetPurchaseOrder()
    }
  }

  // 获取用户列表
  handleGetPurchaseOrder(ref = false) {
    util.getYangAxios(
      api.memberDeatils,
      {
        search_data: {
          general_id: this.state.order,
        },
      },
      async (res) => {
        const dataList = res.list
        this.setState({
          totalPage: res.totalPage,
        })

        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 滑动到底部时加载更多
  onEndReached = (event) => {
    // 加载中或没有数据了都不再加载
    if (this.state.isLoading || !this.state.hasMore) {
      return
    }
    this.setState(
      {
        isLoading: true,
        pageNo: this.state.pageNo + 1, // 加载下一页
      },
      () => {
        this.handleGetOrder()
      }
    )
  }

  // 订单文字渲染
  handleOrderTextRender = (title, content, isLook = false) => {
    return (
      <div className={"row_align order_text_div"} style={{ width: "100%" }}>
        <div className={"row_align"}>
          <div className={"order_title_text"}>{title}</div>
          <div className={"order_content_text"}>{content}</div>
        </div>
        {isLook && (
          <div className={"order_look_text row_end"}>
            查看
            <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
          </div>
        )}
      </div>
    )
  }

  // 跳转到订单详情
  handleSkipDetails = (obj, num) => {
    console.log(obj)
    this.props.history.push({
      pathname: "/memberDetails",
      state: {
        order: obj,
      },
    })
  }

  // 渲染消息
  handleMsgRender = (title, num) => {
    let row
    if (num == 1) {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            <div
              className={"order_card"}
              key={index}
              onClick={() => this.handleSkipDetails(item, num)}
            >
              {this.handleOrderTextRender("ID：", item.id)}
              {this.handleOrderTextRender(
                "姓名：",
                item.child_name ? item.child_name : "无"
              )}
              {this.handleOrderTextRender("创建时间：", item.created_at)}
            </div>
            <div className={"order_card_line"} />
          </>
        )
      }
    }
    return (
      <div>
        <div className={"order_top_line row_align"}>{title}</div>
        <ListView
          contentContainerStyle={{ backgroundColor: "#F6F7FB" }}
          ref={(el) => (this.lv = el)}
          dataSource={this.state.dataSource}
          renderFooter={() => (
            <div className="footer">
              {this.state.isLoading ? "加载中..." : "暂无更多数据"}
            </div>
          )}
          renderRow={row}
          useBodyScroll
          onEndReachedThreshold={10}
          onEndReached={this.onEndReached}
          pageSize={10}
        />
      </div>
    )
  }

  // TAB切换
  changeTabs = (key) => {
    console.log(key)
    this.setState(
      {
        activeKey: key,
        datas: [],
        pageNo: 1,
        pageSize: 2,
        hasMore: true,
        refreshing: true,
        isLoading: true,
        dataArr: [],
        dataSource: new ListView.DataSource({
          rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
        }),
        totalPage: 1,
      },
      () => {
        this.handleGetOrder()
      }
    )
  }

  render() {
    return (
      <div
        className={"pay_result_view"}
        style={{ height: window.innerHeight + "px" }}
      >
        <HeaderView title={"会员"} />
        {/* <Tabs
          activeKey={activeKey}
          onChange={this.changeTabs}
          tabBarStyle={{
            marginBottom: 0,
            color: "#34373D",
            paddingTop: "13px",
            paddingLeft: "40px",
          }}
          centered
          tabBarGutter={40}
        >
          <TabPane tab="会员" key="1">
            {this.handleMsgRender("购买记录", 1)}
          </TabPane>
        </Tabs> */}
        {this.handleMsgRender("购买记录", 1)}
      </div>
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
  }
)(MemberDetails)
