
import React, { Component } from "react"
import ss from "./css/affiliation.module.css"
import backBlackIcon from "../../image/back_black_icon.png"
import { isNull } from "utils/isNull"
import CustomerCount from "./count"
import Memberx from "page/member/memberx"
class Affiliation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            actived:1
        }
    }
    componentDidMount() {
        let selectedActived = sessionStorage.getItem("selectedActived")
        if (!isNull(selectedActived)) {
          this.setState({
            actived:parseInt(selectedActived),
          })
        }
      }
    goBackF = () => {
        let page = this.props.page
        if (!isNull(page)) {
          // console.log("有page")
          this.props.history.push(page)
        } else {
          // console.log("无page")
          this.props.history.goBack()
        }
      }
      handNavs=(type)=>{
         this.setState({
            actived:type
         })
      }
    render() {
        const {actived}=this.state
        return (
            <div className={ss.affiliation_container} >
                <div className={ss.affiliation_nav_container} style={{borderBottom:actived===2?'1px solid #DADADA':0}}>
                    {/* <img
                        src={backBlackIcon}
                        alt=""
                        className={ss.back_black_icon}
                        onClick={this.goBackF}
                    /> */}
                      <div onClick={()=>this.handNavs(1)} className={[`${ss.affiliation_nav}`,`${actived===1?ss.affiliation_nav_active:''}`].join(' ')}>客户统计</div>
                      <div onClick={()=>this.handNavs(2)} className={[`${ss.affiliation_nav}`,`${actived===2?ss.affiliation_nav_active:''}`].join(' ')}>客户关系</div>
                </div>
                {actived===1&&<CustomerCount history={this.props.history}></CustomerCount>}
                {actived===2&&<Memberx history={this.props.history}></Memberx>}
                
            </div>
        )
    }
}

export default Affiliation