import React, { Component } from 'react'
import {connect} from 'react-redux'
import {
    userDataSet,
} from '../../actions/XggsUserInformation'
import './css/marketOrder.css'
import HeaderView from "../../component/header/headerView";
import order_default_img from "../../image/order_default_img.png";
class MarketOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            order: null,
        };
    }

    componentDidMount() {
        console.log(this.props.history.location.state);
        this.setState({
            order: this.props.history.location.state.order
        })
    }

    // 订单卡片渲染
    handleOrderCardRender=(title,content)=>{
        return(
            <div className={'order_content_card row_align'}>
                <div className={'order_title_text'}>
                    {title}
                </div>
                <div className={'order_content_text'}>
                    {content}
                </div>
            </div>
        )
    }

    render() {
        const { order }=this.state
        return (
            <div className={"pay_result_view"} style={{height: window.innerHeight + 'px',background: '#F6F7FB'}}>
                <HeaderView
                    title={'订单详情'}
                />
                {
                    order&& <>
                        <div className={'order_header_card row_align'}>
                            <img src={order_default_img} alt="" className={'order_default_img'}/>
                            <div>
                                <div className={'order_num_text'}>订单号：{order.order_sn}</div>
                                <div className={'order_date_text'}>{order.created_at}</div>
                            </div>
                        </div>
                        {this.handleOrderCardRender('产品名称：',order.product_name)}
                        {this.handleOrderCardRender('产品数量：',order.number)}
                        {this.handleOrderCardRender('产品金额：',order.unit_price)}
                        {this.handleOrderCardRender('折扣率：',order.discount+'%')}
                        {this.handleOrderCardRender('订单总价：',"¥"+order.total_price)}
                        {this.handleOrderCardRender('实付金额：',"¥"+order.actual_price)}
                    </>
                }


            </div>
        )
    }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect((state) => {
    const {UserDataReducer} = state
    return {
        UserDataReducer,
    }
}, {
    userDataSet
})(MarketOrder)
