
import React, { Component } from "react"
import CommonTitle  from "./CommonTitle"
import ss from "./css/recentActive.module.css"
import ambassador_company_img from "../../../image/ambassador_company_img.png"
const list=[
    {title:'如何通过阅读帮助孩子培养学习习惯？',time:"2023-03-18 18:00"},
    {title:'如何通过阅读帮助孩子培养学习习惯？',time:"2023-03-18 18:00"},
]
 class RecentActive extends Component {
    constructor(props) {
        super(props)
        this.state = { }
    }
    render() {
        return (
            <div className={ss.recent_active_container}>
                <CommonTitle showMoreBtn={true}>最新直播</CommonTitle>
                <div className={ss.recent_active_main}>
                    {list.map((item,index)=>{
                        return <div className={ss.recent_item_info} key={index}>
                            <img src={ambassador_company_img} alt="" className={ss.recent_active_img} />
                            <div className={ss.recent_active_title}>{item.title}</div>
                            <div className={ss.recent_time_wrap}>
                                <span className={ss.recent_time_tag}>直播时间</span>
                                <span className={ss.recent_time}>{item.time}</span>
                            </div>
                        </div>
                    })}
                </div>
                <CommonTitle>最近活动</CommonTitle>
                <div className={ss.recent_active_main2}>
                    {list.map((item,index)=>{
                        return <div className={ss.recent_item_info} key={index}>
                            <img src={ambassador_company_img} alt="" className={ss.recent_active_img} />
                            <div className={ss.recent_active_title2}>{item.title}</div>
                        </div>
                    })}
                </div>
            </div>
        )
    }
}

export default RecentActive