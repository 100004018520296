import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet } from "../../actions/XggsUserInformation"
import "./css/payResult.css"
import pay_select_blue_icon from "../../image/pay_select_blue_icon.png"
import pay_select_red_icon from "../../image/pay_select_red_icon.png"
import pay_perfect_modal_bg from "../../image/pay_perfect_modal_bg.png"
import { Modal } from "antd"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import { Toast } from "antd-mobile"
import close_black_icon from "../../image/close_black_icon.png"
class PayResult extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalVisible: false,
      orderId: "",
      orderObj: null,
      resultIs: false,
      paramObj: {},
    }
  }

  componentDidMount() {
    document.title='支付结果'
    Toast.info("加载中", 1.5)
    this.handleGetPayData()
  }

  // 支付
  handleGetPayData = () => {
    let obj = this.getUrlParams()
    obj.agent_id && sessionStorage.setItem("qd_agent_id", obj.agent_id)
    obj.dlsFromPage && sessionStorage.setItem("dlsFromPage", obj.dlsFromPage)

    if (obj && obj.orderId) {
      util.getYangAxios(
        api.unionPay + `/${obj.orderId}`,
        {},
        async (res) => {
          this.setState({
            orderId: obj.orderId,
            orderObj: res,
            resultIs: true,
          })
          this.handleGetUserData()
        },
        (err) => {
          console.log(err)
        }
      )
      if (obj.type) {
        this.setState({
          paramObj: obj
        })

      }
    } else {
      Toast.info("您的订单无效,请重新支付！", 1.5)
    }
  }

  // 获取用户信息
  handleGetUserData = () => {
    util.postYangAxios(
      api.info,
      {},
      async (res) => {
        this.props.userDataSet(res)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 获取url参数
  getUrlParams = () => {
    let url = window.location.search //?articleId=1&&articleNum=1&opt=edit
    let index = url.indexOf("?")
    let obj = {}
    if (index !== -1) {
      let str = url.substr(1)
      let arr = str.split("&")
      for (let i = 0; i < arr.length; i++) {
        obj[arr[i].split("=")[0]] = arr[i].split("=")[1]
      }
    }
    return obj //{articleId: "1", articleNum: "1", opt: "edit"}
  }

  // 订单文字渲染
  handleOrderTextRender = (title, content) => {
    return (
      <div className={"pay_order_box row_between"}>
        <div>{title}</div>
        <div>{content}</div>
      </div>
    )
  }
  goJionGroup = () => {
    const { paramObj } = this.state
    this.props.history.push("/payNewSuccessJoin?type=" + paramObj.type)
  }

  /* 点击下载按钮 */
downloadApp= ()=> {
  this.props.history.replace('/downLoad')
}

  render() {
    const { isModalVisible, orderId, orderObj, resultIs, paramObj,  } = this.state
    return (
      <div
        className={"pay_result_view"}
        style={{ height: window.innerHeight + "px" }}
      >
        <div className={"row_center title_text"}>
          <div></div>
          <img
            src={close_black_icon}
            alt=""
            className={"close_black_icon"}
            style={{ opacity: 0 }}
          />
        </div>
        <div className={"pay_result_box column_align"}>
          {resultIs ? (
            <>
              <img
                src={
                  orderObj && orderObj.ispay == 1
                    ? pay_select_blue_icon
                    : pay_select_red_icon
                }
                alt=""
                className={"pay_ok_img"}
              />
              <div className={"pay_ok_text"}>
                {orderObj && orderObj.ispay == 1
                  ? "恭喜您，购买成功！"
                  : "支付失败"}
              </div>
            </>
          ) : (
            <div className={"null_box"}></div>
          )}
          <div className={"pay_order_div"}>
            {this.handleOrderTextRender("订单号：", orderId)}
            {this.handleOrderTextRender("支付金额：", paramObj.price)}
            {this.handleOrderTextRender(
              "支付时间：",
              orderObj ? orderObj.pay_at : ""
            )}
          </div>
          {orderObj && orderObj.ispay == 1 && (paramObj.type == 1 || paramObj.type == 3) && (
            <>
              <div className="pay_susscee_jion_bnt" onClick={this.goJionGroup}>立即进群</div>
            </>
          )}
          {orderObj && orderObj.ispay == 1 && paramObj.type == 2 && (
            <>
              <div className="pay_susscee_jion_bnt" onClick={this.downloadApp}>下载app</div>
            </>
          )}
        </div>
       
        <Modal
          visible={isModalVisible}
          footer={null}
          width={304}
          closable={false}
          wrapClassName={"pay_perfect_modal"}
        >
          <img
            src={pay_perfect_modal_bg}
            alt=""
            className={"pay_perfect_modal_bg"}
          />
          <div className={"pay_perfect_modal_text"}>
            请完善您的个人资料，进行实名认证
            <div style={{ color: "#ED3A35" }}>否则您将无法使用以下功能</div>
          </div>
          <div className={"row_between pay_perfect_btn_div"}>
            <div className={"pay_perfect_close_btn row_center"}>取消</div>
            <div className={"pay_perfect_yes_btn row_center"}>确定</div>
          </div>
        </Modal>
      </div>
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
  }
)(PayResult)
