import React, { Component } from "react"
import HeaderView from "../../component/header/headerView"
import pay_select_blue_icon from "../../image/pay_select_blue_icon.png"
import { Steps } from "antd-mobile"
import "./css/withdrawalDetails.css"

const Step = Steps.Step
export default class withdrawalDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      order: null,
      orderDetails: null,
    }
  }

  componentDidMount() {
    console.log(this.props.history.location.state)
    this.setState(
      {
        order: this.props.history.location.state.order,
      },
      () => this.handleGetDetails()
    )
  }

  // 获取详情接口
  handleGetDetails = () => {
    const { order } = this.state
    console.log("order", order)
    /* util.getYangAxios(
            api.purchaseDetails,
            {
                order_sn: order.order_sn
            },
            async (res) => {
                this.setState({
                    orderDetails: res
                })
            },
            (err) => {
                console.log(err);
            },
        ); */
  }

  // 订单文字渲染
  handleOrderTextRender = (title, content) => {
    return (
      <div className={"row_align order_text_div"} style={{ width: "100%" }}>
        <div className={"row_align"}>
          <div className={"order_title_text"}>{title}</div>
          <div className={"order_content_text"}>{content}</div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="withdrawal_details">
        <HeaderView title={"提现详情"} />
        <div className={"details_img"}>
          <img src={pay_select_blue_icon} alt="" />
        </div>
        <div className={"center_container"}>
          <p>提现金额</p>
          <p>
            <span>¥</span>
            <span>16000.00</span>
          </p>
        </div>
        <div className={"money_strip"}>
          {this.handleOrderTextRender("提现单号：", 111)}
          {this.handleOrderTextRender("提现银行：", 111)}
          {this.handleOrderTextRender("转出时间：", 111)}
          {this.handleOrderTextRender("未到账时间：", 11)}
        </div>
        <div className={"purchase_order_flow_card"}>
          {/* <Steps current={orderDetails.purchaseorderprocess.length - 1}>
            {orderDetails.purchaseorderprocess.length > 0 &&
              orderDetails.purchaseorderprocess.map((item, index) => {
                return (
                  <Step
                    title={item.workflowlinks.name}
                    description={item.workflowlinks.tits}
                    key={index}
                  />
                )
              })}
          </Steps> */}
          <Steps current={2}>
            return (
            <Step title={"习惯公社发起申请"} description={""} key={0} />
            <Step
              title={"习惯商务商务审核"}
              description={"已确认并提交"}
              key={1}
            />
            <Step
              title={"习惯负责人审核"}
              description={"订单审核通过并提交"}
              key={2}
            />
            )
          </Steps>
        </div>
      </div>
    )
  }
}
