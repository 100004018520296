import React, { Component } from "react"
import HeaderView from "component/header/headerView"
import { Button} from "antd-mobile"
import { util } from "common/util"
import { api } from "common/Config"
import ss from "./css/bankCard.module.css"
import AddCard from "image/add_card.png"

export default class bankCard extends Component {
  state = {
    bankCardList: [],
  }
  render() {
    const { bankCardList } = this.state
    return (
      <div className={ss.bankCard}>
        <HeaderView title="银行卡" page="/"></HeaderView>

        <div className={ss.allBank_view}>
          {bankCardList.map((item) => {
            return (
              <div>
                {item.bankinfo && item.bankinfo.background_url && (
                  <div
                    key={item.bankAcctNo}
                    className={ss.card_view}
                    style={{
                      background: `url( "${"https://cdn.ellll.com/"}${
                        item.bankinfo.background_url
                        }")`,
                      backgroundSize:'100% 121px'
                    }}
                  >
                    {item.bankinfo && item.bankinfo.logo_url && (
                      <img
                        className={ss.img_logo}
                        src={"https://cdn.ellll.com/" + item.bankinfo.logo_url}
                        alt=""
                        
                      />
                    )}

                    <div>
                      <p className={ss.bankName}>{item.bankName}</p>
                      <p className={ss.card_num}>{item.bankAcctNo}</p>
                    </div>
                  </div>
                )}
              </div>
            )
          })}
        </div>

        <Button
          className={ss.addBank_btn}
          onClick={() =>
            this.props.history.push({
              pathname: "addBankCard",
              state: {
                page: "bankCard",
              },
            })
          }
        >
          <img src={AddCard} className={ss.addCard_img}  alt="" />
          <span> 添加银行卡</span>
        </Button>
      </div>
    )
  }

  componentDidMount() {
    this.init()
  }

  init = () => {
    this.getBankCardList()
  }
  getBankCardList = () => {
    util.getYangAxios(
      api.bankCardListL,
      {},
      async (res) => {
        // console.log(res, "res")
        this.setState({ bankCardList: res })
      },
      (err) => {
        console.error(err)
      }
    )
  }
}
