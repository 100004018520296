import React, { Component } from "react"
import HeaderView from "../../component/header/headerView"
import { ListView } from "antd-mobile"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import ss from "./css/home.module.css"

export default class fiveDetails extends Component {
  constructor(props) {
    super(props)
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
    })
    this.state = {
      id: "",
      dataSource,
      datas: [],
      pageNo: 1,
      pageSize: 2,
      hasMore: true,
      refreshing: true,
      isLoading: true,
      dataArr: [],
      totalPage: 1,
    }
  }
  render() {
    return (
      <div className={ss.fiveDeatile_view}>
        <HeaderView title="战略合伙人信息" page="/"></HeaderView>
        {this.handleMsgRender("战略合伙人信息", 1)}
      </div>
    )
  }

  componentDidMount() {
    this.init()
  }

  init = () => {
    // this.clearData(this.props.location.state.id)
  }

  goBackF = () => {
    this.props.history.replace({
      pathname: "/",
    })
  }

  // 渲染消息
  handleMsgRender = (title, num) => {
    const { stage, count, completeCount } = this.props.location.state
    let row
    if (num == 1) {
      row = (item, sectionID, index) => {
        // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
        return (
          <>
            <div className={"order_card"} key={index}>
              {this.zlLineRow(item.id, "编码：", item.id)}
              {this.zlLineRow(item.id, "姓名：", item.name)}
              {this.zlLineRow(item.id, "联系电话：", item.phone)}
            </div>
            <div className={"order_card_line"} />
          </>
        )
      }
    }
    return (
      <div>
        <div className={ss.zlDetails_top_view}>
          <h4 className={ss.zl_h4_title}>{stage}</h4>
          <div>
            任务人数：{count}人 &nbsp;&nbsp;&nbsp;&nbsp;已完成：{completeCount}
            人
          </div>
        </div>
        <ListView
          contentContainerStyle={{ backgroundColor: "#F6F7FB" }}
          ref={(el) => (this.lv = el)}
          dataSource={this.state.dataSource}
          renderFooter={() => (
            <div className="footer">
              {this.state.isLoading ? "加载中..." : "暂无更多数据"}
            </div>
          )}
          renderRow={row}
          useBodyScroll
          onEndReachedThreshold={10}
          onEndReached={this.onEndReached}
          pageSize={10}
        />
      </div>
    )
  }

  zlLineRow = (id, l, r, isShow = false) => {
    return (
      <div className={`${ss.order_text_div} row_between`}>
        <div className={"row_align"}>
          <div className={ss.order_title_text}>{l}</div>
          <div className={ss.order_content_text}>{r}</div>
        </div>
      </div>
    )
  }

  // 总接口
  handleGetOrder = () => {
    if (this.state.pageNo > this.state.totalPage) {
      this.setState({
        refreshing: false,
        isLoading: false,
        hasMore: false,
      })
      return false
    }
    this.zlDeatiles()
  }

  // 获取详情合伙人
  zlDeatiles() {
    util.getYangAxios(
      api.channelsList,
      {
        currentPage: this.state.pageNo,
        search_data: {
          parent_id: this.state.id,
        },
      },
      async (res) => {
        const dataList = res.list
        this.setState({
          totalPage: res.totalPage,
        })
        let dataArr = this.state.dataArr.concat(dataList)
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr),
          refreshing: false,
          isLoading: false,
          dataArr: dataArr,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  clearData = (id) => {
    this.setState(
      {
        id,
        datas: [],
        pageNo: 1,
        pageSize: 2,
        hasMore: true,
        refreshing: true,
        isLoading: true,
        dataArr: [],
        dataSource: new ListView.DataSource({
          rowHasChanged: (row1, row2) => row1 !== row2,
        }),
        totalPage: 1,
        sValue: "",
      },
      () => {
        this.handleGetOrder()
      }
    )
  }
}
