
import React, { Component } from "react"
import { Icon} from 'antd-mobile';
import ss from "./css/commonTitle.module.css"
import {  withRouter } from 'react-router-dom'

 class CommonTitle extends Component {
    constructor(props) {
        super(props)
        this.state = { }
    }
    goPath=(path,obj)=>{
        this.props.history.push({
            pathname: path,
        });
    }
    render() {
        const {showMoreBtn,children,path,obj}=this.props
        return (
            <div className={ss.common_title_container}>
                <div className={ss.common_title}>{children}</div>
                {showMoreBtn&&<div className={ss.common_action_btn} onClick={()=>this.goPath(path,obj)}>更多 <Icon type="right" size={20} /></div>}
                
            </div>
        )
    }
}

export default withRouter(CommonTitle)