import React, { Component } from "react"
import HeaderView from "component/header/headerView"
import { Tabs } from "antd"
import { ListView, } from "antd-mobile"
import { util, } from "common/util"
import { api } from "common/Config"
import grayRightIcon from "../../image/gray_right_icon.png"
import ss from "./css/cashMoney.module.css"
import "./css/uploadImg.css"

const { TabPane } = Tabs

export default class CashMoney extends Component {
  bagInputRef = React.createRef()
  bankInputRef = React.createRef()
  constructor(props) {
    super(props)
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
    })
    this.state = {
      activeKey: "1",
      dataSource,
      datas: [],
      pageNo: 1,
      pageSize: 2,
      totalPage: 1,
      hasMore: true,
      refreshing: true,
      isLoading: true,
      dataArr: [],
      isMoneyBag: false, //false 去钱包  true 去银行卡
      enterPageType: null,
      enterActive: "1",
      hasMoney: 0,
      djMoney: 0, //冻结金额
    }
  }
  render() {
    return (
      <div id="cashMoney" className={`${"gray_input"} ${ss.cashMoney}`}>
        {this.cashMoneyDom()}
      </div>
    )
  }

  componentDidMount() {
    this.init()
    // new Vconsole()
  }

  init = () => {
    this.handleGetChannel({ mode: 1 })
    this.handleGetCashBag()
    this.getEnterType()
  }
  getEnterType = () => {
    // console.log(this.props, "this.props")
    this.setState({
      enterPageType: this.props.location.state.enterPageType,
    }, () => {
      document.title = this.state.enterPageType == 1 ? "账户余额" : "提现"
    })
  }

  // TAB切换
  changeTabs = (key, TYPE) => {
    // console.log("key", key)
    // console.log("TYPE", TYPE)
    this.setState(
      {
        [TYPE]: key,
        datas: [],
        pageNo: 1,
        pageSize: 2,
        totalPage: 1,
        hasMore: true,
        refreshing: true,
        isLoading: true,
        dataArr: [],
        dataSource: new ListView.DataSource({
          rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
        }),
      },
      () => {
        if (TYPE == "activeKey") {
          this.handleAllApi(key)
        } else {
          this.props.history.push({
            pathname: "financial",
            state: { ...this.props.location.state, enterPageType: 1 },
          })
        }
      }
    )
  }

  handleAllApi = (key) => {
    if (this.state.pageNo > this.state.totalPage) {
      this.setState({
        refreshing: false,
        isLoading: false,
        hasMore: false,
      })
      return false
    }

    if (key == "1") {
      this.handleGetCashBag()
    } else {
      this.handleGetCashBank()
    }
  }

  //获取转出至钱包的列表
  handleGetCashBag(ref = false) {
    let data = {
      currentPage: this.state.pageNo,
    }

    util.getYangAxios(
      api.cashTablelist,
      data,
      async (res) => {
        const dataList = res.list
        this.setState({
          totalPage: res.totalPage,
        })
        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      },
      () => { },
      true
    )
  }

  //获取转出至银行的列表
  handleGetCashBank(ref = false) {
    console.log("获取转出至银行的列表")
    let data = {
      currentPage: this.state.pageNo,
    }
    util.getYangAxios(
      api.cashBanklist,
      data,
      async (res) => {
        const dataList = res.list
        this.setState({
          totalPage: res.totalPage,
        })
        // 这里表示上拉加载更多
        // 合并state中已有的数据和新增的数据
        var dataArr = this.state.dataArr.concat(dataList) //关键代码
        this.setState({
          pageNo: this.state.pageNo,
          dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
          refreshing: false,
          isLoading: false,
          dataArr: dataArr, // 保存新数据进state
        })
      },
      (err) => {
        console.log(err)
      },
      () => { },
      true
    )
  }

  // 滑动到底部时加载更多
  onEndReached = (event) => {
    // 加载中或没有数据了都不再加载
    if (this.state.isLoading || !this.state.hasMore) {
      return
    }
    this.setState(
      {
        isLoading: true,
        pageNo: this.state.pageNo + 1, // 加载下一页
      },
      () => {
        this.handleAllApi(this.state.activeKey)
      }
    )
  }

  // 跳转到订单详情
  handleSkipDetails = (obj, num) => {
    this.props.history.push({
      pathname: "/cashDetails",
      state: {
        order: obj.id,
      },
    })
  }

  // 商家账户统计
  handleGetChannel = (data) => {
    util.getYangAxios(
      api.agentsIncome,
      data,
      async (res) => {
        this.setState({
          hasMoney: res.surplus,
          djMoney: 0,
        })
      },
      (err) => {
        console.log(err)
      },
      () => { },
      true
    )
  }

  // 商家账户统计
  // handleGetChannel = (data) => {
  //   util.getYangAxios(
  //     api.accountstatis,
  //     data,
  //     async (res) => {
  //       this.setState({
  //         hasMoney: res.agent.balance,
  //         //djMoney: res.agent.freeze_balance,
  //         djMoney: 0,
  //       })
  //     },
  //     (err) => {
  //       console.log(err)
  //     },
  //     () => {},
  //     true
  //   )
  // }

  cashMoneyDom = () => {
    const { hasMoney } = this.state ?? "0.00"
    const { djMoney } = this.state ?? "0.00"
    const { enterPageType } = this.state
    return (
      <>
        <div className={ss.posiNull}>
          {/* <HeaderView
            title={enterPageType == 1 ? "账户余额" : "提现"}
            page="/"
          ></HeaderView> */}
          <HeaderView
            title={''}
            page="/"
          ></HeaderView>
          {enterPageType == 1 && this.topTabDom()}
          {this.twoLineDom("账户余额", Number(hasMoney) + Number(djMoney) == 0 ? "0.00" : Number(hasMoney) + Number(djMoney), "提现", 1)}
          {this.cashListDom()}
        </div>
      </>
    )
  }

  topTabDom = () => {
    const { enterActive } = this.state
    return (
      <>
        <div className={ss.topTab_view}>
          <Tabs
            centered
            className={ss.topTab}
            activeKey={enterActive}
            onChange={(key) => {
              this.changeTabs(key, "enterActive")
            }}
            tabBarStyle={{ paddingLeft: 16, marginBottom: 0 }}
          >
            <TabPane tab="提现" key="1"></TabPane>
            <TabPane tab="财务流水" key="2"></TabPane>
          </Tabs>
        </div>
      </>
    )
  }

  twoLineDom = (l, m, r, type) => {
    return (
      <>
        <div className={`${ss.line_row_view} ${ss.bottom_border}`}>
          <span className={ss.line_row_item}>{l}</span>
          <span className={ss.line_row_item}>¥ {Number(m).toFixed(2)}</span>
          <span className={`${ss.line_row_item} ${ss.font_right}`}>
            <i
              className={ss.cash_btn_style}
              onClick={this.toBag.bind(this, type)}
            >
              {r}
            </i>
          </span>
        </div>
      </>
    )
  }

  toBag = (type) => {
    let isMoneyBag = null
    type == 1 ? (isMoneyBag = false) : (isMoneyBag = true)

    this.props.history.push({
      pathname: "cashMoney",
      state: {
        ...this.props.location.state,
        isMoneyBag,
      },
    })
  }

  cashListDom = () => {
    const { activeKey } = this.state
    return (
      <>
        <div className={`${ss.line_row_view} ${ss.bottom_border}`}>
          <span className={ss.line_row_item}>历史记录</span>
        </div>

        <div className={`${ss.list_view}`}>
          <Tabs
            activeKey={activeKey}
            onChange={(key) => {
              this.changeTabs(key, "activeKey")
            }}
            tabBarStyle={{ paddingLeft: 16, marginBottom: 0 }}
          >
            <TabPane tab="提现" key="1">
              {this.handleDataRender()}
            </TabPane>
            <TabPane tab="银联钱包" key="2">
              {this.handleDataRender()}
            </TabPane>
          </Tabs>
        </div>
      </>
    )
  }

  // 订单文字渲染
  handleOrderTextRender = (title, content, isLook = false) => {
    return (
      <div className={"row_align order_text_div"} style={{ width: "100%" }}>
        <div className={"row_align"}>
          <div className={"order_title_text"}>{title}</div>
          <div className={"order_content_text"}>{content}</div>
        </div>
        {isLook && (
          <div className={"order_look_text row_end"}>
            查看
            <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
          </div>
        )}
      </div>
    )
  }

  // 渲染消息
  handleDataRender = (title, num) => {
    const { isLoading, dataSource, activeKey } = this.state
    let row = ""
    row = (item, sectionID, index) => {
      return (
        <>
          {activeKey == 1 && (
            <>
              <div className={"order_card"} key={index}>
                <div>
                  {/* 
                    10 审核中 
                    20 审核失败
                    30 待提现
                    40 成功
                      */}
                  {item.status == 40 ? (
                    <span className={ss.red_font}>提现到账成功</span>
                  ) : item.status == 20 ? (
                    <span className={ss.green_font}>提现到账失败</span>
                  ) : (
                    <span className={ss.yellow_font}>提现审核中</span>
                  )}
                </div>
                <div className={"cashTop_view"}>
                  <div className={"cash_title"}>提现金额</div>
                  <div className={"cash_number"}>¥ {item.money}</div>
                </div>
                <div className={"cashMiddle_view"}>
                  {this.handleOrderTextRender("提现至", item.id > 802 ? "银行卡" : "银联钱包")}
                  {this.handleOrderTextRender("提现单号", item.order_sn)}
                  {this.handleOrderTextRender("服务费", item.all_service_money)}
                  {item.tax_money &&
                    Number(item.tax_money) > 0 &&
                    this.handleOrderTextRender("所得税", item.tax_money)}
                  {this.handleOrderTextRender("实际到账", item.real_money)}
                  {this.handleOrderTextRender("提现时间", item.created_at)}
                </div>
                <div
                  className={"cashBottom_view row_between"}
                  onClick={() => this.handleSkipDetails(item, num)}
                >
                  <span>查看</span>
                  <img
                    src={grayRightIcon}
                    alt=""
                    className={"gray_right_icon"}
                  />
                </div>
              </div>
              <div className={"order_card_line"} />
            </>
          )}

          {activeKey == 2 && (
            <>
              <div className={"order_card"} key={index}>
                <div>
                  <span className={ss.red_font}>转出到账成功</span>
                </div>
                <div className={"cashTop_view"}>
                  <div className={"cash_title"}>转出金额</div>
                  <div className={"cash_number"}>¥ {item.money}</div>
                </div>
                <div className={"bankMiddle_view"}>
                  {this.handleOrderTextRender("转出至", item.bankAcc_name)}
                  {this.handleOrderTextRender("转出卡号", item.bankAcct_no)}
                  {this.handleOrderTextRender(
                    "转出单号",
                    item.exterior_trade_no
                  )}
                  {this.handleOrderTextRender("转出时间", item.created_at)}
                </div>
              </div>
              <div className={"order_card_line"} />
            </>
          )}
        </>
      )
    }
    return (
      <ListView
        contentContainerStyle={{ backgroundColor: "#F6F7FB" }}
        ref={(el) => (this.lv = el)}
        dataSource={dataSource}
        renderFooter={() => (
          <div className="footer">
            {isLoading ? "加载中..." : "暂无更多数据"}
          </div>
        )}
        renderRow={row}
        useBodyScroll
        onEndReachedThreshold={10}
        onEndReached={this.onEndReached}
        pageSize={10}
      />
    )
  }
}
