import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet, tabSetIndex } from "../../actions/XggsUserInformation"
import { Accordion, List } from "antd-mobile"
import "page/home/css/home.css"
import dingdan from "image/dingdan.png"
import caiwu from "image/caiwu.png"
import qudao from "image/qudao.png"
import yonghu from "image/yonghu.png"
import shangwu from "image/shangwu.png"
import huiyuan from "image/huiyuan.png"

/* 手风琴 */
class Accord extends Component {
  render() {
    const { userData } = this.props.UserDataReducer
    if (userData && userData.role) {
      /* 商务 */
      if (userData.role == 4 || userData.role == 6) {
        return (
          <Accordion className="my-accordion" onChange={this.onChange}>
            <Accordion.Panel
              header={
                <span className={"panelLine"}>
                  <img src={shangwu} className={"panelImg"} alt="" />
                  商务
                </span>
              }
              className="pad"
            >
              <List className="my-list">
                <List.Item
                  onClick={() =>
                    this.handleSkip("order", "1", "business", "商务")
                  }
                >
                  销售
                </List.Item>
                <List.Item
                  onClick={() =>
                    this.handleSkip("order", "2", "business", "商务")
                  }
                >
                  采购
                </List.Item>
                <List.Item
                  onClick={() =>
                    this.handleSkip("order", "5", "business", "商务")
                  }
                >
                  客户
                </List.Item>
                {/* 提现注释 */}
                <List.Item
                  onClick={() =>
                    this.handleSkip("order", "6", "business", "商务")
                  }
                >
                  提现
                </List.Item>
              </List>
            </Accordion.Panel>
            <Accordion.Panel
              header={
                <span className={"panelLine"}>
                  <img src={huiyuan} className={"panelImg"} alt="" />
                  会员
                </span>
              }
              className="pad"
            >
              <List className="my-list">
                <List.Item
                  onClick={() => this.handleSkip("member", "1", "member")}
                >
                  会员
                </List.Item>
              </List>
            </Accordion.Panel>
          </Accordion>
        )
      }
      /* 财务 */
      if (userData.role == 5 || userData.role == 7) {
        return (
          <Accordion className="my-accordion" onChange={this.onChange}>
            <Accordion.Panel
              header={
                <span className={"panelLine"}>
                  <img src={caiwu} className={"panelImg"} alt="" />
                  财务
                </span>
              }
              className="pad"
            >
              <List className="my-list">
                <List.Item
                  onClick={() =>
                    this.handleSkip("financial", "1", "financial", "财务")
                  }
                >
                  销售
                </List.Item>
                <List.Item
                  onClick={() =>
                    this.handleSkip("financial", "2", "financial", "财务")
                  }
                >
                  采购
                </List.Item>
                {/*<List.Item onClick={()=>this.handleSkip("financial","2")}>收付款</List.Item>*/}
                <List.Item
                  onClick={() =>
                    this.handleSkip("financial", "3", "financial", "财务")
                  }
                >
                  财务流水
                </List.Item>
                {/* 提现注释 */}
                <List.Item
                  onClick={() =>
                    this.handleSkip("financial", "6", "financial", "财务")
                  }
                >
                  提现
                </List.Item>
              </List>
            </Accordion.Panel>
          </Accordion>
        )
      }
    }
    return (
      <Accordion className="my-accordion" onChange={this.onChange}>
        <Accordion.Panel
          header={
            <span className={"panelLine"}>
              <img src={dingdan} className={"panelImg"} alt="" />
              订单
            </span>
          }
        >
          <List className="my-list">
            <List.Item
              onClick={() => this.handleSkip("order", "1", "order", "订单")}
            >
              销售
            </List.Item>
            <List.Item
              onClick={() => this.handleSkip("order", "2", "order", "订单")}
            >
              采购
            </List.Item>
            <List.Item
              onClick={() => this.handleSkip("order", "3", "order", "订单")}
            >
              库存
            </List.Item>
            <List.Item
              onClick={() => this.handleSkip("order", "4", "order", "订单")}
            >
              学校码
            </List.Item>
            {/* 提现注释 */}
            <List.Item
              onClick={() => this.handleSkip("order", "6", "order", "订单")}
            >
              提现
            </List.Item>
          </List>
        </Accordion.Panel>
        <Accordion.Panel
          header={
            <span className={"panelLine"}>
              <img src={qudao} className={"panelImg"} alt="" />
              渠道
            </span>
          }
          className="pad"
        >
          <List className="my-list">
            <List.Item
              onClick={() => this.handleSkip("channel", "1", "channel", "渠道")}
            >
              商家
            </List.Item>
            <List.Item
              onClick={() => this.handleSkip("channel", "2", "channel", "渠道")}
            >
              客户
            </List.Item>
          </List>
        </Accordion.Panel>
        <Accordion.Panel
          header={
            <span className={"panelLine"}>
              <img src={huiyuan} className={"panelImg"} alt="" />
              会员
            </span>
          }
          className="pad"
        >
          <List className="my-list">
            <List.Item onClick={() => this.handleSkip("member", "1", "member")}>
              会员
            </List.Item>
          </List>
        </Accordion.Panel>
        <Accordion.Panel
          header={
            <span className={"panelLine"}>
              <img src={yonghu} className={"panelImg"} alt="" />
              用户
            </span>
          }
          className="pad"
        >
          <List className="my-list">
            <List.Item
              onClick={() => this.handleSkip("consumer", "1", "consumer")}
            >
              用户
            </List.Item>
          </List>
        </Accordion.Panel>
        <Accordion.Panel
          header={
            <span className={"panelLine"}>
              <img src={shangwu} className={"panelImg"} alt="" />
              商务
            </span>
          }
          className="pad"
        >
          <List className="my-list">
            <List.Item
              onClick={() => this.handleSkip("order", "1", "business", "商务")}
            >
              销售
            </List.Item>
            <List.Item
              onClick={() => this.handleSkip("order", "2", "business", "商务")}
            >
              采购
            </List.Item>
            <List.Item
              onClick={() => this.handleSkip("order", "5", "business", "商务")}
            >
              客户
            </List.Item>
            {/* 提现注释 */}
            <List.Item
              onClick={() => this.handleSkip("order", "6", "order", "商务")}
            >
              提现
            </List.Item>
          </List>
        </Accordion.Panel>
        <Accordion.Panel
          header={
            <span className={"panelLine"}>
              <img src={caiwu} className={"panelImg"} alt="" />
              财务
            </span>
          }
          className="pad"
        >
          <List className="my-list">
            <List.Item
              onClick={() =>
                this.handleSkip("financial", "1", "financial", "财务")
              }
            >
              销售
            </List.Item>
            <List.Item
              onClick={() =>
                this.handleSkip("financial", "2", "financial", "财务")
              }
            >
              采购
            </List.Item>
            {/*<List.Item onClick={()=>this.handleSkip("financial","2")}>收付款</List.Item>*/}
            <List.Item
              onClick={() =>
                this.handleSkip("financial", "3", "financial", "财务")
              }
            >
              财务流水
            </List.Item>
            {/* 提现注释 */}
            <List.Item
              onClick={() =>
                this.handleSkip("financial", "6", "financial", "财务")
              }
            >
              提现
            </List.Item>
          </List>
        </Accordion.Panel>
      </Accordion>
    )
  }

  componentDidMount() {
    this.init()
  }

  init = () => {
    // console.log(this.props, "-------------")
  }

  // 手风情菜单跳转
  handleSkip = (path, key, sourcePage, HeaderTitle) => {
    this.props.history.push({
      pathname: `/${path}`,
      state: {
        key: key,
        sourcePage,
        HeaderTitle,
      },
    })
  }
}

export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
    tabSetIndex,
  }
)(Accord)
