
import React, { Component } from "react"
import CommonTitle from "./CommonTitle"
import ss from "./css/incomeList.module.css"
import { Icon } from 'antd-mobile';
import { util } from "../../../common/util"
import { api } from "../../../common/Config"
import { ListView } from "antd-mobile"
import { parseTime } from '../../../utils/methods';

function MyBody(props) {
    return (
      <div className="am-list-body my-body">
        <span style={{ display: 'none' }}>you can custom body wrap element</span>
        {props.children}
      </div>
    );
  }
class IncomeList extends Component {
    constructor(props) {
        super(props)
        const dataSource = new ListView.DataSource({
            rowHasChanged: (row1, row2) => row1 !== row2, // rowHasChanged(prevRowData, nextRowData); 用其进行数据变更的比较
        })
        this.state = {
            dataSource,
            pageNo: 1,
            pageSize: 2,
            hasMore: true,
            refreshing: true,
            isLoading: true,
            dataArr: [],
            totalPage: 1,
            currentTime:"",
            timeList:[]
        }
    }
    componentDidMount() {
        this.handleGetOrder()
    }

    // 总接口
    handleGetOrder = () => {
        if (this.state.pageNo > this.state.totalPage) {
            this.setState({
                refreshing: false,
                isLoading: false,
                hasMore: false,
            })
            return false
        }
        this.handleGetInventoryOrder() //库存
    }
    // 获取库存订单
    handleGetInventoryOrder(ref = false) {
        util.getYangAxios(
            api.agentsRevenueList,
            {
                currentPage: this.state.pageNo,
                type: 1,
                pagesize: 15
            },
            async (res) => {
                const format = '{y}年{m}月'
                let resultArr = []
                //第一项头存不存在，不存在就先push一个
                const firstTime = parseTime(res.list.length > 0 ? res.list[0].created_at : '', format)
                if (this.state.currentTime !== firstTime) {
                    if(res.list.length > 0){
                        resultArr.push({ currentTime: firstTime, type: 2,down:true,formatTime: firstTime})
                        this.setState({
                            timeList:[{ currentTime: firstTime, type: 2,down:true,formatTime: firstTime}]
                        })
                    }
                    this.setState({
                        currentTime: firstTime
                    })
                }
                for (const item of res.list) {
                    item.formatTime = parseTime(item.created_at, format)
                    if (item.formatTime === this.state.currentTime) {
                        // resultArr.push({ ...item, type: 1, showItem: true })
                    } else {
                        this.setState({
                            currentTime: parseTime(item.created_at, format)
                        })
                        resultArr.push({ currentTime: this.state.currentTime, type: 2 ,down:true,formatTime: this.state.currentTime})
                        // resultArr.push({ ...item, type: 1, showItem: true })
                        this.setState({
                            timeList:this.state.timeList.concat([{currentTime: this.state.currentTime, type: 2 ,down:true,formatTime: this.state.currentTime}])
                        })
                       
                    }
                     this.state.timeList.map((arrItem, idex) => {
                        if (arrItem.currentTime === item.formatTime&&arrItem.down===false) {
                            item.showItem =false
                            return arrItem
                        } else {
                            item.showItem =true
                            return arrItem
                        }
                    })
                    resultArr.push({ ...item, type: 1, })
                }
                const dataList = resultArr
                this.setState({
                    totalPage: res.totalPage,
                })
                // 这里表示上拉加载更多
                // 合并state中已有的数据和新增的数据
                var dataArr = this.state.dataArr.concat(dataList) //关键代码
                this.setState({
                    pageNo: this.state.pageNo,
                    dataSource: this.state.dataSource.cloneWithRows(dataArr), // 数据源中的数据本身是不可修改的,要更新datasource中的数据，请（每次都重新）调用cloneWithRows方法
                    refreshing: false,
                    isLoading: false,
                    dataArr: dataArr, // 保存新数据进state
                })
            },
            (err) => {
                console.log(err)
            }
        )
    }
    handShow = (info) => {
        const { dataArr} = this.state
        let list = dataArr.map((item, idex) => {
            if (item.formatTime === info.currentTime) {
                item.showItem = !item.showItem
                item.down = !item.down
                return item
            } else {
                return item
            }
        })
        this.setState({
            dataSource: this.state.dataSource.cloneWithRows(JSON.parse(JSON.stringify(list))),
            dataArr: list
        })
      let arr= this.state.timeList.map((arrItem, idex) => {
            if (info.currentTime === arrItem.currentTime) {
                arrItem.down = !arrItem.down
                return arrItem
            } else {
                return arrItem
            }
        })
        this.setState({
            timeList: arr
        })
       
    }

    // 渲染消息
    handleMsgRender = (title, num) => {
        let row
        row = (infos, sectionID, index) => {
            // 这里rowData,就是上面方法cloneWithRows的数组遍历的单条数据了，直接用就行
            return (
                <>
                    <div className={ss.income_info_item} key={index}>
                        {infos.type === 2 && (<div className={ss.income_year_month} onClick={() => this.handShow(infos)}>
                            {infos.currentTime}  {infos.down&&(<Icon type="down" size={9} />)} {infos.down===false&&(<Icon type="up" size={9} />)}
                        </div>)}
                        {infos.type === 1 && infos.showItem === true && (<div className={ss.income_info_details} key={index}>
                            <div className={ss.infos_line}>
                                <div className={ss.info_des}>{infos.source}{infos.status === -1 && (<span>退货</span>)}</div>
                                <div className={[`${ss.info_money}`, `${infos.status !== -1 ? ss.icome_color_red : ''}`].join(' ')}>{infos.status !== -1 ? '+' : '-'}{infos.balance}</div>
                            </div>
                            <div className={ss.infos_line}>
                                <div className={ss.info_time}>{infos.created_at}</div>
                                {/* <div className={ss.info_time}>余额：{infos.balance}</div> */}
                            </div>
                        </div>)}
                    </div>
                </>
            )
        }
        return (
            <div>
                <ListView
                    contentContainerStyle={{ backgroundColor: "#F6F7FB" }}
                    ref={(el) => (this.lv = el)}
                    dataSource={this.state.dataSource}
                    renderFooter={() => (
                        <div className="footer">
                            {this.state.isLoading ? "加载中..." : "暂无更多数据"}
                        </div>
                    )}
                    renderRow={row}
                    style={{
                        height:window.innerHeight/2+'px',
                        overflow: 'auto',
                      }}
                      renderSectionBodyWrapper={() => <MyBody />}
                    // useBodyScroll
                    onEndReachedThreshold={30}
                    onEndReached={this.onEndReached}
                    pageSize={15}
                    initialListSize={0}
                />
            </div>
        )
    }
    
    // 滑动到底部时加载更多
    onEndReached = (event) => {
        // 加载中或没有数据了都不再加载
        if (this.state.isLoading || !this.state.hasMore) {
            return
        }
        this.setState(
            {
                isLoading: true,
                pageNo: this.state.pageNo + 1, // 加载下一页
            },
            () => {
                this.handleGetOrder()
            }
        )
    }
    render() {
        return (
            <div className={ss.income_info_container}>
                <CommonTitle><div className={ss.income_title_container}>收益明细（全部）</div></CommonTitle>
                {this.handleMsgRender()}
            </div>
            
        )
    }
}

export default IncomeList