import React, { Component } from "react"
import ss from "./css/poster.module.css"
import "./css/poster.css"
import { Modal } from "antd"
import { util } from "../../common/util"
import { api } from "../../common/Config"

import Vconsole from "common/Vconsole"

/*  */
export default class poster extends Component {
  state = {
    info: null,
    isModalVisible: false,
  }
  render() {
    const { info, isModalVisible } = this.state
    return (
      <div className={ss.posterpage_view}>
        <div className={ss.goback_view} onClick={this.goBack}>
          返回
        </div>
        {info && info.path && (
          <div
            className={ss.back_poster}
            style={{
              background: `url( "${"https://cdn.ellll.com/"}${info.path}")`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className={ss.lookSeed} onClick={this.toLookSeed}>
              观看直播
            </div>
            <div className={ss.makeImg} onClick={this.makeImg}>
              生成分享海报
            </div>
          </div>
        )}

        <Modal
          visible={isModalVisible}
          footer={null}
          width={304}
          closable={false}
          maskClosable
          wrapClassName={ss.pay_perfect_modal}
        >
          <div>确认要返回商家号服务端吗？</div>
          <div className={`row_between ${ss.pay_perfect_btn_div}`}>
            <div
              className={`row_center ${ss.pay_perfect_close_btn}`}
              onClick={() => this.setState({ isModalVisible: false })}
            >
              取消
            </div>
            <div
              className={`row_center ${ss.pay_perfect_yes_btn}`}
              onClick={() => {
                this.props.history.push("/")
              }}
            >
              确定
            </div>
          </div>
        </Modal>
      </div>
    )
  }

  componentDidMount() {
    new Vconsole()
    console.log(window.location.href, "poster页面的地址")
    let obj = this.getUrlParams()
    console.log(obj, "obj--扫码进来的参数")
    console.log(this.props, "--this.props")
    let posterToken = localStorage.getItem("posterToken")
    if (posterToken == "" || posterToken == undefined || posterToken == null) {
      this.props.history.push({
        pathname: "/posterLogin",
        state: { obj },
      })
    } else {
      let objV = this.props.location.state ? this.props.location.state.obj : obj
      this.setState({
        obj: objV,
      })

      this.getPosterDetils(objV.id)
    }
  }

  getPosterDetils = (id) => {
    util.getYangAxios(
      api.posterDetails + "/" + id,
      {
        id,
      },
      async (res) => {
        console.log("res-宋海报详情", res)

        if (res) {
          this.setState({
            info: res,
          })
        }
      },
      (err) => {
        console.log(err)
      }
    )
  }

  getUrlParams = () => {
    let url = window.location.search //?articleId=1&&articleNum=1&opt=edit
    let index = url.indexOf("?")
    let obj = {}
    if (index !== -1) {
      let str = url.substr(1)
      let arr = str.split("&")
      for (let i = 0; i < arr.length; i++) {
        obj[arr[i].split("=")[0]] = arr[i].split("=")[1]
      }
    }
    return obj //{articleId: "1", articleNum: "1", opt: "edit"}
  }

  /* 看直播 */
  toLookSeed = () => {
    window.location.href = this.state.info.live_url
  }

  goBack = () => {
    //去首页
    this.setState({
      isModalVisible: true,
    })
  }

  /* 生成 */
  makeImg = () => {
    this.props.history.push({
      pathname: "shareBill",
      state: {
        info: this.state.info,
      },
    })
  }
}
