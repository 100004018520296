import React, { Component } from "react"
import HeaderView from "../../component/header/headerView"
import { Image } from "antd"
import "./css/profit.css"
import profitBanner from "../../image/profit_banner.png"
import profitContent1 from "../../image/profitContent1.png"
import profitContent2 from "../../image/profitContent2.png"
import profitContent3 from "../../image/profitContent3.png"
import profitContent4 from "../../image/profitContent4.png"
export default class profit extends Component {
  render() {
    return (
      <div className={"profit"}>
        <HeaderView title={"合伙人收益机制"} />
        {/* <h2 className={'profit_title'}>代理人收益机制</h2> */}{" "}
        <Image
          width={"100%"}
          src={profitBanner}
          preview={false}
          placeholder={
            <Image
              preview={false}
              src={profitBanner}
              width={"100%"}
            />
          }
        />
        <Image
          width={"100%"}
          src={profitContent1}
          preview={false}
          placeholder={
            <Image
              preview={false}
              src={profitContent1}
              width={"100%"}
            />
          }
        />
        <Image
          width={"100%"}
          src={profitContent2}
          preview={false}
          placeholder={
            <Image
              preview={false}
              src={profitContent2}
              width={"100%"}
            />
          }
        />
        <Image
          width={"100%"}
          src={profitContent3}
          preview={false}
          placeholder={
            <Image
              preview={false}
              src={profitContent3}
              width={"100%"}
            />
          }
        />
         <Image
          width={"100%"}
          src={profitContent4}
          preview={false}
          placeholder={
            <Image
              preview={false}
              src={profitContent4}
              width={"100%"}
            />
          }
        />
      </div>
    )
  }
}
