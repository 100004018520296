import React, { Component } from "react"
import HeaderView from "../../component/header/headerView"
import "./css/dlsCourse.css"

/* 运营中心授权 */
export default class warrant extends Component {
  render() {
    return (
      <div className={"warrant_view"}>
        <HeaderView title="运营中心授权" page="dlsCourse"></HeaderView>
        <div>
          习惯公社推广协议（以下简称“本协议”）由以下双方根据《中华人民共和国合同法》等相关法律法规签署并遵照执行。
          <br />
          平台方上海形者网络科技发展有限公司以下称“习惯公社” <br /> <br />
          1. 推广大使主体即可以是个人，也可以公司。其中个人
          须上传并验证个人身份证信息，公司须上传公司营业 执照和法人信息。 <br />
          2. 推广大使主体所有信息必须真实有效，如因为虚假
          信息导致的损失有推广大使承担。 <br />
          3. 推广大使在平台缴纳相关费用后即可开展相关务。 <br />
          4. 推广大使拥有唯一账户信息（注册手机号），习惯
          公社应保证其信息隐私安全，但由于推广大使个人
          原因导致的信息外露造成的损失有推广大使承担。 <br /> 5.
          推广大使有权使用指定名称在习惯公社授权期限内 进行推广、服务会员。{" "}
          <br /> 6. 推广大使有权发展下级推广大使，并享受各级别的
          招商奖金。推广大使也可以协助习惯公社拓展其他
          业务线，并按标准获得相应业务奖金。
        </div>
        <div>
          <div className={"enclosure_view"}>附件</div>
        </div>
      </div>
    )
  }
}
