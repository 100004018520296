import React, { Component } from "react"
import { connect } from "react-redux"
import { userDataSet } from "../../actions/XggsUserInformation"
import "./css/personalData.css"
import userImg from "../../image/user_img.png"
import BusinessLicense from "../../image/business_license_img.png"
import HeaderView from "../../component/header/headerView"
import grayRightIcon from "../../image/gray_right_icon.png"
import { util } from "../../common/util"
import { api } from "../../common/Config"
import { Upload, } from "antd"
import { Toast } from "antd-mobile"
class PersonalData extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mailboxValue: "",
      wxValue: "",
      qiToken: "",
      imgUrl: "",
      attestation: false,
      roleName: "",
      roleType: null,
    }
  }

  componentDidMount() {
    this.handleGetData()
    this.handleGetQiNiu()
  }

  // 获取合伙人信息
  handleGetData = () => {
    util.getYangAxios(
      api.infoDetails,
      {},
      async (res) => {
        this.setState({
          mailboxValue: res.email ? res.email : "",
          wxValue: res.wechat_code ? res.wechat_code : "",
          imgUrl: res.head_portrait ? res.head_portrait : "", //head_portrait
          attestation: res.is_realname,
          roleName: res.name ? res.name : "",
          roleType: res.type ? res.type : 1, // 1代理商  2合伙人
          idPhoto: res.id_photo ? res.id_photo : "",
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // token
  handleGetQiNiu = () => {
    util.postYangAxios(
      api.qiNiuToken,
      {},
      async (res) => {
        this.setState({
          qiToken: res.token,
        })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  onMailboxChange = (event) => {
    this.setState({
      mailboxValue: event.target.value,
    })
  }

  onWxChange = (event) => {
    this.setState({
      wxValue: event.target.value,
    })
  }

  /**
   * 习惯公社上传图片随机名 xggs_********
   */
  randomImageNum = () => {
    let r = Math.floor(Math.random() * 1000000000)
    let f = Math.floor(Math.random() * 1000000000)
    let d = Date.parse(new Date())
    // console.log(`xg_GS${d}${r}${f}`)
    return `xg_GS${d}${r}${f}`
  }

  // 提交修改
  handleSubmit = () => {
    const { mailboxValue, wxValue, imgUrl } = this.state
    if (!imgUrl) {
      Toast.info("请上传头像", 1.5)
      return null
    }
    // if(!mailboxValue){
    //     Toast.info('请填写邮箱',1.5)
    //     return null;
    // }if(!wxValue){
    //     Toast.info('请填写微信号',1.5)
    //     return null;
    // }
    util.postYangAxios(
      api.personal,
      {
        head_portrait: imgUrl,
        email: mailboxValue,
        wechat_code: wxValue,
      },
      async (res) => {
        Toast.success("资料保存成功", 2, () => {
          this.props.history.goBack()
        })
        // this.setState({
        //     qiToken: res.token
        // })
      },
      (err) => {
        console.log(err)
      }
    )
  }

  // 跳转认证
  handleAttestation = () => {
    const { attestation } = this.state
    // this.props.history.push('authentication')
    if (!attestation) {
      this.props.history.push("authentication")
    }
  }

  render() {
    const {
      mailboxValue,
      wxValue,
      qiToken,
      imgUrl,
      attestation,
      roleName,
      roleType,
      idPhoto,
    } = this.state
    return (
      roleType && (
        <div
          style={{
            position: "relative",
            background: "#fff",
            height: window.innerHeight + "px",
          }}
        >
          <HeaderView title={"个人资料"} />
          <div className={"gray_line"} />
          {roleType && roleType == 1 ? (
            <div className={"row_between user_box"}>
              <div>企业logo</div>
              <img
                src={imgUrl ? "https://cdn.ellll.com/" + imgUrl : userImg}
                alt=""
                className={"user_img"}
              />
            </div>
          ) : (
            <div
              className={"row_between user_box"}
              onClick={() => this.props.history.push("personalData")}
            >
              <div>头像</div>
              <Upload
                action={api.uploadUrl}
                // listType="picture-card"
                // fileList={coverList}
                // onPreview={this.handlePreview}
                accept="image/png, image/jpeg"
                onChange={({ fileList }) => {
                  console.log(fileList)
                  Toast.loading("上传中", 0)
                  if (fileList[0].response) {
                    this.setState(
                      {
                        imgUrl: `${fileList[0].response.key}`,
                      },
                      () => {
                        Toast.hide()
                        Toast.info("上传成功！", 1.5)
                      }
                    )
                  }
                }}
                maxCount={1}
                showUploadList={false}
                data={{ token: qiToken, key: this.randomImageNum() }}
              >
                <img
                  src={imgUrl ? "https://cdn.ellll.com/" + imgUrl : userImg}
                  alt=""
                  className={"user_img"}
                />
              </Upload>
            </div>
          )}

          {roleType && roleType != 1 ? (
            <div
              className={"row_between user_box"}
              onClick={this.handleAttestation}
            >
              <div>实名认证</div>
              <div className={"row_align user_gary"}>
                {attestation ? "已认证" : "未认证"}
                <img src={grayRightIcon} alt="" className={"gray_right_icon"} />
              </div>
            </div>
          ) : (
            ""
          )}

          {roleType && roleType == 1 ? (
            <>
              <div className={"row_between user_box"}>
                <div>营业执照</div>
                <img
                  src={
                    idPhoto
                      ? "https://cdn.ellll.com/" + idPhoto
                      : BusinessLicense
                  }
                  alt=""
                  className={"Business_license"}
                />
              </div>
            </>
          ) : (
            ""
          )}

          <div
            className={"row_between user_box"}
            onClick={() => this.props.history.push("personalData")}
          >
            <div> {roleType == 1 ? "企业名称" : "合伙人名称"} </div>
            <div>{roleName && roleName ? roleName : ""}</div>
          </div>

          <div
            className={"row_between user_box"}
            onClick={() => this.props.history.push("personalData")}
          >
            <div>邮箱</div>
            {roleType && roleType == 1 ? (
              <div>{mailboxValue && mailboxValue ? mailboxValue : ""}</div>
            ) : (
              <input
                type="text"
                value={mailboxValue}
                onChange={this.onMailboxChange}
                className={"personal_input_text"}
                placeholder={"请输入邮箱"}
              />
            )}
          </div>
          <div
            className={"row_between user_box"}
            onClick={() => this.props.history.push("personalData")}
          >
            <div>微信号</div>
            {roleType && roleType == 1 ? (
              <div>{wxValue && wxValue ? wxValue : ""}</div>
            ) : (
              <input
                type="text"
                value={wxValue}
                onChange={this.onWxChange}
                className={"personal_input_text"}
                placeholder={"请输入微信号"}
              />
            )}
          </div>
          {roleType && roleType == 1 ? (
            ""
          ) : (
            <div className={"row_center btnPadding_top"}>
              <div
                className={"row_center save_btn_blue"}
                onClick={this.handleSubmit}
              >
                保存
              </div>
            </div>
          )}
        </div>
      )
    )
  }
}

// connect 连接 Recucer ，我ShiTu.js的Reducer叫ShiTuReducer，
// userToken等方法是我在action里面创建的，所以调用的也就是action方法
export default connect(
  (state) => {
    const { UserDataReducer } = state
    return {
      UserDataReducer,
    }
  },
  {
    userDataSet,
  }
)(PersonalData)
